<template>
  <div>
    <span dark text class="v-list-item--link" @click="openItem(id)">
      <slot></slot>
    </span>
    <v-dialog v-model="dialog" scrollable persistent width="600">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>发行说明</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          开发团队将持续跟进和处理用户反馈的需求与问题。并对代码进行修正，定期发布新的版本。在此将罗列所有更新记录，以便对需求完成情况进行回顾与确认。此外，在主要版本之间，可能会有许多突破性的更改，您可以遵循下面最新的迁移指南来帮助您简化过渡。
          <v-autocomplete
            v-model="selectedItem"
            :items="releaseList"
            :loading="releaseLoading"
            @change="getItem(selectedItem)"
            class="mt-8 mb-12"
            dense
            flat
            solo
            dark
            hide-details
            item-text="version"
            item-value="_id"
            label="选择一个发行版本"
            prepend-inner-icon="search"
            background-color="primary"
          >
            <template v-slot:selection="data">
              v{{ data.item.version }}
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-icon>
                  <v-icon>new_releases</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>v{{data.item.version}}</v-list-item-title>
                  <v-list-item-subtitle>{{data.item.publish_date | formatDate}}发布</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-card min-height="180" outlined>
            <div v-if="!!itemData" class="d-flex">
              <v-list-item v-if="itemData.publisher && itemData.publisher.personal">
                <v-list-item-avatar size="48">
                  <v-img :src="itemData.publisher.personal.profile ? ossURL +'/'+ itemData.publisher.personal.profile : '/static/grey_silhouette.png'" aspect-ratio="1" contain></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="mb-1 text-h6">
                    {{itemData.publish_date | formatDate}}发布
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    发布者：<widgets-employee-dialogs :account="itemData.publisher._id" class="v-list-item--link">{{itemData.publisher.personal ? itemData.publisher.personal.name : '*未设置姓名'}}</widgets-employee-dialogs>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
            <v-divider />
            <div class="pa-4" v-html="itemData.context"></div>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="close">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {
  FETCH_RELEASE_LIST,
  FETCH_RELEASE,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {SET_ERROR} from "@/store/mutations.type";
export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      itemData: {},
      itemList: [],
      selectedItem: undefined,
    }
  },
  computed: {
    ...mapGetters([
      "releaseList",
      "releaseLoading",
      "releaseCount",
    ]),
  },
  mounted() {
    if (!this.id) this.getItem()
  },
  methods: {
    getItem(id) {
      store.dispatch(FETCH_RELEASE, id ? id : 'last')
        .then((data) => {
          this.itemData = Object.assign({}, data)
        })
    },
    openItem() {
      let getId = this.id
      store.dispatch(FETCH_RELEASE_LIST)
        .then((data) => {
          if (!getId && data.release.length) getId = data.release[0]._id
          this.selectedItem = getId
          this.getItem(getId)
          this.dialog = true
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    close () {
      this.dialog = false
    },
  },
}
</script>
