var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-8 mx-auto",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "to": _vm.path
    }
  }, [_c('v-list-item-avatar', {
    attrs: {
      "color": "secondary"
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.icon)
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title font-weight-light mb-2",
    domProps: {
      "textContent": _vm._s(_vm.count)
    }
  }), _c('v-list-item-subtitle', {
    staticClass: "subheading font-weight-light",
    domProps: {
      "textContent": _vm._s(_vm.label)
    }
  })], 1), _c('v-list-item-action', [_c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-1"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("schedule")]), _c('span', {
    staticClass: "caption font-weight-light"
  }, [_vm._v("更新时间：" + _vm._s(_vm._f("fromDate")(_vm.updateTime)))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }