var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "align-with-title": ""
    },
    model: {
      value: _vm.active_tab,
      callback: function callback($$v) {
        _vm.active_tab = $$v;
      },
      expression: "active_tab"
    }
  }, [_c('v-tabs-slider', {
    attrs: {
      "color": "secondary"
    }
  }), _c('v-tab', [_vm._v("产品")]), _c('v-tab', [_vm._v("附件")]), _c('v-tab', [_vm._v("备注")]), _c('v-tab-item', [_c('v-card-text', {
    staticClass: "px-0"
  }, [_c('v-data-table', {
    staticClass: "elevation-0 my-4",
    attrs: {
      "headers": _vm.productHeaders,
      "items": _vm.productList,
      "items-per-page": 100,
      "hide-default-footer": "",
      "item-key": "index",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.product_name",
      fn: function fn(_ref) {
        var item = _ref.item,
            index = _ref.index;
        return [_c('td', {
          class: !item.openRow ? 'text-truncate text-decoration-underline' : 'text-decoration-underline',
          staticStyle: {
            "max-width": "150px",
            "cursor": "pointer"
          },
          attrs: {
            "nowrap": item.openRow ? false : true
          },
          on: {
            "click": function click($event) {
              return _vm.showRowText(index);
            }
          }
        }, [_vm._v(" " + _vm._s(item.product_name) + " ")])];
      }
    }, {
      key: "item.purchase_margin",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" " + _vm._s(parseFloat(item.purchase_margin * 100).toFixed(2)) + " % ")])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref3) {
        var item = _ref3.item,
            index = _ref3.index;
        return [_vm.itemData.isEdit && _vm.itemData.status < 2 ? _c('v-btn', {
          attrs: {
            "icon": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editProduct(index);
            }
          }
        }, [_c('v-icon', [_vm._v("edit")])], 1) : _vm._e()];
      }
    }])
  })], 1)], 1), _c('v-tab-item', [_c('widgets-files-list', {
    attrs: {
      "entityType": "PurchaseProduct",
      "entityId": _vm.itemId,
      "type": 1
    }
  })], 1), _c('v-tab-item', [_c('widgets-remarks-list', {
    attrs: {
      "entityType": "PurchaseProduct",
      "entityId": _vm.itemId,
      "type": 0
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "650px"
    },
    model: {
      value: _vm.dlgEditProduct,
      callback: function callback($$v) {
        _vm.dlgEditProduct = $$v;
      },
      expression: "dlgEditProduct"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveProduct(_vm.editIndex);
      }
    },
    model: {
      value: _vm.validEditProduct,
      callback: function callback($$v) {
        _vm.validEditProduct = $$v;
      },
      expression: "validEditProduct"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("产品外采信息"), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeProduct();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 产品名称 ")]), _c('v-col', {
    class: !_vm.showText['product_name'] ? 'text-truncate' : '',
    attrs: {
      "cols": "10"
    }
  }, [_c('strong', {
    on: {
      "click": function click($event) {
        return _vm.showMoreText('product_name');
      }
    }
  }, [_vm._v(_vm._s(_vm.editItem.product_name))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 产品型号 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.editItem.part_number))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 产品类型 ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.editItem.product_type ? _c('strong', [_vm._v(_vm._s(_vm.editItem.product_type.name))]) : _vm._e()]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 品牌厂商 ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.editItem.product_vendor ? _c('strong', [_vm._v(_vm._s(_vm.editItem.product_vendor.name))]) : _vm._e()]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 销售单价 ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.editItem.sales_price))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 采购数量 ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.editItem.quantity))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 采购单价 ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "采购单价",
      "rules": [_vm.rules.required, _vm.rules.negative],
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "prefix": "¥"
    },
    model: {
      value: _vm.editItem.purchase_price,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "purchase_price", $$v);
      },
      expression: "editItem.purchase_price"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 采购小记 ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v("¥ " + _vm._s(parseFloat(_vm.editItem.purchase_price * _vm.editItem.quantity).toFixed(4)))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 采购地区 ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "采购地区",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.purchase_area,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "purchase_area", $$v);
      },
      expression: "editItem.purchase_area"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 采购货期 ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "采购货期",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.purchase_delivery,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "purchase_delivery", $$v);
      },
      expression: "editItem.purchase_delivery"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 利润率 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('strong', [_vm._v(_vm._s(parseFloat((_vm.editItem.sales_price - _vm.editItem.purchase_price) / _vm.editItem.sales_price * 100).toFixed(2)) + " %")])])], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeProduct();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.validEditProduct
    },
    on: {
      "click": function click($event) {
        return _vm.saveProduct(_vm.editIndex);
      }
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }