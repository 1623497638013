import {PurchaseService} from "@/common/http";
import {
  PUBLISH_PURCHASE,
  FETCH_PURCHASE,
  FETCH_PURCHASE_LIST,
  ADD_MARK_PURCHASE,
  REMOVE_MARK_PURCHASE,
  SAVE_PURCHASE,
  CANCEL_PURCHASE,
  ASSIST_PURCHASE
} from "./actions.type";
import {
  SET_ERROR,
  LOAD_PURCHASE_LIST,
  SET_PURCHASE_LIST,
  SET_PURCHASE,
} from "./mutations.type"
const state = {
  purchaseLoading: true,
  purchaseList: [],
  purchaseCount: 0,
  todoPurchaseCount: 0,
  purchaseInfo: {},
};
const getters = {
  purchaseInfo: state => state.purchaseInfo,
  purchaseLoading: state => state.purchaseLoading,
  purchaseList: state => state.purchaseList,
  purchaseCount: state => state.purchaseCount,
  todoPurchaseCount: state => state.todoPurchaseCount,
};
const actions = {
  [PUBLISH_PURCHASE](context, params) {
    return new Promise((resolve, reject) => {
      PurchaseService.create('', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [FETCH_PURCHASE_LIST]({ commit }, params) {
    commit(LOAD_PURCHASE_LIST);
    return new Promise((resolve, reject) => {
      PurchaseService.get('/list', '?'+params)
        .then(({ data }) => {
          commit(SET_PURCHASE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_PURCHASE]({ commit }, params) {
    return new Promise((resolve, reject) => {
      PurchaseService.get('', params.query)
        .then(({ data }) => {
          commit(SET_PURCHASE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    })
  },
  [ADD_MARK_PURCHASE](context, params) {
    return PurchaseService.create('/bookmaker/'+params)
  },
  [REMOVE_MARK_PURCHASE](context, params) {
    return PurchaseService.destroy('/bookmaker/'+params)
  },
  [SAVE_PURCHASE](context, params) {
    return PurchaseService.update('', params.id, params.item);
  },
  [CANCEL_PURCHASE](context, params) {
    return PurchaseService.update('/cancel', params.id, params)
  },
  [ASSIST_PURCHASE](context, params) {
    return PurchaseService.update('/assist', params.id, params)
  },
};
const mutations = {
  [LOAD_PURCHASE_LIST](state) {
    state.purchaseLoading = true;
  },
  [SET_PURCHASE_LIST](state, list) {
    state.purchaseCount = list.query.count;
    state.todoPurchaseCount = list.query.todoCount;
    state.purchaseList = list.purchases;
    state.purchaseLoading = false;
  },
  [SET_PURCHASE](state, order) {
    state.purchaseInfo = order.purchaseInfo;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
