var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "px-16",
    attrs: {
      "fluid": "",
      "no-gutters": ""
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-carousel', {
          attrs: {
            "height": "275px",
            "cycle": "",
            "hide-delimiter-background": "",
            "show-arrows-on-hover": "",
            "delimiter-icon": "remove"
          }
        }, [_vm._l(_vm.editBodyData.images, function (item, i) {
          return _c('v-carousel-item', {
            key: i,
            attrs: {
              "src": item.src ? _vm.ossURL + '/' + item.src : '/static/error/empty_banner.png',
              "href": item.href,
              "target": "_blank"
            }
          });
        }), _vm.editBodyData.images.length === 0 ? _c('v-sheet', {
          attrs: {
            "color": "grey lighten-2",
            "height": "100%",
            "tile": ""
          }
        }, [_c('v-row', {
          staticClass: "fill-height",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('div', {
          staticClass: "grey--text"
        }, [_vm._v("轮播区域")])])], 1) : _vm._e(), _c('v-fade-transition', [hover ? _c('v-overlay', {
          staticClass: "align-end",
          staticStyle: {
            "top": "225px"
          },
          attrs: {
            "absolute": "",
            "color": "grey lighten-5"
          }
        }, [_c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("edit")]), _vm._v(" 编辑")], 1), _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("delete")]), _vm._v(" 删除")], 1)], 1) : _vm._e()], 1)], 2)];
      }
    }])
  }), _c('v-bottom-sheet', {
    attrs: {
      "retain-focus": false,
      "eager": ""
    },
    model: {
      value: _vm.dlgSetting,
      callback: function callback($$v) {
        _vm.dlgSetting = $$v;
      },
      expression: "dlgSetting"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("设置")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-row', [_vm._l(_vm.editBodyData.images, function (item, i) {
    return _c('v-col', {
      key: i,
      staticClass: "text-center",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-row', {
      staticClass: "pa-3",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-card', {
      staticClass: "text-center",
      attrs: {
        "outlined": "",
        "hover": "",
        "tile": ""
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var hover = _ref2.hover;
          return [_c('v-img', {
            staticStyle: {
              "width": "100%",
              "height": "80px"
            },
            attrs: {
              "src": item.src ? _vm.ossURL + '/' + item.src : '/static/error/empty_banner.png'
            }
          }, [_c('v-fade-transition', [hover ? _c('v-overlay', {
            staticClass: "align-end",
            attrs: {
              "absolute": "",
              "color": "grey lighten-5"
            }
          }, [_c('v-btn', {
            staticClass: "ma-2",
            attrs: {
              "text": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.saveImage(i);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "left": ""
            }
          }, [_vm._v("compare_arrows")]), _vm._v(" 更换")], 1), _c('v-btn', {
            staticClass: "ma-2",
            attrs: {
              "text": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.removeImage(i);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "left": ""
            }
          }, [_vm._v("delete")]), _vm._v(" 删除")], 1)], 1) : _vm._e()], 1)], 1)];
        }
      }], null, true)
    })], 1)], 1), _c('v-text-field', {
      attrs: {
        "width": "300px",
        "type": "text",
        "label": "链接地址",
        "hint": "横幅的链接地址",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.href,
        callback: function callback($$v) {
          _vm.$set(item, "href", $$v);
        },
        expression: "item.href"
      }
    })], 1);
  }), _vm.editBodyData.images.length < 4 ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-row', {
    staticClass: "pa-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-card', {
    staticClass: "text-center",
    staticStyle: {
      "width": "100%",
      "height": "80px"
    },
    attrs: {
      "outlined": "",
      "hover": ""
    },
    on: {
      "click": function click($event) {
        return _vm.saveImage(-1);
      }
    }
  }, [_c('v-card-title'), _c('v-card-text', [_c('v-icon', [_vm._v("add")])], 1)], 1)], 1)], 1) : _vm._e()], 2)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }