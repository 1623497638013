import Vue from 'vue';
import VueMask from 'vue-the-mask'
// import VueSocketIO from 'vue-socket.io';

Vue.use(VueMask);

// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: process.env.VUE_APP_BASE_API,
// }))
