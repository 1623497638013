import {RiskService} from "@/common/http";

import {
  FETCH_RISK_ECONOMY_LIST,
  PUBLISH_RISK_ECONOMY,
  FETCH_RISK_ECONOMY,
  EDIT_RISK_ECONOMY,
  DELETE_RISK_ECONOMY,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_RISK_ECONOMY_LIST,
  SET_RISK_ECONOMY_LIST,
  SET_RISK_ECONOMY,
} from "./mutations.type"

const state = {
  riskEconomyLoading: true,
  riskEconomyList: [],
  riskEconomyCount: 0,
  riskEconomy: {},
};

const getters = {
  riskEconomyLoading: state => state.riskEconomyLoading,
  riskEconomyList: state => state.riskEconomyList,
  riskEconomyCount: state => state.riskEconomyCount,
  riskEconomy: state => state.riskEconomy,
};

const actions = {
  [FETCH_RISK_ECONOMY_LIST]({ commit }, slug) {
    commit(LOAD_RISK_ECONOMY_LIST);
    return new Promise((resolve, reject) => {
      RiskService.get('/economy/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RISK_ECONOMY_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_RISK_ECONOMY]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      RiskService.get('/economy', slug)
        .then(({ data }) => {
          commit(SET_RISK_ECONOMY, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_RISK_ECONOMY](context, data) {
    return RiskService.create('/economy', data);
  },
  [EDIT_RISK_ECONOMY](context, data) {
    return RiskService.update('/economy', data._id, data);
  },
  [DELETE_RISK_ECONOMY](context, data) {
    return RiskService.delete('/economy/' + data._id);
  },
};

const mutations = {
  [LOAD_RISK_ECONOMY_LIST] (state) {
    state.riskEconomyLoading = true;
  },
  [SET_RISK_ECONOMY_LIST](state, list) {
    state.riskEconomyCount = list.query.count;
    state.riskEconomyList = list.docs;
    state.riskEconomyLoading = false;
  },
  [SET_RISK_ECONOMY](state, riskEconomy) {
    state.riskEconomy = riskEconomy;
    state.riskEconomyLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

