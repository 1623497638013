<template>
  <v-container fluid no-gutters class="px-16">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card flat>
          <div class="d-flex flex-no-wrap">
            <!--<v-avatar class="ma-3" size="280" tile>-->
              <v-img
                :src="editBodyData.cover ? ossURL + '/'+ editBodyData.cover : '/static/error/empty_state.svg'"
                height="250"
                max-width="350"
              ></v-img>
            <!--</v-avatar>-->
            <div class="ma-6">
              <v-card-title
                :class="'headline '+ editBodyData.title_color +'--text'"
                v-text="editBodyData.title || '输入标题文字'"
              ></v-card-title>
              <v-card-subtitle>
                <pre class="grey--text mt-4" v-html="editBodyData.context || '文字区域'"></pre>
              </v-card-subtitle>
              <!--<v-card-actions>-->
                <!--<v-spacer></v-spacer>-->
                <!--<v-btn text>选择素材</v-btn>-->
              <!--</v-card-actions>-->
            </div>
          </div>
          <v-fade-transition>
            <v-overlay v-if="hover" absolute class="align-end" color="grey lighten-5">
              <v-btn class="ma-2" text color="primary" @click="editItem()"><v-icon left>edit</v-icon> 编辑</v-btn>
              <v-btn class="ma-2" text color="primary" @click="deleteItem()"><v-icon left>delete</v-icon> 删除</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
    <v-bottom-sheet v-model="dlgSetting" :retain-focus="false" eager>
      <v-form v-model="valid" @submit.prevent="submit">
        <input :id="'orc_file'+ index" type="file" name="file" ref="uploadInput" accept="image/*" :multiple="false" @change="detectFiles($event)" />
        <v-progress-linear color="secondary" v-if="Upload.uploading" :value="Upload.progressUpload"></v-progress-linear>
        <v-card>
          <v-card-title>
            <span class="headline">设置</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="3" class="text-center">
                  <v-row align="center" justify="center">
                    <v-card outlined tile class="text-center">
                      <v-img :src="editBodyData.cover ? ossURL + '/'+ editBodyData.cover : '/static/error/empty_state.svg'" >
                      </v-img>
                      <v-card-actions>
                        <v-btn text color="primary" @click="uploadImage()"><v-icon left>backup</v-icon> 上传图片</v-btn>
                        <v-btn text color="primary" @click="choiceMaterial()"><v-icon left>photo_library</v-icon> 选择素材</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-row>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="editBodyData.title"
                    width="300px"
                    type="text"
                    label="标题"
                    hint="输入标题"
                    outlined
                    dense>
                  </v-text-field>
                  <v-select
                    v-model="editBodyData.title_color"
                    :items="fontColor"
                    autocomplete="off"
                    label="标题颜色"
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small :color="item">
                        {{item | stringModule}}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip small :color="data.item">{{data.item | stringModule}}</v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <editor v-if="activeEdit" v-model="editBodyData.context" :init="editorInit" :id="'element'+index"></editor>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">关闭</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-bottom-sheet>
    <v-dialog v-model="dlgMaterial" hide-overlay max-width="450px">
      <v-card outlined>
        <v-card-title>选择内容</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="editBodyData.material"
            :items="materialList"
            item-text="title"
            item-value="_id"
            autocomplete="off"
            return-object
            outlined
            clearable
            dense
            label="关联素材"
            hint="请选择该服务关联的素材详细介绍"
            prepend-inner-icon="photo_library"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.title"></v-list-item-title>
                <v-list-item-subtitle>{{data.item.word_count}}字 {{data.item.updateTime | fromDate}}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dlgMaterial = false">关闭</v-btn>
          <v-btn color="secondary" text @click="confirmMaterial">确定</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-container>
</template>
<script>
// eslint-disable-next-line
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/lists'
import axios from "axios";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_UPLOAD,
  FETCH_PAGE,
  EDIT_BODY_ELEMENT,
  DELETE_BODY_ELEMENT,
  FETCH_MATERIAL_LIST,
} from "@/store/actions.type";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  components: {
    Editor
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        }
      },
      dlgSetting: false,
      dlgMaterial: false,
      valid: true,
      editBodyData: {},
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      fontColor: ['red', 'secondary', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey', 'grey', 'primary', 'white'],
      editorInit: {
        language_url: '/tinymce/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/MDass',
        content_css: '/tinymce/skins/content/MDass/content.css',
        height: '100%',
        branding: false,
        menubar: false,
        statusbar: false,
        link_quicklink: true,
        // inline: true,
        toolbar: false,
        plugins: [
          'lists',
          'link',
          'quickbars'
        ],
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body,h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif; }",
        font_formats:
          "Roboto=roboto;",
        quickbars_insert_toolbar: 'formatselect forecolor',
        quickbars_selection_toolbar: 'bold italic underline strikethrough | fontselect formatselect forecolor | alignleft aligncenter alignright alignjustify | bullist numlist | quicklink unlink ｜ removeformat',
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',
      },
      activeEdit: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentEmployer', 'uploadSign', 'materialList']),
  },
  watch: {
    dlgSetting (val) {
      val || this.close()
    },
  },
  created() {
    this.getPageInfo()
  },
  mounted () {
    this.activeEdit = true
  },
  methods: {
    getPageInfo(component = 'body') {
      this.$store.dispatch(FETCH_PAGE, '?name=home&component=' + component +'&owner='+ this.currentUser.employer.ownerId._id)
        .then(data => {
          this.$store.dispatch(FETCH_MATERIAL_LIST)
          this.editBodyData = Object.assign({_id: data._id}, data.config[this.index])
          if (!this.editBodyData.type) this.editBodyData.type = 'onerowcard'
        });
    },
    close() {
      this.dlgSetting = false
      this.getPageInfo('body')
    },
    editItem() {
      this.dlgSetting = true
    },
    choiceMaterial() {
      this.dlgMaterial = true
    },
    confirmMaterial() {
      if (this.editBodyData.material) {
        this.dlgMaterial = false
        this.editBodyData.cover = this.editBodyData.material.profile_image
        this.editBodyData.title = this.editBodyData.material.title
        this.$forceUpdate()
        this.submit(false)
      } else {
        this.dlgMaterial = false
        this.editBodyData.cover = ''
        this.editBodyData.title = ''
        this.$forceUpdate()
        this.submit(false)
      }
    },
    deleteItem() {
      this.$store.dispatch(DELETE_BODY_ELEMENT, {_id: this.id, index: this.index})
        .then(() => {
          this.$emit('DeleteItem')
          this.getPageInfo('body')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    uploadImage() {
      this.$store.dispatch(FETCH_UPLOAD)
        .then(() => {
          this.$refs.uploadInput.click()
        })
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.currentEmployer.ownerId._id +'/page/home/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };
      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.editBodyData.cover = this.currentEmployer.ownerId._id +'/page/home/'+ this.uploadSign.key;
          this.$forceUpdate()
          this.submit(false)
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    submit(isClose = true) {
      if (this.editBodyData.material) {
        if (typeof this.editBodyData.material === "object") {
          this.editBodyData.material = this.editBodyData.material._id
        }
      } else {
        this.editBodyData.material = ''
      }
      this.$store.dispatch(EDIT_BODY_ELEMENT, {_id: this.id, index: this.index, config: [this.editBodyData]})
        .then(() => {
          this.$emit('UpdateItem')
          this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
          if (isClose) this.close();
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
  },
  destroyed () {
    this.activeEdit = false
  }
}
</script>
<style scoped>
  .show_upload {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
  .v-dialog__content {z-index: 203 !important;}
  pre {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
</style>
