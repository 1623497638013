import {BranchService} from "@/common/http";

import {
  FETCH_BRANCH_LIST,
  ORDER_BRANCH_LIST,
  PUBLISH_BRANCH,
  EDIT_BRANCH,
  FETCH_BRANCH,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_BRANCH_LIST,
  SET_BRANCH_LIST,
  SET_BRANCH,
} from "./mutations.type"

const state = {
  branchLoading: true,
  branchList: [],
  branch: {}
};

const getters = {
  branchList: state => state.branchList,
  branchLoading: state => state.branchLoading,
  branch: state => state.branch,
};

const actions = {
  [FETCH_BRANCH]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      BranchService.get('', slug)
        .then(({ data }) => {
          commit(SET_BRANCH, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_BRANCH_LIST]({ commit }, slug) {
    commit(LOAD_BRANCH_LIST);
    return new Promise((resolve, reject) => {
      BranchService.get('/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_BRANCH_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [ORDER_BRANCH_LIST]({ commit }, branchList) {
    commit(SET_BRANCH_LIST, branchList);
    return BranchService.order(branchList);
  },
  [PUBLISH_BRANCH](context, branch) {
    return BranchService.create('', branch);
  },
  [EDIT_BRANCH](context, branch) {
    return BranchService.update(branch._id, branch);
  },
};

const mutations = {
  [LOAD_BRANCH_LIST] (state) {
    state.branchLoading = true;
  },
  [SET_BRANCH_LIST](state, { branch }) {
    state.branchList = branch;
    state.branchLoading = false;
  },
  [SET_BRANCH](state, branch) {
    state.branch = branch;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
