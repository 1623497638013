<template>
  <v-row v-if="orderData">
    <v-col cols="2" class="text-right">PO号</v-col>
    <v-col cols="4" class="text--secondary">
      {{ orderData.po_number }}
      <v-btn
        icon
        small
        @click="editOrder('po_number', 'PO号')"
        v-if="isEdit && orderData.status === 0"
      >
        <v-icon small>edit</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="2" class="text-right" v-if="orderData.type === 0">DT号</v-col>
    <v-col cols="4" class="text--secondary" v-if="orderData.type === 0">
      {{ orderData.out_number }}
      <v-btn
        icon
        small
        @click="editOrder('out_number', 'DT号')"
        v-if="isEdit && orderData.status === 0"
      >
        <v-icon small>edit</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="2" class="text-right" v-if="orderData.type === 1 && orderData.parentId && orderData.parentId.order_number">上级单号</v-col>
    <v-col cols="4" class="text--secondary" v-if="orderData.type === 1 && orderData.parentId && orderData.parentId.order_number">
      <span class="v-list-item--link" @click="openParentOrder">
        {{ orderData.parentId.order_number }}<v-icon class="ml-2">read_more</v-icon>
      </span>
    </v-col>
    <v-col cols="2" class="text-right" v-if="orderData.type === 1 && !orderData.parentId"></v-col>
    <v-col cols="4" class="text--secondary" v-if="orderData.type === 1 && !orderData.parentId"></v-col>
    <v-col cols="2" class="text-right">订单编号</v-col>
    <v-col cols="4" class="text--secondary">{{ orderData.order_number }}</v-col>
    <v-col cols="2" class="text-right">客户</v-col>
    <v-col cols="4" class="text--secondary" v-if="orderData.enterprise">
      <span
        class="v-list-item--link"
        @click="openEnterInfo(orderData.enterprise._id)"
      >
        {{ orderData.enterprise.name }}
        <v-icon class="ml-2">read_more</v-icon>
      </span>
      <v-btn
        icon
        small
        @click="editOrder('enterprise', '客户')"
        v-if="isEdit"
      >
        <v-icon small>edit</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="2" class="text-right">客户销售</v-col>
    <v-col cols="4" class="text--secondary">
      <widgets-employee-dialogs
        v-if="orderData.sales"
        :account="orderData.sales._id"
      >
        {{
          orderData.sales.personal
            ? orderData.sales.personal.name
            : orderData.sales.username
        }}
        <v-icon class="ml-2">read_more</v-icon>
      </widgets-employee-dialogs>
      <span v-else>未指定销售</span>
      <v-btn
        icon
        small
        @click="editOrder('sales', '销售人员')"
        v-if="isEdit && orderData.status === 0"
      >
        <v-icon small>edit</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="2" class="text-right">分支机构</v-col>
    <v-col cols="4" class="text--secondary"
      >{{ orderData.branch || "" }}
      <v-btn
        icon
        small
        @click="editOrder('enterprise', '客户')"
        v-if="isEdit && orderData.status === 0"
      >
        <v-icon small>edit</v-icon>
      </v-btn></v-col
    >
    <v-col cols="2" class="text-right">创建人</v-col>
    <v-col
      cols="4"
      class="text--secondary"
      v-if="orderData.creator && orderData.creator.personal"
    >
      <widgets-employee-dialogs
        :account="orderData.creator._id"
        class="v-list-item--link"
      >
        {{
          orderData.creator.personal
            ? orderData.creator.personal.name
            : "*未设置姓名"
        }}
        <v-icon class="ml-2">read_more</v-icon>
      </widgets-employee-dialogs>
    </v-col>
    <v-col cols="2" class="text-right">创建时间</v-col>
    <v-col cols="4" class="text--secondary">{{
      orderData.createTime | formatTime
    }}</v-col>
    <v-col cols="2" class="text-right">发票信息</v-col>
    <v-col cols="4" class="text--secondary">
      <div v-if="orderData.invoice_info">
        <div>{{ orderData.invoice_info.title || orderData.invoice_info.delivery_name }}</div>
        <div>{{ orderData.invoice_info.license_code || orderData.invoice_info.delivery_phone }}</div>
        <div v-if="orderData.invoice_info.title">
          <span v-if="orderData.invoice_info.invoice_type === 1">增值税专用发票</span>
          <span v-if="orderData.invoice_info.invoice_type === 0">增值税普通发票</span>
        </div>
        <div v-else>
          <span>{{ orderData.invoice_info.delivery_address }}</span>
        </div>
      </div>
      <div>
        <span
          class="v-list-item--link"
          @click="editOrder('invoice_info', '发票信息')"
        >
          <v-icon small v-if="isEdit && orderData.status < 2">edit</v-icon>
          <v-icon v-else>read_more</v-icon>
        </span>
      </div>
    </v-col>
    <v-col cols="2" class="text-right">收货信息</v-col>
    <v-col cols="4" class="text--secondary">
      <div v-if="orderData.delivery_info">
        <div>{{ orderData.delivery_info.address }}</div>
        <div>{{ orderData.delivery_info.phone }}</div>
        <div>{{ orderData.delivery_info.name }}</div>
      </div>
      <div>
        <span
          class="v-list-item--link"
          @click="editOrder('delivery_info', '收货信息')"
        >
          <v-icon small v-if="isEdit && orderData.status < 2">edit</v-icon>
          <v-icon v-else>read_more</v-icon>
        </span>
      </div>
    </v-col>
    <v-dialog v-model="enterInfoDlg" scrollable max-width="600px" persistent>
      <widgets-enterprise-dialogs
        :id="activeId"
        :key="activeId"
        v-on:Close="closeEnterInfo"
      ></widgets-enterprise-dialogs>
    </v-dialog>
    <v-dialog v-model="editDlg" width="600px" persistent>
      <v-form v-model="editValid">
        <v-card>
          <v-card-title>{{ this.editDlgTitle === '订单说明' ? '请填写订单说明，如详细的退货记录等，该内容仅在导出订单时展现。' : this.editDlgTitle }}</v-card-title>
          <v-card-text>
            <v-textarea
              v-if="editDlgTitle === '订单说明'"
              outlined
              v-model="editOrderData.explain"
              :rules="[rules.required]"
              label="订单解释说明"
            ></v-textarea>
            <v-text-field
              v-if="editDlgTitle === 'PO号'"
              v-model="editOrderData.po_number"
              label="PO号"
              :rules="[rules.required]"
              autocomplete="off"
              clearable
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="editDlgTitle === 'DT号'"
              v-model="editOrderData.out_number"
              label="DT号"
              :rules="[rules.required]"
              autocomplete="off"
              clearable
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="editDlgTitle === '订单进展描述'"
              v-model="editOrderData.no_order_text"
              label="当订单无工单时将呈现给企业用户的文字信息"
              :rules="[rules.required]"
              autocomplete="off"
              clearable
              outlined
              dense
            ></v-text-field>
            <v-autocomplete
              v-if="editDlgTitle === '客户'"
              v-model="editOrderData.enterprise"
              label="客户"
              autocomplete="off"
              :items="enterpriseList"
              :loading="isEnterpriseLoading"
              :search-input.sync="searchEnterprise"
              item-text="name"
              item-value="_id"
              :return-object="false"
              :rules="[rules.required]"
              :disabled="orderData.status > 0"
              hide-no-data
              outlined
              dense
            ></v-autocomplete>
            <v-autocomplete
              v-if="editDlgTitle === '客户' && enterpriseList && enterpriseList.length"
              v-model="editOrderData.branch"
              label="分支机构"
              autocomplete="off"
              :items="enterpriseList[0].branch"
              hide-no-data
              outlined
              clearable
              dense
            ></v-autocomplete>
            <v-autocomplete
              v-if="editDlgTitle === '销售人员'"
              v-model="editOrderData.sales"
              label="分配销售"
              item-text="personal.name"
              :rules="[rules.selected]"
              item-value="_id"
              :loading="isSalesLoading"
              :search-input.sync="searchSales"
              :items="salesList"
              hide-no-data
              outlined
              dense
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{
                      data.item.personal.name
                        ? data.item.personal.name
                        : "未设置名称"
                    }}</v-list-item-title>
                  <v-list-item-subtitle
                  >{{ data.item.username }} -
                    {{ data.item.phone }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-text-field
              v-if="editDlgTitle === '数量'"
              v-model="editProduct.quantity"
              label="数量"
              type="number"
              autocomplete="off"
              :rules="[rules.required, rules.negative]"
              clearable
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="editDlgTitle === '单价'"
              v-model="editProduct.sales_price"
              label="单价"
              type="number"
              autocomplete="off"
              :rules="[rules.required, rules.negative]"
              clearable
              outlined
              dense
            ></v-text-field>
            <v-row v-if="editDlgTitle === '发票信息'">
              <v-col cols="12">
                <v-radio-group
                  v-model="editOrderData.invoice_info.invoice_type"
                  row
                  :disabled="!isEdit || orderData.status >= 2"
                >
                  <v-radio label="增值税普通发票" :value="0"></v-radio>
                  <v-radio label="增值税专用发票" :value="1"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="抬头名称"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.title"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="统一社会信用代码"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.license_code"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="开户行"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.bank_name"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="银行账号"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.bank_account"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="企业地址"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.license_address"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="企业电话"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.license_telephone"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="*发票接收人"
                  :rules="[rules.required]"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.delivery_name"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="*接收人电话"
                  :rules="[rules.required]"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.delivery_phone"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="*发票邮寄地址"
                  :rules="[rules.required]"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.delivery_address"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="editDlgTitle === '收货信息'">
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="收货人姓名"
                  dense
                  hide-details
                  v-model="editOrderData.delivery_info.name"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="收货人电话"
                  dense
                  hide-details
                  v-model="editOrderData.delivery_info.phone"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="收货人地址"
                  dense
                  hide-details
                  v-model="editOrderData.delivery_info.address"
                  :disabled="!isEdit || orderData.status >= 2"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="closeEdit">关闭</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="secondary"
              @click="submitEdit"
              :disabled="!editValid || !isEdit"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  EDIT_ORDER_PURCHASE,
  FETCH_ENTERPRISE_LIST,
  FETCH_ACCOUNT_LIST,
  INIT_ORDER_PURCHASE,
} from "@/store/actions.type";
import store from "@/store";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
export default {
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeId: '',
      enterInfoDlg: false,
      editDlgTitle: "",
      activeAction: "",
      editDlg: false,
      editValid: true,
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
      },
      editOrderData: {
        po_number: "",
        out_number: "",
        enterprise: "",
        sales: "",
        explain: '',
        product_list: [],
      },
      isEnterpriseLoading: false,
      searchEnterprise: null,
      enterpriseList: [],
      salesList: [],
      isSalesLoading: false,
      searchSales: null,
      parentOrderNum: '',
    }
  },
  computed: {
    ...mapGetters([
      "orderPurchaseInit",
    ]),
  },
  watch: {
    orderData() {
      this.initOrder()
    },
    searchEnterprise(val) {
      if (this.countDown) {
        clearTimeout(this.countDown);
      }
      this.countDown = setTimeout(() => {
        if (this.isEnterpriseLoading) return;
        this.isEnterpriseLoading = true;
        store
          .dispatch(FETCH_ENTERPRISE_LIST, "&key=" + val)
          .then((data) => {
            this.enterpriseList = data.enterprise;
            if (this.enterpriseList.length && !(this.enterpriseList[0].branch.includes(this.editOrderData.branch))) {
              this.editOrderData.branch = ''
            }
            this.isEnterpriseLoading = false;
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
            this.isEnterpriseLoading = false;
          });
      }, 500);
    },
    searchSales(val) {
      if (this.countDown) {
        clearTimeout(this.countDown);
      }
      this.countDown = setTimeout(() => {
        if (this.isSalesLoading) return;
        this.isSalesLoading = true;
        store
          .dispatch(FETCH_ACCOUNT_LIST, "&key=" + val + "&roles="+ this.orderPurchaseInit.sales_roles)
          .then((data) => {
            if (data) {
              const { employees } = data;
              this.salesList = employees;
            }
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
          })
          .finally(() => (this.isSalesLoading = false));
      }, 500);
    },
  },
  methods: {
    openParentOrder() {
      let newWin = this.$router.resolve({
        path: "/dealer/workbench/order/list?key=" + this.orderData.parentId.order_number,
      })
      window.open(newWin.href, "_blank")
    },
    getReturn() {
      this.editOrder('explain', '订单说明')
    },
    setNoData() {
      this.editOrder('no_order_text', '订单进展描述')
    },
    initOrder() {
      store.dispatch(INIT_ORDER_PURCHASE).then((data) => {
        let sales = data.sales_roles.join(",");
        store.dispatch(FETCH_ACCOUNT_LIST, "roles=" + sales).then((item) => {
          this.salesList = item.employees;
        })
      })
    },
    openEnterInfo(id) {
      this.activeId = id;
      this.enterInfoDlg = true;
    },
    closeEnterInfo() {
      this.enterInfoDlg = false;
    },
    editOrder(action, title, item) {
      this.editOrderData = JSON.parse(JSON.stringify(this.orderData));
      this.editOrderData.enterprise = this.editOrderData.enterprise._id;
      this.editOrderData.sales = this.editOrderData.sales ? this.editOrderData.sales._id : '';
      if (action === 'enterprise') {
        this.isEnterpriseLoading = true;
        store.dispatch(FETCH_ENTERPRISE_LIST, "&key=" + this.orderData.enterprise.name || '')
          .then((data) => {
            this.enterpriseList = data.enterprise;
            this.isEnterpriseLoading = false;
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
            this.isEnterpriseLoading = false;
          })
      } else if (action === 'invoice_info') {
        if (this.editOrderData.invoice_info.invoice_type === null || this.editOrderData.invoice_info.invoice_type === undefined) this.editOrderData.invoice_info.invoice_type = 0;
      }
      if (item) {
        this.editIndex = this.linkedProduct.indexOf(item);
        this.editProduct = JSON.parse(JSON.stringify(item));
      }
      this.activeAction = action;
      this.editDlgTitle = title;
      this.editDlg = true;
    },
    closeEdit() {
      this.editOrderData = JSON.parse(JSON.stringify(this.orderData));
      this.editIndex = -1;
      this.activeAction = "";
      this.editDlgTitle = "";
      this.editDlg = false;
    },
    submitEdit() {
      let json = {};
      json.id = this.orderId;
      let obj = {};
      if (this.activeAction === "product_list") {
        let proList = JSON.parse(JSON.stringify(this.linkedProduct));
        this.$set(proList, this.editIndex, this.editProduct);
        obj[this.activeAction] = proList;
      } else {
        obj[this.activeAction] = this.editOrderData[this.activeAction];
        if (this.activeAction === 'enterprise') {
          obj['branch'] = this.editOrderData['branch'];
          if (this.editOrderData.enterprise != this.orderData.enterprise._id || this.editOrderData.branch != this.orderData.branch) {
            obj.invoice_info = {}
          }
          if (this.orderData.status > 0) {
            delete obj.enterprise
            delete obj.invoice_info
          }
        }
      }

      json.item = obj;
      store
        .dispatch(EDIT_ORDER_PURCHASE, json)
        .then(() => {
          this.closeEdit();
          this.$emit("Update");
          store.commit(SET_ERROR, { msg: "修改成功。", color: "primary" });
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
  },
}
</script>
