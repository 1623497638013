var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.editFooterData ? _c('v-container', {
    staticClass: "mt-16",
    attrs: {
      "fluid": "",
      "no-gutters": ""
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-footer', {
          attrs: {
            "color": _vm.editFooterData.bgColor || 'primary',
            "absolute": "",
            "padless": ""
          }
        }, [_c('v-row', {
          attrs: {
            "justify": "center",
            "no-gutters": ""
          }
        }, [_vm._l(_vm.editFooterData.links, function (link, i) {
          return _c('v-btn', {
            key: i + '_link',
            staticClass: "my-2",
            attrs: {
              "href": link.url,
              "color": "white",
              "text": "",
              "rounded": ""
            }
          }, [_vm._v(" " + _vm._s(link.text) + " ")]);
        }), _c('v-col', {
          staticClass: "py-4 text-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('pre', {
          domProps: {
            "innerHTML": _vm._s(_vm.editFooterData.context || '文字区域')
          }
        })])], 2), _c('v-fade-transition', [hover ? _c('v-overlay', {
          attrs: {
            "absolute": "",
            "color": "grey lighten-5"
          }
        }, [_c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("edit")]), _vm._v(" 编辑")], 1)], 1) : _vm._e()], 1)], 1)];
      }
    }], null, false, 3138778416)
  }), _c('v-bottom-sheet', {
    attrs: {
      "persistent": "",
      "retain-focus": false,
      "eager": ""
    },
    model: {
      value: _vm.dlgSetting,
      callback: function callback($$v) {
        _vm.dlgSetting = $$v;
      },
      expression: "dlgSetting"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("设置")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "start",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.barColor,
      "autocomplete": "off",
      "label": "请选择背景颜色",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": item
          }
        }, [_vm._v(" " + _vm._s(_vm._f("stringModule")(item)) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": data.item
          }
        }, [_vm._v(_vm._s(_vm._f("stringModule")(data.item)))])];
      }
    }], null, false, 1547827071),
    model: {
      value: _vm.editFooterData.bgColor,
      callback: function callback($$v) {
        _vm.$set(_vm.editFooterData, "bgColor", $$v);
      },
      expression: "editFooterData.bgColor"
    }
  })], 1), _vm._l(_vm.editFooterData.links, function (link, i) {
    return _c('v-col', {
      key: i + '_link',
      staticClass: "text-center",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-text-field', {
      attrs: {
        "type": "text",
        "label": "链接文字",
        "hide-details": "",
        "hint": "显示的链接文字",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: link.text,
        callback: function callback($$v) {
          _vm.$set(link, "text", $$v);
        },
        expression: "link.text"
      }
    }), _c('v-text-field', {
      staticClass: "mt-2",
      attrs: {
        "type": "text",
        "label": "链接地址",
        "hide-details": "",
        "hint": "点击跳转的链接地址",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: link.url,
        callback: function callback($$v) {
          _vm.$set(link, "url", $$v);
        },
        expression: "link.url"
      }
    }), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.removeLink(i);
        }
      }
    }, [_c('v-icon', [_vm._v("remove")])], 1)], 1);
  }), _vm.editFooterData.links && _vm.editFooterData.links.length <= 4 ? _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.addLink
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("link")]), _vm._v("增加链接")], 1)], 1) : _vm._e(), _c('v-col', {
    class: 'edit-inline text-center ' + _vm.editFooterData.bgColor,
    attrs: {
      "cols": "12"
    }
  }, [_vm.activeEdit ? _c('editor', {
    attrs: {
      "init": _vm.editorInit,
      "id": "element_footer"
    },
    model: {
      value: _vm.editFooterData.context,
      callback: function callback($$v) {
        _vm.$set(_vm.editFooterData, "context", $$v);
      },
      expression: "editFooterData.context"
    }
  }) : _vm._e()], 1)], 2)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }