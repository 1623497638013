var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.orderData ? _c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("PO号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.orderData.po_number) + " "), _vm.isEdit && _vm.orderData.status === 0 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editOrder('po_number', 'PO号');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _vm.orderData.type === 0 ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("DT号")]) : _vm._e(), _vm.orderData.type === 0 ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.orderData.out_number) + " "), _vm.isEdit && _vm.orderData.status === 0 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editOrder('out_number', 'DT号');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1) : _vm._e(), _vm.orderData.type === 1 && _vm.orderData.parentId && _vm.orderData.parentId.order_number ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("上级单号")]) : _vm._e(), _vm.orderData.type === 1 && _vm.orderData.parentId && _vm.orderData.parentId.order_number ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('span', {
    staticClass: "v-list-item--link",
    on: {
      "click": _vm.openParentOrder
    }
  }, [_vm._v(" " + _vm._s(_vm.orderData.parentId.order_number)), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v("read_more")])], 1)]) : _vm._e(), _vm.orderData.type === 1 && !_vm.orderData.parentId ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }) : _vm._e(), _vm.orderData.type === 1 && !_vm.orderData.parentId ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("订单编号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.orderData.order_number))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("客户")]), _vm.orderData.enterprise ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('span', {
    staticClass: "v-list-item--link",
    on: {
      "click": function click($event) {
        return _vm.openEnterInfo(_vm.orderData.enterprise._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.orderData.enterprise.name) + " "), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v("read_more")])], 1), _vm.isEdit ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editOrder('enterprise', '客户');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("客户销售")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm.orderData.sales ? _c('widgets-employee-dialogs', {
    attrs: {
      "account": _vm.orderData.sales._id
    }
  }, [_vm._v(" " + _vm._s(_vm.orderData.sales.personal ? _vm.orderData.sales.personal.name : _vm.orderData.sales.username) + " "), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v("read_more")])], 1) : _c('span', [_vm._v("未指定销售")]), _vm.isEdit && _vm.orderData.status === 0 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editOrder('sales', '销售人员');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("分支机构")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.orderData.branch || "") + " "), _vm.isEdit && _vm.orderData.status === 0 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editOrder('enterprise', '客户');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("创建人")]), _vm.orderData.creator && _vm.orderData.creator.personal ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('widgets-employee-dialogs', {
    staticClass: "v-list-item--link",
    attrs: {
      "account": _vm.orderData.creator._id
    }
  }, [_vm._v(" " + _vm._s(_vm.orderData.creator.personal ? _vm.orderData.creator.personal.name : "*未设置姓名") + " "), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v("read_more")])], 1)], 1) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("创建时间")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatTime")(_vm.orderData.createTime)))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("发票信息")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm.orderData.invoice_info ? _c('div', [_c('div', [_vm._v(_vm._s(_vm.orderData.invoice_info.title || _vm.orderData.invoice_info.delivery_name))]), _c('div', [_vm._v(_vm._s(_vm.orderData.invoice_info.license_code || _vm.orderData.invoice_info.delivery_phone))]), _vm.orderData.invoice_info.title ? _c('div', [_vm.orderData.invoice_info.invoice_type === 1 ? _c('span', [_vm._v("增值税专用发票")]) : _vm._e(), _vm.orderData.invoice_info.invoice_type === 0 ? _c('span', [_vm._v("增值税普通发票")]) : _vm._e()]) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.orderData.invoice_info.delivery_address))])])]) : _vm._e(), _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    on: {
      "click": function click($event) {
        return _vm.editOrder('invoice_info', '发票信息');
      }
    }
  }, [_vm.isEdit && _vm.orderData.status < 2 ? _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("edit")]) : _c('v-icon', [_vm._v("read_more")])], 1)])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("收货信息")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm.orderData.delivery_info ? _c('div', [_c('div', [_vm._v(_vm._s(_vm.orderData.delivery_info.address))]), _c('div', [_vm._v(_vm._s(_vm.orderData.delivery_info.phone))]), _c('div', [_vm._v(_vm._s(_vm.orderData.delivery_info.name))])]) : _vm._e(), _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    on: {
      "click": function click($event) {
        return _vm.editOrder('delivery_info', '收货信息');
      }
    }
  }, [_vm.isEdit && _vm.orderData.status < 2 ? _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("edit")]) : _c('v-icon', [_vm._v("read_more")])], 1)])]), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "600px",
      "persistent": ""
    },
    model: {
      value: _vm.enterInfoDlg,
      callback: function callback($$v) {
        _vm.enterInfoDlg = $$v;
      },
      expression: "enterInfoDlg"
    }
  }, [_c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": _vm.closeEnterInfo
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "width": "600px",
      "persistent": ""
    },
    model: {
      value: _vm.editDlg,
      callback: function callback($$v) {
        _vm.editDlg = $$v;
      },
      expression: "editDlg"
    }
  }, [_c('v-form', {
    model: {
      value: _vm.editValid,
      callback: function callback($$v) {
        _vm.editValid = $$v;
      },
      expression: "editValid"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(this.editDlgTitle === '订单说明' ? '请填写订单说明，如详细的退货记录等，该内容仅在导出订单时展现。' : this.editDlgTitle))]), _c('v-card-text', [_vm.editDlgTitle === '订单说明' ? _c('v-textarea', {
    attrs: {
      "outlined": "",
      "rules": [_vm.rules.required],
      "label": "订单解释说明"
    },
    model: {
      value: _vm.editOrderData.explain,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData, "explain", $$v);
      },
      expression: "editOrderData.explain"
    }
  }) : _vm._e(), _vm.editDlgTitle === 'PO号' ? _c('v-text-field', {
    attrs: {
      "label": "PO号",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "clearable": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editOrderData.po_number,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData, "po_number", $$v);
      },
      expression: "editOrderData.po_number"
    }
  }) : _vm._e(), _vm.editDlgTitle === 'DT号' ? _c('v-text-field', {
    attrs: {
      "label": "DT号",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "clearable": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editOrderData.out_number,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData, "out_number", $$v);
      },
      expression: "editOrderData.out_number"
    }
  }) : _vm._e(), _vm.editDlgTitle === '订单进展描述' ? _c('v-text-field', {
    attrs: {
      "label": "当订单无工单时将呈现给企业用户的文字信息",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "clearable": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editOrderData.no_order_text,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData, "no_order_text", $$v);
      },
      expression: "editOrderData.no_order_text"
    }
  }) : _vm._e(), _vm.editDlgTitle === '客户' ? _c('v-autocomplete', {
    attrs: {
      "label": "客户",
      "autocomplete": "off",
      "items": _vm.enterpriseList,
      "loading": _vm.isEnterpriseLoading,
      "search-input": _vm.searchEnterprise,
      "item-text": "name",
      "item-value": "_id",
      "return-object": false,
      "rules": [_vm.rules.required],
      "disabled": _vm.orderData.status > 0,
      "hide-no-data": "",
      "outlined": "",
      "dense": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchEnterprise = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchEnterprise = $event;
      }
    },
    model: {
      value: _vm.editOrderData.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData, "enterprise", $$v);
      },
      expression: "editOrderData.enterprise"
    }
  }) : _vm._e(), _vm.editDlgTitle === '客户' && _vm.enterpriseList && _vm.enterpriseList.length ? _c('v-autocomplete', {
    attrs: {
      "label": "分支机构",
      "autocomplete": "off",
      "items": _vm.enterpriseList[0].branch,
      "hide-no-data": "",
      "outlined": "",
      "clearable": "",
      "dense": ""
    },
    model: {
      value: _vm.editOrderData.branch,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData, "branch", $$v);
      },
      expression: "editOrderData.branch"
    }
  }) : _vm._e(), _vm.editDlgTitle === '销售人员' ? _c('v-autocomplete', {
    attrs: {
      "label": "分配销售",
      "item-text": "personal.name",
      "rules": [_vm.rules.selected],
      "item-value": "_id",
      "loading": _vm.isSalesLoading,
      "search-input": _vm.searchSales,
      "items": _vm.salesList,
      "hide-no-data": "",
      "outlined": "",
      "dense": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchSales = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchSales = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : "未设置名称"))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }], null, false, 626566227),
    model: {
      value: _vm.editOrderData.sales,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData, "sales", $$v);
      },
      expression: "editOrderData.sales"
    }
  }) : _vm._e(), _vm.editDlgTitle === '数量' ? _c('v-text-field', {
    attrs: {
      "label": "数量",
      "type": "number",
      "autocomplete": "off",
      "rules": [_vm.rules.required, _vm.rules.negative],
      "clearable": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editProduct.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "quantity", $$v);
      },
      expression: "editProduct.quantity"
    }
  }) : _vm._e(), _vm.editDlgTitle === '单价' ? _c('v-text-field', {
    attrs: {
      "label": "单价",
      "type": "number",
      "autocomplete": "off",
      "rules": [_vm.rules.required, _vm.rules.negative],
      "clearable": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editProduct.sales_price,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "sales_price", $$v);
      },
      expression: "editProduct.sales_price"
    }
  }) : _vm._e(), _vm.editDlgTitle === '发票信息' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.invoice_info.invoice_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.invoice_info, "invoice_type", $$v);
      },
      expression: "editOrderData.invoice_info.invoice_type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "增值税普通发票",
      "value": 0
    }
  }), _c('v-radio', {
    attrs: {
      "label": "增值税专用发票",
      "value": 1
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "抬头名称",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.invoice_info.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.invoice_info, "title", $$v);
      },
      expression: "editOrderData.invoice_info.title"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "统一社会信用代码",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.invoice_info.license_code,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.invoice_info, "license_code", $$v);
      },
      expression: "editOrderData.invoice_info.license_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "开户行",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.invoice_info.bank_name,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.invoice_info, "bank_name", $$v);
      },
      expression: "editOrderData.invoice_info.bank_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "银行账号",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.invoice_info.bank_account,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.invoice_info, "bank_account", $$v);
      },
      expression: "editOrderData.invoice_info.bank_account"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "企业地址",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.invoice_info.license_address,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.invoice_info, "license_address", $$v);
      },
      expression: "editOrderData.invoice_info.license_address"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "企业电话",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.invoice_info.license_telephone,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.invoice_info, "license_telephone", $$v);
      },
      expression: "editOrderData.invoice_info.license_telephone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "*发票接收人",
      "rules": [_vm.rules.required],
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.invoice_info.delivery_name,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.invoice_info, "delivery_name", $$v);
      },
      expression: "editOrderData.invoice_info.delivery_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "*接收人电话",
      "rules": [_vm.rules.required],
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.invoice_info.delivery_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.invoice_info, "delivery_phone", $$v);
      },
      expression: "editOrderData.invoice_info.delivery_phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "*发票邮寄地址",
      "rules": [_vm.rules.required],
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.invoice_info.delivery_address,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.invoice_info, "delivery_address", $$v);
      },
      expression: "editOrderData.invoice_info.delivery_address"
    }
  })], 1)], 1) : _vm._e(), _vm.editDlgTitle === '收货信息' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "收货人姓名",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.delivery_info.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.delivery_info, "name", $$v);
      },
      expression: "editOrderData.delivery_info.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "收货人电话",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.delivery_info.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.delivery_info, "phone", $$v);
      },
      expression: "editOrderData.delivery_info.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "收货人地址",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.isEdit || _vm.orderData.status >= 2
    },
    model: {
      value: _vm.editOrderData.delivery_info.address,
      callback: function callback($$v) {
        _vm.$set(_vm.editOrderData.delivery_info, "address", $$v);
      },
      expression: "editOrderData.delivery_info.address"
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("关闭")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.editValid || !_vm.isEdit
    },
    on: {
      "click": _vm.submitEdit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }