<template>
  <div>
    <v-card-text class="text-center">
      <v-data-table
        :headers="headerAsset"
        :items="selectedAsset"
        :server-items-length="selectedAsset.length"
        :footer-props="{'items-per-page-options': [5, 10, 15]}"
        item-key="_id"
        return-object="false"
        dense
      >
        <template v-slot:item.name="{ item }">
          <widgets-asset-dialogs :getId="item._id">
            <div>
              <v-tooltip bottom v-if="item.isLock">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small>lock</v-icon>
                </template>
                <span>设备锁定中</span>
              </v-tooltip>
              {{item.name}}
              <v-tooltip bottom v-if="item.ownerType === 'Enterprise'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small>corporate_fare</v-icon>
                </template>
                <span>企业自有设备</span>
              </v-tooltip>
              <v-tooltip bottom v-else-if="item.ownerType === 'Account'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small>person</v-icon>
                </template>
                <span>个人自有设备</span>
              </v-tooltip>
            </div>
          </widgets-asset-dialogs>
        </template>
        <template v-slot:item.product="{ item }">
          <span v-if="item.product" @click="openProduct(item.product)" class="v-list-item--link">{{item.product.name}} - {{item.product.part_number}}</span>
        </template>
        <template v-slot:item.state="{ item }">
          {{item.state.name}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="unlinkAsset(item)">remove</v-icon>
        </template>
      </v-data-table>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-btn block text @click="openCreateAsset()"><v-icon left>add</v-icon>创建新的设备</v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn block text color="secondary" @click="selectAsset()"><v-icon left>link</v-icon>关联现有设备</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="dlgAsset" persistent scrollable max-width="750px">
      <widgets-select-asset ref="select_asset" :vendor="vendor" :enterprise="enterprise" key="selectAsset" :isRepairService="true" :selectItem="selectedAsset" v-on:Close="closeAsset" v-on:Selected="setAssetList"></widgets-select-asset>
    </v-dialog>
    <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
      <widgets-product-dialogs v-if="activeType === 'Product'" :id="activeId" :key="activeId" v-on:Close="closeInfo()"></widgets-product-dialogs>
    </v-dialog>
    <v-dialog v-model="dlgCreateAsset" persistent scrollable max-width="600px">
      <v-form v-model="validAsset" @submit.prevent="createAsset">
        <v-card>
          <v-card-title>
            新增设备
            <v-spacer></v-spacer>
            <v-btn icon @click="closeCreateAsset"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row align="center" justify="center" v-if="currentUser">
                <v-col cols="2" class="text-right" v-if="currentUser.hasAdmin.includes('enterprise') || currentUser.employer.ownerType === 'Dealer'">
                  设备所属
                </v-col>
                <v-col cols="10" v-if="currentUser.hasAdmin.includes('enterprise') || currentUser.employer.ownerType === 'Dealer'">
                  <v-autocomplete
                    v-model="assetForm.editedItem.ownerType"
                    :items="assetForm.ownerType"
                    :rules="[rules.required]"
                    item-text="name"
                    item-value="code"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                    label="设备所属"
                    placeholder="设备归属人"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" class="text-right" v-if="currentUser.hasAdmin.includes('enterprise') || currentUser.employer.ownerType === 'Dealer'">
                  设备用户
                </v-col>
                <v-col cols="10" v-if="currentUser.hasAdmin.includes('enterprise') || currentUser.employer.ownerType === 'Dealer'">
                  <v-autocomplete
                    v-model="assetForm.editedItem.account"
                    :rules="[rules.required]"
                    :items="employeeList"
                    return-object
                    autocomplete="off"
                    outlined
                    dense
                    item-text="personal.name"
                    item-value="_id"
                    label="选择用户"
                    hide-details
                    placeholder="输入员工姓名、电话或手机号码进行查找"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                        <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2" class="text-right">
                  品牌厂商
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="assetForm.editedItem.vendor"
                    :items="vendorList"
                    @change="setProductList"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                    label="产品供应商"
                    placeholder="请选择产品供应商"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" class="text-right">
                  品牌类型
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="assetForm.editedItem.product_type"
                    :items="typeList"
                    @change="setProductList"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                    label="产品类型"
                    placeholder="请选择产品类型"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" class="text-right">
                  产品
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="assetForm.editedItem.product"
                    :items="productList"
                    :rules="[rules.required, rules.selected]"
                    @change="setWarrantyTime()"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                    label="关联产品"
                    placeholder="请选择设备关联的产品"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{data.item.name}} - {{data.item.part_number}}</v-list-item-title>
                        <v-list-item-subtitle v-if="data.item.it_info">{{data.item.it_info.ram}}G - {{data.item.it_info.network}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2" class="text-right">
                  序列号
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="assetForm.editedItem.serial_number"
                    :rules="[rules.required]"
                    type="text"
                    label="序列号"
                    placeholder="产品唯一标示SN号码"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeCreateAsset">取消</v-btn>
            <v-btn color="secondary" depressed @click="createAsset" :disabled="!validAsset">创建</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {
  FETCH_ASSET, FETCH_ASSETSTATE_LIST,
  FETCH_EMPLOYEE_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCTTYPE_LIST, FETCH_VENDOR_LIST,
  PUBLISH_ASSET
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  props: {
    selectItem: {
      type: Array
    },
    enterprise: {
      type: String
    },
    vendor: {
      type: String
    }
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      headerAsset: [
        { text: '名称', value: 'name', width: "155px" },
        { text: '状态', value: 'state', width: "155px" },
        { text: '产品', value: 'product'},
        { text: '取消关联', value: 'actions', sortable: false },
      ],
      selectedAsset:[],
      activeId: '',
      activeType: '',
      dlgInfo: false,
      dlgAsset: false,
      dlgCreateAsset: false,
      validAsset: true,
      assetForm: {
        menu_purchase: false,
        ownerType: [{name: '企业所有', code: 'Enterprise'}, {name: '个人所有', code: 'Account'}],
        editedItem: {
          _id: '',
          ownerType: '',
          serial_number: '',
          vendor: '',
          product_type: '',
          product: '',
          warranty_time: '',
          purchase_time: '',
          warranty_deadline: '',
          state: '',
          account: '',
        },
        defaultItem: {
          _id: '',
          ownerType: '',
          serial_number: '',
          vendor: '',
          product_type: '',
          product: '',
          warranty_time: '',
          purchase_time: '',
          warranty_deadline: '',
          state: '',
          account: '',
        },
        uploading: false,
        progressUpload: 0,
      },
    }
  },
  mounted() {},
  watch: {},
  created() {
    this.initiate()
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "employeeList",
      "vendorList",
      "typeList",
      "productList",
      "stateList",
    ]),
  },
  methods: {
    getAssetList(ids = []) {
      return Promise.all(ids.map(async (id) => {
        if (!id) return null
        const info = await store.dispatch(FETCH_ASSET, id)
        return info;
      }))
    },
    initiate() {
      this.getAssetList(this.selectItem).then(data => {
        if (data.length) {
          let strVendor = data[0].vendor
          let diffVendor = data.find(item => item.vendor !== strVendor)
          if (diffVendor) {
            store.commit(SET_ERROR, {msg: '报修申请只能选择同一个厂商品牌的设备。'});
          } else {
            this.selectedAsset = data.concat()
          }
        }
      })

    },
    openProduct(objProduct) {
      this.activeId = objProduct._id
      this.activeType = 'Product'
      this.dlgInfo = true
    },
    closeInfo() {
      this.dlgInfo = false
    },
    unlinkAsset(asset) {
      this.selectedAsset.splice(this.selectedAsset.indexOf(asset), 1)
    },
    selectAsset() {
      if (this.$refs.select_asset) this.$refs.select_asset.getAssetList()
      this.dlgAsset = true
    },
    openCreateAsset() {
      Promise.all([
        store.dispatch(FETCH_ASSETSTATE_LIST, 'isEnable=true'),
        store.dispatch(FETCH_EMPLOYEE_LIST, '&limit=1000&enterprise='+ this.enterprise),
        store.dispatch(FETCH_PRODUCTTYPE_LIST, 'isEnable=true'),
        store.dispatch(FETCH_VENDOR_LIST, 'limit=-1&isEnable=true&key=Apple,HUAWEI,'+ encodeURIComponent('华为')),
      ]).then(() =>{
        store.dispatch(FETCH_PRODUCT_LIST, 'isEnable=true&limit=0&vendor='+ this.vendorList.map((vendor) => vendor._id))
        this.dlgCreateAsset = true
      })
    },
    closeCreateAsset() {
      this.dlgCreateAsset = false
      this.$nextTick(() => {
        store.dispatch(FETCH_PRODUCT_LIST, 'isEnable=true&limit=0')
        this.assetForm.editedItem = Object.assign({}, this.assetForm.defaultItem)
      })
    },
    setWarrantyDeadline() {
      this.assetForm.menu_purchase = false
      if (this.assetForm.editedItem.warranty_time && this.assetForm.editedItem.purchase_time) {
        let purchase_time = moment(this.assetForm.editedItem.purchase_time)
        purchase_time = purchase_time.format('YYYY-MM-DD')
        let warranty_deadline = moment(purchase_time).add(this.assetForm.editedItem.warranty_time, 'M')
        warranty_deadline = moment(warranty_deadline).subtract(1, 'd')
        warranty_deadline = moment(warranty_deadline).format('YYYY-MM-DD')
        this.assetForm.editedItem.warranty_deadline = warranty_deadline
        this.$forceUpdate()
      }
    },
    setWarrantyTime() {
      if (this.assetForm.editedItem.product) {
        let objProduct = this.productList.find(o => o._id === this.assetForm.editedItem.product)
        this.assetForm.editedItem.vendor = objProduct.vendor._id
        this.assetForm.editedItem.product_type = objProduct.product_type._id
        if (objProduct.guarantee) {
          this.assetForm.editedItem.warranty_time = objProduct.guarantee
        } else {
          this.assetForm.editedItem.warranty_time = 0
        }
        if (this.assetForm.editedItem.purchase_time) this.setWarrantyDeadline()
      }
    },
    setProductList() {
      let strQuery = 'isEnable=true&limit=100'
      if (this.assetForm.editedItem.vendor) strQuery += '&vendor='+ this.assetForm.editedItem.vendor
      if (this.assetForm.editedItem.product_type) strQuery += '&type='+ this.assetForm.editedItem.product_type
      store.dispatch(FETCH_PRODUCT_LIST, strQuery)
    },
    createAsset() {
      let strInsert = {}
      strInsert.name = this.assetForm.editedItem.serial_number
      strInsert.serial_number = this.assetForm.editedItem.serial_number
      strInsert.vendor = this.assetForm.editedItem.vendor
      strInsert.productType = this.assetForm.editedItem.product_type
      strInsert.product = this.assetForm.editedItem.product
      strInsert.purchase_time = this.assetForm.editedItem.purchase_time
      strInsert.warranty_time = this.assetForm.editedItem.warranty_time
      strInsert.warranty_deadline = this.assetForm.editedItem.warranty_deadline
      strInsert.enterprise = this.enterprise
      strInsert.ownerType = this.assetForm.editedItem.ownerType
      if (this.stateList && this.stateList.length) {
        let usedState = this.stateList.find(item => item.name === '在用')
        if (!usedState) return store.commit(SET_ERROR, {msg: '请在系统设置中配置资产在用状态。'})
        strInsert.state = usedState._id
      } else {
        return store.commit(SET_ERROR, {msg: '请在系统设置中配置资产在用状态。'})
      }
      if (this.currentUser.hasAdmin.includes('enterprise') || this.currentUser.employer.ownerType === 'Dealer') {
        strInsert.account = this.assetForm.editedItem.account._id
        strInsert.user = {
          name: this.assetForm.editedItem.account.personal.name || '',
          code: this.assetForm.editedItem.account.employer.employeeNo || '',
          phone: this.assetForm.editedItem.account.phone,
          email: this.assetForm.editedItem.account.username,
          city: this.assetForm.editedItem.account.personal.city || '',
        }
      } else {
        strInsert.account = this.currentUser._id
        strInsert.user = {
          name: this.currentUser.personal.name || '',
          code: this.currentUser.employer.employeeNo || '',
          phone: this.currentUser.phone,
          email: this.currentUser.username,
          city: this.currentUser.personal.city || '',
        }
      }
      store.dispatch(PUBLISH_ASSET, strInsert)
        .then((data) => {
          store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
          store.dispatch(FETCH_ASSET, data.data._id).then((asset) => {
            this.selectedAsset.push(asset)
            this.$emit('RepairVendor', strInsert.vendor)
            this.dlgCreateAsset = false
          }).catch((err) => {
            store.commit(SET_ERROR, {msg: err});
          })
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    closeAsset() {
      this.dlgAsset = false
    },
    setAssetList(data) {
      this.selectedAsset = [...data.filter(item => !item.isLock)]
      if (data.close) {
        this.dlgAsset = false
      }
      if (data && data.length) {
        this.$emit('RepairVendor', data[0].vendor)
      } else {
        this.$emit('RepairVendor', '')
      }
    },
  }
}
</script>
