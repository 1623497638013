import { MaterialService } from "@/common/http";
import {
  FETCH_MATERIAL_LIST,
  FETCH_MATERIAL,
  PUBLISH_MATERIAL,
  EDIT_MATERIAL,
  AUTH_MATERIAL,
  BLOCK_MATERIAL,
  DELETE_MATERIAL,
} from "./actions.type";
import {
  SET_ERROR,
  LOAD_MATERIALS,
  SET_MATERIAL_LIST,
  SET_MATERIAL,
} from "./mutations.type"

const state = {
  materialLoading: true,
  materialList: [],
  materialCount: 0,
  material: {},
};

const getters = {
  material: state => state.material,
  materialList: state => state.materialList,
  materialCount: state => state.materialCount,
  materialLoading: state => state.materialLoading,
};

const actions = {
  [FETCH_MATERIAL_LIST]({ commit }, slug) {
    commit(LOAD_MATERIALS);
    return new Promise((resolve, reject) => {
      MaterialService.get('', 'list?'+slug)
        .then(({ data }) => {
          commit(SET_MATERIAL_LIST, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_MATERIAL]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      MaterialService.get('', slug)
        .then(({ data }) => {
          commit(SET_MATERIAL, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_MATERIAL](context, material) {
    return MaterialService.create('', material);
  },
  [EDIT_MATERIAL](context, material) {
    return MaterialService.update(material._id, material);
  },
  [AUTH_MATERIAL](context, material) {
    return MaterialService.auth(material._id)
  },
  [BLOCK_MATERIAL](context, material) {
    return MaterialService.block(material._id, material.reply)
  },
  [DELETE_MATERIAL](context, material) {
    return MaterialService.destroy(material._id)
  }
};

const mutations = {
  [LOAD_MATERIALS] (state) {
    state.materialLoading = true;
  },
  [SET_MATERIAL_LIST](state, list) {
    state.materialList = list.material;
    state.materialCount = list.query.count;
    state.materialLoading = false;
  },
  [SET_MATERIAL](state, material) {
    state.material = material;
    state.materialLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
