<template>
  <div>
    <span :color="color" dark text class="v-list-item--link" @click="doAction('open_asset')">
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    <v-dialog v-model="dialog" persistent scrollable width="750">
      <v-card v-if="assetInfo.product">
        <v-card-title>
          {{assetInfo.name ? assetInfo.name : '设备信息'}}
          <v-chip class="ma-2" label small v-if="assetInfo.asset_type === 3" color="warning"><v-icon small left>currency_exchange</v-icon>租赁设备</v-chip>
          <v-chip class="ma-2" label small v-else-if="assetInfo.ownerType === 'Account'" color="warning"><v-icon small left>corporate_fare</v-icon>个人设备</v-chip>
          <v-chip class="ma-2" label small v-else-if="assetInfo.ownerType === 'Enterprise'" color="primary"><v-icon small left>corporate_fare</v-icon>企业自有</v-chip>
          <v-chip class="ma-2" label small v-else color="secondary"><v-icon small left>verified</v-icon>经销商服务</v-chip>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-expansion-panels v-model="openPanel" multiple accordion flat>
            <v-expansion-panel>
              <v-expansion-panel-header>基本信息</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="2" class="text-right">设备名称</v-col>
                  <v-col cols="4" class="text--secondary">{{assetInfo.name}}</v-col>

                  <v-col cols="2" class="text-right">设备状态</v-col>
                  <v-col cols="4" class="text--secondary">{{assetInfo.state ? assetInfo.state.name : assetInfo.state}} <span v-if="assetInfo.state && assetInfo.state.name === '维修'"> - [{{ assetInfo.repair_state | repairStatus }}]</span></v-col>

                  <v-col cols="2" class="text-right">产品</v-col>
                  <v-col cols="10" class="text--secondary v-list-item--link" @click="doAction('open_product', assetInfo.product)">{{assetInfo.product.name}}<v-icon class="mx-2">read_more</v-icon></v-col>

                  <v-col cols="2" class="text-right">设备序列号</v-col>
                  <v-col cols="10" class="text--secondary">{{assetInfo.product.product_type.noSN ? '产品类型不需要序列号' : assetInfo.serial_number}}</v-col>

                  <v-col cols="2" class="text-right">设备标签</v-col>
                  <v-col cols="4" class="text--secondary"><v-chip class="mx-2" label small v-for="(label, index) in assetInfo.label" :key="index">{{ label }}</v-chip></v-col>

                  <v-col cols="2" class="text-right">外部编号</v-col>
                  <v-col cols="4" class="text--secondary">{{assetInfo.out_number}}</v-col>

                  <v-col cols="2" class="text-right">所属企业</v-col>
                  <v-col cols="10" class="text--secondary v-list-item--link" @click="doAction('open_enterprise', assetInfo.enterprise)" v-if="assetInfo.enterprise">{{assetInfo.enterprise.name}}<v-icon class="mx-2">read_more</v-icon> {{assetInfo.branch ? '-'+ assetInfo.branch : ''}}</v-col>
                  <v-col cols="10" class="text--secondary" v-else></v-col>

                  <v-col cols="2" class="text-right">使用人员</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.account"><widgets-employee-dialogs :account="assetInfo.account._id" class="v-list-item--link">{{assetInfo.account.personal ? assetInfo.account.personal.name : '*未设置姓名'}}<v-icon class="mx-2">read_more</v-icon></widgets-employee-dialogs></v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>详细信息</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="2" class="text-right">品牌</v-col>
                  <v-col cols="4" class="text--secondary">{{assetInfo.product.vendor && assetInfo.product.vendor.name ? assetInfo.product.vendor.name : ''}}</v-col>
                  <v-col cols="2" class="text-right">产品类型</v-col>
                  <v-col cols="4" class="text--secondary">{{assetInfo.product.product_type && assetInfo.product.product_type.name ? assetInfo.product.product_type.name : ''}}</v-col>
                  <v-col cols="2" class="text-right">购买日期</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.purchase_time">{{assetInfo.purchase_time | formatDate}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">质保期限</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.warranty_time">{{assetInfo.warranty_time}}个月</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">质保到期</v-col>
                  <v-col cols="10" class="text--secondary" v-if="assetInfo.warranty_deadline">{{assetInfo.warranty_deadline | formatDate}}（{{assetInfo.warranty_deadline | fromDate}}）</v-col>
                  <v-col cols="10" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">延保类型</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.guaranteed_type">{{ guaranteedType.filter(g => assetInfo.guaranteed_type.includes(g.code)).map(g => g.name) }}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">延保日期</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.guaranteed_time">{{assetInfo.guaranteed_time | formatDate}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">延保期限</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.guaranteed_limit">{{assetInfo.guaranteed_limit}}个月</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">延保到期</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.guaranteed_deadline">{{assetInfo.guaranteed_deadline | formatDate}}（{{assetInfo.guaranteed_deadline | fromDate}}）</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">所在仓库</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.warehouse">{{assetInfo.warehouse.name}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">入库日期</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.warehouse_time">{{assetInfo.warehouse_time | formatDate}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">设备来源</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.asset_from">{{assetInfo.asset_from}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">设备情况</v-col>
                  <v-col cols="4" class="text--secondary" v-if="Array.isArray(assetInfo.asset_state)">{{assetInfo.asset_state.map(state => assetState.find(o => o.code === state).name)}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else-if="Array.isArray(assetInfo.asset_state)">{{!isNaN(assetInfo.asset_state) ? assetState.find(o => o.code === assetInfo.asset_state).name : ''}}</v-col>
                  <v-col cols="2" class="text-right">SIM卡实名</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.sim_verified !== undefined">{{assetInfo.sim_verified ? '是' : '否'}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                </v-row>
                <v-row v-if="assetInfo.asset_type === 3">
                  <v-col cols="2" class="text-right">租赁方式</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info">{{assetInfo.rent_info.rent_type === 0 ? '融资租赁' : '经营租赁'}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  <v-col cols="2" class="text-right">起租日期</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info && assetInfo.rent_info.lease_start">{{assetInfo.rent_info.lease_start | formatDate}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  <v-col cols="2" class="text-right">租期</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info && assetInfo.rent_info.lease_term">{{assetInfo.rent_info.lease_term / 365}} 年</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  <v-col cols="2" class="text-right">支付周期</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info">{{assetInfo.rent_info.payment_cycle === '0' ? '月付' : assetInfo.rent_info.payment_cycle === '1' ? '季付' : '半年付'}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  <v-col cols="2" class="text-right">总期数</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info">{{assetInfo.rent_info.total_period}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  <v-col cols="2" class="text-right">租赁到期</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info && assetInfo.rent_info.expiry_date">{{assetInfo.rent_info.expiry_date | formatDate}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  <v-col cols="2" class="text-right">付款日</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info">{{assetInfo.rent_info.payment_day}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  <v-col cols="2" class="text-right">客户帐期</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info">{{assetInfo.rent_info.payment_period}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  <v-col cols="2" class="text-right">投资方</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info && assetInfo.rent_info.stakeholders">{{assetInfo.rent_info.stakeholders.investor}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  <v-col cols="2" class="text-right">代理商</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info && assetInfo.rent_info.stakeholders">{{assetInfo.rent_info.stakeholders.agent}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  <v-col cols="2" class="text-right">回收商</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info && assetInfo.rent_info.stakeholders">{{assetInfo.rent_info.stakeholders.recycler}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  <v-col cols="2" class="text-right">签约方</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.rent_info && assetInfo.rent_info.stakeholders">{{assetInfo.rent_info.stakeholders.party}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else>-</v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="2" class="text-right">Apple ID</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.apple_id">{{assetInfo.apple_id}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">Password</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.apple_password">{{assetInfo.apple_password}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">是否ABM</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.isABM !== undefined">{{assetInfo.isABM ? '是' : '否'}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">SIM卡号</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.sim_code">{{assetInfo.sim_code}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">MAC地址</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.mac_address">{{assetInfo.mac_address}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">IMEI编号</v-col>
                  <v-col cols="4" class="text--secondary" v-if="assetInfo.imei_number">{{assetInfo.imei_number}}</v-col>
                  <v-col cols="4" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">ICCID串号</v-col>
                  <v-col cols="10" class="text--secondary" v-if="assetInfo.iccid_code">{{assetInfo.iccid_code}}</v-col>
                  <v-col cols="10" class="text--secondary" v-else></v-col>
                  <v-col cols="2" class="text-right">备注信息</v-col>
                  <v-col cols="10" class="text--secondary" v-if="assetInfo.remarks"><pre>{{assetInfo.remarks || '无概述信息'}}</pre></v-col>
                  <v-col cols="10" class="text--secondary" v-else></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" v-if="assetInfo.enterprise && assetInfo.enterprise.asset_field">
                    <v-row v-for="field in assetInfo.enterprise.asset_field" :key="field._id">
                      <v-col cols="2" class="text-right">{{field.name}}</v-col>
                      <v-col cols="4" class="text--secondary">{{assetInfo[field._id]}}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-if="assetInfo.files">
                    <v-list subheader dense>
                      <v-subheader >附件清单</v-subheader>
                      <v-list-item v-for="file in assetInfo.files" :key="file.href">
                        <v-list-item-avatar>
                          <v-icon>text_snippet</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="file.name"></v-list-item-title>
                          <v-list-item-subtitle>{{file.creator.personal.name}} - {{file.createTime | formatTime}}上传</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="d-inline">
                          <v-btn icon><v-icon color="grey lighten-1" @click="doAction('download_file', '', file.href)">get_app</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>服务请求</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list dense v-if="(assetCase && assetCase.length) || (repairCase && repairCase.length)">
                  <v-list-item v-for="cases in assetCase" :key="cases._id">
                    <v-list-item-content>
                      <v-list-item-title v-if="caseType === 'service'">{{cases.catalog.name}} - {{cases.status | caseStatus}}</v-list-item-title>
                      <v-list-item-title v-else>{{cases.catalog.name}} - {{cases.status | repairStatus}}</v-list-item-title>
                      <v-list-item-subtitle>{{cases.createTime | formatTime}} - {{cases.case_number}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon v-if="caseType === 'repair'" @click="$router.push({path:'/dealer/workbench/maintain/list?serial_number='+ assetInfo.serial_number})">
                        <v-icon color="grey lighten-1">search</v-icon>
                      </v-btn>
                      <v-btn icon v-else @click="$router.push({path:'/dealer/workbench/'+ caseType +'/list?serial_number='+ assetInfo.serial_number})">
                        <v-icon color="grey lighten-1">search</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-for="cases in repairCase" :key="cases._id">
                    <v-list-item-content>
                      <v-list-item-title v-if="caseType === 'service'">{{cases.catalog.name}} - {{cases.status | repairStatus}}</v-list-item-title>
                      <v-list-item-title v-else>{{cases.catalog.name}} - {{cases.status | caseStatus}}</v-list-item-title>
                      <v-list-item-subtitle>{{cases.createTime | formatTime}} - {{cases.case_number}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon v-if="caseType === 'service'" @click="$router.push({path:'/dealer/workbench/maintain/list?serial_number='+ assetInfo.serial_number})">
                        <v-icon color="grey lighten-1">search</v-icon>
                      </v-btn>
                      <v-btn icon v-else @click="$router.push({path:'/dealer/workbench/service/list?serial_number='+ assetInfo.serial_number})">
                        <v-icon color="grey lighten-1">search</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>历史记录</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-timeline dense clipped align-top>
                  <v-timeline-item
                    v-for="(item, n) in assetLogs"
                    :key="n"
                    color="grey"
                    icon-color="grey lighten-2"
                    small
                    fill-dot
                    :icon="item.action === 'Create' ? 'add' : item.action === 'Batch' ? 'layers' : 'create'"
                  >
                    <v-row class="mt-n4">
                      <v-col cols="5">
                        <strong>{{ item.createTime | formatTime }}</strong> {{item.action | stringModule}}
                        <div class="caption"><widgets-employee-dialogs :account="item.operator._id" class="v-list-item--link">{{item.operator.personal.name ? item.operator.personal.name : '*未设置姓名'}}<v-icon class="mx-2">read_more</v-icon></widgets-employee-dialogs></div>
                      </v-col>
                      <v-col cols="7" class="text-right" v-if="item.record.length">
                        <div class="caption text-left" v-for="(change, j) in item.record" :key="j">
                          <span v-if="item.action === 'Batch'">{{change.text}}: {{change.value}}</span>
                          <span v-else>{{change}}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Asset_Manage')">
          <v-subheader>最后更新时间：{{ assetInfo.updateTime | formatTime }} </v-subheader>
          <v-subheader>
            <case-express-info v-if="assetInfo.transport" :id="assetInfo.transport._id" from="asset">物流状态</case-express-info>
          </v-subheader>
          <v-spacer></v-spacer>
          <v-btn text v-if="assetInfo.isLock && !assetCase.find(item => item.status < 2)" @click="doAction('unlock_asset', assetInfo)">解锁</v-btn>
          <v-btn text @click="closeDialog()">关闭</v-btn>
          <v-btn text color="secondary" :disabled="assetInfo.isLock" @click="$router.push({path:'/dealer/asset/edit/'+assetInfo._id})">编辑</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgInfo" persistent scrollable width="700">
      <widgets-enterprise-dialogs v-if="activeType === 'enterprise'" :id="activeId" :key="activeId" v-on:Close="doAction('close_info')"></widgets-enterprise-dialogs>
      <widgets-product-dialogs v-if="activeType === 'product'" :id="activeId" :key="activeId" v-on:Close="doAction('close_info')"></widgets-product-dialogs>
    </v-dialog>
  </div>
</template>
<script>
import Util from '@/common/util';
import {
  FETCH_ASSET, FETCH_URL, FETCH_LOGS_LIST, ASYNC_CASE_LIST, LOCK_ASSET, FETCH_ASSET_LIST
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {SET_ERROR} from "@/store/mutations.type";
import store from "@/store";
export default {
  props: {
    getId: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    caseType: {
      type: String,
      default: 'service'
    },
    divider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      dlgInfo: false,
      assetInfo: {},
      assetLogs: {},
      assetCase: [],
      repairCase: [],
      openPanel: [0],
      activeType: 'asset',
      activeId: null,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    assetType () {
      return Util.categories('assetType')
    },
    assetState () {
      return Util.categories('assetState')
    },
    guaranteedType () {
      return Util.categories('guaranteedType')
    },
  },
  mounted() { },
  watch: { },
  methods: {
    closeDialog () {
      this.assetInfo = Object.assign({}, {})
      this.assetLogs = Object.assign({}, {})
      this.dialog = false
    },
    async getAsset(id) {
      store.dispatch(FETCH_ASSET, id)
        .then(async (data) => {
          this.assetInfo = Object.assign({}, data)
          await store.dispatch(ASYNC_CASE_LIST, {form: this.caseType, query: 'page=1&limit=100&serial_number='+ data.serial_number})
            .then((cases) => {
              if (cases.cases && cases.cases.length) this.assetCase = cases.cases.concat()
            })
            .catch(err => console.log('err =>'+ JSON.stringify(err)))
          await store.dispatch(ASYNC_CASE_LIST, {form: this.caseType === 'service' ? 'repair' : 'service', query: 'page=1&limit=100&serial_number='+ data.serial_number})
            .then((cases) => {
              if (cases.cases && cases.cases.length) this.repairCase = cases.cases.concat()
            })
            .catch(err => console.log('err =>'+ JSON.stringify(err)))
          await store.dispatch(FETCH_LOGS_LIST, '&limit=10&key='+ id)
            .then((logs) => {
              this.assetLogs = Object.assign({}, logs.logs)
            })
        })
    },
    doAction(action, item = {}, additional) {
      switch (action) {
        case 'open_asset': {
          this.getAsset(this.getId)
          this.dialog = true
          break
        }
        case 'open_enterprise': {
          this.activeType = 'enterprise'
          this.activeId = item._id
          this.dlgInfo = true
          break
        }
        case 'open_product': {
          this.activeType = 'product'
          this.activeId = item._id
          this.dlgInfo = true
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          this.activeType = 'asset'
          this.activeId = null
          break
        }
        case 'download_file': {
          store.dispatch(FETCH_URL, additional)
            .then((fileurl) => {
              window.location.href = fileurl;
            })
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'unlock_asset': {
          store.dispatch(LOCK_ASSET, { _id: item._id, reply: false })
            .then(() => {
              store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
              this.getAsset(this.getId)
              store.dispatch(FETCH_ASSET_LIST)
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
        }
      }
    },
  }
}
</script>
