var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_vm.isAuthenticated && !_vm.$route.meta.public ? _c('core-drawer', {
    staticClass: "d-print-none"
  }) : _vm._e(), _vm.isAuthenticated && !_vm.$route.meta.public ? _c('core-toolbar', {
    staticClass: "d-print-none"
  }) : _vm._e(), _c('core-view'), _c('core-footer', {
    staticClass: "d-print-none"
  }), _c('widgets-error'), _c('widgets-popup-tips'), _c('widgets-update-message')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }