<template>
  <div :id="title" :style="{ width: width, height: height }"></div>
</template>
<script>
import * as echarts from 'echarts';
import "@/common/echarts.theme";
export default {
  props: {
    title: {
      type: String,
    },
    label: {
      type: Array,
    },
    data: {
      type: Object,
    },
    width: {
      type: String
    },
    height: {
      type: String
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.drawLine();
    })

  },
  watch: {
    data() {
      this.drawLine();
    }
  },
  methods: {
    drawLine() {
      let myChart = echarts.init(document.getElementById(this.title), 'espreport2');
      myChart.setOption({
        title: {
          text: this.title,
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: this.label,
          bottom: 10,
          left: 'center',
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.data.category
        },
        yAxis: {
          type: 'value'
        },
        series: this.data.series
      })
    }
  }
}
</script>
