var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.caseLogs && _vm.caseLogs.length ? _c('v-timeline', {
    attrs: {
      "dense": "",
      "clipped": "",
      "align-top": ""
    }
  }, _vm._l(_vm.caseLogs, function (item, n) {
    return _c('v-timeline-item', {
      key: n,
      attrs: {
        "small": "",
        "fill-dot": "",
        "icon": item.action === 'Create' ? 'add' : item.action === 'Batch' ? 'layers' : 'create'
      }
    }, [_c('v-row', {
      staticClass: "mt-n4"
    }, [_c('v-col', {
      attrs: {
        "cols": "5"
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))]), _vm._v(" " + _vm._s(_vm._f("stringModule")(item.action)) + " "), _c('div', {
      staticClass: "caption"
    }, [_c('widgets-employee-dialogs', {
      staticClass: "v-list-item--link",
      attrs: {
        "account": item.operator._id
      }
    }, [_vm._v(_vm._s(item.operator.personal.name ? item.operator.personal.name : '*未设置姓名')), _c('v-icon', {
      staticClass: "mx-2"
    }, [_vm._v("read_more")])], 1)], 1)]), item.record.length ? _c('v-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "7"
      }
    }, _vm._l(item.record, function (change, j) {
      return _c('div', {
        key: j,
        staticClass: "caption text-left"
      }, [item.action === 'Batch' ? _c('span', [_vm._v(_vm._s(change.text) + ": " + _vm._s(change.value))]) : _c('span', [_vm._v(_vm._s(change))])]);
    }), 0) : _vm._e()], 1)], 1);
  }), 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }