<template>
  <div class="timer">
    <div ref="startTimer">00 天 00:00:00</div>
  </div>
</template>
<script>
export default {
  name:'Timer',
  props:{
    time:{
      type:Number
    }
  },
  data() {
    return {
      timer: "",
      days: 0,
      hour: 0,
      minutes: 0,
      seconds: 0,
      cr: ''
    }
  },
  created() {
    this.timer = setInterval(this.startTimer, 1000);
  },
  methods: {
    startTimer() {
      this.seconds += 1;
      if (this.seconds >= 60) {
        this.seconds = 0;
        this.minutes = this.minutes + 1;
      }

      if (this.minutes >= 60) {
        this.minutes = 0;
        this.hour = this.hour + 1;
      }
      this.$refs.startTimer.innerHTML = (this.hour < 10 ? '0' + this.hour: this.hour) + ':' + (this.minutes < 10 ? '0' + this.minutes: this.minutes) + ':' + (this.seconds < 10 ? '0' + this.seconds: this.seconds);
      this.cr =  this.seconds
    },
    stop () {
      clearInterval(this.timer)
    },
    start () {
      this.timer = setInterval(this.startTimer, 1000)
    }
  }
}
</script>
————————————————
版权声明：本文为CSDN博主「star_29」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
原文链接：https://blog.csdn.net/star_29/article/details/105505567
