var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_vm._v(" 选择图标 "), _c('v-spacer'), _c('v-text-field', {
    staticClass: "ma-2",
    attrs: {
      "hide-details": "",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "label": "搜索图标名称"
    },
    model: {
      value: _vm.txtSearch,
      callback: function callback($$v) {
        _vm.txtSearch = $$v;
      },
      expression: "txtSearch"
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('Close');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticStyle: {
      "height": "450px"
    }
  }, [_c('v-container', _vm._l(_vm.filteredIcons, function (icon) {
    return _c('v-icon', {
      key: icon.id,
      staticClass: "ma-2",
      attrs: {
        "size": "48"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleSelectIcon(icon.id);
        }
      }
    }, [_vm._v(_vm._s(icon.id))]);
  }), 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('Close');
      }
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('Select', _vm.iconSelected);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(_vm._s(_vm.iconSelected))]), _vm._v(" 确定")], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }