var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "600"
    },
    on: {
      "input": function input($event) {
        return _vm.getEmployee(_vm.account);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          attrs: {
            "color": _vm.btnColor,
            "dark": "",
            "text": "",
            "ripple": false
          }
        }, 'span', attrs, false), on), [_vm.avatar ? _c('v-avatar', {
          staticClass: "mr-2"
        }, [_c('v-img', {
          attrs: {
            "src": _vm.ossURL + '/' + _vm.avatar,
            "aspect-ratio": "1"
          }
        })], 1) : _vm._e(), _vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2)];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [!_vm.isEdited && _vm.employeeInfo.personal && _vm.employeeInfo.employer ? _c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-avatar', {
    staticClass: "mr-2",
    attrs: {
      "size": "52"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.employeeInfo.personal.profile ? _vm.ossURL + '/' + _vm.employeeInfo.personal.profile : '/static/grey_silhouette.png',
      "aspect-ratio": "1",
      "contain": ""
    }
  })], 1), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.employeeInfo.personal.name ? _vm.employeeInfo.personal.name : '用户信息'))]), _vm.employeeInfo.isBlock ? _c('v-chip', {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("已注销")]) : _vm._e(), _vm.employeeInfo.status === 9 ? _c('v-chip', {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("已删除")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _vm.employeeInfo.status !== 9 ? _c('v-card-text', [_c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "accordion": "",
      "flat": ""
    },
    model: {
      value: _vm.panelInfo,
      callback: function callback($$v) {
        _vm.panelInfo = $$v;
      },
      expression: "panelInfo"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("人员资料")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("face")]), _vm._v("真实姓名")], 1), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(_vm._s(_vm.employeeInfo.personal.name))]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("corporate_fare")]), _vm._v("所属企业")], 1), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(_vm._s(_vm.employeeInfo.employer.ownerId.name) + " " + _vm._s(_vm.employeeInfo.employer.sub_corp ? ' - ' + _vm.employeeInfo.employer.sub_corp : ''))]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("folder_shared")]), _vm._v("所属部门")], 1), _vm.employeeInfo.employer.ownerType !== 'Enterprise' ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(_vm._s(_vm.employeeInfo.employer.department.map(function (item) {
    return item.name;
  })))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(_vm._s(_vm.employeeInfo.employer.subordinate || '-'))]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("perm_identity")]), _vm._v("所属角色")], 1), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(_vm._s(_vm.employeeInfo.employer.roles.map(function (item) {
    return item.name;
  })))]), _vm.employeeInfo.employer.ownerType !== 'Enterprise' ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("store")]), _vm._v("所属门店")], 1) : _vm._e(), _vm.employeeInfo.employer.ownerType !== 'Enterprise' ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(_vm._s(_vm.employeeInfo.employer.branch ? _vm.employeeInfo.employer.branch.name : '未分派门店'))]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("assignment_ind")]), _vm._v("员工编号")], 1), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(_vm._s(_vm.employeeInfo.employer.employeeNo))]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("stay_current_portrait")]), _vm._v("手机号码")], 1), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(_vm._s(_vm.employeeInfo.phone))]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("email")]), _vm._v("电子邮件")], 1), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(_vm._s(_vm.employeeInfo.username))]), _vm.employeeInfo.employer.ownerType === 'Enterprise' ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("room")]), _vm._v("地址")], 1) : _vm._e(), _vm.employeeInfo.employer.ownerType === 'Enterprise' ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(_vm._s(_vm.employeeInfo.personal.address))]) : _vm._e()], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_vm._v("自定义字段")]), _c('v-expansion-panel-content', _vm._l(_vm.employeeInfo.customFields.filter(function (f) {
    return !f._id.name.includes('已删除字段');
  }), function (item, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('v-icon', {
      staticClass: "mr-2"
    }, [_vm._v("list")]), _vm._v(_vm._s(item._id.name))], 1), _c('v-col', {
      staticClass: "text--secondary",
      attrs: {
        "cols": "8"
      }
    }, [_vm._v(_vm._s(item.value))])], 1);
  }), 1)], 1)], 1)], 1) : _vm._e(), _vm.employeeInfo.status !== 9 ? _c('v-divider') : _vm._e(), _vm.employeeInfo.status !== 9 && _vm.employeeInfo.employer.ownerType === 'Enterprise' && (_vm.currentUser._id === _vm.employeeInfo._id || _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.hasAdmin.includes('enterprise') || _vm.currentUser.permission.includes('Enterprise_Employee')) ? _c('v-card-actions', [!_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.resetPass
    }
  }, [_vm._v("重置密码")]) : _vm._e(), !_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.blockAccount
    }
  }, [_vm._v("注销用户")]) : _vm._e(), !_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.removeAccount
    }
  }, [_vm._v("删除用户")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("取消")]), !_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": _vm.editEmployee
    }
  }, [_vm._v("编辑")]) : _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": _vm.recover
    }
  }, [_vm._v("恢复")])], 1) : _vm._e(), _vm.employeeInfo.status !== 9 && _vm.employeeInfo.employer.ownerType === 'Dealer' && (_vm.currentUser._id === _vm.employeeInfo._id || _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Organize')) ? _c('v-card-actions', [!_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.resetPass
    }
  }, [_vm._v("重置密码")]) : _vm._e(), !_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.blockAccount
    }
  }, [_vm._v("注销用户")]) : _vm._e(), !_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.removeAccount
    }
  }, [_vm._v("删除用户")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("取消")]), !_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": _vm.editEmployee
    }
  }, [_vm._v("编辑")]) : _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": _vm.recover
    }
  }, [_vm._v("恢复")])], 1) : _vm._e()], 1) : _c('v-card', [_c('v-card-title', [_vm._v(" 编辑人员 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1)], 1), _vm.employeeInfo.personal && _vm.employeeInfo.employer ? _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "start",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var hover = _ref2.hover;
        return [_c('v-avatar', [_c('v-img', {
          attrs: {
            "src": _vm.editedEmployee.personal.profile ? _vm.ossURL + '/' + _vm.editedEmployee.personal.profile : '/static/grey_silhouette.png',
            "alt": "设置照片",
            "aspect-ratio": "1"
          }
        }, [_vm.Upload.uploading ? _c('v-progress-circular', {
          attrs: {
            "color": "secondary",
            "rotate": "360",
            "size": "48",
            "width": "5",
            "value": _vm.Upload.progressUpload
          }
        }) : _vm._e()], 1), hover ? _c('div', {
          staticClass: "show_upload",
          staticStyle: {
            "height": "100%",
            "cursor": "pointer"
          }
        }, [_c('v-icon', {
          on: {
            "click": _vm.uploadAvatar
          }
        }, [_vm._v("add_a_photo")])], 1) : _vm._e()], 1)];
      }
    }], null, false, 973718124)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工姓名",
      "hint": "员工姓名为用户的真实姓名",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedEmployee.personal.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee.personal, "name", $$v);
      },
      expression: "editedEmployee.personal.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("https")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "电子邮件",
      "hint": "电子邮件将作为登陆名称使用",
      "outlined": "",
      "dense": "",
      "required": "",
      "disabled": ""
    },
    model: {
      value: _vm.editedEmployee.username,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "username", $$v);
      },
      expression: "editedEmployee.username"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("stay_current_portrait")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.phonenum],
      "error-messages": _vm.showErr,
      "clearable": _vm.showErr !== '',
      "type": "number",
      "label": "手机号码",
      "hint": "电话号码将用于恢复密码和登陆凭证",
      "outlined": "",
      "dense": ""
    },
    nativeOn: {
      "mousewheel": function mousewheel($event) {
        $event.preventDefault();
      },
      "DOMMouseScroll": function DOMMouseScroll($event) {
        $event.preventDefault();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.editedEmployee.chkPhone ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 488914429),
    model: {
      value: _vm.editedEmployee.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "phone", $$v);
      },
      expression: "editedEmployee.phone"
    }
  })], 1)], 1), _vm.employeeInfo.employer.ownerType === 'Enterprise' && _vm.employeeInfo.employer.ownerId.branch ? _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("account_tree")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.employeeInfo.employer.ownerId.branch,
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择员工所属分公司"
    },
    model: {
      value: _vm.editedEmployee.employer.sub_corp,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee.employer, "sub_corp", $$v);
      },
      expression: "editedEmployee.employer.sub_corp"
    }
  })], 1)], 1) : _vm._e(), _vm.employeeInfo.employer.ownerType !== 'Enterprise' ? _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("account_tree")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.getDeptList(_vm.departments[0]),
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "value",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择所属部门",
      "multiple": ""
    },
    model: {
      value: _vm.editedEmployee.department,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "department", $$v);
      },
      expression: "editedEmployee.department"
    }
  })], 1)], 1) : _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("account_tree")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "所属部门",
      "hint": "请填写员工所属部门",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedEmployee.employer.subordinate,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee.employer, "subordinate", $$v);
      },
      expression: "editedEmployee.employer.subordinate"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("perm_identity")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.flatGroupRoles(_vm.groupList),
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择所属角色",
      "multiple": "",
      "disabled": _vm.editedEmployee._id === _vm.currentUser._id
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_typeof(data.item) !== 'object' ? [_c('v-list-item-content', {
          domProps: {
            "textContent": _vm._s(data.item)
          }
        })] : [_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "input-value": data.attrs.inputValue
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        })], 1)]];
      }
    }], null, false, 3296285918),
    model: {
      value: _vm.editedEmployee.roles,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "roles", $$v);
      },
      expression: "editedEmployee.roles"
    }
  })], 1)], 1), _vm.employeeInfo.employer.ownerType === 'Enterprise' ? _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("room")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "rules": [_vm.rules.required],
      "error-messages": _vm.check.chkAddressMessage,
      "clearable": _vm.check.chkAddressMessage !== '',
      "label": "收件地址",
      "hint": "收件地址为接收设备的目的地",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-fade-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.check.chkAddressLoading ? _c('v-progress-circular', {
          attrs: {
            "width": "2",
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "append-outer",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.copyAddress();
            }
          }
        }, [_vm._v("复制公司地址")])];
      },
      proxy: true
    }], null, false, 1555211762),
    model: {
      value: _vm.editedEmployee.address,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "address", $$v);
      },
      expression: "editedEmployee.address"
    }
  })], 1)], 1) : _vm._e(), _vm.employeeInfo.employer.ownerType !== 'Enterprise' ? _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("store")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.branchList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "请选择所属门店"
    },
    model: {
      value: _vm.editedEmployee.employer.branch,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee.employer, "branch", $$v);
      },
      expression: "editedEmployee.employer.branch"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("assignment_ind")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工编号",
      "hint": "员工编号为企业内部的员工编号",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedEmployee.employer.employeeNo,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee.employer, "employeeNo", $$v);
      },
      expression: "editedEmployee.employer.employeeNo"
    }
  })], 1)], 1), _vm._l(_vm.customFields, function (item, i) {
    return _c('v-row', {
      key: i,
      staticClass: "mt-n6",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-center",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-icon', {
      staticClass: "mt-n6",
      attrs: {
        "color": "primary lighten-4"
      }
    }, [_vm._v("list")])], 1), item.format.type === 'text' ? _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [item.format.mask ? _c('v-text-field', {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: item.format.mask,
        expression: "item.format.mask"
      }],
      attrs: {
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "label": item.name,
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _c('v-text-field', {
      attrs: {
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.format.type === 'selects' ? _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": item.format.items,
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e()], 1);
  })], 2)], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.resetPass
    }
  }, [_vm._v("重置密码")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("返回")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1), _c('v-dialog', {
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.dialogPass,
      callback: function callback($$v) {
        _vm.dialogPass = $$v;
      },
      expression: "dialogPass"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitPass.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validPass,
      callback: function callback($$v) {
        _vm.validPass = $$v;
      },
      expression: "validPass"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("更新密码")])]), _c('v-card-text', [_c('v-container', [_c('v-text-field', {
    attrs: {
      "label": "请输入新的密码",
      "rules": [_vm.rules.required],
      "type": _vm.showPass ? 'text' : 'password',
      "append-icon": _vm.showPass ? 'visibility_off' : 'visibility',
      "outlined": "",
      "dense": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPass = !_vm.showPass;
      }
    },
    model: {
      value: _vm.editedEmployee.password,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "password", $$v);
      },
      expression: "editedEmployee.password"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "请再次输入密码",
      "rules": [_vm.rules.required, _vm.rules.confirmpass],
      "type": _vm.showPass ? 'text' : 'password',
      "append-icon": _vm.showPass ? 'visibility_off' : 'visibility',
      "outlined": "",
      "dense": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPass = !_vm.showPass;
      }
    },
    model: {
      value: _vm.editedEmployee.repassword,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "repassword", $$v);
      },
      expression: "editedEmployee.repassword"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialogPass = false;
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.validPass
    },
    on: {
      "click": _vm.submitPass
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }