var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.caseData.isEdit ? _c('v-card-actions', [_c('v-subheader', [_vm._v("更新时间：" + _vm._s(_vm._f("formatTime")(_vm.caseData.updateTime)))]), _c('v-subheader', [_vm._v("工单持续时间：" + _vm._s(_vm._f("fromDate")(_vm.caseData.createTime)))]), _vm.caseData.status === 0 && _vm.caseData.accept_sla ? _c('v-subheader', [_vm._v(" 当前环节："), _c('widgets-baseTimer', {
    attrs: {
      "endTime": _vm.caseData.accept_sla.expectTime,
      "alertThreshold": _vm.caseData.accept_sla.sla_limit / 2,
      "warningThreshold": _vm.caseData.accept_sla.sla_limit,
      "type": "text"
    }
  })], 1) : _vm._e(), _vm.caseData.total_sla ? _c('v-subheader', [_vm._v(" 整体流程："), _c('widgets-baseTimer', {
    attrs: {
      "endTime": _vm.caseData.total_sla.expectTime,
      "alertThreshold": _vm.caseData.total_sla.sla_limit / 2,
      "warningThreshold": _vm.caseData.total_sla.sla_limit,
      "type": "text"
    }
  })], 1) : _vm._e(), _c('v-spacer'), _vm.caseData.status > 0 && _vm.caseData.status < 8 ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('change_status', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("change_circle")]), _vm._v("变更状态")], 1) : _vm._e(), _vm.caseData.status < 3 ? _c('div', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('cancel', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("cancel")]), _vm._v("取消服务")], 1), _vm.caseData.status > 0 && (_vm.caseData.out_req_type === '保外送修' || _vm.caseData.out_req_type === '保外维修') && !_vm.caseData.confirm_price ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('confirm', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("check")]), _vm._v("确认维修")], 1) : _vm.caseData.status === 0 ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('submit', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("check_circle")]), _vm._v("提交")], 1) : _vm._e()], 1) : _c('div', [_vm.caseData.returns && _vm.caseData.returns.express_corp ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('complete', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("check_circle")]), _vm._v("确认完成")], 1) : _vm.caseData.status === 3 ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('submit', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("check_circle")]), _vm._v("提交")], 1) : _vm._e()], 1), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v("关闭")])], 1) : _c('v-card-actions', [_c('v-subheader', [_vm._v("最后更新时间：" + _vm._s(_vm._f("formatTime")(_vm.caseData.updateTime)) + " ")]), _c('v-spacer'), _vm.caseData.status > 0 && _vm.caseData.status < 3 && (_vm.caseData.out_req_type === '保外送修' || _vm.caseData.out_req_type === '保外维修') && !_vm.caseData.confirm_price && _vm.currentUser._id === _vm.caseData.creator._id ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('confirm', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("check")]), _vm._v("确认维修")], 1) : _vm._e(), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v("关闭")])], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "350px"
    },
    model: {
      value: _vm.dlgAction,
      callback: function callback($$v) {
        _vm.dlgAction = $$v;
      },
      expression: "dlgAction"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("确认"), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.actionTitle))]), _vm._v("该服务请求？")]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.actionLabel) + " "), _vm.caseData.accept_sla && new Date() > new Date(_vm.caseData.accept_sla.expectTime) ? _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "label": "超时原因说明"
    },
    model: {
      value: _vm.action_remarks,
      callback: function callback($$v) {
        _vm.action_remarks = $$v;
      },
      expression: "action_remarks"
    }
  }) : _vm._e(), _vm.actionTitle === '完成' ? _c('div', {
    staticClass: "text-center"
  }, [_c('v-rating', {
    attrs: {
      "background-color": "secondary lighten-3",
      "color": "secondary",
      "hover": ""
    },
    model: {
      value: _vm.satisfaction.rating,
      callback: function callback($$v) {
        _vm.$set(_vm.satisfaction, "rating", $$v);
      },
      expression: "satisfaction.rating"
    }
  }), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "label": "服务建议",
      "rows": "3"
    },
    model: {
      value: _vm.satisfaction.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.satisfaction, "remarks", $$v);
      },
      expression: "satisfaction.remarks"
    }
  })], 1) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('cancel_action');
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction(_vm.actionKey, _vm.actionID);
      }
    }
  }, [_vm._v("确认")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }