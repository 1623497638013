<template>
  <core-card
    v-bind="$attrs"
    class="v-card--material-stats"
    v-on="$listeners"
  >
    <v-card
      :color="color"
      slot="offset"
      :class="elevation-5"
      class="pa-4"
    >
      <v-icon
        dark
        size="40"
      >
        {{ icon }}
      </v-icon>
    </v-card>
    <div class="text-xs-right">
      <p
        class="category font-weight-light"
        v-text="title"
      ></p>
      <h3 class="font-weight-black headline">{{ value }} <small>{{ smallValue }}</small></h3>
    </div>

    <template slot="actions">
      <v-icon
        :color="subIconColor"
        size="20"
        class="mr-2"
      >
        {{ subIcon }}
      </v-icon>
      <span
        :class="subTextColor"
        class="caption font-weight-light"
        v-text="subText"
      ></span>
    </template>
  </core-card>
</template>

<script>
import Card from '../core/Card'

export default {
  inheritAttrs: false,

  props: {
    ...Card.props,
    icon: {
      type: String,
      required: true
    },
    subIcon: {
      type: String,
      default: undefined
    },
    subIconColor: {
      type: String,
      default: undefined
    },
    subTextColor: {
      type: String,
      default: undefined
    },
    subText: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    value: {
      type: Number,
      default: 0
    },
    smallValue: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss">
  .v-card--material-stats {
    margin:25px;
    .v-offset {
      display: inline-block;
      margin-bottom: 0 !important;
      /*max-width: auto;*/
      padding: 0 16px 0;
    }

    .v-card {
      /*border-radius: 0px;*/
      /*flex: 0 1 auto;*/
    }

    .v-card__text {
      /*display: inline-block;*/
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
    }

    .v-card__actions {
      /*flex: 1 0 100%;*/
    }
  }
</style>
