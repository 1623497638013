var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.remarks, function (item, index) {
    return [_c('v-list-item', {
      key: index + '_remarks_title',
      attrs: {
        "three-line": ""
      }
    }, [_c('widgets-employee-dialogs', {
      attrs: {
        "account": item.account
      }
    }, [_c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": item.avatar ? _vm.ossURL + '/' + item.avatar : '/static/grey_silhouette.png'
      }
    })], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title) + " " + _vm._s(_vm._f("fromDate")(item.createTime)) + " " + _vm._s(item.private ? ' - 仅个人可见' : '') + " " + _vm._s(item.public ? ' - 客户可见' : ''))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(item.context))])], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.removeRemark(item);
        }
      }
    }, [_vm._v("delete")])], 1)], 1)], 1), _c('v-divider', {
      key: index + '_remarks_divider',
      attrs: {
        "inset": ""
      }
    })];
  }), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.createRemarks();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("rate_review")]), _vm._v("增加备注")], 1)], 1)], 1)], 1)], 2)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "550px"
    },
    model: {
      value: _vm.dlgRemarks,
      callback: function callback($$v) {
        _vm.dlgRemarks = $$v;
      },
      expression: "dlgRemarks"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 增加备注 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeRemarks
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "\u4EC5\u4E2A\u4EBA\u53EF\u89C1: ".concat(_vm.editedRemark.private ? '是' : '否')
    },
    on: {
      "change": function change($event) {
        return _vm.changeRemarkPublic();
      }
    },
    model: {
      value: _vm.editedRemark.private,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRemark, "private", $$v);
      },
      expression: "editedRemark.private"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "\u5BA2\u6237\u53EF\u89C1: ".concat(_vm.editedRemark.public ? '是' : '否')
    },
    on: {
      "change": function change($event) {
        return _vm.changeRemarkPrivate();
      }
    },
    model: {
      value: _vm.editedRemark.public,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRemark, "public", $$v);
      },
      expression: "editedRemark.public"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "备注内容",
      "value": ""
    },
    model: {
      value: _vm.editedRemark.context,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRemark, "context", $$v);
      },
      expression: "editedRemark.context"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeRemarks();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.editedRemark.context
    },
    on: {
      "click": function click($event) {
        return _vm.addRemarks(_vm.editedRemark);
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }