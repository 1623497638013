<template>
  <v-card-text v-if="notesList">
    <v-list three-line dense>
      <template v-for="(item, i) in notesList">
        <v-list-item :key="'remarks_' + i">
          <widgets-employee-dialogs :account="item.author._id">
            <v-list-item-avatar>
              <v-img :src="item.author.personal && item.author.personal.profile ? ossURL + '/' + item.author.personal.profile : '/static/grey_silhouette.png'"></v-img>
            </v-list-item-avatar>
          </widgets-employee-dialogs>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.author._id === currentUser._id ? "我" : item.author.personal.name }} - {{ item.createTime | fromDate }}
              <span class="grey" v-if="item.status === 1"> - 已作废</span>
              <span class="red--text text--accent-3" v-else-if="item.private"> - 仅个人可见</span>
              <span class="light-blue--text text--accent-3" v-else-if="item.public"> - 客户可见</span>
            </v-list-item-title>
            <v-subheader>
              <span class="grey" v-if="item.status === 1" v-html="item.content"></span>
              <span class="red--text text--accent-3" v-else-if="item.private" v-html="item.content"></span>
              <span class="light-blue--text text--accent-3" v-else-if="item.public" v-html="item.content"></span>
              <span v-else v-html="item.content">{{ item.content }}</span>
            </v-subheader>
          </v-list-item-content>
          <v-list-item-action v-if="item.author._id === currentUser._id && item.status === 0 && item._id">
            <v-btn icon>
              <v-icon @click="blockItem(item._id)">block</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="'remarks_divider_' + i" inset></v-divider>
      </template>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title align="end">
            <v-btn text @click="openEditDialog()">
              <v-icon left>rate_review</v-icon>增加备注
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="editDialog" persistent width="550px">
      <v-card>
        <v-card-title>增加备注<v-spacer></v-spacer><v-btn icon @click="closeEditDialog()"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="6"><v-switch v-model="editedItem.private" :label="`仅个人可见: ${editedItem.private ? '是' : '否'}`"></v-switch></v-col>
            <v-col cols="6" v-if="entityType !== 'PurchaseOrder'"><v-switch v-model="editedItem.public" :label="`客户可见: ${editedItem.public ? '是' : '否'}`"></v-switch></v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                auto-grow
                rows="3"
                v-model="editedItem.content"
                label="备注内容"
                value=""
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeEditDialog()">取消</v-btn>
          <v-btn text color="secondary" :disabled="!editedItem.content" @click="createItem()">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
  <v-overlay v-else :value="notesLoading" z-index="9999">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>
<script>
import store from "@/store";
import { FETCH_NOTES_LIST, PUBLISH_NOTES, REMOVE_NOTES } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";

export default {
  props: {
    entityType: {
      type: String,
      default: 'Case'
    },
    entityId: {
      type: String,
      required: true
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      notesList: [],
      notesLoading: true,
      editDialog: false,
      editedItem: {
        private: false,
        public: false,
        content: '',
      },
      defaultItem: {
        private: false,
        public: false,
        content: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  mounted() {},
  watch: {},
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.notesLoading = true
      store.dispatch(FETCH_NOTES_LIST, "entityType="+ this.entityType +"&entityId="+ this.entityId +"&type="+ this.type)
        .then((data) => {
          this.notesList = data.notes
          this.notesLoading = false
        })
    },
    openEditDialog() {
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      });
    },
    createItem() {
      this.editedItem['entityType'] = this.entityType
      this.editedItem['entityId'] = this.entityId
      this.editedItem['type'] = this.type

      store.dispatch(PUBLISH_NOTES, this.editedItem)
        .then(() => {
          this.getList()
          this.closeEditDialog()
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err.message })
        });
    },
    blockItem(id) {
      store.dispatch(REMOVE_NOTES, id)
        .then(() => {
          this.getList()
          store.commit(SET_ERROR, {msg: '作废成功。', color: 'primary'});
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err.message })
        });
    },
  },
}

</script>
