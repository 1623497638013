var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.filesList, function (file, i) {
    return [_c('v-list-item', {
      key: file.href
    }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("text_snippet")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(file.name)
      }
    })], 1), _c('v-list-item-action', {
      staticClass: "d-inline"
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.removeFile(i);
        }
      }
    }, [_vm._v("delete")])], 1), _c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.downloadFiles(file.href);
        }
      }
    }, [_vm._v("get_app")])], 1)], 1)], 1), _c('v-divider', {
      key: i + '_files_divider',
      attrs: {
        "inset": ""
      }
    })];
  }), _c('v-list-item', [_c('v-list-item-content', [_vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('input', {
    ref: "uploadFile",
    attrs: {
      "type": "file",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-list-item-title', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.uploadFile();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("attach_file")]), _vm._v("上传附件")], 1)], 1)], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }