var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: {
      width: _vm.width,
      height: _vm.height
    },
    attrs: {
      "id": "Heatmap"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }