var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v(_vm._s(_vm.product.name))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('Close');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _vm.product.pictures && _vm.product.pictures.length ? _c('v-carousel', {
    attrs: {
      "delimiter-icon": "remove",
      "show-arrows-on-hover": "",
      "height": "250"
    }
  }, _vm._l(_vm.product.pictures, function (item, i) {
    return _c('v-carousel-item', {
      key: i + '_' + item,
      attrs: {
        "reverse-transition": "fade-transition",
        "transition": "fade-transition"
      }
    }, [_c('v-img', {
      attrs: {
        "src": _vm.ossURL + '/' + item,
        "contain": "",
        "max-height": "250"
      }
    })], 1);
  }), 1) : _vm._e(), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("产品名称")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(_vm._s(_vm.product.name))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("产品简称")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.product.product_code))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("产品型号")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.product.part_number))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("品牌")]), _vm.product.vendor ? _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.product.vendor.name))]) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("产品类型")]), _vm.product.product_type ? _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.product.product_type.name))]) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.showMore = !_vm.showMore;
      }
    }
  }, [_vm._v(_vm._s(_vm.showMore ? '隐藏' : '查看') + "更多信息")]), _vm.product.isRent && (_vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Lease_Product') || _vm.currentUser.permission.includes('Lease_Admin') || _vm.currentUser.permission.includes('Lease_Audit')) ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.showRental = !_vm.showRental;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.showRental ? '隐藏' : '查看') + "租赁信息")]) : _vm._e()], 1), _vm.showRental ? _c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("官网价格")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.product.rentInfo.official_price.$numberDecimal) + "元")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("采购价格")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.product.rentInfo.purchase_price.$numberDecimal) + "元")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("采购折扣比例")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(parseFloat((_vm.product.rentInfo.purchase_price.$numberDecimal / _vm.product.rentInfo.official_price.$numberDecimal * 100).toFixed(2))) + "%")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("1年余值比例")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(parseFloat((_vm.product.rentInfo.SV._1_year_ratio * 100).toFixed(4))) + "%")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("2年余值比例")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(parseFloat((_vm.product.rentInfo.SV._2_year_ratio * 100).toFixed(4))) + "%")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("3年余值比例")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(parseFloat((_vm.product.rentInfo.SV._3_year_ratio * 100).toFixed(4))) + "%")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("1年到期余值")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(parseFloat((_vm.product.rentInfo.official_price.$numberDecimal > 0 ? _vm.product.rentInfo.official_price.$numberDecimal * _vm.product.rentInfo.SV._1_year_ratio : _vm.product.rentInfo.purchase_price.$numberDecimal * _vm.product.rentInfo.SV._1_year_ratio).toFixed(2))) + "元")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("2年到期余值")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(parseFloat((_vm.product.rentInfo.official_price.$numberDecimal > 0 ? _vm.product.rentInfo.official_price.$numberDecimal * _vm.product.rentInfo.SV._2_year_ratio : _vm.product.rentInfo.purchase_price.$numberDecimal * _vm.product.rentInfo.SV._2_year_ratio).toFixed(2))) + "元")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("3年到期余值")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(parseFloat((_vm.product.rentInfo.official_price.$numberDecimal > 0 ? _vm.product.rentInfo.official_price.$numberDecimal * _vm.product.rentInfo.SV._3_year_ratio : _vm.product.rentInfo.purchase_price.$numberDecimal * _vm.product.rentInfo.SV._3_year_ratio).toFixed(2))) + "元")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("1年回收残值")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.product.rentInfo.SV._1_year_recycle.$numberDecimal) + "元")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("2年回收残值")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.product.rentInfo.SV._2_year_recycle.$numberDecimal) + "元")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("3年回收残值")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.product.rentInfo.SV._3_year_recycle.$numberDecimal) + "元")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("1年残值比例")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(parseFloat((_vm.product.rentInfo.official_price.$numberDecimal > 0 ? _vm.product.rentInfo.SV._1_year_recycle.$numberDecimal / _vm.product.rentInfo.official_price.$numberDecimal * 100 : _vm.product.rentInfo.SV._1_year_recycle.$numberDecimal / _vm.product.rentInfo.purchase_price.$numberDecimal * 100).toFixed(2))) + "%")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("2年残值比例")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(parseFloat((_vm.product.rentInfo.official_price.$numberDecimal > 0 ? _vm.product.rentInfo.SV._2_year_recycle.$numberDecimal / _vm.product.rentInfo.official_price.$numberDecimal * 100 : _vm.product.rentInfo.SV._2_year_recycle.$numberDecimal / _vm.product.rentInfo.purchase_price.$numberDecimal * 100).toFixed(2))) + "%")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("3年残值比例")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(parseFloat((_vm.product.rentInfo.official_price.$numberDecimal > 0 ? _vm.product.rentInfo.SV._3_year_recycle.$numberDecimal / _vm.product.rentInfo.official_price.$numberDecimal * 100 : _vm.product.rentInfo.SV._3_year_recycle.$numberDecimal / _vm.product.rentInfo.purchase_price.$numberDecimal * 100).toFixed(2))) + "%")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("2年延保单价")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.product.rentInfo.warranty._2_year_inside.$numberDecimal) + "元")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("3年延保单价")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.product.rentInfo.warranty._3_year_inside.$numberDecimal) + "元")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("2年延保零售")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.product.rentInfo.warranty._2_year_retail.$numberDecimal) + "元")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("3年延保零售")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.product.rentInfo.warranty._3_year_retail.$numberDecimal) + "元")])], 1) : _vm._e(), _vm.showMore ? _c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("产品描述")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "10"
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.product.remarks))])]), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Product') ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("部件号")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.product.model_number))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("ERP物料号")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.product.erp_code))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("成本")]), _vm.product.cost_price ? _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.product.cost_price.$numberDecimal))]) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("保修期")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.product.guarantee) + " 个月")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("可用模块")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(_vm._s(_vm._f("productEnableModel")(_vm.product.selected)))])], 1)], 1) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("内存大小")]), _vm.product.it_info ? _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.product.it_info.ram) + " G")]) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("网络制式")]), _vm.product.it_info ? _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.product.it_info.network))]) : _vm._e(), _vm._l(_vm.customFields, function (item, i) {
    return [_c('v-col', {
      key: i + '_' + item.name,
      staticClass: "text-right",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(_vm._s(item.name))]), _c('v-col', {
      key: i + '_' + item.value,
      staticClass: "font-weight-bold",
      attrs: {
        "cols": "4"
      }
    }, [_vm._v(_vm._s(item.value))])];
  })], 2) : _vm._e()], 1)], 1), _c('v-divider'), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Asset') ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("关闭")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }