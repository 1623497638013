<template>
  <v-card-text>
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          :headers="itemHeader"
          :items="assetList"
          :options.sync="itemOptions"
          :server-items-length="assetCount"
          :loading="assetLoading"
          loading-text="数据读取中... 请稍后"
          :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
          item-key="_id"
          return-object="false"
          transition
        >
          <template v-slot:item.name="{ item }">
            <td nowrap="true" class="v-list-item--link text-truncate" style="max-width: 150px;">
              <widgets-asset-dialogs :getId="item._id" :caseType="caseType">
                <div>
                  <v-tooltip bottom v-if="item.isLock">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>lock</v-icon>
                    </template>
                    <span>设备锁定中</span>
                  </v-tooltip>
                  {{item.name}}
                  <v-tooltip bottom v-if="item.ownerType === 'Enterprise'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>corporate_fare</v-icon>
                    </template>
                    <span>企业自有设备</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else-if="item.ownerType === 'Account'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>person</v-icon>
                    </template>
                    <span>个人自有设备</span>
                  </v-tooltip>
                </div>
              </widgets-asset-dialogs>
            </td>
          </template>
          <template v-slot:item.serial_number="{ item }">
            <td nowrap="true" v-if="item.product && item.product.product_type && item.product.product_type.noSN">无序列号</td>
            <td nowrap="true" v-else>{{item.serial_number}}</td>
          </template>
          <template v-slot:item.product.name="{ item }">
            <td nowrap="true" @click="openDialog('Product', item.product._id)" class="v-list-item--link text-truncate" style="max-width: 150px;">{{item.product.name}}</td>
          </template>
          <template v-slot:item.product.part_number="{ item }">
            <td nowrap="true">{{item.product.part_number}}</td>
          </template>
          <template v-slot:item.state="{ item }">
            <td nowrap="true">{{item.state ? item.state.name : item.state}}</td>
          </template>
          <template v-slot:item.branch="{ item }">
            <td nowrap="true" class="v-list-item--link text-truncate" style="max-width: 150px;">{{item.branch}}</td>
          </template>
          <template v-slot:item.asset_state="{ item }">
            <td nowrap="true" v-if="item.asset_state && Array.isArray(item.asset_state)">{{item.asset_state.map(state => assetState.find(o => o.code === state).name)}}</td>
            <td nowrap="true" v-else-if="item.asset_state">{{assetState.find(o => o.code === item.asset_state).name}}</td>
            <td nowrap="true" v-else>-</td>
          </template>
          <template v-slot:item.label="{ item }">
            <td nowrap="true">{{item.label}}</td>
          </template>
          <template v-slot:item.account="{ item }">
            <td nowrap="true">{{item.account ? item.account.personal.name : ''}}</td>
          </template>
          <template v-slot:item.transport.delivery_user="{ item }">
            <td nowrap="true" v-if="item.transport && item.transport.delivery_user">
              {{ item.transport.delivery_user }}
            </td>
          </template>
          <template v-slot:item.transport.delivery_phone="{ item }">
            <td nowrap="true" v-if="item.transport && item.transport.delivery_phone">
              {{ item.transport.delivery_phone}}
            </td>
          </template>
          <template v-slot:item.transport.delivery_address="{ item, index }">
            <td
              v-if="item.transport && item.transport.delivery_address"
              style="max-width: 150px; cursor: pointer;"
              :nowrap="open[index]?false:true"
              :class="!open[index]?'text-truncate text-decoration-underline':'text-decoration-underline'"
              @click="showText(index)"
            >
              {{ item.transport.delivery_address }}
            </td>
          </template>
          <template v-slot:item.transport.express_number="{ item }">
            <td nowrap="true" v-if="item.transport && item.transport.express_number"><case-express-info :id="item.transport._id" from="asset">{{item.transport.express_number}}</case-express-info></td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="unlinkAsset(item)">remove</v-icon>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" align="end">
        <v-btn v-if="caseData.order_id && assetList.length && caseData.status === 1 && caseData.isEdit && currentTask.task && currentTask.task.powers.includes('relate_asset')" text @click="openDialog('Import')"><v-icon left>upgrade</v-icon>批量更新设备</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dlgInfo" persistent scrollable max-width="750px">
      <widgets-product-dialogs v-if="activeType === 'Product'"  :id="activeId" :key="activeId" v-on:Close="closeDialog()"></widgets-product-dialogs>
      <v-card v-if="activeType === 'Import'">
        <v-card-title>
          批量导入
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog()"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <p>1. 下载设备清单，在EXCEL中编辑设备信息。</p>
                <v-btn text @click="downloadAssets()" :loading="downloading">下载设备<v-icon>file_download</v-icon></v-btn>
              </v-col>
              <v-col cols="6">
                <input
                  id="import_file"
                  type="file"
                  name="import_file"
                  ref="uploadImport"
                  accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :multiple="false"
                  @change="importFile($event)"/>
                <p>2. 上传填好的文件.</p>
                <v-btn text @click="selectFile()" :loading="uploading"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="importFailed.length || importResult">
              <v-col cols="12">
                <v-divider></v-divider>
                <v-alert
                  outlined
                  type="error"
                  text
                >
                  导入失败：<span v-if="importFailed.length === 0">无</span><ul v-else><li v-for="(fail,index) in importFailed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
                </v-alert>
                <v-alert
                  outlined
                  type="success"
                  text
                >
                  成功创建：{{importResult.insertSum}} 条记录； 更新：{{importResult.updateSum}} 条记录；
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>
<script>
import store from "@/store";
import {
  SET_ERROR
} from "@/store/mutations.type";
import Util from "@/common/util";
import ApiService from "@/common/http";
import {
  mapGetters
} from "vuex";
import {
  FETCH_ASSET_LIST
} from "@/store/actions.type";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
    caseType: {
      type: String,
      default: 'service'
    },
  },
  data() {
    return {
      currentTask: {},
      itemHeader: [
        { text: '名称', value: 'name', width: "100px" },
        { text: '序列号', value: 'serial_number', width: "100px" },
        { text: '标签', value: 'label', width: "100px"},
        { text: '状态', value: 'state', width: "100px" },
        { text: '分支机构', value: 'branch', width: "100px" },
        { text: '用户', value: 'account', width: "100px" },
        { text: '产品', value: 'product.name', width: "100px" },
        { text: '产品型号', value: 'product.part_number', width: "100px"},
        { text: '设备情况', value: 'asset_state', width: "100px"},
        { text: '收货人', value: 'transport.delivery_user', width: "100px" },
        { text: '收货电话', value: 'transport.delivery_phone', width: "100px" },
        { text: '收货地址', value: 'transport.delivery_address', width: "150px" },
        { text: '物流信息', value: 'transport.express_number', width: "150px" },
        { text: '操作', value: 'actions', width: "85px" },
      ],
      itemOptions: {},
      dlgInfo: false,
      activeType: '',
      activeId: null,
      downloading: false,
      uploading: false,
      importResult: null,
      importFailed: [],
      open: {},
    }
  },
  computed: {
    ...mapGetters([
      "assetList",
      "assetLoading",
      "assetCount",
    ]),
    assetState () {
      return Util.categories('assetState')
    },
  },
  mounted() {},
  watch: {
    itemOptions: {
      handler() {
        this.getList()
      },
      deep: true,
    },
    dlgInfo (val) {
      val || this.closeDialog()
    },
  },
  created() {
    this.getList()
    if (this.caseData.tasks_list) this.currentTask = this.caseData.tasks_list.find(item => item.isActive)
  },
  methods: {
    showText(i) {
      this.open[i]= true
      this.$forceUpdate()
    },
    getList() {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.itemOptions;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      let urlQuery = '';
      urlQuery += '&caseId='+ this.caseData._id
      if (Object.keys(this.itemOptions).length > 0) {
        store.dispatch(FETCH_ASSET_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    closeDialog() {
      this.dlgInfo = false
      this.$nextTick(() => {
        this.activeType = ''
        this.activeId = ''
      })
    },
    openDialog(openType, openId) {
      switch (openType) {
        default: {
          this.activeType = openType
          this.activeId = openId
          this.dlgInfo = true
          break
        }
      }
    },
    downloadAssets() {
      if (!this.downloading && this.caseData._id) {
        this.downloading = true
        ApiService.download('/asset/export?page=1&limit=-1&enterprise='+ this.caseData.enterprise._id +'&caseId='+ this.caseData._id, {
          responseType:'blob'
        })
          .then(resp => {
            let fileName = '工单'+ this.caseData.case_number +'_设备清单.xlsx';
            let contentDisposition = resp.headers['content-disposition'];
            if (contentDisposition) {
              fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
            }
            let blob = new Blob([resp.data], {type: 'application/octet-stream'});
            if (window.navigator.msSaveOrOpenBlob) { //支持IE
              navigator.msSaveBlob(blob, fileName);
            } else {
              let link = document.createElement('a');
              link.style.display = "none";
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              this.downloading = false
              //释放内存
              window.URL.revokeObjectURL(link.href);
              document.body.removeChild(link);
            }
          })
          .catch((error) => { // 请求失败处理
            this.downloading = false
            store.commit(SET_ERROR, {msg: '下载错误：'+ error});
          });
      }
    },
    selectFile() {
      this.$refs.uploadImport.click()
    },
    importFile(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importAsset(fileList[x]);
      })
    },
    importAsset(file) {
      if (this.caseData.enterprise && this.caseData.enterprise._id) {
        this.uploading = true
        let param = new FormData();
        param.append('file', file);
        param.append('enterprise', this.caseData.enterprise._id);
        ApiService.post('/asset/import', param)
          .then((data) => {
            this.importResult = data.data.Success
            this.importFailed = data.data.Failed
            this.$refs.uploadImport.value = '';
            this.uploading = false
          })
          .catch((err) => {
            this.$refs.uploadImport.value = '';
            this.importResult = { updateSum: 0, insertSum: 0 }
            this.importFailed = []
            this.importFailed = [{line: 2, error: err.response.data.message}]
            this.uploading = false
          });

      }
    }
  },
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
