<template>
  <div class="base-timer" v-if="type === 'circle'">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="base-timer__circle">
        <circle
          class="base-timer__path-elapsed"
          cx="50"
          cy="50"
          r="45"
        ></circle>
        <path
          :stroke-dasharray="circleDasharray"
          class="base-timer__path-remaining"
          :class="remainingPathColor"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        ></path>
      </g>
    </svg>
    <span class="base-timer__label">
      {{ formattedTimeLeft }}
    </span>
  </div>
  <div v-else>
    <font :color="remainingPathColor">
      <!-- <v-avatar left>
        <v-icon>timelapse</v-icon>
      </v-avatar> -->
      {{ formattedTextLeft }}
    </font>
  </div>
</template>
<script>
const FULL_DASH_ARRAY = 283;

export default {
  props: {
    endTime: {
      type: String
    },
    countDown: {
      type: Number
    },
    type: {
      type: String
    },
    alertThreshold: {
      type: Number,
      default: 24
    },
    warningThreshold: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {
      timePassed: 0,
      timerInterval: null
    };
  },
  computed: {
    timeLeft() {
      let num = 0;
      if (this.type === "text") {
        num =
          Date.parse(this.endTime) - (this.timePassed + new Date().getTime());
      } else {
        num = this.countDown - this.timePassed;
      }
      return num;
    },
    formattedTextLeft() {
      const timeLeft = this.timeLeft;
      if (timeLeft > 0) {
        let time = timeLeft / 1000; // 获取天、时、分、秒
        let days = parseInt(time / (60 * 60 * 24));
        let hours = parseInt((time % (60 * 60 * 24)) / 3600);
        let minutes = parseInt(((time % (60 * 60 * 24)) % 3600) / 60);
        let seconds = parseInt(((time % (60 * 60 * 24)) % 3600) % 60);
        if (days < 10) days = `0${days}`;
        if (hours < 10) hours = `0${hours}`;
        if (minutes < 10) minutes = `0${minutes}`;
        if (seconds < 10) seconds = `0${seconds}`;
        return `${days}天${hours}:${minutes}:${seconds}`;
      } else {
        return "00天00:00:00";
      }
    },
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      if (timeLeft > 0) {
        return `${minutes}:${seconds}`;
      } else {
        return "0:00";
      }
    },
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },
    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.countDown;

      return rawTimeFraction - (1 / this.countDown) * (1 - rawTimeFraction);
    },
    colorCodes() {
      return {
        info: {
          color: "green"
        },
        warning: {
          color: "orange",
          threshold: this.warningThreshold
        },
        alert: {
          color: "red",
          threshold: this.alertThreshold
        }
      }
    },
    remainingPathColor() {
      const { alert, warning, info } = this.colorCodes;
      if (this.type === "text") {
        let time = this.timeLeft / 1000; // 获取天、时、分、秒
        let days = parseInt(time / (60 * 60 * 24));
        let hours = parseInt((time % (60 * 60 * 24)) / 3600);
        if (days > 0) hours = days * 24 + hours;
        if (hours <= alert.threshold) {
          return alert.color;
        } else if (hours <= warning.threshold) {
          return warning.color;
        } else {
          return info.color;
        }
      } else {
        if (this.timeLeft <= alert.threshold) {
          return alert.color;
        } else if (this.timeLeft <= warning.threshold) {
          return warning.color;
        } else {
          return info.color;
        }
      }
    }
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timePassed += 1;
        // console.log('limitTime =>'+ (Date.parse(this.endTime)-new Date().getTime()));
        if (this.timeLeft <= 0) clearInterval(this.timerInterval);
      }, 1000);
    }
  }
};
</script>
<style scoped lang="scss">
.base-timer {
  position: relative;
  width: 300px;
  height: 300px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 300px;
    height: 300px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}
</style>
