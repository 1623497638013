import { EnterpriseService } from "@/common/http";
import {
  FETCH_EARNINGS_LIST,
  PUBLISH_EARNINGS,
  EDIT_EARNINGS,
  REMOVE_EARNINGS,
} from "./actions.type";
import {
  SET_ERROR,
  SET_EARNINGS_LIST,
} from "./mutations.type"

const state = {
  earningsList: [],
};

const getters = {
  earningsList: state => state.earningsList,
};

const actions = {
  [PUBLISH_EARNINGS](context, earnings) {
    return new Promise((resolve, reject) => {
      EnterpriseService.create('/earnings/'+ earnings.enterprise, earnings)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  },
  [FETCH_EARNINGS_LIST]({ commit }, eid) {
    return new Promise((resolve, reject) => {
      EnterpriseService.get('/earnings', eid)
        .then(({data}) => {
          commit(SET_EARNINGS_LIST, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, {msg: error});
          reject(error);
        })
    })
  },
  [EDIT_EARNINGS](context, earnings) {
    return new Promise((resolve, reject) => {
      EnterpriseService.update('earnings/'+ earnings._id, earnings)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  },
  [REMOVE_EARNINGS](context, earnings) {
    return new Promise((resolve, reject) => {
      EnterpriseService.destroy('earnings/'+ earnings)
        .then(({data}) => {
          resolve(data)
        })
        .catch(({response}) => {
          reject(response)
        })
    })
  }
};

const mutations = {
  [SET_EARNINGS_LIST](state, list) {
    state.earningsList = list;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
