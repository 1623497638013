var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._g(_vm._b({
    style: _vm.styles
  }, 'v-card', _vm.$attrs, false), _vm.$listeners), [_vm.hasOffset ? _c('helper-offset', {
    attrs: {
      "inline": _vm.inline,
      "full-width": _vm.fullWidth,
      "offset": _vm.offset
    }
  }, [_vm._t("offset")], 2) : _vm._e(), _c('v-card-text', [_vm._t("default")], 2), _vm.$slots.actions ? _c('v-divider', {
    staticClass: "mx-3"
  }) : _vm._e(), _vm.$slots.actions ? _c('v-card-actions', [_vm._t("actions")], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }