<template>
  <v-snackbar
    v-if="Global.ENV === 'production' &&
    Global.DOMAIN_NAME &&
    !Global.DOMAIN_NAME.includes('mdaas.com') &&
    !Global.IN_PWA"
    v-model="snackbar"
    :timeout=-1
    max-width="450"
    bottom
    vertical
  >
    <v-toolbar-title><v-icon color="secondary">switch_access_shortcut</v-icon> 新域名启用 </v-toolbar-title>
    <v-card-text>{{ text }}</v-card-text>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false">
        暂不访问
      </v-btn>
      <v-btn text color="secondary" v-bind="attrs" @click="gotoLink()">
        立即跳转
      </v-btn>
    </template>
    <iframe id="mdaas" src="https://portal.mdaas.com" style="display: none"></iframe>
  </v-snackbar>
</template>
<script>
import {mapGetters} from "vuex";
import JwtService from "@/common/jwt";

export default {
  props: {
  },
  created () {

  },
  data () {
    return {
      text: '尊敬的用户您好，目前 ESP平台 已经启用新的域名 MDAAS.COM 请使用此域名访问 ESP平台',
      snackbar: true,
      toLink: 'https://portal.mdaas.com'
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    gotoLink() {
      const mdaasIframe = document.getElementById('mdaas');
      if (mdaasIframe) {
        let token = JwtService.getToken()
        let path = GetURLPath()
        let link = ChangeURL(path, this.toLink)
        mdaasIframe.contentWindow.postMessage(token, this.toLink)
        // window.location.href = link
        window.open(link, "_self")
      }
    }
  }
}
function GetURLPath() {
  let url = document.location.toString()
  let arrUrl = url.split('//')

  let start = arrUrl[1].indexOf('/')
  let reUrl = arrUrl[1].substring(start)

  return reUrl
}
function ChangeURL(path, domain) {
  return domain + path
}
</script>
