<template>
  <div class="text-center">
    <v-dialog v-model="dlgField" persistent width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :color="btnColor" dark v-bind="attrs" v-on="on" text>增加自定义字段</v-btn>
      </template>
      <v-card>
        <v-form v-model="valid" @submit.prevent="submit">
          <v-card-title class="headline">
            增加{{module | stringModule}}字段
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="editedField.name"
                    :rules="[rules.required]"
                    type="text"
                    label="字段名称"
                    hint="字段名称提示用户输入内容"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    autocomplete="off"
                    v-model="editedField.type"
                    :items="inputType"
                    :rules="[rules.selected]"
                    label="输入形式"
                    item-text="name"
                    item-value="code"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="editedField.type === 'text'" class="mt-n6">
                <v-col cols="12">
                  <v-text-field
                    v-model="editedField.mask"
                    type="text"
                    label="内容格式"
                    hint="限定内容，为空即不限制任何输入"
                    append-outer-icon="help_outline"
                    @click:append-outer="doAction('help_mask')"
                    outlined
                    dense>
                  </v-text-field>
                  <v-btn text x-small @click="doAction('set_mask', 'datatime')" class="mt-n6">
                    日期时间 2016-12-12 20:32
                  </v-btn>
                  <v-btn text x-small @click="doAction('set_mask', 'time')" class="mt-n6">
                    时间 05:23:12
                  </v-btn>
                  <v-btn text x-small @click="doAction('set_mask', 'telnumber')" class="mt-n6">
                    国际电话 +086-01062011122
                  </v-btn>
                  <v-btn text x-small @click="doAction('set_mask', 'compose')" class="mt-n6">
                    字母数字组合 CN001
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="editedField.type === 'selects'">
                <v-col cols="12" v-for="(item, i) in editedField.items" :key="i">
                  <v-text-field
                    v-model="editedField.items[i]"
                    type="text"
                    label="内容"
                    hint="下拉菜单选项内容"
                    outlined
                    dense
                    class="mt-n6"
                    append-outer-icon="remove"
                    @click:append-outer="doAction('remove_item', item)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="text-center mt-n6">
                  <v-btn text @click="doAction('add_item')">
                    <v-icon>add</v-icon>
                    增加下拉菜单内容
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    autocomplete="off"
                    v-model="editedField.rules"
                    :items="inputRules"
                    label="输入验证"
                    hint="请选择用户输入该字段时验证方式"
                    item-text="name"
                    item-value="code"
                    outlined
                    dense
                    multiple
                    chips
                    small-chips
                    deletable-chips
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeField">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">确定</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Util from '@/common/util';
import { SET_ERROR } from "@/store/mutations.type";
import {
  CREATE_FIELD,
} from "@/store/actions.type";
export default {
  props: {
    module: {
      type: String,
      default: "Account"
    },
    ownerId: {
      type: String,
    },
    ownerType: {
      type: String,
      default: "Dealer"
    },
    btnColor: {
      type: String,
      default: "primary"
    }
  },
  data() {
    return {
      valid: true,
      dlgField: false,
      editedField: {
        name: '',
        type: '',
        mask: '',
        items: [],
        rules: [],
      },
      defaultField: {
        name: '',
        type: '',
        mask: '',
        items: [],
        rules: [],
      },
      inputType: [
        { name: "文本", code: 'text' },
        { name: "下拉菜单", code: 'selects' },
      ],
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
    }
  },
  computed: {
    inputRules () {
      return Util.categories('inputRules')
    }
  },
  mounted() {

  },
  watch: {
    dlgField (val) {
      val || this.closeField()
    },
  },
  methods: {
    submit() {
      let strCreate = {}
      strCreate['format'] = {}
      strCreate['module'] = this.module
      strCreate['ownerId'] = this.ownerId
      strCreate['ownerType'] = this.ownerType
      strCreate['name'] = this.editedField.name
      strCreate['format'].type = this.editedField.type
      strCreate['format'].mask = this.editedField.mask
      strCreate['format'].items = this.editedField.items
      strCreate['format'].rules = this.editedField.rules
      this.$store.dispatch(CREATE_FIELD, strCreate)
        .then(()=> {
          this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
          this.$emit('Complete')
          this.closeField()
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    closeField() {
      this.dlgField = false
      this.$nextTick(() => {
        this.editedField.items = [];
        this.editedField = Object.assign({}, this.defaultField)
      })
    },
    doAction(action, item) {
      switch (action) {
        case 'help_mask': {
          window.open('https://v-mask-demo.netlify.app/')
          break;
        }
        case 'add_item': {
          this.editedField.items.push('');
          break;
        }
        case 'remove_item': {
          const index = this.editedField.items.indexOf(item)
          this.editedField.items.splice(index, 1)
          break;
        }
        case 'set_mask': {
          if (item === 'datatime') this.editedField.mask = '####-##-## ##:##'
          if (item === 'time') this.editedField.mask = '##:##:##'
          if (item === 'telnumber') this.editedField.mask = '+(###)-###########'
          if (item === 'compose') this.editedField.mask = 'AA###'
        }
      }
    }
  },
}
</script>
