<template>

    <v-row no-gutters style="position: relative" class="ma-2">
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-icon small class="float-right" style="cursor: pointer; position: absolute; left: 50%;" color="grey lighten-2" @click="deleteItem()">delete</v-icon>
    </v-row>

</template>
<script>
import {
  FETCH_PAGE,
  DELETE_BODY_ELEMENT,
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
import {mapGetters} from "vuex";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      editBodyData: {}
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {},
  created() {
    this.getPageInfo()
  },
  mounted () {},
  methods: {
    getPageInfo(component = 'body') {
      this.$store.dispatch(FETCH_PAGE, '?name=home&component=' + component +'&owner='+ this.currentUser.employer.ownerId._id)
        .then(data => {
          this.editBodyData = Object.assign({_id: data._id}, data.config[this.index])
        });
    },
    close() {
      this.getPageInfo('body')
    },
    deleteItem() {
      this.$store.dispatch(DELETE_BODY_ELEMENT, {_id: this.id, index: this.index})
        .then(() => {
          this.$emit('DeleteItem')
          this.getPageInfo('body')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
  },
  destroyed () {}
}
</script>
<style scoped>
  .show_upload {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
</style>
