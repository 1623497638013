import {SettingService} from "@/common/http";

import {
  FETCH_RENT_PARAMETER_LIST,
  PUBLISH_RENT_PARAMETER,
  EDIT_RENT_PARAMETER,
  DEFAULT_RENT_PARAMETER,
  FETCH_RENT_INDUSTRY_LIST,
  PUBLISH_RENT_INDUSTRY,
  EDIT_RENT_INDUSTRY,
  ACTIVE_RENT_INDUSTRY,
  FETCH_RENT_TEMPLATE_LIST,
  PUBLISH_RENT_TEMPLATE,
  EDIT_RENT_TEMPLATE,
  ACTIVE_RENT_TEMPLATE,
  TOP_RENT_TEMPLATE,
  FETCH_RENT_INDUSTRY,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_RENT_PARAMETER_LIST,
  SET_RENT_PARAMETER_LIST,
  LOAD_RENT_INDUSTRY_LIST,
  SET_RENT_INDUSTRY_LIST,
  LOAD_RENT_TEMPLATE_LIST,
  SET_RENT_TEMPLATE_LIST,
  SET_INDUSTRY,
} from "./mutations.type"

const state = {
  rentParameterLoading: true,
  rentParameterList: [],
  rentParameterCount: 0,
  rentIndustryLoading: true,
  rentIndustryList: [],
  rentIndustryCount: 0,
  rentTemplateLoading: true,
  rentTemplateList: [],
  rentTemplateCount: 0,
  industryInfo: {},
};

const getters = {
  rentParameterLoading: state => state.rentParameterLoading,
  rentParameterList: state => state.rentParameterList,
  rentParameterCount: state => state.rentParameterCount,
  rentIndustryLoading: state => state.rentIndustryLoading,
  rentIndustryList: state => state.rentIndustryList,
  rentIndustryCount: state => state.rentIndustryCount,
  rentTemplateLoading: state => state.rentTemplateLoading,
  rentTemplateList: state => state.rentTemplateList,
  rentTemplateCount: state => state.rentTemplateCount,
  industryInfo: state => state.industryInfo,
};

const actions = {
  [FETCH_RENT_INDUSTRY]({ commit }, slug) {
    commit(LOAD_RENT_INDUSTRY_LIST);
    return new Promise((resolve, reject) => {
      SettingService.get('/rent/industry', slug)
        .then(({ data }) => {
          commit(SET_INDUSTRY, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_RENT_PARAMETER_LIST]({ commit }, slug) {
    commit(LOAD_RENT_PARAMETER_LIST);
    return new Promise((resolve, reject) => {
      SettingService.get('/rent/parameter/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RENT_PARAMETER_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_RENT_PARAMETER](context, parameter) {
    return SettingService.create('/rent/parameter', parameter);
  },
  [EDIT_RENT_PARAMETER](context, parameter) {
    return SettingService.update('/rent/parameter', parameter._id, parameter);
  },
  [DEFAULT_RENT_PARAMETER](context, parameter) {
    return SettingService.update('/rent/parameter/default', parameter._id +'/'+ parameter.reply);
  },
  [FETCH_RENT_INDUSTRY_LIST]({ commit }, slug) {
    commit(LOAD_RENT_INDUSTRY_LIST);
    return new Promise((resolve, reject) => {
      SettingService.get('/rent/industry/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RENT_INDUSTRY_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_RENT_INDUSTRY](context, parameter) {
    return SettingService.create('/rent/industry', parameter);
  },
  [EDIT_RENT_INDUSTRY](context, parameter) {
    return SettingService.update('/rent/industry', parameter._id, parameter);
  },
  [ACTIVE_RENT_INDUSTRY](context, parameter) {
    return SettingService.update('/rent/industry/enable', parameter._id +'/'+ parameter.reply);
  },
  [FETCH_RENT_TEMPLATE_LIST]({ commit }, slug) {
    commit(LOAD_RENT_TEMPLATE_LIST);
    return new Promise((resolve, reject) => {
      SettingService.get('/rent/template/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RENT_TEMPLATE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_RENT_TEMPLATE](context, parameter) {
    return SettingService.create('/rent/template', parameter);
  },
  [EDIT_RENT_TEMPLATE](context, parameter) {
    return SettingService.update('/rent/template', parameter._id, parameter);
  },
  [ACTIVE_RENT_TEMPLATE](context, parameter) {
    return SettingService.update('/rent/template/enable', parameter._id +'/'+ parameter.reply);
  },
  [TOP_RENT_TEMPLATE](context, parameter) {
    return SettingService.update('/rent/template/top', parameter._id +'/'+ parameter.reply);
  },
};

const mutations = {
  [LOAD_RENT_PARAMETER_LIST] (state) {
    state.rentParameterLoading = true;
  },
  [SET_RENT_PARAMETER_LIST](state, list) {
    state.rentParameterCount = list.query.count;
    state.rentParameterList = list.parameters;
    state.rentParameterLoading = false;
  },
  [LOAD_RENT_INDUSTRY_LIST] (state) {
    state.rentIndustryLoading = true;
  },
  [SET_RENT_INDUSTRY_LIST](state, list) {
    state.rentIndustryCount = list.query.count;
    state.rentIndustryList = list.industry;
    state.rentIndustryLoading = false;
  },
  [LOAD_RENT_TEMPLATE_LIST] (state) {
    state.rentTemplateLoading = true;
  },
  [SET_RENT_TEMPLATE_LIST](state, list) {
    state.rentTemplateCount = list.query.count;
    state.rentTemplateList = list.template;
    state.rentTemplateLoading = false;
  },
  [SET_INDUSTRY](state, item) {
    state.industryInfo = item;
    state.rentIndustryLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
