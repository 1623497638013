var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerProduct,
      "items": _vm.editedItem,
      "server-items-length": _vm.editedItem.length,
      "loading": _vm.loadingList,
      "hide-default-footer": "",
      "item-key": "_id",
      "return-object": "false",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.product_vendor.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" " + _vm._s(item.product_vendor.name) + " ")])];
      }
    }, {
      key: "item.product_type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" " + _vm._s(item.product_type.name) + " ")])];
      }
    }, {
      key: "item.part_number",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" " + _vm._s(item.part_number) + " ")])];
      }
    }, {
      key: "item.product_name",
      fn: function fn(_ref4) {
        var item = _ref4.item,
            index = _ref4.index;
        return [_c('td', {
          class: !_vm.open[index] ? 'text-truncate text-decoration-underline' : 'text-decoration-underline',
          staticStyle: {
            "max-width": "200px",
            "cursor": "pointer"
          },
          attrs: {
            "nowrap": _vm.open[index] ? false : true
          },
          on: {
            "click": function click($event) {
              return _vm.showText(index);
            }
          }
        }, [_vm._v(_vm._s(item.product_name))])];
      }
    }, {
      key: "item.sales_price",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "d-inline-block text-truncate",
          staticStyle: {
            "max-width": "175px"
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(item.sales_price.$numberDecimal)) + " ")])];
      }
    }, {
      key: "item.purchase_price",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "d-inline-block text-truncate",
          staticStyle: {
            "max-width": "175px"
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(item.purchase_price.$numberDecimal)) + " ")])];
      }
    }, {
      key: "item.purchase_margin",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "d-inline-block text-truncate",
          staticStyle: {
            "max-width": "175px"
          }
        }, [_vm._v(" " + _vm._s(parseFloat(item.purchase_margin * 100).toFixed(2)) + "% ")])];
      }
    }, {
      key: "item.sum_price",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm.caseData.rent_info && _vm.caseData.rent_info.total_period > 0 ? _c('span', {
          staticClass: "d-inline-block text-truncate",
          staticStyle: {
            "max-width": "175px"
          }
        }, [_vm._v(" " + _vm._s(parseFloat(item.sales_price.$numberDecimal * item.quantity * _vm.caseData.rent_info.total_period).toFixed(4)) + " ")]) : _c('span', {
          staticClass: "d-inline-block text-truncate",
          staticStyle: {
            "max-width": "175px"
          }
        }, [_vm._v(" " + _vm._s(parseFloat(item.sales_price.$numberDecimal * item.quantity).toFixed(4)) + " ")])];
      }
    }, {
      key: "item.import",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.createSN(item);
            }
          }
        }, [_c('v-icon', [_vm._v("playlist_add")])], 1)];
      }
    }, {
      key: "body.append",
      fn: function fn() {
        return [_c('tr', {
          staticClass: "secondary--text"
        }, [_c('td', {
          staticClass: "text-end",
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v("合计")]), _vm.caseData.rent_info && _vm.caseData.rent_info.total_period > 0 ? _c('td', {
          staticClass: "text-start"
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.editedItem.reduce(function (n, m) {
          return n + m.sales_price.$numberDecimal * m.quantity * _vm.caseData.rent_info.total_period;
        }, 0))))]) : _c('td', {
          staticClass: "text-start"
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.editedItem.reduce(function (n, m) {
          return n + m.sales_price.$numberDecimal * m.quantity;
        }, 0))))]), _vm.caseData.purchase_id ? _c('td', {
          staticClass: "text-start",
          attrs: {
            "colspan": "2"
          }
        }) : _vm._e(), _c('td', {
          staticClass: "text-start"
        }, [_vm._v(_vm._s(_vm.editedItem.reduce(function (n, m) {
          return n + m.quantity;
        }, 0)))]), _c('td', {
          staticClass: "text-start",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v(_vm._s(_vm.editedItem.reduce(function (n, m) {
          return n + m.asset;
        }, 0)))])])];
      },
      proxy: true
    }])
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'import' ? _c('v-card', [_c('v-card-title', [_vm._v(" 批量导入 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeInfo();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载设备模版，填写设备信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.downloadTemplate
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importFiles($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": "",
      "loading": !_vm.importEnd
    },
    on: {
      "click": _vm.selectFiles
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1), _vm.importFailed.length || _vm.importResult ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _vm.importFailed.length === 0 ? _c('span', [_vm._v("无")]) : _c('ul', _vm._l(_vm.importFailed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.importResult.insertSum) + " 条记录； 更新：" + _vm._s(_vm.importResult.updateSum) + " 条记录； ")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e(), _vm.activeType === 'relate' ? _c('v-card', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitRelate.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card-title', [_vm._v(" 关联序列号 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeInfo();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-alert', {
    attrs: {
      "dense": "",
      "text": "",
      "type": "success"
    }
  }, [_vm._v(" 需要交付 "), _c('b', [_vm._v(_vm._s(_vm.activeItem.quantity))]), _vm._v(" 台型号为：" + _vm._s(_vm.activeItem.part_number) + "的" + _vm._s(_vm.activeItem.product_name) + "。 ")]), _vm.activeItem.product_type && _vm.activeItem.product_type.noSN ? _c('v-subheader', [_vm._v(" 配件无需关联序列号，请确认是否已经准备齐全所有配件。 "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("准备齐全点击保存按钮")])]) : _c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "label": "导入序列号",
      "hint": "请输入需要关联的设备序列号，多个序列号使用换行或 , 分隔。"
    },
    model: {
      value: _vm.activeItem.serial_number,
      callback: function callback($$v) {
        _vm.$set(_vm.activeItem, "serial_number", $$v);
      },
      expression: "activeItem.serial_number"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeInfo();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid,
      "loading": _vm.exeing
    },
    on: {
      "click": _vm.submitRelate
    }
  }, [_vm._v("保存")])], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }