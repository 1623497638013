<template>
  <div v-if="caseType === 'service'">
    <v-chip class="ma-2" label small v-if="caseData.order_id && caseData.order_id.type === 0">采购订单</v-chip>
    <v-chip class="ma-2" label small v-if="caseData.order_id && caseData.order_id.type === 1">租赁订单</v-chip>
    <v-chip class="ma-2" label small v-if="caseData.status === 0" color="secondary">待受理</v-chip>
    <v-chip class="ma-2" label small v-else-if="caseData.status === 1" color="primary">处理中</v-chip>
    <v-chip class="ma-2" label small v-else-if="caseData.status === 2">已取消</v-chip>
    <v-chip class="ma-2" label small v-else-if="caseData.status === 3">已拒绝</v-chip>
    <v-chip class="ma-2" label small v-else-if="caseData.status === 4">已完成</v-chip>
    <v-chip class="ma-2" label small v-else>已删除</v-chip>
  </div>
  <div v-else>
    <v-chip class="ma-2" label small v-if="caseData.status === 0" color="secondary">待受理</v-chip>
    <v-chip class="ma-2" label small v-else-if="caseData.status === 1" color="primary">
      {{caseData.gsx_create_time ? '检测完成' : '检测中'}}
    </v-chip>
    <v-chip class="ma-2" label small v-else-if="caseData.status === 2">
      {{caseData.repaired_time ? '维修完成' : '维修中'}}
    </v-chip>
    <v-chip class="ma-2" label small v-else-if="caseData.status === 3">待取机</v-chip>
    <v-chip class="ma-2" label small v-else-if="caseData.status === 4">服务结束</v-chip>
    <v-chip class="ma-2" label small v-else-if="caseData.status === 8">取消服务</v-chip>
    <v-chip class="ma-2" label small v-else>已删除</v-chip>
  </div>

</template>
<script>
export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
    caseType: {
      type: String,
      default: 'service'
    }
  },
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  created() {},
  computed: {},
  methods: {}
}
</script>
