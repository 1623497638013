<template>
  <v-dialog v-model="dialog" scrollable persistent width="374" @input="getItem(id)">
    <template v-slot:activator="{ on, attrs }">
      <span :color="btnColor" dark v-bind="attrs" v-on="on" text :ripple="false">
        <slot></slot> <span v-if="divider">{{divider}}</span>
      </span>
    </template>
    <v-card v-if="itemData">
      <v-toolbar
        dark
        prominent
        :src="itemData.profileImage ? ossURL +'/'+ itemData.profileImage : '/static/error/empty_state.svg'"
        fade-img-on-scroll
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            class="white--text align-end"
            gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.1)"
          ></v-img>
        </template>
        <v-toolbar-title>{{itemData.name}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="mt-6">{{itemData.city}}</div>
        <div>{{itemData.address}}</div>

        <div class="mt-2">{{itemData.contactName}}</div>
        <div>{{itemData.contactPhone}}</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">关闭</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { SET_ERROR } from "@/store/mutations.type";
import { mapGetters } from 'vuex'
import {
  FETCH_BRANCH
} from "@/store/actions.type";
import store from "@/store";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    btnColor: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      itemData: {},
    }
  },
  computed: {
    ...mapGetters(['branch']),
  },
  mounted() {},
  watch: {
  },
  methods: {
    getItem(id) {
      return new Promise((resolve, reject) => {
        store.dispatch(FETCH_BRANCH, id)
          .then((data) => {
            this.itemData = Object.assign({}, data)
            resolve(data)
          })
          .catch(err => {
            reject(err)
            store.commit(SET_ERROR, {msg: err.response.data.message});
          })
      })
    },
    closeDialog () {
      this.dialog = false
    },
  },
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
