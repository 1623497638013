<template>
  <div>
    <v-card-text v-if="caseData.files">
      <v-list subheader dense>
        <v-list-item v-for="file in caseData.files" :key="file.href">
          <v-list-item-avatar v-if="file.suffix === 'jpeg' || file.suffix === 'jpg' || file.suffix === 'png' || file.suffix === 'gif'">
            <v-img :src="file.preUrl && file.preUrl" @click="zoomFile(file)"></v-img>
          </v-list-item-avatar>
          <v-list-item-avatar v-else>
            <v-icon>text_snippet</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="file.name"></v-list-item-title>
            <v-list-item-subtitle>{{ file.creator._id === currentUser._id ? '我' : file.creator.personal.name }} 于 {{file.createTime | formatTime}} 上传</v-list-item-subtitle>
          </v-list-item-content>
          <!--        <v-list-item-action class="d-inline" v-if="caseData.status === 0">-->
          <v-list-item-action class="d-inline">
            <v-btn icon v-if="file.creator._id === currentUser._id && caseData.status < 2" @click="deleteFile(file)"><v-icon>delete</v-icon></v-btn>
            <v-btn icon v-if="file.preUrl"><v-icon @click="zoomFile(file)">zoom_in</v-icon></v-btn>
            <v-btn icon><v-icon @click="downloadFile(file.href)">get_app</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-for="(file) in editedItem.files" :key="file.href">
          <v-list-item-avatar>
            <v-icon>text_snippet</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="file.name"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="d-inline">
            <v-btn icon @click="removeFile(file)"><v-icon>delete</v-icon></v-btn>
            <v-btn icon @click="downloadFile(file.href)"><v-icon>get_app</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-progress-linear color="secondary" v-if="Upload.uploading" :value="Upload.progressUpload"></v-progress-linear>
            <input type="file" ref="uploadFile" :multiple="false" @change="detectFiles($event)" />
            <v-list-item-title v-if="caseType === 'service'">
              <v-btn v-if="caseData.status === 0" block outlined @click="uploadFile(-1)"><v-icon left>attach_file</v-icon>上传附件</v-btn>
              <v-btn v-else-if="caseData.status === 1 && currentTask.task.powers.includes('upload_files')" block outlined @click="uploadFile(-1)"><v-icon left>attach_file</v-icon>上传附件</v-btn>
            </v-list-item-title>
            <v-list-item-title v-else>
              <v-btn v-if="caseData.isEdit" block outlined @click="uploadFile(-1)"><v-icon left>attach_file</v-icon>上传附件</v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-dialog v-model="dlgImage" fullscreen hide-overlay scrollable transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{activeImage.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="downloadFile(activeImage.href)"><v-icon>get_app</v-icon></v-btn>
          <v-btn icon @click="closeImage()"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-img :src="activeImage.preUrl" aspect-ratio="1" contain></v-img>
        </v-card-text>
        <v-card-actions v-if="activeImage.creator">
          <v-subheader>{{ activeImage.creator._id === currentUser._id ? '我' : activeImage.creator.personal.name }} 于 {{activeImage.createTime | formatTime}} 上传</v-subheader>
          <v-spacer></v-spacer>
          <v-btn text @click="downloadFile(activeImage.href)">下载</v-btn>
          <v-btn text @click="closeImage()">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {FETCH_UPLOAD, FETCH_URL, SAVE_CASE} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
    caseType: {
      type: String,
      default: 'service'
    },
  },
  data() {
    return {
      currentTask: {},
      editedItem: {
        files: []
      },
      Upload: {
        fileIndex: -1,
        uploading: false,
        progressUpload: 0,
      },
      activeImage: {},
      dlgImage: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'uploadSign']),
  },
  mounted() {},
  watch: {},
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.caseData.tasks_list) this.currentTask = this.caseData.tasks_list.find(item => item.isActive)
      if (this.caseData.files && this.caseData.files.length) {
        this.caseData.files.forEach((file) => {
          if (file.suffix.toLowerCase() === 'jpeg' || file.suffix.toLowerCase() === 'jpg' || file.suffix.toLowerCase() === 'png' || file.suffix.toLowerCase() === 'gif') {
            store.dispatch(FETCH_URL, file.href)
              .then((fileurl) => {
                file.preUrl = fileurl
                this.$forceUpdate()
              })
          }
        })
      }
    },
    zoomFile(objImage) {
      this.activeImage = objImage
      this.dlgImage = true
    },
    closeImage() {
      this.activeImage = {}
      this.dlgImage = false
    },
    downloadFile(additional) {
      store.dispatch(FETCH_URL, additional)
        .then((fileurl) => {
          window.location.href = fileurl;
        })
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    deleteFile(file) {
      let strUpdate = {}
      this.caseData.files.splice(this.caseData.files.indexOf(file), 1)
      strUpdate.form = this.caseType
      strUpdate.caseId = this.caseData._id
      strUpdate['files'] = this.caseData.files
      store.dispatch(SAVE_CASE, strUpdate)
        .then(() => {
          store.commit(SET_ERROR, {msg: '删除成功。', color: 'primary'});
        })
        .catch((err) => {
          this.editedItem['files'].push(file)
          this.$store.commit(SET_ERROR, {msg: err});
        })
    },
    async removeFile(file) {
      let strUpdate = {}
      await this.editedItem['files'].splice(this.editedItem['files'].indexOf(file), 1)
      strUpdate.form = this.caseType
      strUpdate.caseId = this.caseData._id
      strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''
      strUpdate['files'] = this.caseData.files.concat(this.editedItem['files'])
      store.dispatch(SAVE_CASE, strUpdate)
        .then(() => {
          store.commit(SET_ERROR, {msg: '删除成功。', color: 'primary'});
        })
        .catch((err) => {
          this.editedItem['files'].push(file)
          this.$store.commit(SET_ERROR, {msg: err});
        })
    },
    detectFiles (e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    uploadFile(index) {
      this.Upload.fileIndex = index
      store.dispatch(FETCH_UPLOAD, 'private')
        .then(this.$refs.uploadFile.click())
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    async upload(file) {
      let fileFullName = file.name,
        fileSuffix = fileFullName.substring(fileFullName.lastIndexOf('.') + 1);
      fileFullName = fileFullName.replace(/[%#&]/g,"");
      this.Upload.uploading = true
      let param = new FormData()
      param.append('name', fileFullName);
      param.append('key', 'case/'+ this.uploadSign.key +'/'+ fileFullName);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, fileFullName);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };
      await axios.post(this.uploadSign.host, param, config)
        .then(async () => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          this.$refs.uploadFile.value = '';

          if (this.Upload.fileIndex > -1) {
            await this.editedItem.files.splice(this.Upload.fileIndex, 1, {
              creator: this.currentUser._id,
              suffix: fileSuffix,
              name: fileFullName,
              href: param.get('key'),
            })
          } else {
            await this.editedItem.files.push({
              creator: this.currentUser._id,
              suffix: fileSuffix,
              name: fileFullName,
              href: param.get('key'),
            })
          }
          let strUpdate = {}
          strUpdate.form = this.caseType
          strUpdate.caseId = this.caseData._id
          strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''
          strUpdate['files'] = this.caseData.files.concat(this.editedItem.files)
          await store.dispatch(SAVE_CASE, strUpdate)
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          this.$refs.uploadInput.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
  },
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
