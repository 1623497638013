<template>
  <v-app-bar app clipped-left color="primary" dark>
    <v-img alt="Logo" class="shrink mr-2" contain src="/static/logo.png" transition="scale-transition" max-width="40"></v-img>
    <v-toolbar-title>ESP - 经销商门户</v-toolbar-title>
    <core-version class="ml-2 mt-2" :id="verisonInfo._id">v{{verisonInfo.version}}</core-version>
    <v-app-bar-nav-icon @click.stop="drawerToggle"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-menu
      v-if="Global.ENV === 'development'"
      offset-y bottom left open-on-hover :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon v-bind="attrs" v-on="on">
          <v-icon>chat</v-icon>
        </v-btn>
      </template>
      <v-list two-line>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, index) in chatMessage" :key="index" ripple>
            <v-list-item-avatar>
              <img :src="item.avatar">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.title"></v-list-item-title>
              <v-list-item-subtitle
                v-html="item.subtitle"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-menu offset-y bottom left :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon v-bind="attrs" v-on="on">
          <v-icon>account_circle</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list v-if="currentUser">
          <v-list-item>
            <v-list-item-avatar v-if="currentUser.personal">
              <v-img :src="currentUser.personal.profile ? ossURL +'/'+ currentUser.personal.profile : '/static/grey_silhouette.png'" aspect-ratio="1" contain></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-if="currentUser.personal">{{currentUser.personal.name || '*未填写名称'}}</v-list-item-title>
              <v-list-item-subtitle v-if="currentUser.employer">{{currentUser.employer.ownerId.name || '无企业信息'}}：{{currentUser.employer.roles.map(item => item.name)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list v-if="currentUser.notice">
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="currentUser.notice.phone" color="secondary" @change="editNotice()"></v-switch>
            </v-list-item-action>
            <v-list-item-title>短信通知</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="currentUser.notice.email" color="secondary" @change="editNotice()"></v-switch>
            </v-list-item-action>
            <v-list-item-title>邮件通知</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <widgets-employee-dialogs :account="currentUser._id"><v-btn text>个人资料</v-btn></widgets-employee-dialogs>
          <v-btn color="secondary" text @click="logout">退出登陆</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-btn icon @click="changeTheme(!darkTheme)"><v-icon>invert_colors</v-icon></v-btn>
    <v-btn icon v-if="!$vuetify.breakpoint.mobile" @click="fullScreen()"><v-icon>fullscreen</v-icon></v-btn>
    <v-btn icon v-if="showInstall" @click="installPWA()"><v-icon>install_desktop</v-icon></v-btn>
  </v-app-bar>

</template>
<script>
import Util from '@/common/util';
import {
  LOGOUT,
  CHECK_AUTH,
  EDIT_ACCOUNT,
  FETCH_RELEASE,
  CHANGE_THEME,
} from '@/store/actions.type'
import { SET_ERROR } from "@/store/mutations.type";
import { mapGetters } from 'vuex'
import store from "@/store";
export default {
  data: () => ({
    verisonInfo: {},
    installEvent: undefined,
    showInstall: false,
    chatMessage: [
      {
        avatar: 'https://picsum.photos/250/300?image=660',
        title: 'Meeting @ Noon',
        subtitle:
          `<span class="font-weight-bold">Spike Lee</span> &mdash; I'll be in your neighborhood`,
      },
      {
        avatar: 'https://picsum.photos/250/300?image=821',
        title: 'Summer BBQ <span class="grey--text text--lighten-1"></span>',
        subtitle:
          '<span class="font-weight-bold">to Operations support</span> &mdash; Wish I could come.',
      },
      {
        avatar: 'https://picsum.photos/250/300?image=783',
        title: 'Yes yes',
        subtitle:
          '<span class="font-weight-bold">Bella</span> &mdash; Do you have Paris recommendations',
      },
      {
        avatar: 'https://picsum.photos/250/300?image=1006',
        title: 'Dinner tonight?',
        subtitle:
          '<span class="font-weight-bold">LaToya</span> &mdash; Do you want to hang out?',
      },
      {
        avatar: 'https://picsum.photos/250/300?image=146',
        title: 'So long',
        subtitle:
          '<span class="font-weight-bold">Nancy</span> &mdash; Do you see what time it is?',
      },
      {
        avatar: 'https://picsum.photos/250/300?image=1008',
        title: 'Breakfast?',
        subtitle:
          '<span class="font-weight-bold">LaToya</span> &mdash; Do you want to hang out?',
      },
      {
        avatar: 'https://picsum.photos/250/300?image=839',
        title:
          'Winter Porridge <span class="grey--text text--lighten-1"></span>',
        subtitle:
          '<span class="font-weight-bold">cc: Daniel</span> &mdash; Tell me more...',
      },
      {
        avatar: 'https://picsum.photos/250/300?image=145',
        title: 'Oui oui',
        subtitle:
          '<span class="font-weight-bold">Nancy</span> &mdash; Do you see what time it is?',
      },
    ],
  }),
  computed: {
    ...mapGetters(['darkTheme', 'currentUser']),
  },
  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.showInstall = true
    })
  },
  created() {
    store.dispatch(FETCH_RELEASE, 'last')
      .then((data) => {
        this.verisonInfo = Object.assign({}, data)
      })
  },
  methods: {
    fullScreen () {
      Util.toggleFullScreen();
    },
    drawerToggle() {
      window.getApp.$emit("APP_DRAWER_TOGGLED");
    },
    changeTheme (dark) {
      this.$store.dispatch(CHANGE_THEME, dark)
      this.$vuetify.theme.dark = dark
    },
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        location.reload()
      })
    },
    updateInfo() {
      this.$store.dispatch(CHECK_AUTH)
    },
    editNotice() {
      this.$store.dispatch(EDIT_ACCOUNT, {noticePhone: this.currentUser.notice.phone, noticeEmail: this.currentUser.notice.email})
        .then(() => {
          this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
          this.updateInfo()
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the banner once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
    dismissPrompt() {
      this.showInstall = false
    }
  }
};
</script>
