<template>
  <v-card-text v-if="caseData.remarks">
    <v-list dense>
      <template v-for="(item, i) in caseData.remarks">
        <v-list-item v-if="!item.divider" :key="'remarks_'+i+'_title'" three-line>
          <widgets-employee-dialogs :account="item.account._id">
            <v-list-item-avatar>
              <v-img :src="item.avatar ? ossURL +'/'+ item.avatar : '/static/grey_silhouette.png'"></v-img>
            </v-list-item-avatar>
          </widgets-employee-dialogs>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }} - {{ item.account._id === currentUser._id ? "我" : item.account.personal.name }} - {{ item.createTime | fromDate }}
            </v-list-item-title>
            <span :class="item.isBlock ? 'text-decoration-line-through' : ''">
                  {{ item.context }}
                </span>
          </v-list-item-content>
          <v-list-item-action v-if="item.account._id === currentUser._id && !item.isBlock">
            <v-btn icon>
              <v-icon @click="blockRemark(item)">block</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      <template v-for="(item, index) in editedItem.remarks">
        <v-list-item v-if="!item.divider" :key="'remarks_'+index+'_title_new'" three-line>
          <widgets-employee-dialogs :account="item.account">
            <v-list-item-avatar>
              <v-img :src="item.avatar ? ossURL +'/'+ item.avatar : '/static/grey_silhouette.png'"></v-img>
            </v-list-item-avatar>
          </widgets-employee-dialogs>
          <v-list-item-content>
            <v-list-item-title>{{item.title}} {{ item.createTime | fromDate }}</v-list-item-title>
            {{item.context}}
          </v-list-item-content>
<!--          <v-list-item-action v-if="caseType === 'service'">-->
<!--            <v-btn icon v-if="caseData.status === 0 && caseData.isEdit">-->
<!--              <v-icon @click="removeRemark(item)">delete</v-icon>-->
<!--            </v-btn>-->
<!--            <v-btn icon v-else-if="caseData.status === 1 && caseData.isEdit && currentTask.task.powers.includes('create_remarks')">-->
<!--              <v-icon @click="removeRemark(item)">delete</v-icon>-->
<!--            </v-btn>-->
<!--          </v-list-item-action>-->
          <v-list-item-action>
            <v-btn icon>
              <v-icon @click="removeRemark(item)">delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-list-item>

        <v-list-item-content v-if="caseType === 'service'">
<!--          <v-list-item-title v-if="caseData.status === 0"><v-btn block outlined @click="editRemarks()"><v-icon left>rate_review</v-icon>增加备注</v-btn></v-list-item-title>-->
<!--          <v-list-item-title v-else-if="caseData.status === 1 && caseData.isEdit && currentTask.task.powers.includes('create_remarks')"><v-btn block outlined @click="editRemarks()"><v-icon left>rate_review</v-icon>增加备注</v-btn></v-list-item-title>-->
          <v-list-item-title v-if="caseData.status === 0 || (currentTask && currentTask.task.powers.includes('create_remarks'))"><v-btn block outlined @click="editRemarks()"><v-icon left>rate_review</v-icon>增加备注</v-btn></v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title><v-btn block outlined @click="editRemarks()"><v-icon left>rate_review</v-icon>增加备注</v-btn></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="dlgRemarks" persistent max-width="550px">
      <v-card>
        <v-card-title>
          增加备注
          <v-spacer></v-spacer>
          <v-btn icon @click="closeRemarks"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedRemarks.title"
                type="text"
                label="备注标题"
                outlined
                dense
              >
              </v-text-field>
              <v-textarea
                outlined
                auto-grow
                rows="3"
                v-model="editedRemarks.context"
                label="备注内容"
                value=""
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeRemarks()">取消</v-btn>
          <v-btn text color="secondary" :disabled="!editedRemarks.title || !editedRemarks.context" @click="createRemarks(editedRemarks)">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>
<script>
import store from "@/store";
import {mapGetters} from "vuex";
import {SAVE_CASE} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
    caseType: {
      type: String,
      default: 'service'
    },
  },
  data() {
    return {
      currentTask: {},
      editedItem: {
        remarks: []
      },
      editedRemarks: {
        title: '',
        context: '',
      },
      defaultRemarks: {
        title: '',
        context: '',
      },
      dlgRemarks: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  mounted() {},
  watch: {},
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.caseData.tasks_list) this.currentTask = this.caseData.tasks_list.find(item => item.isActive)
    },
    async removeRemark(item) {
      let strUpdate = {}
      await this.editedItem.remarks.splice(this.editedItem.remarks.indexOf(item), 2)
      strUpdate.form = this.caseType
      strUpdate.caseId = this.caseData._id
      strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''
      strUpdate['remarks'] = this.caseData.remarks.concat(this.editedItem.remarks)
      store.dispatch(SAVE_CASE, strUpdate)
        .then(() => {
          store.commit(SET_ERROR, {msg: '删除成功。', color: 'primary'});
        })
        .catch((err) => {
          this.editedItem['remarks'].push(item)
          store.commit(SET_ERROR, {msg: err});
        })
    },
    blockRemark(item) {
      let [ ...arrRemarks ] = this.caseData.remarks
      let editIndex = arrRemarks.indexOf(item)
      if (editIndex > -1) {
        arrRemarks[editIndex].isBlock = true
        arrRemarks[editIndex].context = arrRemarks[editIndex].context + ' - ' +this.$options.filters['formatTime'](new Date())
      }
      let strUpdate = {}
      strUpdate.form = this.caseType
      strUpdate.caseId = this.caseData._id
      strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''
      strUpdate['remarks'] = arrRemarks
      store.dispatch(SAVE_CASE, strUpdate)
        .then(() => {
          store.commit(SET_ERROR, {msg: '取消成功。', color: 'primary'});
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err});
        })
    },
    editRemarks() {
      this.dlgRemarks = true
    },
    async createRemarks(objRemarks) {
      objRemarks['account'] = this.currentUser._id
      objRemarks['avatar'] = this.currentUser.personal.profile || ''
      objRemarks['createTime'] = new Date()
      await this.editedItem.remarks.push(Object.assign({}, objRemarks))
      await this.editedItem.remarks.push({ divider: true, inset: true },)
      let strUpdate = {}
      strUpdate.form = this.caseType
      strUpdate.caseId = this.caseData._id
      strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''
      strUpdate['remarks'] = this.caseData.remarks.concat(this.editedItem.remarks)
      store.dispatch(SAVE_CASE, strUpdate)
        .then(() => {
          this.closeRemarks()
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err})
        })
    },
    closeRemarks() {
      this.dlgRemarks = false
      this.$nextTick(() => {
        this.editedRemarks = Object.assign({}, this.defaultRemarks)
      })
    }
  }
}
</script>
