<template>
  <v-card-title>
    {{itemData.request_number}}
    <v-chip v-if="itemData.status === 0" class="ml-2" label small color="secondary">待处理</v-chip>
    <v-chip v-else-if="itemData.status === 1" class="ml-2" label small color="primary">处理中</v-chip>
    <v-chip v-else-if="itemData.status === 2" class="ml-2" label small>已取消</v-chip>
    <v-chip v-else class="ml-2" label small>已完成</v-chip>
    <v-spacer></v-spacer>
    <v-btn icon class="mr-2" @click="closeDialog"><v-icon>close</v-icon></v-btn>
  </v-card-title>
</template>
<script>
export default {
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  watch: {},
  created() {},
  methods: {
    closeDialog() {
      this.$emit('Close')
    }
  },
}
</script>
<style scoped>
</style>
