var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "px-16",
    attrs: {
      "fluid": "",
      "no-gutters": ""
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-sheet', {
          attrs: {
            "color": _vm.editBodyData.bg_color || 'transparent'
          }
        }, [_c('v-card-title', {
          staticClass: "text-center justify-center py-6"
        }, [_c('h1', {
          class: 'font-weight-bold display-1 ' + _vm.editBodyData.color + '--text'
        }, [_vm._v(_vm._s(_vm.editBodyData.title || '服务内容'))])]), _vm.serviceList && _vm.serviceList.length ? _c('v-tabs', {
          attrs: {
            "background-color": "transparent",
            "icons-and-text": "",
            "color": _vm.editBodyData.tab_color || 'primary',
            "grow": ""
          }
        }, [_vm._l(_vm.editBodyData.services, function (item) {
          return _c('v-tab', {
            key: item
          }, [_vm._v(" " + _vm._s(_vm.serviceList.find(function (service) {
            return service._id === item;
          }).name) + " "), _c('v-icon', [_vm._v(_vm._s(_vm.serviceList.find(function (service) {
            return service._id === item;
          }).icon))])], 1);
        }), _vm._l(_vm.editBodyData.services, function (item, i) {
          return _c('v-tab-item', {
            key: i
          }, [_c('v-card', {
            attrs: {
              "flat": "",
              "color": _vm.editBodyData.bg_color || 'transparent'
            }
          }, [_c('v-card-text', {
            staticClass: "text-center"
          }, [_c('pre', {
            attrs: {
              "width": "50%"
            }
          }, [_vm._v(_vm._s(_vm.serviceList.find(function (service) {
            return service._id === item;
          }).remarks))]), _c('v-btn', {
            staticClass: "ma-6",
            attrs: {
              "text": ""
            }
          }, [_vm._v("了解详情 >>")])], 1)], 1)], 1);
        })], 2) : _vm._e(), _c('v-fade-transition', [hover ? _c('v-overlay', {
          staticClass: "align-end",
          staticStyle: {
            "top": "100%"
          },
          attrs: {
            "absolute": "",
            "color": "grey lighten-5"
          }
        }, [_c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("edit")]), _vm._v(" 编辑")], 1), _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("delete")]), _vm._v(" 删除")], 1)], 1) : _vm._e()], 1)], 1)];
      }
    }])
  }), _c('v-bottom-sheet', {
    attrs: {
      "retain-focus": false,
      "eager": ""
    },
    model: {
      value: _vm.dlgSetting,
      callback: function callback($$v) {
        _vm.dlgSetting = $$v;
      },
      expression: "dlgSetting"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("设置")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "标题",
      "hint": "显示在服务列表上面的标题",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editBodyData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData, "title", $$v);
      },
      expression: "editBodyData.title"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.titleColor,
      "label": "标题颜色",
      "autocomplete": "off",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": item
          }
        }, [_vm._v(" " + _vm._s(_vm._f("stringModule")(item)) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": data.item
          }
        }, [_vm._v(_vm._s(_vm._f("stringModule")(data.item)))])];
      }
    }]),
    model: {
      value: _vm.editBodyData.color,
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData, "color", $$v);
      },
      expression: "editBodyData.color"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.tabColor,
      "label": "选项卡选中后颜色",
      "autocomplete": "off",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": item
          }
        }, [_vm._v(" " + _vm._s(_vm._f("stringModule")(item)) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": data.item
          }
        }, [_vm._v(_vm._s(_vm._f("stringModule")(data.item)))])];
      }
    }]),
    model: {
      value: _vm.editBodyData.tab_color,
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData, "tab_color", $$v);
      },
      expression: "editBodyData.tab_color"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.bgColor,
      "label": "整体背景色",
      "autocomplete": "off",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": item
          }
        }, [_vm._v(" " + _vm._s(_vm._f("stringModule")(item)) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": data.item
          }
        }, [_vm._v(_vm._s(_vm._f("stringModule")(data.item)))])];
      }
    }]),
    model: {
      value: _vm.editBodyData.bg_color,
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData, "bg_color", $$v);
      },
      expression: "editBodyData.bg_color"
    }
  })], 1)], 1), _c('v-item-group', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.editBodyData.services,
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData, "services", $$v);
      },
      expression: "editBodyData.services"
    }
  }, [_c('v-row', _vm._l(_vm.flatGroupCatalog(_vm.catalogList), function (item, i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "2"
      }
    }, [_c('v-item', {
      attrs: {
        "value": item._id
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref5) {
          var active = _ref5.active,
              toggle = _ref5.toggle;
          return [_c('v-card', {
            attrs: {
              "outlined": "",
              "hover": ""
            },
            on: {
              "click": toggle
            }
          }, [_c('v-card-title', [_vm._v(_vm._s(item.name)), _c('v-spacer'), _c('v-icon', [_vm._v(_vm._s(active ? 'check_circle' : 'check_circle_outline'))])], 1)], 1)];
        }
      }], null, true)
    })], 1);
  }), 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }