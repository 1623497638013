<template>
  <div>
    <v-autocomplete
      v-model="account"
      :rules="[rules.required]"
      :items="employeeResult.filter(item => item.personal && item.personal.name)"
      :loading="searchEmployee.loadingList"
      :search-input.sync="searchEmployee.searchList"
      :filter="filterEmployee"
      @change="selectedEmployee"
      append-icon="add"
      @click:append="createEmployee"
      return-object
      item-text="personal.name"
      item-value="_id"
      autocomplete="off"
      outlined
      dense
      label="选择用户"
      placeholder="输入员工姓名、电话或手机号码进行查找"
      clearable
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
          <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-dialog v-model="dlgEmployee" persistent scrollable max-width="600px">
      <v-form v-model="validEmployee" @submit.prevent="submitEmployee">
        <v-card>
          <v-card-title>
            <span class="headline">创建员工</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeEmployee"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row align="start" justify="center">
                <v-col cols="2" class="text-center">
                  <input
                    id="filesAvatar"
                    type="file"
                    name="filesAvatar"
                    ref="uploadAvatar"
                    accept="image/*"
                    :multiple="false"
                    @change="detectAvatar($event)"/>
                  <v-hover v-slot:default="{ hover }">
                    <v-avatar>
                      <v-img
                        :src="employee.editedItem.profile ? ossURL +'/'+ employee.editedItem.profile : '/static/grey_silhouette.png'"
                        alt="设置头像"
                        aspect-ratio="1">
                        <v-progress-circular
                          color="secondary"
                          rotate="360"
                          size="48"
                          width="5"
                          v-if="employee.uploading"
                          :value="employee.progressUpload">
                        </v-progress-circular>
                      </v-img>
                      <div v-if="hover" class="show_upload" style="height: 100%; cursor: pointer">
                        <v-icon @click="selectAvatar()" >add_a_photo</v-icon>
                      </div>
                    </v-avatar>
                  </v-hover>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.name"
                    :rules="[rules.required]"
                    type="text"
                    label="员工姓名"
                    hint="员工姓名为用户的真实姓名"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">https</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.username"
                    :rules="[rules.required, rules.email]"
                    :error-messages="employee.editedItem.msgName"
                    :clearable="employee.editedItem.msgName !== ''"
                    type="text"
                    label="电子邮件"
                    hint="电子邮件将作为登陆名称使用"
                    outlined
                    dense
                    required
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="employee.editedItem.chkName"
                          :width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-scale-transition>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">vpn_key</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.password"
                    :rules="[rules.required]"
                    :type="employee.editedItem.showPass ? 'text' : 'password'"
                    :append-icon="employee.editedItem.showPass ? 'visibility_off' : 'visibility'"
                    label="账号密码"
                    hint="请牢记此密码，密码将作为登陆凭证"
                    outlined
                    dense
                    required
                    @click:append="employee.editedItem.showPass = !employee.editedItem.showPass"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">stay_current_portrait</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.phone"
                    :rules="[rules.required, rules.phonenum]"
                    :error-messages="employee.editedItem.msgPhone"
                    :clearable="employee.editedItem.msgPhone !== ''"
                    type="number"
                    label="手机号码"
                    hint="手机号码可作为登陆名称使用"
                    outlined
                    color="secondary"
                    dense
                    required
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="employee.editedItem.chkPhone"
                          :width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-scale-transition>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6" v-if="enterprise.branch">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">account_tree</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="employee.editedItem.sub_corp"
                    :items="enterprise.branch"
                    autocomplete="off"
                    outlined
                    dense
                    label="请选择员工所属分公司"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">supervisor_account</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="employee.editedItem.roles"
                    :items="flatGroupRoles(groupList)"
                    :rules="[rules.selected]"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择所属角色"
                    multiple
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-action>
                          <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">integration_instructions</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.employeeNo"
                    type="text"
                    label="员工编号"
                    hint="员工编号为企业内部的员工编号"
                    outlined
                    color="secondary"
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">room</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.address"
                    :rules="[rules.required]"
                    :error-messages="employee.editedItem.msgAddress"
                    :clearable="employee.editedItem.msgAddress !== ''"
                    type="text"
                    label="收件地址"
                    hint="收件地址为接收设备的目的地"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <v-fade-transition leave-absolute>
                        <v-progress-circular
                          v-if="employee.editedItem.chkAddress"
                          width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-fade-transition>
                    </template>
                    <template v-slot:append-outer>
                      <v-btn text @click="copyAddress(enterprise)">复制公司地址</v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6" v-for="(item, i) in this.employee.editedItem.customFields" :key="i+'_field'">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">list</v-icon>
                </v-col>
                <v-col cols="10" v-if="item.format.type === 'text'">
                  <v-text-field
                    v-if="item.format.mask"
                    v-model="item.value"
                    v-mask="item.format.mask"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense>
                  </v-text-field>
                  <v-text-field
                    v-else
                    v-model="item.value"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="10" v-if="item.format.type === 'selects'">
                  <v-autocomplete
                    autocomplete="off"
                    v-model="item.value"
                    :items="item.format.items"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <widgets-custom-field module="Account" :ownerId="enterprise._id" ownerType="Enterprise" v-on:Complete="updateFields()"></widgets-custom-field>
            <v-spacer></v-spacer>
            <v-btn text @click="closeEmployee">取消</v-btn>
            <v-btn color="secondary" text @click="submitEmployee" :disabled="!validEmployee">创建</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {
  CHECK_ADDR,
  CHECK_USERNAME, CHECK_USERPHONE,
  CREATE_EMPLOYEE,
  FETCH_EMPLOYEE_LIST,
  FETCH_FIELDS, FETCH_GROUP_LIST,
  FETCH_UPLOAD,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {SET_ERROR} from "@/store/mutations.type";
import _ from "lodash";
import axios from "axios";

export default {
  props: {
    enterprise: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      account: '',
      dlgEmployee: false,
      validEmployee: true,
      employee: {
        editedItem: {
          _id: '',
          username: '',
          password: '',
          phone: '',
          name: '',
          profile: '',
          employeeNo: '',
          address: '',
          roles: [],
          sub_corp: '',
          chkName: false,
          msgName: '',
          chkPhone: false,
          msgPhone: '',
          chkAddress: false,
          msgAddress: '',
          showPass: false,
          customFields: []
        },
        defaultItem: {
          _id: '',
          username: '',
          password: '',
          phone: '',
          name: '',
          profile: '',
          employeeNo: '',
          roles: [],
          sub_corp: '',
          address: '',
          chkName: false,
          msgName: '',
          chkPhone: false,
          msgPhone: '',
          chkAddress: false,
          msgAddress: '',
          showPass: false,
          customFields: []
        },
        uploading: false,
        progressUpload: 0,
      },
      searchEmployee: {
        loadingList: false,
        searchList: null,
        timerList: null,
        entriesList: [],
      },
    }
  },
  watch: {
    dlgEmployee (val) {
      val || this.closeEmployee()
    },
    'employee.editedItem.username'() {
      this.checkName();
    },
    'employee.editedItem.phone'() {
      this.checkPhone();
    },
    'employee.editedItem.address'() {
      this.checkAddress();
    },
    'searchEmployee.searchList'(val) {
      if (this.searchEmployee.timerList) {
        clearTimeout(this.searchEmployee.timerList)
      }
      this.searchEmployee.timerList = setTimeout(() => {
        if (this.searchEmployee.loadingList) return
        this.searchEmployee.loadingList = true
        if (this.enterprise._id || val) {
          let strEnterprise = this.enterprise._id || '', strKey = val || ''
          store.dispatch(FETCH_EMPLOYEE_LIST, 'enterprise='+ strEnterprise +'&key='+ strKey)
            .then((data) =>{
              if (data) {
                const { employees } = data
                this.searchEmployee.entriesList = employees
              }
            })
            .catch(err => {
              store.commit(SET_ERROR, {msg: err})
            })
            .finally(() => (this.searchEmployee.loadingList = false))
        } else {
          this.searchEmployee.loadingList = false
        }
      }, 500)
    },
  },
  computed: {
    ...mapGetters([
      "groupList",
      "uploadSign",
    ]),
    employeeResult() {
      return this.searchEmployee.entriesList.map(user => {
        return Object.assign({}, user)
      })
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      store.dispatch(FETCH_GROUP_LIST, 'owner='+ this.enterprise._id)
      store.dispatch(FETCH_EMPLOYEE_LIST, 'limit=10&enterprise='+ this.enterprise._id)
        .then((user) =>{
          if (user) {
            const { employees } = user
            this.searchEmployee.entriesList = employees
            this.$forceUpdate()
          }
        })
        .catch(err => {
          store.commit(SET_ERROR, {msg: err})
        })
    },
    selectedEmployee() {
      this.$emit('Selected', this.account)
    },
    createEmployee() {
      this.getFieldList(this.enterprise._id, 'Account')
      this.dlgEmployee = true
    },
    getFieldList(eid, mod = 'Account') {
      store.dispatch(FETCH_FIELDS, eid+'/'+mod)
        .then((data) => {
          if (data && data.length) {
            this.employee.editedItem.customFields.length = 0
            data.forEach(item => {
              this.employee.editedItem.customFields.push(Object.assign(item, {value: ''}))
            })
          }
        })
    },
    flatGroupRoles(arrGroup) {
      let arrRoles = []
      // arrRoles.push({ _id: '', name: '全部', group: ''})
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.roles.forEach((rolesItem) => {
          arrRoles.push({ _id: rolesItem._id, name: rolesItem.name, count: rolesItem.employeeCount, group: groupItem.name, type: rolesItem.type, service: rolesItem.service })
        })
      })
      return arrRoles
    },
    checkName: _.debounce(function () {
      if (this.employee.editedItem.username) {
        this.employee.editedItem.chkName = true;
        this.$store.dispatch(CHECK_USERNAME, this.employee.editedItem.username)
          .then(() => {
            this.employee.editedItem.msgName = '电子邮件已被注册，请更换其它地址';
            this.employee.editedItem.chkName = false;
          })
          .catch(() => {
            this.employee.editedItem.msgName = '';
            this.employee.editedItem.chkName = false;
          })
      }
    }, 1000),
    checkPhone: _.debounce(function () {
      if (this.employee.editedItem.phone) {
        this.employee.editedItem.chkPhone = true;
        this.$store.dispatch(CHECK_USERPHONE, this.employee.editedItem.phone)
          .then(() => {
            this.employee.editedItem.msgPhone = '手机号码已被注册，请更换其它号码';
            this.employee.editedItem.chkPhone = false;
          })
          .catch(() => {
            this.employee.editedItem.msgPhone = '';
            this.employee.editedItem.chkPhone = false;
          })
      }
    }, 1000),
    checkAddress: _.debounce(function () {
      if (this.employee.editedItem.address) {
        this.employee.editedItem.chkAddress = true;
        this.$store.dispatch(CHECK_ADDR, this.employee.editedItem.address)
          .then((data) => {
            if (data.status !== 0 || data.result.reliability < 7) {
              this.employee.editedItem.msgAddress = '地址信息错误，请输入正确的地址信息';
            } else {
              this.employee.editedItem.addressInfo = data.result;
              this.employee.editedItem.msgAddress = '';
            }
            this.employee.editedItem.chkAddress = false;
          })
          .catch((err) => {
            this.employee.editedItem.msgAddress = err.data.message;
            this.employee.editedItem.chkAddress = false;
          })
      }
    }, 500),
    copyAddress(item) {
      this.employee.editedItem.address = item.address
    },
    selectAvatar() {
      store.dispatch(FETCH_UPLOAD)
        .then(this.$refs.uploadAvatar.click())
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    detectAvatar(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.uploadAvatar(fileList[x]);
      })
    },
    async uploadAvatar(file) {
      this.fileName = file.name;
      this.employee.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.enterprise._id +'/avatar/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.employee.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.employee.uploading = false;
          this.employee.progressUpload = 0;
          this.$refs.uploadAvatar.value = '';
          this.employee.editedItem.profile = this.enterprise._id +'/avatar/'+ this.uploadSign.key;
        })
        .catch((error) => {
          this.employee.uploading = false;
          this.employee.progressUpload = 0;
          this.$refs.uploadAvatar.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
    submitEmployee() {
      let strInsert = {}
      strInsert['personal'] = {}
      strInsert['employer'] = {}
      strInsert['customFields'] = []
      strInsert.username = this.employee.editedItem.username
      strInsert.password = this.employee.editedItem.password
      strInsert.phone = this.employee.editedItem.phone
      strInsert.ownerType = 'Enterprise'
      strInsert.ownerId = this.enterprise._id
      strInsert['employer'].roles = this.employee.editedItem.roles
      if (this.employee.editedItem.customFields.length) {
        this.employee.editedItem.customFields.forEach(item => {
          strInsert['customFields'].push({ _id: item._id, value: item.value })
        })
      }
      if (this.employee.editedItem.name) strInsert['personal'].name = this.employee.editedItem.name
      if (this.employee.editedItem.profile) strInsert['personal'].profile = this.employee.editedItem.profile
      if (this.employee.editedItem.address) strInsert['personal'].address = this.employee.editedItem.address
      if (this.employee.editedItem.employeeNo) strInsert['employer'].employeeNo = this.employee.editedItem.employeeNo;
      if (this.employee.editedItem.sub_corp) strInsert['employer'].sub_corp = this.employee.editedItem.sub_corp;
      if (this.employee.editedItem.addressInfo) {
        strInsert.addressInfo = this.employee.editedItem.addressInfo
      } else {
        this.$store.commit(SET_ERROR, {msg: '请等待地址验证中...', color: 'secondary', timeout: 500});
        return
      }
      store.dispatch(CREATE_EMPLOYEE, strInsert)
        .then(async (data) => {
          store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
          this.entriesEmployee.push(data)
          this.account = data
          this.$emit('Selected', data)
          this.closeEmployee()
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    closeEmployee() {
      this.dlgEmployee = false
      this.$nextTick(() => {
        this.employee.editedItem = Object.assign({}, this.employee.defaultItem)
        this.employee.editedItem.roles.length = 0
        this.employee.editedItem.customFields.length = 0
      })
    },
    updateFields() {
      this.getFieldList(this.enterprise._id, 'Account')
    },
    filterEmployee(item, search) {
      return (
        item.username.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        || item.phone.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        || (item.personal && item.personal.name && item.personal.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1)
      )
    },
  }
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>

