<template>
  <v-card-text>
    <v-data-table
      :headers="headerProduct"
      :items="editedItem"
      :server-items-length="editedItem.length"
      :loading="loadingList"
      hide-default-footer
      item-key="_id"
      return-object="false"
      dense
    >
<!--      { text: '产品品牌', value: 'product_vendor.name' },-->
<!--      { text: '产品类型', value: 'product_type.name' },-->
<!--      { text: '产品型号', value: 'part_number' },-->
<!--      { text: '产品名称', value: 'product_name'},-->
<!--      { text: '单价（元）', value: 'sales_price' },-->
<!--      { text: '小计（元）', value: 'sum_price' },-->
<!--      { text: '需交付数量', value: 'quantity' },-->
<!--      { text: '已导入数量', value: 'asset' },-->

<!--      产品品牌-->
      <template v-slot:item.product_vendor.name="{ item }">
        <td nowrap="true"> {{ item.product_vendor.name }} </td>
      </template>
<!--      产品类型-->
      <template v-slot:item.product_type="{ item }">
        <td nowrap="true"> {{ item.product_type.name }} </td>
      </template>
<!--      产品型号-->
      <template v-slot:item.part_number="{ item }">
        <td nowrap="true"> {{ item.part_number }} </td>
      </template>
<!--      产品名称-->
      <template v-slot:item.product_name="{ item, index }">
        <td
          :nowrap="open[index]?false:true"
          :class="!open[index]?'text-truncate text-decoration-underline':'text-decoration-underline'"
          style="max-width: 200px; cursor: pointer;"
          @click="showText(index)"
        >{{ item.product_name }}</td>
      </template>
      <template v-slot:item.sales_price="{ item }">
        <span class="d-inline-block text-truncate" style="max-width: 175px;"> {{item.sales_price.$numberDecimal | formatMoney}} </span>
      </template>
      <template v-slot:item.purchase_price="{ item }">
        <span class="d-inline-block text-truncate" style="max-width: 175px;"> {{item.purchase_price.$numberDecimal | formatMoney}} </span>
      </template>
      <template v-slot:item.purchase_margin="{ item }">
        <span class="d-inline-block text-truncate" style="max-width: 175px;"> {{parseFloat(item.purchase_margin * 100).toFixed(2)}}% </span>
      </template>
      <template v-slot:item.sum_price="{ item }">
        <span class="d-inline-block text-truncate" style="max-width: 175px;" v-if="caseData.rent_info && caseData.rent_info.total_period > 0"> {{ parseFloat(item.sales_price.$numberDecimal * item.quantity * caseData.rent_info.total_period).toFixed(4) }} </span>
        <span class="d-inline-block text-truncate" style="max-width: 175px;" v-else> {{ parseFloat(item.sales_price.$numberDecimal * item.quantity).toFixed(4) }} </span>
      </template>
      <template v-slot:item.import="{ item }">
        <v-btn icon @click="createSN(item)"><v-icon>playlist_add</v-icon></v-btn>
      </template>
      <template v-slot:body.append>
        <tr class="secondary--text">
          <td colspan="5" class="text-end">合计</td>
          <td class="text-start" v-if="caseData.rent_info && caseData.rent_info.total_period > 0">{{ editedItem.reduce((n, m) => n + (m.sales_price.$numberDecimal * m.quantity * caseData.rent_info.total_period), 0) | formatMoney}}</td>
          <td class="text-start" v-else>{{ editedItem.reduce((n, m) => n + (m.sales_price.$numberDecimal * m.quantity), 0) | formatMoney}}</td>
          <td class="text-start" colspan="2" v-if="caseData.purchase_id"></td>
          <td class="text-start">{{ editedItem.reduce((n, m) => n + m.quantity, 0) }}</td>
          <td class="text-start" colspan="2">{{ editedItem.reduce((n, m) => n + m.asset, 0) }}</td>
        </tr>
      </template>
    </v-data-table>
<!--    <v-row no-gutters>-->
<!--      <v-col cols="12">-->
<!--        <v-btn v-if="caseData.status === 1 && caseData.isEdit && currentTask.task && currentTask.task.powers.includes('relate_asset')" block outlined @click="openImport()"><v-icon left>publish</v-icon>导入设备</v-btn>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
      <v-card v-if="activeType === 'import'">
        <v-card-title>
          批量导入
          <v-spacer></v-spacer>
          <v-btn icon @click="closeInfo()"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <p>1. 下载设备模版，填写设备信息.</p>
                <v-btn text @click="downloadTemplate">下载模版<v-icon>file_download</v-icon></v-btn>
              </v-col>
              <v-col cols="6">
                <input
                  id="import_file"
                  type="file"
                  name="import_file"
                  ref="uploadImport"
                  accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :multiple="false"
                  @change="importFiles($event)"/>
                <p>2. 上传填好的文件.</p>
                <v-btn text @click="selectFiles" :loading="!importEnd"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
              </v-col>
              <v-row v-if="importFailed.length || importResult">
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-alert
                    outlined
                    type="error"
                    text
                  >
                    导入失败：<span v-if="importFailed.length === 0">无</span><ul v-else><li v-for="(fail,index) in importFailed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
                  </v-alert>
                  <v-alert
                    outlined
                    type="success"
                    text
                  >
                    成功创建：{{importResult.insertSum}} 条记录； 更新：{{importResult.updateSum}} 条记录；
                  </v-alert>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card v-if="activeType === 'relate'">
        <v-form v-model="valid" @submit.prevent="submitRelate">
          <v-card-title>
            关联序列号
            <v-spacer></v-spacer>
            <v-btn icon @click="closeInfo()"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-alert dense text type="success">
              需要交付 <b>{{ activeItem.quantity }}</b> 台型号为：{{ activeItem.part_number }}的{{ activeItem.product_name }}。
            </v-alert>
            <v-subheader v-if="activeItem.product_type && activeItem.product_type.noSN">
              配件无需关联序列号，请确认是否已经准备齐全所有配件。
              <span class="font-weight-bold">准备齐全点击保存按钮</span>
            </v-subheader>
            <v-textarea
              v-else
              v-model="activeItem.serial_number"
              :rules="[rules.required]"
              outlined
              label="导入序列号"
              hint="请输入需要关联的设备序列号，多个序列号使用换行或 , 分隔。"
            ></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeInfo()">取消</v-btn>
            <v-btn color="secondary" text @click="submitRelate" :disabled="!valid" :loading="exeing">保存</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import XLSX from "xlsx";
import ApiService from "@/common/http";
import store from "@/store";
import {FETCH_CASE, RELATE_SERIAL} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
    caseType: {
      type: String,
      default: 'service'
    },
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
      },
      currentTask: {},
      headerProduct: [
        { text: '产品品牌', value: 'product_vendor.name' },
        { text: '产品类型', value: 'product_type' },
        { text: '产品型号', value: 'part_number' },
        { text: '产品名称', value: 'product_name'},
        { text: '单价（元）', value: 'sales_price' },
        { text: '小计（元）', value: 'sum_price' },
        { text: '需出库数量', value: 'quantity' },
        { text: '已导入数量', value: 'asset' },
      ],
      loadingList: true,
      editedItem: [],
      dlgInfo: false,
      activeType: '',
      activeItem: {},
      importExcelHeader: ['ESP订单号', 'ESP工单号', 'PO单号', '商务单号', '企业客户', '产品品牌', '产品类型', '产品名称', '产品型号', '序列号'],
      importExcelNotice: [['订单产品导入仅限在ESP平台执行工单任务时批量导入商品使用。']],
      importEnd: true,
      importResult: null,
      importFailed: [],
      valid: true,
      exeing: false,
      open: {},
    }
  },
  watch: {
    dlgInfo (val) {
      val || this.closeInfo()
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.loadingList = true
      if (this.caseData.purchase_id && this.caseData.purchase_id.request_number) {
        this.headerProduct.splice(6, 0,
          { text: '采购成本（元）', value: 'purchase_price' },
          { text: '利润率', value: 'purchase_margin' })
      }
      if (this.caseData.tasks_list) this.currentTask = this.caseData.tasks_list.find(item => item.isActive)
      if (this.caseData.product_list) {
        this.editedItem = this.caseData.product_list.concat()
        if (this.caseData.status === 1 && this.caseData.isEdit && this.currentTask.task && this.currentTask.task.powers.includes('relate_asset')) {
          this.headerProduct.push({
            text: '导入序列号', value: 'import'
          })
        }
        this.loadingList = false
      }
    },
    createSN(item) {
      if (item.asset >= item.quantity) {
        store.commit(SET_ERROR, {msg: '该产品已全部交付。', timeout: 5000, color: 'primary'});
        return
      }
      this.activeType = 'relate'
      this.activeItem = Object.assign({}, item)
      this.dlgInfo = true
    },
    showText(i) {
      this.open[i]= !this.open[i]
      this.$forceUpdate()
    },
    submitRelate() {
      this.exeing = true
      let objRelate = JSON.parse(JSON.stringify(this.activeItem))
      let canNumber = parseInt(this.activeItem.quantity) - parseInt(this.activeItem.asset)

      if (this.activeItem.product_type && this.activeItem.product_type.noSN) {
        let uuidSN = Array(canNumber).fill('')
        uuidSN = uuidSN.map(() => uuidv4())
        objRelate.serial_number = uuidSN
      } else {
        objRelate.serial_number = objRelate.serial_number.replace(/\t/g, '\n')
        objRelate.serial_number = objRelate.serial_number.split('\n')
      }

      if (objRelate.serial_number.length !== canNumber) {
        store.commit(SET_ERROR, {msg: '序列号数量必须为'+ canNumber + '个。', timeout: 5000});
        this.exeing = false
      } else {
        objRelate.enterprise = this.caseData.enterprise._id
        objRelate.order_id = this.caseData.order_id._id
        objRelate.case_id = this.caseData._id

        store.dispatch(RELATE_SERIAL, {form: 'service', data: objRelate})
          .then(() => {
            store.commit(SET_ERROR, {msg: '关联成功', color: 'primary'});
            store.dispatch(FETCH_CASE, {form: 'service', query: this.caseData._id})
              .then((data) => {
                this.editedItem = data.product_list.concat()
              })
            this.exeing = false
            this.$emit('Update')
            this.closeInfo()
          })
          .catch((error) => {
            this.exeing = false
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
    openImport() {
      this.activeType = 'import'
      this.dlgInfo = true
    },
    closeInfo() {
      this.dlgInfo = false
      this.$nextTick(() => {
        this.activeItem = Object.assign({}, {})
      })
    },
    downloadTemplate() {
      let allProductList = [];
      let ws = XLSX.utils.aoa_to_sheet(this.importExcelNotice)
      for (let product of this.caseData.product_list) {
        for (let i = 0; i < product.quantity; i++) {
          allProductList.push({
            'ESP订单号': this.caseData.order_id.order_number,
            'ESP工单号': this.caseData.case_number,
            'PO单号': this.caseData.out_number,
            '商务单号': this.caseData.erp_number,
            '企业客户': this.caseData.enterprise.name,
            '产品品牌': product.product_vendor.name,
            '产品类型': product.product_type.name,
            '产品名称': product.product_name,
            '产品型号': product.part_number,
            '序列号': '',
          })
        }
      }

      XLSX.utils.sheet_add_json(ws, allProductList, {header: this.importExcelHeader, origin: 'A2'})
      const merge = [
        {s: {r:0, c:0}, e: {r:0, c:this.importExcelHeader.length - 1}}
      ]
      ws['!merges'] = merge
      ws.A1.s = {alignment:{ wrapText: true }};
      let wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Import_Product')
      XLSX.writeFile(wb, this.caseData.case_number +'_设备模版.xlsx')
    },
    selectFiles() {
      this.$refs.uploadImport.click()
    },
    importFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.uploadFiles(fileList[x]);
      })
    },
    uploadFiles(file) {
      this.importEnd = false
      let param = new FormData();
      param.append('file', file);
      param.append('cases', this.caseData._id);
      ApiService.post('/case/service/asset/import', param)
        .then((data) => {
          this.importResult = data.data.Success
          this.importFailed = data.data.Failed
          this.$refs.uploadImport.value = '';
          this.importEnd = true
          store.dispatch(FETCH_CASE, {form: 'service', query: this.caseData._id})
            .then((data) => {
              this.editedItem = data.product_list.concat()
            })
        })
        .catch((err) => {
          this.$refs.uploadImport.value = '';
          this.importResult = { updateSum: 0, insertSum: 0 }
          this.importFailed = []
          this.importEnd = true
          this.importFailed = [{line: 2, error: err.response.data ? err.response.data.message : err}]
        });
    }
  }
}

</script>

<style scoped>
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
