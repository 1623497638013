var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.caseData.remarks ? _c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.caseData.remarks, function (item, i) {
    return [!item.divider ? _c('v-list-item', {
      key: 'remarks_' + i + '_title',
      attrs: {
        "three-line": ""
      }
    }, [_c('widgets-employee-dialogs', {
      attrs: {
        "account": item.account._id
      }
    }, [_c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": item.avatar ? _vm.ossURL + '/' + item.avatar : '/static/grey_silhouette.png'
      }
    })], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.title) + " - " + _vm._s(item.account._id === _vm.currentUser._id ? "我" : item.account.personal.name) + " - " + _vm._s(_vm._f("fromDate")(item.createTime)) + " ")]), _c('span', {
      class: item.isBlock ? 'text-decoration-line-through' : ''
    }, [_vm._v(" " + _vm._s(item.context) + " ")])], 1), item.account._id === _vm.currentUser._id && !item.isBlock ? _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.blockRemark(item);
        }
      }
    }, [_vm._v("block")])], 1)], 1) : _vm._e()], 1) : _vm._e()];
  }), _vm._l(_vm.editedItem.remarks, function (item, index) {
    return [!item.divider ? _c('v-list-item', {
      key: 'remarks_' + index + '_title_new',
      attrs: {
        "three-line": ""
      }
    }, [_c('widgets-employee-dialogs', {
      attrs: {
        "account": item.account
      }
    }, [_c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": item.avatar ? _vm.ossURL + '/' + item.avatar : '/static/grey_silhouette.png'
      }
    })], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title) + " " + _vm._s(_vm._f("fromDate")(item.createTime)))]), _vm._v(" " + _vm._s(item.context) + " ")], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.removeRemark(item);
        }
      }
    }, [_vm._v("delete")])], 1)], 1)], 1) : _vm._e()];
  }), _c('v-list-item', [_vm.caseType === 'service' ? _c('v-list-item-content', [_vm.caseData.status === 0 || _vm.currentTask && _vm.currentTask.task.powers.includes('create_remarks') ? _c('v-list-item-title', [_c('v-btn', {
    attrs: {
      "block": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editRemarks();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("rate_review")]), _vm._v("增加备注")], 1)], 1) : _vm._e()], 1) : _c('v-list-item-content', [_c('v-list-item-title', [_c('v-btn', {
    attrs: {
      "block": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editRemarks();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("rate_review")]), _vm._v("增加备注")], 1)], 1)], 1)], 1)], 2), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "550px"
    },
    model: {
      value: _vm.dlgRemarks,
      callback: function callback($$v) {
        _vm.dlgRemarks = $$v;
      },
      expression: "dlgRemarks"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 增加备注 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeRemarks
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "备注标题",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedRemarks.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRemarks, "title", $$v);
      },
      expression: "editedRemarks.title"
    }
  }), _c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "备注内容",
      "value": ""
    },
    model: {
      value: _vm.editedRemarks.context,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRemarks, "context", $$v);
      },
      expression: "editedRemarks.context"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeRemarks();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.editedRemarks.title || !_vm.editedRemarks.context
    },
    on: {
      "click": function click($event) {
        return _vm.createRemarks(_vm.editedRemarks);
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }