<template>
  <v-row v-if="caseData && caseData.rent_info">
    <v-col cols="2" class="text--secondary text-right">租赁方式</v-col>
    <v-col cols="4"><strong>{{ caseData.rent_info.rent_type === 0 ? '融资租赁' : '经营性租赁' }}</strong></v-col>
<!--    <v-col cols="2" class="text-right">起租日期</v-col>-->
<!--    <v-col cols="4" class="text&#45;&#45;secondary">{{ caseData.rent_info.lease_start | formatDate }}</v-col>-->
    <v-col cols="2" class="text--secondary text-right">租期</v-col>
    <v-col cols="4"><strong>{{ caseData.rent_info.lease_term / 365 + '年' }}</strong></v-col>
<!--    <v-col cols="2" class="text-right">到期日期</v-col>-->
<!--    <v-col cols="4" class="text&#45;&#45;secondary">{{ caseData.rent_info.expiry_date | formatDate }}</v-col>-->
    <v-col cols="2" class="text--secondary text-right">支付周期</v-col>
    <v-col cols="4">
      <span v-if="caseData.rent_info.payment_cycle === 0"><strong>月付</strong></span>
      <span v-else-if="caseData.rent_info.payment_cycle === 1"><strong>季付</strong></span>
      <span v-else-if="caseData.rent_info.payment_cycle === 2"><strong>半年付</strong></span>
      <span v-else><strong>年付</strong></span>
    </v-col>
    <v-col cols="2" class="text--secondary text-right">账期</v-col>
    <v-col cols="4"><strong>{{ caseData.rent_info.payment_period + '天' }}</strong></v-col>
    <v-col cols="2" class="text--secondary text-right">总期数</v-col>
    <v-col cols="4"><strong>{{ caseData.rent_info.total_period + '期' }}</strong></v-col>
    <v-col cols="2" class="text--secondary text-right">签约方</v-col>
    <v-col cols="4"><strong>{{ PartyList.find(p => p.code === caseData.rent_info.stakeholders.party) ? PartyList.find(p => p.code === caseData.rent_info.stakeholders.party).name : caseData.rent_info.stakeholders.party}}</strong></v-col>
  </v-row>
</template>
<script>
import Util from "@/common/util";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  mounted() {},
  watch: {},
  created() {},
  computed: {
    PartyList() {
      return Util.categories('PartyList')
    }
  },
  methods: {}
}
</script>
