var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.copy
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.clicked ? 'done' : 'content_copy'))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }