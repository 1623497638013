var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    attrs: {
      "color": _vm.color,
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('open_case');
      }
    }
  }, [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.caseData && _vm.caseData.catalog && _vm.caseData.creator && _vm.caseData.enterprise ? _c('v-card', {
    attrs: {
      "loading": _vm.caseLoading ? 'secondary' : false
    }
  }, [_c('case-card-title', {
    attrs: {
      "caseData": _vm.caseData
    },
    on: {
      "Close": _vm.closeDialog
    }
  }), _c('v-divider'), _c('v-card-text', [_c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "accordion": "",
      "flat": ""
    },
    model: {
      value: _vm.openPanel,
      callback: function callback($$v) {
        _vm.openPanel = $$v;
      },
      expression: "openPanel"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("申请信息")]), _c('v-expansion-panel-content', [_c('case-basic-info', {
    attrs: {
      "caseData": _vm.caseData
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_vm._v("详细信息")]), _c('v-expansion-panel-content', [_c('case-rent-info', {
    attrs: {
      "caseData": _vm.caseData
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', [_vm.caseData.order_id ? _c('v-tab', [_vm._v("订单产品")]) : _vm._e(), _c('v-tab', [_vm._v("关联设备")]), _vm.caseData.process && _vm.caseData.process.isRecovery ? _c('v-tab', [_vm._v("更换新设备")]) : _vm._e(), _c('v-tab', [_vm._v("附件")]), _c('v-tab', [_vm._v("备注")]), _vm.caseData.order_id ? _c('v-tab-item', [_c('case-order-product', {
    attrs: {
      "caseData": _vm.caseData
    },
    on: {
      "Update": function Update($event) {
        return _vm.getCase(_vm.getId);
      }
    }
  })], 1) : _vm._e(), _c('v-tab-item', [_vm.caseData.order_id ? _c('case-link-asset-batch', {
    attrs: {
      "caseData": _vm.caseData
    }
  }) : _c('case-link-asset', {
    attrs: {
      "caseData": _vm.caseData,
      "assetKey": "link_asset"
    }
  })], 1), _vm.caseData.process && _vm.caseData.process.isRecovery ? _c('v-tab-item', [_c('case-link-asset', {
    attrs: {
      "caseData": _vm.caseData,
      "assetKey": "link_asset_new",
      "isWareHouse": true
    }
  })], 1) : _vm._e(), _c('v-tab-item', [_c('widgets-files-list', {
    attrs: {
      "entityType": "Case",
      "entityId": _vm.getId,
      "type": 1
    }
  })], 1), _c('v-tab-item', [_c('widgets-remarks-list', {
    attrs: {
      "entityType": "Case",
      "entityId": _vm.getId,
      "type": 0
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_vm._v("相关任务")]), _c('v-expansion-panel-content', [_c('case-task', {
    ref: "edit_task",
    attrs: {
      "caseData": _vm.caseData
    },
    on: {
      "Update": _vm.updateEditTask
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_vm._v("历史记录")]), _c('v-expansion-panel-content', [_c('case-action-logs', {
    attrs: {
      "caseData": _vm.caseData
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('case-card-actions', {
    attrs: {
      "caseData": _vm.caseData,
      "editedTask": _vm.editedTask
    },
    on: {
      "Close": _vm.closeDialog,
      "Update": _vm.updateDialog
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }