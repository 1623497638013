<template>
  <div>
    <span :color="color" dark text class="v-list-item--link" @click="openDialog(getId)">
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    <v-bottom-sheet v-model="dialog" persistent scrollable>
      <v-card>
        <purchase-title :itemData="itemData" v-on:Close="closeDialog"></purchase-title>
        <v-card-text >
          <v-expansion-panels multiple accordion flat v-model="showPanels">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>基本信息</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <purchase-base-info :itemData="itemData"></purchase-base-info>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <span>详细信息</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <purchase-detail-info :itemData="itemData" :itemId="getId" v-on:Update="getItem(getId)"></purchase-detail-info>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :loading="exeing" v-if="itemData.isEdit" @click="cancelItem()"><v-icon left>remove_circle</v-icon>取消采购</v-btn>
          <v-btn text :loading="exeing" v-if="itemData.isEdit" @click="assistItem()"><v-icon left>assistant_direction</v-icon>协助</v-btn>
          <v-btn text color="secondary" :loading="exeing" v-if="itemData.isEdit" @click="createCase()"><v-icon left>check_circle</v-icon>提交</v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
    <v-dialog v-model="dlgAction" persistent max-width="350px">
      <v-card>
        <v-card-title>{{ actionTitle }}</v-card-title>
        <v-card-text>
          {{actionLabel}}
          <v-textarea
            v-if="actionTitle.includes('确认取消')"
            outlined
            v-model="explain"
            class="mt-4"
            label="取消原因说明"
          ></v-textarea>
          <v-select
            v-if="actionTitle.includes('协助处理')"
            v-model="editor"
            label="选择外采受理人"
            :rules="[rules.required, rules.selected]"
            :items="purchaserList"
            dense
            class="mt-4"
            item-text="personal.name"
            item-value="_id"
            outlined
            hide-no-data
            hide-details
            clearable
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeAction()">取消</v-btn>
          <v-btn text color="secondary" v-if="actionTitle.includes('确认取消')" @click="submitCancel()">提交</v-btn>
          <v-btn text color="secondary" v-if="actionTitle.includes('协助处理')" @click="submitAssist()">提交</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {
  FETCH_PURCHASE, PUBLISH_CASE, CANCEL_PURCHASE, FETCH_ACCOUNT_LIST, ASSIST_PURCHASE
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  props: {
    getId: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
      },
      dialog: false,
      itemData: {},
      showPanels: [0, 1],
      exeing: false,
      dlgAction: false,
      actionTitle: '',
      actionLabel: '',
      explain: null,
      editor: null,
      purchaserList: [],
    }
  },
  dialog (val) {
    val || this.closeDialog()
  },
  methods: {
    openDialog(itemId) {
      if (itemId) {
        this.getItem(itemId)
        this.dialog = true
      }
    },
    getItem(id) {
      store.dispatch(FETCH_PURCHASE, { query: id })
        .then((data) => {
          this.itemData = data
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err });
        });
    },
    closeDialog() {
      this.itemData = {}
      this.dialog = false
      this.$emit("Update");
    },
    createCase() {
      if (this.itemData.product_list && this.itemData.product_list.length) {
        this.exeing = true
        if (this.itemData.product_list.find(item => item.purchase_price <= 0)) {
          this.exeing = false
          return store.commit(SET_ERROR, {msg: "缺少采购成本信息"});
        } else {
          let strCase = {};
          strCase.purchase_id = this.itemData._id;
          strCase.process = this.itemData.pre_case.process;
          store.dispatch(PUBLISH_CASE, { form: "service", data: strCase })
            .then(() => {
              store.commit(SET_ERROR, { msg: "创建成功", color: "primary" });
              this.getItem(this.getId);
              this.closeDialog()
              this.exeing = false
            })
            .catch((error) => {
              store.commit(SET_ERROR, { msg: error.response.data.message });
              this.exeing = false
            });
        }
      }
    },
    assistItem() {
      store.dispatch(FETCH_ACCOUNT_LIST, "&power=Purchase_Service")
        .then((data) => {
          this.purchaserList.push(...data.employees)
          this.actionTitle = '确认请求其他人协助处理该外采请求？';
          this.actionLabel = '协助处理人必须具备【外部采购 > 专员】权限，确认后该外采请求状态将更新为待处理。';
          this.dlgAction = true;
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err.response.data.message });
        })
    },
    cancelItem() {
      this.actionTitle = '确认取消该外采服务请求？';
      this.actionLabel = '取消后的外采服务请求将关闭，且不能再进行任何编辑，同时通知订单处理人。';
      this.dlgAction = true;
    },
    submitCancel() {
      store.dispatch(CANCEL_PURCHASE, { id: this.getId,  explain: this.explain })
        .then(() => {
          store.commit(SET_ERROR, {msg: '请求已被取消', color: 'primary'});
          this.$emit("Update");
          this.closeAction();
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err.response.data.message });
        })
    },
    submitAssist() {
      store.dispatch(ASSIST_PURCHASE, { id: this.getId,  editor: this.editor })
        .then(() => {
          store.commit(SET_ERROR, {msg: '外采请求已更新。', color: 'primary'});
          this.$emit("Update");
          this.closeAction();
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err.response.data.message });
        })
    },
    closeAction() {
      this.actionTitle = '';
      this.actionLabel = '';
      this.explain = null;
      this.editor = null;
      this.purchaserList = [];
      this.dlgAction = false;
    }
  }
}
</script>
<style scoped>
</style>
