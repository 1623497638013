import { ReportService } from "@/common/http";
import {
  FETCH_MONTHLY_REPORT_LIST,
  EDIT_MONTHLY_REPORT,
  PUBLISH_MONTHLY_REPORT,
  FETCH_MONTHLY_REPORT
} from "@/store/actions.type";
import {
  LOAD_MONTHLY_REPORT,
  SET_MONTHLY_REPORT_LIST,
  SET_MONTHLY_REPORT,
  SET_ERROR,
} from "@/store/mutations.type";

const state = {
  monthlyLoading: true,
  monthly: {},
  monthlyList: [],
  monthlyCount: 0,
  monthlyEnterpriseList: [],
};
const getters = {
  monthly: state => state.monthly,
  monthlyList: state => state.monthlyList,
  monthlyLoading: state => state.monthlyLoading,
  monthlyCount: state => state.monthlyCount,
  monthlyEnterpriseList: state => state.monthlyEnterpriseList,
};

const actions = {
  [FETCH_MONTHLY_REPORT_LIST]({ commit }, slug) {
    commit(LOAD_MONTHLY_REPORT)
    return new Promise((resolve, reject) => {
      ReportService.get('/monthly/list', '?'+ slug)
        .then(({ data }) => {
          commit(SET_MONTHLY_REPORT_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(SET_ERROR, { msg: response });
          reject(response);
        });
    })
  },
  [FETCH_MONTHLY_REPORT]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      ReportService.get('/monthly', slug)
        .then(({ data }) => {
          commit(SET_MONTHLY_REPORT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(SET_ERROR, { msg: response });
          reject(response);
        });
    })
  },
  [EDIT_MONTHLY_REPORT](context, data) {
    return ReportService.update('monthly/'+ data._id, data);
  },
  [PUBLISH_MONTHLY_REPORT](context, data) {
    return ReportService.update('monthly/publish/'+ data._id +'/'+ data.reply)
  },
};

const mutations = {
  [LOAD_MONTHLY_REPORT]() {
    state.monthlyLoading = true;
  },
  [SET_MONTHLY_REPORT_LIST](state, list) {
    state.monthlyList = list.monthly
    state.monthlyEnterpriseList = list.enterpriseList;
    state.monthlyCount = list.query.count;
    state.monthlyLoading = false;
  },
  [SET_MONTHLY_REPORT](state, monthly) {
    state.monthly = monthly;
    state.monthlyLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
}
