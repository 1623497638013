<template>
  <v-row v-if="caseData">
    <v-col cols="2" class="text-right">服务流程</v-col>
    <v-col cols="4" class="text--secondary">{{caseData.catalog.name}}</v-col>
    <v-col cols="2" class="text-right">服务类型</v-col>
    <v-col cols="4" class="text--secondary">
      {{caseData.out_req_type || '-'}}
      <span v-if="caseData.out_req_type && caseData.out_req_type.includes('三包')">
         - {{repairPolicy.find(p => p.code === caseData.return_policy).name }}
        <v-icon class="mx-2" small v-if="caseData.status < 4 && caseData.isEdit" @click="editCaseType()">create</v-icon>
      </span>
    </v-col>
    <v-col cols="2" class="text-right">ESP工单编号</v-col>
    <v-col cols="4" class="text--secondary">{{caseData.case_number}}</v-col>
    <v-col cols="2" class="text-right">维修单号</v-col>
    <v-col cols="4" class="text--secondary">{{caseData.out_req_id || '-'}}</v-col>
    <v-col cols="2" class="text-right">申请人</v-col>
    <v-col cols="4" class="text--secondary"><widgets-employee-dialogs :account="caseData.creator._id" class="v-list-item--link">{{caseData.creator.personal ? caseData.creator.personal.name : '*未设置姓名'}}-{{caseData.creator.phone}}<v-icon class="mx-2">read_more</v-icon></widgets-employee-dialogs></v-col>
    <v-col cols="2" class="text-right">申请日期</v-col>
    <v-col cols="4" class="text--secondary">{{caseData.createTime | formatTime}}</v-col>
    <v-col cols="2" class="text-right">所属公司</v-col>
    <v-col cols="4" class="text--secondary v-list-item--link" @click="openEnterprise(caseData.enterprise._id)">{{caseData.enterprise.name}}<v-icon class="mx-2">read_more</v-icon></v-col>
    <v-col cols="2" class="text-right">使用人</v-col>
    <v-col cols="4" class="text--secondary"><widgets-employee-dialogs :account="caseData.account._id" class="v-list-item--link">{{caseData.account.personal ? caseData.account.personal.name : '*未设置姓名'}}<v-icon class="mx-2">read_more</v-icon></widgets-employee-dialogs></v-col>
    <v-col cols="2" class="text-right">送修方式</v-col>
    <v-col cols="4" class="text--secondary" v-if="caseData.recovery">{{recoveryType.find(item => item.code === caseData.recovery.channel).name}}</v-col>
    <v-col cols="4" class="text--secondary" v-else></v-col>
    <v-col cols="2" class="text-right">服务门店</v-col>
    <v-col cols="4" class="text--secondary">
      <widgets-branch-dialogs v-if="caseData.service_branch" :id="caseData.service_branch._id">{{caseData.service_branch.name}}
        <v-icon class="mx-2">read_more</v-icon>
      </widgets-branch-dialogs>
      <v-icon class="mx-2" small v-if="caseData.status < 4 && caseData.isEdit" @click="editBranchInfo()">create</v-icon>
    </v-col>
    <v-col cols="2" class="text-right">寄回方式</v-col>
    <v-col cols="4" class="text--secondary" v-if="caseData.returns && caseData.returns.channel">{{returnType.find(item => item.code === caseData.returns.channel).name}}</v-col>
    <v-col cols="4" class="text--secondary" v-else></v-col>
    <v-col cols="2" class="text-right">寄回联系</v-col>
    <v-col cols="4" class="text--secondary">
      <div v-if="caseData.returns">{{caseData.returns.express_address}}</div>
      <div v-if="caseData.returns">{{caseData.returns.contact_name}}</div>
      <div v-if="caseData.returns">{{caseData.returns.contact_phone}}</div>
      <v-icon class="mx-2" small v-if="caseData.status < 4 && caseData.isEdit" @click="editReturnInfo()">create</v-icon>
    </v-col>
    <v-col cols="2" class="text-right">序列号</v-col>
    <v-col cols="4" class="text--secondary">{{caseData.asset_sn ? caseData.asset_sn.join() : '-'}}</v-col>
    <v-col cols="2" class="text-right">故障描述</v-col>
    <v-col cols="4" class="text--secondary" v-html="caseData.detail_fault"></v-col>
    <v-col cols="2" class="text-right">验证邮箱</v-col>
    <v-col cols="10" class="text--secondary">{{ caseData.verifyMail ? protectEmail(caseData.verifyMail) : '' }}</v-col>
    <v-dialog v-model="dlgEnterprise" persistent scrollable width="700">
      <widgets-enterprise-dialogs v-if="activeId" :id="activeId" :key="activeId" v-on:Close="closeEnterprise()"></widgets-enterprise-dialogs>
    </v-dialog>
    <v-dialog v-model="dlgEditInfo" persistent scrollable width="450">
      <v-card>
        <v-card-title>{{ editTitle }}</v-card-title>
        <v-card-text>
          <v-container v-if="editType === 'returns'">
            <v-autocomplete
              autocomplete="off"
              v-model="editedItem.returns.channel"
              :items="returnType"
              :rules="[rules.required]"
              label="寄回方式"
              placeholder="服务完成或被取消后将按此方式退回设备"
              item-text="name"
              item-value="code"
              outlined
              dense
            ></v-autocomplete>
            <v-text-field
              v-if="editedItem.returns.channel === 'express'"
              v-model="editedItem.returns.contact_name"
              :rules="[rules.required]"
              :append-icon="editedItem.account ? 'content_copy' : ''"
              type="text"
              label="联系人"
              placeholder="请输入联系人姓名"
              outlined
              dense
            >
            </v-text-field>
            <v-text-field
              v-if="editedItem.returns.channel === 'express'"
              v-model="editedItem.returns.contact_phone"
              :rules="[rules.required]"
              type="text"
              label="联系电话"
              placeholder="请输入联系电话号码"
              outlined
              dense
            >
            </v-text-field>
            <v-text-field
              v-if="editedItem.returns.channel === 'express'"
              v-model="editedItem.returns.express_address"
              :rules="[rules.required]"
              type="text"
              label="寄回地址"
              placeholder="请输入地址信息"
              outlined
              dense
            >
            </v-text-field>
          </v-container>
          <v-container v-else-if="editType === 'branch'">
            <v-autocomplete
              v-if="branchList"
              v-model="editedItem.service_branch"
              :items="branchList"
              return-object
              item-text="name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              clearable
              label="服务门店"
              placeholder="请选择一家服务门店"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{data.item.name ? data.item.name : '未设置名称'}}</v-list-item-title>
                  <v-list-item-subtitle>{{data.item.city}} - {{data.item.address}}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-container>
          <v-container v-else-if="editType === 'caseType'">
            <v-autocomplete
              autocomplete="off"
              v-model="editedItem.return_policy"
              :items="repairPolicy"
              :rules="[rules.required]"
              label="三包服务"
              placeholder="退换货策略"
              item-text="name"
              item-value="code"
              outlined
              dense
            ></v-autocomplete>
          </v-container>
          <v-container v-else>
            无编辑内容
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeReturnInfo()">取消</v-btn>
          <v-btn text color="secondary" @click="saveCase(caseData._id)">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import store from "@/store";
import {
  FETCH_BRANCH_LIST,
  SAVE_CASE
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import Util from "@/common/util";
import {mapGetters} from "vuex";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      dlgEnterprise: false,
      activeId: '',
      editCreateAddress: false,
      editedItem: {
        returns: {
          channel: '',
          express_address: '',
          contact_name: '',
          contact_phone: ''
        },
        service_branch: '',
        return_policy: '',
      },
      dlgEditInfo: false,
      editTitle: '',
      editType: '',

    }
  },
  mounted() {},
  watch: {
    dlgEnterprise (val) {
      val || this.closeEnterprise()
    },
  },
  created() {},
  computed: {
    ...mapGetters([
      "currentUser",
      "branchList",
    ]),
    recoveryType () {
      return Util.categories('recoveryType')
    },
    returnType () {
      return Util.categories('returnType')
    },
    repairPolicy () {
      return Util.categories('repairPolicy')
    },
  },
  methods: {
    protectEmail (email) {
      let avg, splitted, part1, part2;
      splitted = email.split("@");
      part1 = splitted[0];
      avg = part1.length / 2;
      part1 = part1.substring(0, (part1.length - avg));
      part2 = splitted[1];
      return part1 + "...@" + part2;
    },
    openEnterprise(corpId) {
      this.activeId = corpId
      this.dlgEnterprise = true
    },
    closeEnterprise() {
      this.dlgEnterprise = false
      this.$nextTick(() => {
        this.activeId = ''
      })
    },
    editReturnInfo() {
      if (this.caseData.returns) {
        this.editedItem.returns = Object.assign({}, this.caseData.returns)
      }
      this.dlgEditInfo = true
      this.editTitle = '编辑寄回信息'
      this.editType = 'returns'
    },
    editBranchInfo() {
      store.dispatch(FETCH_BRANCH_LIST, 'typedef=1&isHidden=false')
        .then(() => {
          if (this.caseData.service_branch) {
            this.editedItem.service_branch = Object.assign({}, this.caseData.service_branch)
          }
          this.dlgEditInfo = true
          this.editTitle = '编辑服务门店'
          this.editType = 'branch'
        })
    },
    closeReturnInfo() {
      this.dlgEditInfo = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {returns: {}, service_branch: {}})
      })
    },
    editCaseType() {
      this.editedItem.return_policy = this.caseData.return_policy
      this.dlgEditInfo = true
      this.editTitle = '编辑服务策略'
      this.editType = 'caseType'
    },
    saveCase(caseId) {
      let strUpdate = {}
      strUpdate.form = 'repair'
      strUpdate.caseId = caseId
      if (this.editType === 'returns') {
        strUpdate.returns = {}
        if (this.editedItem.returns.channel) strUpdate.returns.channel = this.editedItem.returns.channel
        if (this.editedItem.returns.express_address) strUpdate.returns.express_address = this.editedItem.returns.express_address
        if (this.editedItem.returns.contact_name) strUpdate.returns.contact_name = this.editedItem.returns.contact_name
        if (this.editedItem.returns.contact_phone) strUpdate.returns.contact_phone = this.editedItem.returns.contact_phone
      } else if (this.editType === 'branch') {
        strUpdate.service_branch = ''
        if (this.editedItem.service_branch) strUpdate.service_branch = this.editedItem.service_branch
      } else if (this.editType === 'caseType') {
        strUpdate.return_policy = ''
        if (this.editedItem.return_policy) strUpdate.return_policy = this.editedItem.return_policy
      }

      store.dispatch(SAVE_CASE, strUpdate)
        .then(() => {
          this.dlgEditInfo = false
          Object.assign(this.caseData, strUpdate)
          store.commit(SET_ERROR, {msg: '操作成功。', color: 'primary'});
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
  }
}
</script>
