var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    attrs: {
      "color": _vm.color,
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openDialog(_vm.getId);
      }
    }
  }, [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2), _c('v-bottom-sheet', {
    attrs: {
      "persistent": "",
      "scrollable": ""
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('purchase-title', {
    attrs: {
      "itemData": _vm.itemData
    },
    on: {
      "Close": _vm.closeDialog
    }
  }), _c('v-card-text', [_c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "accordion": "",
      "flat": ""
    },
    model: {
      value: _vm.showPanels,
      callback: function callback($$v) {
        _vm.showPanels = $$v;
      },
      expression: "showPanels"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_vm._v("基本信息")])]), _c('v-expansion-panel-content', [_c('purchase-base-info', {
    attrs: {
      "itemData": _vm.itemData
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_c('span', [_vm._v("详细信息")])]), _c('v-expansion-panel-content', [_c('purchase-detail-info', {
    attrs: {
      "itemData": _vm.itemData,
      "itemId": _vm.getId
    },
    on: {
      "Update": function Update($event) {
        return _vm.getItem(_vm.getId);
      }
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm.itemData.isEdit ? _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.cancelItem();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("remove_circle")]), _vm._v("取消采购")], 1) : _vm._e(), _vm.itemData.isEdit ? _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.assistItem();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("assistant_direction")]), _vm._v("协助")], 1) : _vm._e(), _vm.itemData.isEdit ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.createCase();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("check_circle")]), _vm._v("提交")], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "350px"
    },
    model: {
      value: _vm.dlgAction,
      callback: function callback($$v) {
        _vm.dlgAction = $$v;
      },
      expression: "dlgAction"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.actionTitle))]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.actionLabel) + " "), _vm.actionTitle.includes('确认取消') ? _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "label": "取消原因说明"
    },
    model: {
      value: _vm.explain,
      callback: function callback($$v) {
        _vm.explain = $$v;
      },
      expression: "explain"
    }
  }) : _vm._e(), _vm.actionTitle.includes('协助处理') ? _c('v-select', {
    staticClass: "mt-4",
    attrs: {
      "label": "选择外采受理人",
      "rules": [_vm.rules.required, _vm.rules.selected],
      "items": _vm.purchaserList,
      "dense": "",
      "item-text": "personal.name",
      "item-value": "_id",
      "outlined": "",
      "hide-no-data": "",
      "hide-details": "",
      "clearable": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }], null, false, 59584339),
    model: {
      value: _vm.editor,
      callback: function callback($$v) {
        _vm.editor = $$v;
      },
      expression: "editor"
    }
  }) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeAction();
      }
    }
  }, [_vm._v("取消")]), _vm.actionTitle.includes('确认取消') ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.submitCancel();
      }
    }
  }, [_vm._v("提交")]) : _vm._e(), _vm.actionTitle.includes('协助处理') ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.submitAssist();
      }
    }
  }, [_vm._v("提交")]) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }