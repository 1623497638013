<template>
  <v-row v-if="itemData">
    <v-col cols="2" class="text-right">企业</v-col>
    <v-col cols="10" class="text--secondary" v-if="itemData.enterprise">
      <strong class="v-list-item--link" @click="openEnterpriseInfo(itemData.enterprise._id)">{{itemData.enterprise && itemData.enterprise.name}} {{itemData.branch ? '-'+ itemData.branch : ''}}<v-icon class="ml-2">read_more</v-icon></strong>
    </v-col>
    <v-col cols="2" class="text-right">订单编号</v-col>
    <v-col cols="4" class="text--secondary">
      <strong>{{itemData.order_id && itemData.order_id.order_number}}</strong>
    </v-col>
    <v-col cols="2" class="text-right">企业PO号</v-col>
    <v-col cols="4" class="text--secondary">
      <strong>{{itemData.order_id && itemData.order_id.po_number}}</strong>
    </v-col>
    <v-col cols="2" class="text-right">订单创建</v-col>
    <v-col cols="4" class="text--secondary" v-if="itemData.order_id">
      <strong>{{itemData.order_id.createTime | formatTime}}</strong>
    </v-col>
    <v-col cols="2" class="text-right">订单处理</v-col>
    <v-col cols="4" class="text--secondary" v-if="itemData.order_id && itemData.order_id.creator">
      <widgets-employee-dialogs :account="itemData.order_id.creator._id">
        <strong class="v-list-item--link">
          {{ itemData.order_id.creator.personal ? itemData.order_id.creator.personal.name : "*未设置姓名" }}
          <v-icon class="ml-2">read_more</v-icon>
        </strong>
      </widgets-employee-dialogs>
    </v-col>
    <v-col cols="2" class="text-right">销售</v-col>
    <v-col cols="4" class="text--secondary" v-if="itemData.order_id && itemData.order_id.sales">
      <widgets-employee-dialogs :account="itemData.order_id.sales._id">
        <strong class="v-list-item--link">
          {{ itemData.order_id.sales.personal ? itemData.order_id.sales.personal.name : "*未设置姓名" }}
          <v-icon class="ml-2">read_more</v-icon>
        </strong>
      </widgets-employee-dialogs>
    </v-col>
    <v-col cols="2" class="text-right">商务</v-col>
    <v-col cols="4" class="text--secondary" v-if="itemData.pre_case && itemData.pre_case.order_assigner">
      <widgets-employee-dialogs :account="itemData.pre_case.order_assigner._id">
        <strong class="v-list-item--link">
          {{ itemData.pre_case.order_assigner.personal ? itemData.pre_case.order_assigner.personal.name : "*未设置姓名" }}
          <v-icon class="ml-2">read_more</v-icon>
        </strong>
      </widgets-employee-dialogs>
    </v-col>
    <v-col cols="2" class="text-right" v-if="itemData.case_info">服务工单</v-col>
    <v-col cols="4" class="text--secondary" v-if="itemData.case_info">
      <strong>{{itemData.case_info.case_number}}</strong>
    </v-col>
    <v-col cols="2" class="text-right" v-if="itemData.status === 2">取消说明</v-col>
    <v-col cols="4" class="text--secondary" v-if="itemData.status === 2">
      <strong v-if="itemData.explain"><pre>{{itemData.explain}}</pre></strong>
      <strong v-else>无</strong>
    </v-col>
    <v-dialog v-model="dlgEnterprise" scrollable max-width="600px" persistent>
      <widgets-enterprise-dialogs
        :id="activeId"
        :key="activeId"
        v-on:Close="closeEnterpriseInfo"
      ></widgets-enterprise-dialogs>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeId: '',
      dlgEnterprise: false,
    }
  },
  methods: {
    openEnterpriseInfo(id) {
      this.activeId = id;
      this.dlgEnterprise = true;
    },
    closeEnterpriseInfo() {
      this.dlgEnterprise = false;
    },
  }
}
</script>
