import { WorkflowService } from "@/common/http";

import {
  FETCH_TASK_LIST,
  PUBLISH_TASK,
  EDIT_TASK,
  ACTIV_TASK,
  FETCH_PROCESS_LIST,
  PUBLISH_PROCESS,
  EDIT_PROCESS,
  ACTIV_PROCESS,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_TASK_LIST,
  SET_TASK_LIST,
  LOAD_PROCESS_LIST,
  SET_PROCESS_LIST,
} from "./mutations.type"

const state = {
  taskLoading: true,
  taskList: [],
  taskCount: 0,
  processLoading: true,
  processList: [],
  processCount: 0,
};

const getters = {
  taskLoading: state => state.taskLoading,
  taskList: state => state.taskList,
  taskCount: state => state.taskCount,
  processLoading: state => state.processLoading,
  processList: state => state.processList,
  processCount: state => state.processCount,
};

const actions = {
  [FETCH_TASK_LIST]({ commit }, slug) {
    commit(LOAD_TASK_LIST);
    return new Promise((resolve, reject) => {
      WorkflowService.get('/task/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_TASK_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_TASK](context, data) {
    return WorkflowService.create('/task', data);
  },
  [EDIT_TASK](context, data) {
    return WorkflowService.update('/task', data._id, data);
  },
  [ACTIV_TASK](context, data) {
    return WorkflowService.enable('/task/', data._id +'/'+ data.reply);
  },
  [FETCH_PROCESS_LIST]({ commit }, slug) {
    commit(LOAD_PROCESS_LIST);
    return new Promise((resolve, reject) => {
      WorkflowService.get('/process/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_PROCESS_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_PROCESS](context, data) {
    return WorkflowService.create('/process', data);
  },
  [EDIT_PROCESS](context, data) {
    return WorkflowService.update('/process', data._id, data);
  },
  [ACTIV_PROCESS](context, data) {
    return WorkflowService.enable('/process/', data._id +'/'+ data.reply);
  },
};

const mutations = {
  [LOAD_TASK_LIST] (state) {
    state.taskLoading = true;
  },
  [SET_TASK_LIST](state, list) {
    state.taskCount = list.query.count;
    state.taskList = list.tasks;
    state.taskLoading = false;
  },
  [LOAD_PROCESS_LIST] (state) {
    state.processLoading = true;
  },
  [SET_PROCESS_LIST](state, list) {
    state.processCount = list.query.count;
    state.processList = list.process;
    state.processLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
