var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    attrs: {
      "color": _vm.color,
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('open_case');
      }
    }
  }, [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitCase();
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_vm.caseData && _vm.caseData.group && _vm.caseData.creator && _vm.caseData.enterprise ? _c('v-card', {
    attrs: {
      "loading": _vm.caseLoading ? 'secondary' : false
    }
  }, [_c('case-card-title', {
    attrs: {
      "caseData": _vm.caseData
    },
    on: {
      "Close": _vm.closeDialog
    }
  }), _c('v-divider'), _c('v-card-text', [_c('v-container', [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "outlined": "",
      "max-width": "750"
    }
  }, [_c('v-card-title', [_c('widgets-employee-dialogs', {
    attrs: {
      "account": _vm.caseData.creator._id
    }
  }, [_vm.caseData.creator ? _c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_vm.caseData.creator.personal ? _c('v-list-item-avatar', {
    attrs: {
      "size": "48"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.caseData.creator.personal.profile ? _vm.ossURL + '/' + _vm.caseData.creator.personal.profile : '/static/grey_silhouette.png'
    }
  })]) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.caseData.creator.personal.name ? _vm.caseData.creator.personal.name : _vm.caseData.creator.username))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.caseData.enterprise ? _vm.caseData.enterprise.name : ''))])], 1)], 1) : _vm._e()], 1), _c('v-spacer'), _vm._v(" " + _vm._s(_vm.caseData.group.name) + " " + _vm._s(_vm.caseData.catalog ? ' - ' + _vm.caseData.catalog.name : '') + " ")], 1), _c('v-card-text', [_c('v-list-item', [_c('v-list-item-avatar', {
    attrs: {
      "size": "48"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("help")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(_vm.caseData.subject))])], 1)], 1), _c('pre', {
    staticClass: "ml-16 body-1"
  }, [_vm._v(_vm._s(_vm.caseData.context))]), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, _vm._l(_vm.caseData.files, function (file) {
    return _c('v-list-item', {
      key: file.href
    }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("text_snippet")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(file.name)
      }
    })], 1), _c('v-list-item-action', {
      staticClass: "d-inline"
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.downloadFile(file.href);
        }
      }
    }, [_vm._v("get_app")])], 1)], 1)], 1);
  }), 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _vm._v(" 咨询时间：" + _vm._s(_vm._f("formatTime")(_vm.caseData.createTime)) + " ")], 1)], 1), _vm._l(_vm.caseData.reply, function (reply) {
    return _c('div', {
      key: reply._id
    }, [_c('v-card', {
      key: reply._id,
      staticClass: "mx-auto my-4",
      attrs: {
        "color": reply.form === 'pump' ? '' : 'primary',
        "dark": reply.form !== 'pump',
        "max-width": "750"
      }
    }, [_c('v-card-title', [_c('widgets-employee-dialogs', {
      attrs: {
        "account": reply.author._id
      }
    }, [reply.author ? _c('v-list-item', [reply.author.personal ? _c('v-list-item-avatar', {
      attrs: {
        "size": "48"
      }
    }, [_c('img', {
      attrs: {
        "src": reply.author.personal.profile ? _vm.ossURL + '/' + reply.author.personal.profile : '/static/grey_silhouette.png'
      }
    })]) : _vm._e(), _c('v-list-item-title', [_vm._v(_vm._s(reply.author.personal.name ? reply.author.personal.name : reply.author.username))])], 1) : _vm._e()], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "1"
      }
    }, [_c('v-icon', {
      staticClass: "mx-6",
      attrs: {
        "size": "32"
      }
    }, [_vm._v(_vm._s(reply.form === 'reply' ? 'wb_incandescent' : 'help'))])], 1), _c('v-col', {
      attrs: {
        "cols": "11"
      }
    }, [_c('pre', {
      staticClass: "body-1 mx-4"
    }, [_vm._v(_vm._s(reply.context))])])], 1), _c('v-row', {
      attrs: {
        "justify": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, _vm._l(reply.attach, function (file) {
      return _c('v-list-item', {
        key: file.href
      }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v(_vm._s(file.form === 'Knowledge' ? 'school' : 'text_snippet'))])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
        domProps: {
          "textContent": _vm._s(file.name)
        }
      })], 1), _c('v-list-item-action', {
        staticClass: "d-inline"
      }, [file.form === 'File' ? _c('v-btn', {
        attrs: {
          "icon": ""
        }
      }, [_c('v-icon', {
        on: {
          "click": function click($event) {
            return _vm.downloadFile(file.href);
          }
        }
      }, [_vm._v("get_app")])], 1) : _c('widgets-knowledge-dialogs', {
        key: file.href,
        attrs: {
          "getId": file.href
        }
      }, [_c('v-btn', {
        attrs: {
          "icon": ""
        }
      }, [_c('v-icon', [_vm._v("launch")])], 1)], 1)], 1)], 1);
    }), 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _vm._v(" " + _vm._s(reply.form === 'reply' ? '回复' : '追问') + "时间：" + _vm._s(_vm._f("formatTime")(reply.replyTime)) + " ")], 1)], 1)], 1);
  })], 2)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), (_vm.currentUser.hasAdmin.includes('dealer') || _vm.checkCreatePower(_vm.caseData.group.editor, _vm.currentUser)) && _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.reply('reply', _vm.caseData._id, '回复咨询内容');
      }
    }
  }, [_vm._v("回复")]) : _vm._e(), _vm.caseData.creator._id === _vm.currentUser._id && _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.reply('pump', _vm.caseData._id, '追加补充咨询内容');
      }
    }
  }, [_vm._v("追问")]) : _vm._e(), _vm.caseData.creator._id === _vm.currentUser._id && _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.resolvedCase(_vm.caseData._id);
      }
    }
  }, [_vm._v("已解决")]) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500px"
    },
    model: {
      value: _vm.dlgReply,
      callback: function callback($$v) {
        _vm.dlgReply = $$v;
      },
      expression: "dlgReply"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.dlgTitle))]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "label": "内容",
      "hint": "请输入详细内容"
    },
    model: {
      value: _vm.editedItem.context,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "context", $$v);
      },
      expression: "editedItem.context"
    }
  }), _vm.editedItem.form === 'reply' ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.knowledgeList,
      "item-text": "title",
      "item-value": "_id",
      "autocomplete": "off",
      "return-object": "",
      "label": "请选择所含知识",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [[_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "input-value": data.attrs.inputValue
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.title)
          }
        }), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.editor.personal ? data.item.editor.personal.name : data.item.editor.username) + "创建于" + _vm._s(_vm._f("formatDate")(data.item.createTime)) + " | 字数：" + _vm._s(data.item.word_count))])], 1)]];
      }
    }], null, false, 2697683070),
    model: {
      value: _vm.editedItem.knowledge,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "knowledge", $$v);
      },
      expression: "editedItem.knowledge"
    }
  }) : _vm._e(), _vm.caseData.enterprise ? _c('case-create-upload-files', {
    ref: "upload_files",
    attrs: {
      "enterprise": _vm.caseData.enterprise._id
    }
  }) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeReply
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.submitReply
    }
  }, [_vm._v(_vm._s(_vm.editedItem.form === 'reply' ? '回复' : '追问'))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }