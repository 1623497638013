var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.editedItem[_vm.assetKey] ? _c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerAsset,
      "items": _vm.editedItem[_vm.assetKey],
      "server-items-length": _vm.editedItem[_vm.assetKey].length,
      "loading": _vm.loadingList,
      "footer-props": {
        'items-per-page-options': [5, 10, 15]
      },
      "item-key": "_id",
      "return-object": "false",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('td', {
          staticClass: "v-list-item--link text-truncate",
          staticStyle: {
            "max-width": "150px"
          },
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-asset-dialogs', {
          attrs: {
            "getId": item._id,
            "caseType": _vm.caseType
          }
        }, [_c('div', [item.isLock ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("lock")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("设备锁定中")])]) : _vm._e(), _vm._v(" " + _vm._s(item.name) + " "), item.ownerType === 'Enterprise' ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("corporate_fare")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("企业自有设备")])]) : item.ownerType === 'Account' ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("person")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("个人自有设备")])]) : _vm._e()], 1)])], 1)];
      }
    }, {
      key: "item.serial_number",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.product && item.product.product_type && item.product.product_type.noSN ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("无序列号")]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.serial_number))])];
      }
    }, {
      key: "item.product.name",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          staticClass: "v-list-item--link text-truncate",
          staticStyle: {
            "max-width": "150px"
          },
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.openDialog('Product', item.product._id);
            }
          }
        }, [_vm._v(_vm._s(item.product.name))])];
      }
    }, {
      key: "item.product.part_number",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.product.part_number))])];
      }
    }, {
      key: "item.state",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.state ? item.state.name : item.state))])];
      }
    }, {
      key: "item.branch",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          staticClass: "v-list-item--link text-truncate",
          staticStyle: {
            "max-width": "150px"
          },
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.branch))])];
      }
    }, {
      key: "item.asset_state",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.asset_state && Array.isArray(item.asset_state) ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.asset_state.map(function (state) {
          return _vm.assetState.find(function (o) {
            return o.code === state;
          }).name;
        })))]) : item.asset_state ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm.assetState.find(function (o) {
          return o.code === item.asset_state;
        }).name))]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.label",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.label))])];
      }
    }, {
      key: "item.account",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.account ? item.account.personal.name : ''))])];
      }
    }, {
      key: "item.transport.delivery_user",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [item.transport && item.transport.delivery_user ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" " + _vm._s(item.transport.delivery_user) + " ")]) : _vm._e()];
      }
    }, {
      key: "item.transport.delivery_phone",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [item.transport && item.transport.delivery_phone ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" " + _vm._s(item.transport.delivery_phone) + " ")]) : _vm._e()];
      }
    }, {
      key: "item.transport.delivery_address",
      fn: function fn(_ref15) {
        var item = _ref15.item,
            index = _ref15.index;
        return [item.transport && item.transport.delivery_address ? _c('td', {
          class: !_vm.open[index] ? 'text-truncate text-decoration-underline' : 'text-decoration-underline',
          staticStyle: {
            "max-width": "150px",
            "cursor": "pointer"
          },
          attrs: {
            "nowrap": _vm.open[index] ? false : true
          },
          on: {
            "click": function click($event) {
              return _vm.showText(index);
            }
          }
        }, [_vm._v(" " + _vm._s(item.transport.delivery_address) + " ")]) : _vm._e()];
      }
    }, {
      key: "item.transport.express_number",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [item.transport && item.transport.express_number ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('case-express-info', {
          attrs: {
            "id": item.transport._id,
            "from": "asset"
          }
        }, [_vm._v(_vm._s(item.transport.express_number))])], 1) : _vm._e()];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.unlinkAsset(item);
            }
          }
        }, [_vm._v("remove")])];
      }
    }], null, false, 212898589)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "align": "end"
    }
  }, [_vm.caseData.order_id && _vm.editedItem[_vm.assetKey].length && _vm.caseData.status === 1 && _vm.caseData.isEdit && _vm.currentTask.task && _vm.currentTask.task.powers.includes('relate_asset') ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openDialog('Import');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("upgrade")]), _vm._v("批量更新设备")], 1) : _vm._e(), !_vm.caseData.order_id && _vm.caseData.status === 0 && _vm.caseData.isEdit ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openDialog('AssetList');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("link")]), _vm._v("关联设备")], 1) : !_vm.caseData.order_id && _vm.caseData.status === 1 && _vm.caseData.isEdit && _vm.currentTask.task && _vm.currentTask.task.powers.includes('relate_asset') ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openDialog('AssetList');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("link")]), _vm._v("关联设备")], 1) : _vm._e()], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "750px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'Product' ? _c('widgets-product-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.closeDialog();
      }
    }
  }) : _vm._e(), _vm.caseData.enterprise && _vm.activeType === 'AssetList' ? _c('widgets-select-asset', {
    key: "linkAsset",
    ref: "select_asset",
    attrs: {
      "enterprise": _vm.caseData.enterprise._id,
      "isInWarehouse": _vm.isWareHouse,
      "maxLimit": _vm.maxLimit,
      "selectItem": _vm.editedItem[_vm.assetKey]
    },
    on: {
      "Close": function Close($event) {
        return _vm.closeDialog();
      },
      "Selected": _vm.setAssetList
    }
  }) : _vm._e(), _vm.activeType === 'Import' ? _c('v-card', [_c('v-card-title', [_vm._v(" 批量导入 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载设备清单，在EXCEL中编辑设备信息。")]), _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.downloading
    },
    on: {
      "click": function click($event) {
        return _vm.downloadAssets();
      }
    }
  }, [_vm._v("下载设备"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importFile($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.uploading
    },
    on: {
      "click": function click($event) {
        return _vm.selectFile();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1), _vm.importFailed.length || _vm.importResult ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _vm.importFailed.length === 0 ? _c('span', [_vm._v("无")]) : _c('ul', _vm._l(_vm.importFailed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.importResult.insertSum) + " 条记录； 更新：" + _vm._s(_vm.importResult.updateSum) + " 条记录； ")])], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }