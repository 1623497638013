var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', {
    staticClass: "subheading font-weight-light",
    staticStyle: {
      "z-index": "4"
    },
    attrs: {
      "app": ""
    }
  }, [_c('v-spacer'), _vm._v(" © 2019-" + _vm._s(new Date().getFullYear()) + " 倍升互联（北京）科技有限公司 ")], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }