<template>
  <div>
    <span dark text class="v-list-item--link" @click="doAction('open_case')">
      <slot></slot>
    </span>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <v-btn icon class="mr-2" @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
          <span>{{ orderData.order_number }}</span>
<!--          <v-chip-->
<!--            class="ml-2"-->
<!--            label-->
<!--            small-->
<!--            color="secondary"-->
<!--            v-if="orderData.status == '0'"-->
<!--            >已接单</v-chip-->
<!--          >-->
          <v-chip
            class="ml-2"
            label
            small
            color="secondary"
            v-if="orderData.status == '0'"
          >授信审批中</v-chip
          >
          <v-chip
            class="ml-2"
            label
            small
            color="primary"
            v-if="orderData.status == '1'"
            >处理中</v-chip
          >
          <v-chip class="ml-2" label small v-if="orderData.status == '2'"
            >已取消</v-chip
          >
          <v-chip class="ml-2" label small v-if="orderData.status == '3'"
            >已拒绝</v-chip
          >
          <v-chip class="ml-2" label small v-if="orderData.status == '4'"
            >已完成</v-chip
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-expansion-panels multiple accordion flat v-model="offerPanels">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>基本信息</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <case-order-basic-info ref="orderBasic" :orderData="orderData" :orderId="getId" :isEdit="false" v-on:Update="getOrder(getId)"></case-order-basic-info>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="orderData.type === 1">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <span>租赁信息</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <case-order-rent-info :orderData="orderData" :orderId="getId" :isEdit="false" v-on:Update="getOrder(getId)"></case-order-rent-info>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <span>详细信息</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <case-order-detail :orderData="orderData" :orderId="getId" :isEdit="false" v-on:Update="getOrder(getId)"></case-order-detail>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <span>相关工单</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <case-order-cases :orderData="orderData" :purchaseList="purchaseList" :activeCaseTab="activeCaseTab" :isEdit="false" v-on:setNoData="getToSetNoData"></case-order-cases>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-subheader>最后更新时间： {{ orderData.updateTime | formatTime }}</v-subheader>
          <v-spacer></v-spacer>
          <v-btn text v-if="orderData.isMark" @click="markOrder(orderData._id, false)"><v-icon left>star</v-icon>取消关注</v-btn>
          <v-btn text v-else @click="markOrder(orderData._id, true)"><v-icon left>star_outline</v-icon>特别关注</v-btn>
<!--          <v-btn text @click="alert(1)"><v-icon left>more</v-icon>订单详情</v-btn>-->
          <v-btn text @click="returnNotice" disabled><v-icon left>error</v-icon>退货说明</v-btn>
          <v-btn text v-if="orderData.type === 1" @click="leaseOrder"><v-icon left>autorenew</v-icon>续租订单</v-btn>
          <v-btn text @click="confirmCancelOrder()" v-if="isEdit && orderData.status < 2"><v-icon left>remove_circle</v-icon>取消订单</v-btn>
          <v-btn color="secondary darken-1" text disabled v-if="isEdit && orderData.status < 2" @click="createCase"><v-icon left>add_circle</v-icon>创建工单</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createCaseDlg" width="800" persistent>
      <v-card>
        <v-card-title>
          <span>创建工单</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeEditCase"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="4">
              <v-autocomplete
                v-model="caseProcess"
                label="选择服务流程"
                autocomplete="off"
                :items="processList.filter(item => item.isEnable)"
                :rules="[rules.required]"
                hide-no-data
                item-text="name"
                item-value="_id"
                return-object
                @change="setActionAccount"
                outlined
                dense
                prepend-inner-icon="low_priority"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="caseAccount"
                label="选择服务受理人"
                :items="caseAccountList"
                dense
                :rules="[rules.selected]"
                item-text="personal.name"
                item-value="_id"
                outlined
                hide-no-data
                hide-details
                prepend-inner-icon="person_outline"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
<!--            <v-col cols="3" class="mt-n3">-->
<!--              <v-switch-->
<!--                v-model="casePurchase"-->
<!--                :label="`是否需要外采: ${casePurchase ? '是': '否'}`"-->
<!--                @change="checkPurchaser"-->
<!--              ></v-switch>-->
<!--            </v-col>-->
            <v-col cols="4">
              <v-select
                v-model="casePurchaser"
                label="选择外采受理人"
                :items="casePurchaserList"
                dense
                item-text="personal.name"
                item-value="_id"
                outlined
                hide-no-data
                hide-details
                clearable
                prepend-inner-icon="shopping_cart"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-alert
            v-if="casePurchase && noPurchaser"
            type="error"
          >以下品牌厂商没有配置外采负责人，请联系管理员进行配置。{{ noPurchaserList }}</v-alert>
          <v-data-table
            :headers="caseProHeaders"
            :items="caseProList"
            hide-default-footer
            :items-per-page="100"
            :item-class="itemRowBackground"
            dense
          >
            <template v-slot:item.product_name="{ item }">
              <td nowrap="true" class="text-truncate" style="max-width: 200px;">{{ item.product_name }}</td>
            </template>
            <template v-slot:item.part_number="{ item }">
              <td nowrap="true" class="text-truncate" style="max-width: 200px;">{{ item.part_number }}</td>
            </template>
            <template v-slot:item.quantity="props">
              <v-edit-dialog :return-value.sync="props.item.quantity" large persistent save-text="保存" cancel-text="取消">
                <div>
                  {{ props.item.quantity }}
                  <v-icon small>edit</v-icon>
                </div>
                <template v-slot:input>
                  <div class="mt-4 subtitle-1">修改：开单数量</div>
                  <v-text-field
                    v-model="props.item.quantity"
                    label="开单数量"
                    single-line
                    type="number"
                    :rules="[rules.required, rules.negative]"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.remove="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon
                    @click="removeCasePro(item)"
                  >
                    <v-icon>remove</v-icon>
                  </v-btn>
                </template>
                <span>移除</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="closeEditCase">关闭</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="resetCaseProList"
            v-if="this.caseProList.length !== this.defaultCaseProList.length"
            >重置产品</v-btn
          >
          <v-btn
            color="secondary"
            text
            @click="submitCase"
            :disabled="!caseProcess || !caseAccount || !caseProList.length || (casePurchase && noPurchaser)"
            :loading="exeing"
            >提交</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgCancelOrder" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span>取消订单</span>
          <v-icon color="primary" class="ml-2">remove_circle</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-6">
          <v-data-table
            :headers="cancelHeaders"
            :items="cancelList"
            hide-default-footer
            :items-per-page="100"
          >
            <template v-slot:item.quantity="props">
              <v-edit-dialog :return-value.sync="props.item.quantity" large persistent save-text="保存" cancel-text="取消">
                <div>
                  {{ props.item.count.cancel }}
                  <v-icon small>edit</v-icon>
                </div>
                <template v-slot:input>
                  <div class="mt-4 subtitle-1">修改：取消数量</div>
                  <v-text-field
                    v-model="props.item.count.cancel"
                    label="取消数量"
                    single-line
                    type="number"
                    min=0
                    :max="parseInt(props.item.count.cancel)"
                    :rules="[rules.required, rules.negative]"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.remove="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon
                    @click="removeCancelPro(item)"
                  >
                    <v-icon>remove</v-icon>
                  </v-btn>
                </template>
                <span>移除</span>
              </v-tooltip>
            </template>
          </v-data-table>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="closeEditCancel">关闭</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="resetCancelList" v-if="this.cancelList.length !== this.cancelDefault.length">重置产品</v-btn>
          <v-btn text color="secondary darken-1" @click="cancelOrder" :disabled="!cancelList.length" :loading="exeing">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {
  FETCH_ORDER_PURCHASE,
  FETCH_VENDOR_LIST,
  FETCH_PRODUCTTYPE_LIST,
  FETCH_PROCESS_LIST,
  PUBLISH_CASE, PUBLISH_PURCHASE,
  FETCH_ACCOUNT_LIST, CANCEL_ORDER_PURCHASE, ADD_MARK_ORDER_PURCHASE, REMOVE_MARK_ORDER_PURCHASE, FETCH_PURCHASE_LIST,
} from "@/store/actions.type";
import store from "@/store";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";

export default {
  props: {
    getId: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    divider: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
      },
      dialog: false,
      offerPanels: [0, 1, 2, 3],
      caseProHeaders: [
        { text: "产品名称", width: "105px", value: "product_name" },
        { text: "产品型号", width: "105px", value: "part_number" },
        { text: "总数", width: "80px", value: "count.order" },
        { text: "取消数", width: "85px", value: "count.cancel" },
        { text: "开单数", width: "85px", value: "count.cases" },
        { text: "开单数", width: "85px", value: "quantity" },
        { text: "移除", width: "35px", value: "remove", sortable: false },
      ],
      productList: [],
      caseProList: [],
      query: { key: "6034b53ddd0c3c580f5e9dd6", page: 1, limit: 10, count: 2 },
      createCaseDlg: false,
      orderData: {},
      currentTask: {},
      productDialog: false,
      proHeaders: [
        { text: "产品名称", value: "name" },
        { text: "产品型号", value: "part_number" },
        { text: "品牌", value: "vendor.name" },
        { text: "产品类型", value: "product_type.name" },
      ],
      queryPro: {
        key: "",
      },
      editInvoice: {
        title: "",
        license_code: "",
        invoice_type: 0,
        bank_name: "",
        bank_account: "",
        license_address: "",
        license_telephone: "",
        delivery_address: "",
        delivery_name: "",
        delivery_phone: "",
      },
      defaultInvoice: {
        title: "",
        license_code: "",
        invoice_type: 0,
        bank_name: "",
        bank_account: "",
        license_address: "",
        license_telephone: "",
        delivery_address: "",
        delivery_name: "",
        delivery_phone: "",
      },
      delivery_info: {
        name: "",
        phone: "",
        address: "",
      },
      defaultDelivery: {
        name: "",
        phone: "",
        address: "",
      },
      caseProcess: "",
      overlay: false,
      editedIndex: -1,
      proBox: [],
      defaultCaseProList: [],
      caseAccount: '',
      caseAccountList: [],
      casePurchaser: null,
      casePurchaserList: [{_id: null, personal: {name: '不需要外采'}}],
      casePurchase: false,
      noPurchaser: false,
      noPurchaserList: [],
      dlgCancelOrder: false,
      isEdit: false,
      cancelHeaders: [
        { text: "产品名称", value: "product_name" },
        { text: "总数量", value: "count.order" },
        { text: "已开单数量", value: "count.cases" },
        { text: "取消数量", value: "quantity" },
        { text: "移除", value: "remove", sortable: false },
      ],
      cancelList: [],
      cancelDefault: [],
      exeing: false,
      linkedProduct: [],
      note: {
        fab: false
      },
      activeCaseTab: 0,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "uploadSign",
      "productCount",
      "vendorList",
      "typeList",
      "processList",
      "orderPurchaseInit",
      "purchaseList",
    ]),
  },
  created() {},
  methods: {
    leaseOrder() {
      this.$router.push(
        "/dealer/workbench/order/edit?orderId="+this.getId+'&type=lease&orderType=rent'
      );
    },
    getToSetNoData() {
      this.$refs.orderBasic.setNoData()
    },
    returnNotice() {
      this.$refs.orderBasic.getReturn()
    },
    closeDialog() {
      this.editedItem = { files: [], remarks: [] };
      this.activeCaseTab = 0;
      this.dialog = false;
      this.$emit("Update");
    },
    confirmCancelOrder() {
      let pros = JSON.parse(JSON.stringify(this.linkedProduct)).map((item) => {
        item.count.cancel = item.count.order - item.count.cases
        return item;
      })
      pros.forEach(item => {
        if (item.count.cases < item.count.order) {
          this.cancelList.push(item)
        }
      })
      this.cancelDefault = JSON.parse(JSON.stringify(this.cancelList))
      this.dlgCancelOrder = true;
    },
    removeCancelPro(item) {
      let proIndex = this.cancelList.indexOf(item);
      if (proIndex > -1) {
        this.cancelList.splice(proIndex, 1);
      }
    },
    resetCancelList() {
      this.cancelList = []
      let pros = JSON.parse(JSON.stringify(this.linkedProduct)).map(
        (item) => {
          item.count.cancel = item.count.order - item.count.cases
          return item;
        }
      )
      pros.forEach(item => {
        if (item.count.cases < item.count.order) {
          this.cancelList.push(item)
        }
      })
      this.cancelDefault = JSON.parse(JSON.stringify(this.cancelList))
    },
    closeEditCancel() {
      this.dlgCancelOrder = false;
      this.cancelList = []
    },
    setActionAccount() {
      this.caseAccount = ''
      if(this.caseProcess.department._id) {
        store
          .dispatch(FETCH_ACCOUNT_LIST, 'department='+this.caseProcess.department._id)
          .then((res) => {
            this.caseAccountList = res.employees
          })
          .catch((error) => {
            store.commit(SET_ERROR, { msg: error.response.data.message });
          });
      }
    },
    resetCaseProList() {
      this.caseProList = []
      let pros = JSON.parse(JSON.stringify(this.linkedProduct)).map(
        (item) => {
          item.quantity = item.count.order - item.count.cases
          return item;
        }
      )
      pros.forEach(item => {
        if (item.count.cases < item.count.order) {
          if (!item.product_vendor.department) {
            this.noPurchaser = true
            item.noPurchaser = true
            if (!this.noPurchaserList.includes(item.product_vendor.name)) this.noPurchaserList.push(item.product_vendor.name)
          } else {
            item.noPurchaser = false
          }
          this.caseProList.push(item)
        }
      })
      this.defaultCaseProList = JSON.parse(JSON.stringify(this.caseProList))
    },
    removeCasePro(item) {
      let proIndex = this.caseProList.indexOf(item);
      if (proIndex > -1) {
        this.caseProList.splice(proIndex, 1);
        let noPurchaser = this.caseProList.filter(product => !product.product_vendor.department)
        if (!noPurchaser.length) this.noPurchaser = false
      }
    },
    submitCase() {
      this.exeing = true
      // if (this.casePurchase) {
      //   let noPurchaser = this.caseProList.filter(product => !product.product_vendor.department)
      //   if (noPurchaser && noPurchaser.length) {
      //     store.commit(SET_ERROR, { msg: "缺少外采负责人" });
      //     return
      //   }
      // }
      let num = -1;
      this.caseProList.map((item) => {
        if (
          item.quantity <= 0 ||
          item.quantity > item.count.order - item.count.cases - item.count.cancel
        )
          num += 1;
      });
      if (num > -1) {
        store.commit(SET_ERROR, {
          msg: "开单数量必须在大于0台，且小于等于未开单数量。",
          color: "primary",
        });
        this.exeing = false
      } else {
        let strCase = {};
        strCase.order_id = this.orderData._id;
        strCase.action_department = this.caseProcess.department._id;
        strCase.action_account = this.caseAccount;
        strCase.process = this.caseProcess._id;
        strCase.enterprise = this.orderData.enterprise._id;
        this.caseProList.forEach(item => {
          item.quantity = parseInt(item.quantity)
        })
        strCase.product_list = this.caseProList;
        if (this.casePurchaser) {
          strCase.casePurchaser = this.casePurchaser
          store.dispatch(PUBLISH_PURCHASE, strCase)
            .then(() => {
              store.commit(SET_ERROR, { msg: "创建成功", color: "primary" });
              this.getOrder(this.getId);
              this.activeCaseTab = 1;
              this.closeEditCase()
              this.exeing = false
            })
            .catch((error) => {
              store.commit(SET_ERROR, { msg: error.response.data.message });
              this.exeing = false
            });
        } else {
          store.dispatch(PUBLISH_CASE, { form: "service", data: strCase })
            .then(() => {
              store.commit(SET_ERROR, { msg: "创建成功", color: "primary" });
              this.getOrder(this.getId);
              this.closeEditCase()
              this.exeing = false
            })
            .catch((error) => {
              store.commit(SET_ERROR, { msg: error.response.data.message });
              this.exeing = false
            });
        }
      }
    },
    closeEditCase() {
      this.createCaseDlg = false;
      this.caseProcess = "";
      this.caseProList = []
      this.caseAccount = ''
      this.caseAccountList = []
      this.casePurchase = false
      this.noPurchaser = false
      this.noPurchaserList = []
      this.casePurchaser = null
      this.casePurchaserList = []
    },
    itemRowBackground: function (item) {
      if (item) {
        if (this.casePurchase && item.noPurchaser) return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
      }
    },
    checkPurchaser() {
      this.itemRowBackground()
    },
    createCase() {
      let num = -1;
      this.linkedProduct.map((item) => {
        if (!item.part_number) num += 1;
      });
      if (num > -1) {
        store.commit(SET_ERROR, {msg: "请补全产品型号", color: "primary",});
      }
      else if (!this.orderData.invoice_info.delivery_name || !this.orderData.invoice_info.delivery_phone || !this.orderData.invoice_info.delivery_address) {
        store.commit(SET_ERROR, {msg: "请完善发票与收货信息", color: "primary",});
      } else {
        store.dispatch(FETCH_PROCESS_LIST, "type=order");
        let pros = JSON.parse(JSON.stringify(this.linkedProduct)).map(
          (item) => {
            item.quantity = item.count.order - item.count.cases - item.count.cancel
            return item;
          }
        )
        pros.forEach(item => {
          if (item.count.cases < item.count.order) {
            if (!item.product_vendor.department) {
              this.noPurchaser = true
              item.noPurchaser = true
              if (!this.noPurchaserList.includes(item.product_vendor.name)) this.noPurchaserList.push(item.product_vendor.name)
            } else {
              if (item.product_vendor.purchaser && item.product_vendor.purchaser.length) {
                store.dispatch(FETCH_ACCOUNT_LIST, "&ids="+ item.product_vendor.purchaser)
                  .then((account) => {
                    if (account) {
                      this.casePurchaserList.push(...account.employees);
                    }
                  })
                  .catch((err) => {
                    store.commit(SET_ERROR, { msg: err });
                  })
              } else {
                store.dispatch(FETCH_ACCOUNT_LIST, "&department="+ item.product_vendor.department)
                  .then((account) => {
                    if (account) {
                      this.casePurchaserList.push(...account.employees);
                    }
                  })
                  .catch((err) => {
                    store.commit(SET_ERROR, { msg: err });
                  })
              }
              item.noPurchaser = false
            }
            this.caseProList.push(item)
          }
        })
        this.defaultCaseProList = JSON.parse(JSON.stringify(this.caseProList))
        this.createCaseDlg = true;
      }
    },
    cancelOrder() {
      this.exeing = true
      store.dispatch(CANCEL_ORDER_PURCHASE, { orderId: this.getId, product_list: this.cancelList})
        .then(() => {
          store.commit(SET_ERROR, {msg: '订单已被取消', color: 'primary'});
          this.getOrder(this.getId)
          this.closeEditCancel()
          this.exeing = false
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
          this.exeing = false
        })
    },
    doAction(action) {
      switch (action) {
        case "open_case": {
          this.getOrder(this.getId)
          store.dispatch(FETCH_PRODUCTTYPE_LIST, "isEnable=true")
          store.dispatch(FETCH_VENDOR_LIST, "limit=-1&isEnable=true")
          store.dispatch(FETCH_PURCHASE_LIST, "limit=200&order_id="+ this.getId)
          if (this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('Order_Service') || this.currentUser.permission.includes('Order_Create')) this.isEdit = true
          this.dialog = true
          break;
        }
      }
    },
    getOrder(id) {
      this.overlay = true;
      store.dispatch(FETCH_ORDER_PURCHASE, { query: id })
        .then((data) => {
          this.orderData = data;
          this.linkedProduct = JSON.parse(JSON.stringify(this.orderData.product_list));
          this.linkedProduct.forEach((item) => {
            item.sales_price = item.sales_price.$numberDecimal;
            item.sum_price = item.sum_price.$numberDecimal;
          });
          store.dispatch(FETCH_PURCHASE_LIST, "limit=200&order_id="+ this.getId)
          this.overlay = false;
        })
        .catch((err) => {
          this.overlay = false;
          store.commit(SET_ERROR, { msg: err });
        });
    },
    markOrder(orderId, result) {
      let actCode = ADD_MARK_ORDER_PURCHASE
      if (!result) actCode = REMOVE_MARK_ORDER_PURCHASE
      store.dispatch(actCode, orderId)
        .then(() => {
          store.commit(SET_ERROR, {msg: '操作成功', color: 'primary'});
          this.getOrder(this.getId)
          this.$emit('Update')
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    }
  },
};
</script>
