/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        '[sw:esp] App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log('[sw:esp] Service worker has been registered.')

      // Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        registration.update()
      }, 1000 * 60 * 60) // hourly checks
    },
    cached () {
      console.log('[sw:esp] Content has been cached for offline use.')
    },
    updatefound () {
      console.log('[sw:esp] New content is downloading.')
    },
    updated (registration) {
      console.log('[sw:esp] New content is available; please refresh.')

      const event = new CustomEvent('swUpdated', { detail: registration })

      document.dispatchEvent(event)
    },
    offline () {
      console.log('[sw:esp] No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('[sw:esp] Error during service worker registration:', error)
    }
  })

  let refreshing

  navigator.serviceWorker.addEventListener('controllerchange', function () {
    if (refreshing) return

    window.location.reload()

    refreshing = true
  })
}
