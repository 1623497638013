import {RiskService} from "@/common/http";

import {
  FETCH_RISK_MODEL_LIST,
  PUBLISH_RISK_MODEL,
  FETCH_RISK_MODEL,
  EDIT_RISK_MODEL,
  DELETE_RISK_MODEL,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_RISK_MODEL_LIST,
  SET_RISK_MODEL_LIST,
  SET_RISK_MODEL,
} from "./mutations.type"

const state = {
  riskModelLoading: true,
  riskModelList: [],
  riskModelCount: 0,
  riskModel: {},
};

const getters = {
  riskModelLoading: state => state.riskModelLoading,
  riskModelList: state => state.riskModelList,
  riskModelCount: state => state.riskModelCount,
  riskModel: state => state.riskModel,
};

const actions = {
  [FETCH_RISK_MODEL_LIST]({ commit }, slug) {
    commit(LOAD_RISK_MODEL_LIST);
    return new Promise((resolve, reject) => {
      RiskService.get('/model/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RISK_MODEL_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_RISK_MODEL]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      RiskService.get('/model', slug)
        .then(({ data }) => {
          commit(SET_RISK_MODEL, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_RISK_MODEL](context, data) {
    return RiskService.create('/model', data);
  },
  [EDIT_RISK_MODEL](context, data) {
    return RiskService.update('/model', data._id, data);
  },
  [DELETE_RISK_MODEL](context, data) {
    return RiskService.delete('/model/' + data._id);
  },
};

const mutations = {
  [LOAD_RISK_MODEL_LIST] (state) {
    state.riskModelLoading = true;
  },
  [SET_RISK_MODEL_LIST](state, list) {
    state.riskModelCount = list.query.count;
    state.riskModelList = list.docs;
    state.riskModelLoading = false;
  },
  [SET_RISK_MODEL](state, riskModel) {
    state.riskModel = riskModel;
    state.riskModelLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

