import {RiskService} from "@/common/http";

import {
  FETCH_RISK_APPROVAL_LIST,
  PUBLISH_RISK_APPROVAL,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_RISK_APPROVAL_LIST,
  SET_RISK_APPROVAL_LIST,
  SET_RISK_APPROVAL,
} from "./mutations.type"

const state = {
  riskApprovalLoading: true,
  riskApprovalList: [],
  riskApprovalCount: 0,
  riskApproval: {},
};

const getters = {
  riskApprovalLoading: state => state.riskApprovalLoading,
  riskApprovalList: state => state.riskApprovalList,
  riskApprovalCount: state => state.riskApprovalCount,
  riskApproval: state => state.riskApproval,
};

const actions = {
  [FETCH_RISK_APPROVAL_LIST]({ commit }, slug) {
    commit(LOAD_RISK_APPROVAL_LIST);
    return new Promise((resolve, reject) => {
      RiskService.get('/approval/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RISK_APPROVAL_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_RISK_APPROVAL](context, data) {
    return RiskService.create('/approval', data);
  },
};

const mutations = {
  [LOAD_RISK_APPROVAL_LIST] (state) {
    state.riskApprovalLoading = true;
  },
  [SET_RISK_APPROVAL_LIST](state, list) {
    state.riskApprovalCount = list.query.count;
    state.riskApprovalList = list.docs;
    state.riskApprovalLoading = false;
  },
  [SET_RISK_APPROVAL](state, riskApproval) {
    state.riskApproval = riskApproval;
    state.riskApprovalLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

