<template>
  <v-tabs align-with-title v-model="active_tab">
    <v-tabs-slider color="secondary"></v-tabs-slider>
    <v-tab>产品</v-tab>
    <v-tab>附件</v-tab>
    <v-tab>备注</v-tab>
    <v-tab-item>
      <v-card-text class="px-0">
        <v-data-table
          :headers="productHeaders"
          :items="productList"
          class="elevation-0 my-4"
          :items-per-page="100"
          hide-default-footer
          item-key="index"
          dense
        >
          <template v-slot:item.product_name="{ item, index }">
            <td
              :nowrap="item.openRow ? false : true"
              :class="!item.openRow ? 'text-truncate text-decoration-underline' : 'text-decoration-underline'"
              style="max-width: 150px; cursor: pointer;"
              @click="showRowText(index)"
            >
              {{ item.product_name }}
            </td>
          </template>
          <template v-slot:item.purchase_margin="{ item }">
            <td nowrap="true">
              {{ parseFloat(item.purchase_margin * 100).toFixed(2) }} %
            </td>
          </template>
          <template v-slot:item.actions="{ item, index }">
            <v-btn v-if="itemData.isEdit && itemData.status < 2" icon x-small @click="editProduct(index)">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-tab-item>
    <v-tab-item>
      <widgets-files-list entityType="PurchaseProduct" :entityId="itemId" :type=1></widgets-files-list>
    </v-tab-item>
    <v-tab-item>
      <widgets-remarks-list entityType="PurchaseProduct" :entityId="itemId" :type=0></widgets-remarks-list>
    </v-tab-item>
    <v-dialog v-model="dlgEditProduct" scrollable persistent max-width="650px">
      <v-form v-model="validEditProduct" @submit.prevent="saveProduct(editIndex)">
        <v-card>
          <v-card-title>产品外采信息<v-spacer></v-spacer><v-btn icon @click="closeProduct()"><v-icon>close</v-icon></v-btn></v-card-title>
          <v-card-text>
            <v-container>
              <v-row align="center">
                <v-col cols="2" class="text-right">
                  产品名称
                </v-col>
                <v-col cols="10" :class="!showText['product_name'] ? 'text-truncate' : ''">
                  <strong @click="showMoreText('product_name')">{{editItem.product_name}}</strong>
                </v-col>
                <v-col cols="2" class="text-right">
                  产品型号
                </v-col>
                <v-col cols="10">
                  <strong>{{editItem.part_number}}</strong>
                </v-col>
                <v-col cols="2" class="text-right">
                  产品类型
                </v-col>
                <v-col cols="4">
                  <strong v-if="editItem.product_type">{{editItem.product_type.name}}</strong>
                </v-col>
                <v-col cols="2" class="text-right">
                  品牌厂商
                </v-col>
                <v-col cols="4">
                  <strong v-if="editItem.product_vendor">{{editItem.product_vendor.name}}</strong>
                </v-col>
                <v-col cols="2" class="text-right">
                  销售单价
                </v-col>
                <v-col cols="4">
                  <strong>{{editItem.sales_price}}</strong>
                </v-col>
                <v-col cols="2" class="text-right">
                  采购数量
                </v-col>
                <v-col cols="4">
                  <strong>{{editItem.quantity}}</strong>
                </v-col>
                <v-col cols="2" class="text-right">
                  采购单价
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="editItem.purchase_price"
                    type="number"
                    label="采购单价"
                    :rules="[rules.required, rules.negative]"
                    outlined
                    dense
                    hide-details
                    prefix="¥"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="text-right">
                  采购小记
                </v-col>
                <v-col cols="4">
                  <strong>¥ {{ parseFloat(editItem.purchase_price * editItem.quantity).toFixed(4) }}</strong>
                </v-col>
                <v-col cols="2" class="text-right">
                  采购地区
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="editItem.purchase_area"
                    label="采购地区"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="text-right">
                  采购货期
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="editItem.purchase_delivery"
                    label="采购货期"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="text-right">
                  利润率
                </v-col>
                <v-col cols="10">
                  <strong>{{ parseFloat((editItem.sales_price - editItem.purchase_price) / editItem.sales_price * 100).toFixed(2) }} %</strong>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeProduct()">取消</v-btn>
            <v-btn text color="secondary" :disabled="!validEditProduct" @click="saveProduct(editIndex)">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-tabs>
</template>

<script>
import store from "@/store";
import {
  SAVE_PURCHASE
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    itemId: {
      type: String
    }
  },
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
      },
      productHeaders: [
        { text: "产品名称", value: "product_name", width: "105px" },
        { text: "产品型号", value: "part_number", width: "155px" },
        { text: "产品类型", value: "product_type.name", width: "105px" },
        { text: "品牌厂商", value: "product_vendor.name", width: "105px" },
        { text: "销售单价", value: "sales_price", width: "105px" },
        { text: "采购数量", value: "quantity", width: "105px" },
        { text: "采购成本", value: "purchase_price", width: "105px" },
        { text: "毛利率", value: "purchase_margin", width: "105px" },
        { text: "操作", value: "actions", sortable: false, align: " d-none", width: "85px" },
      ],
      productList: [],
      dlgEditProduct: false,
      validEditProduct: true,
      editIndex: -1,
      editItem: {},
      defaultItem: {},
      showText: {},
      active_tab: 0,
    }
  },
  watch: {
    itemData() {
      this.initialize()
    },
  },
  methods: {
    initialize() {
      if (this.itemData.product_list) {
        this.itemData.product_list.forEach((item) => {
          item.sum_price = item.sales_price.$numberDecimal * item.quantity
          item.sales_price = item.sales_price.$numberDecimal;
          item.purchase_price = item.purchase_price.$numberDecimal;
          item.openRow = false;
        });
        this.productList = JSON.parse(JSON.stringify(this.itemData.product_list));
        this.checkEditStatus()
      }
    },
    checkEditStatus() {
      if (this.itemData.status < 2 && this.itemData.isEdit) {
        this.productHeaders.find(item => {
          if (item.value === 'actions') {
            item.align = ''
          }
        })
      }
    },
    showRowText(i) {
      this.productList[i].openRow = !this.productList[i].openRow
    },
    showMoreText(field) {
      this.showText[field] = !this.showText[field]
      this.$forceUpdate()
    },
    editProduct(index) {
      this.editIndex = index
      this.editItem = Object.assign({}, this.productList[index])
      this.dlgEditProduct = true
    },
    saveProduct(index) {
      Object.assign(this.productList[index], this.editItem)
      this.productList.map(product => {
        product.purchase_margin = parseFloat((product.sales_price - product.purchase_price) / product.sales_price).toFixed(4)
      })
      store.dispatch(SAVE_PURCHASE, { id: this.itemData._id, item: {product_list: this.productList }})
        .then(() => {
          this.closeProduct()
          store.commit(SET_ERROR, { msg: "修改成功。", color: "primary" });
          this.$emit("Update");
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err.response.data.message });
        })

    },
    closeProduct() {
      this.dlgEditProduct = false
      this.$nextTick(() => {
        this.editItem = Object.assign({}, this.defaultItem)
        this.editIndex = -1
      })
    }
  }
}
</script>
