var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.caseType === 'service' ? _c('div', [_vm.caseData.order_id && _vm.caseData.order_id.type === 0 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("采购订单")]) : _vm._e(), _vm.caseData.order_id && _vm.caseData.order_id.type === 1 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("租赁订单")]) : _vm._e(), _vm.caseData.status === 0 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "secondary"
    }
  }, [_vm._v("待受理")]) : _vm.caseData.status === 1 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "primary"
    }
  }, [_vm._v("处理中")]) : _vm.caseData.status === 2 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("已取消")]) : _vm.caseData.status === 3 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("已拒绝")]) : _vm.caseData.status === 4 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("已完成")]) : _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("已删除")])], 1) : _c('div', [_vm.caseData.status === 0 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "secondary"
    }
  }, [_vm._v("待受理")]) : _vm.caseData.status === 1 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.caseData.gsx_create_time ? '检测完成' : '检测中') + " ")]) : _vm.caseData.status === 2 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.caseData.repaired_time ? '维修完成' : '维修中') + " ")]) : _vm.caseData.status === 3 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("待取机")]) : _vm.caseData.status === 4 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("服务结束")]) : _vm.caseData.status === 8 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("取消服务")]) : _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("已删除")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }