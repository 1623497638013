import {SettingService} from "@/common/http";

import {
  FETCH_FIELD_LIST,
  FETCH_FIELDS,
  CREATE_FIELD,
  EDIT_FIELD,
  REMOVE_FIELD,
  FETCH_WAREHOUSE_LIST,
  PUBLISH_WAREHOUSE,
  EDIT_WAREHOUSE,
  ACTIV_WAREHOUSE,
  FETCH_VENDOR_LIST,
  PUBLISH_VENDOR,
  EDIT_VENDOR,
  ACTIV_VENDOR,
} from "./actions.type";

import {
  SET_ERROR,
  SET_FIELD,
  PURGE_FIELD,
  LOAD_FIELD_LIST,
  SET_FIELD_LIST,
  LOAD_WAREHOUSE_LIST,
  SET_WAREHOUSE_LIST,
  LOAD_VENDOR_LIST,
  SET_VENDOR_LIST,
} from "./mutations.type"

const state = {
  customFields: [],
  warehouseLoading: true,
  warehouseList: [],
  warehouseCount: 0,
  vendorLoading: true,
  vendorList: [],
  vendorCount: 0,
  fieldLoading: true,
  fieldList: [],
  fieldCount: 0,
  fieldEnterpriseList: [],
};

const getters = {
  customFields: state => state.customFields,
  warehouseLoading: state => state.warehouseLoading,
  warehouseList: state => state.warehouseList,
  warehouseCount: state => state.warehouseCount,
  vendorLoading: state => state.vendorLoading,
  vendorList: state => state.vendorList,
  vendorCount: state => state.vendorCount,
  fieldLoading: state => state.fieldLoading,
  fieldList: state => state.fieldList,
  fieldCount: state => state.fieldCount,
  fieldEnterpriseList: state => state.fieldEnterpriseList,
};

const actions = {
  [FETCH_FIELD_LIST]({ commit }, slug) {
    commit(LOAD_FIELD_LIST);
    return new Promise((resolve, reject) => {
      SettingService.get('/fields/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_FIELD_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_FIELDS]({commit}, slug) {
    return new Promise((resolve, reject) => {
      SettingService.get('/fields', slug)
        .then(({data}) => {
          commit(SET_FIELD, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, {msg: error});
          reject(error);
        })
    });
  },
  [CREATE_FIELD](context, field) {
    return new Promise((resolve, reject) => {
      SettingService.create('/fields', field)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  [EDIT_FIELD](context, field) {
    return new Promise((resolve, reject) => {
      SettingService.update('/fields', '', field)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  [REMOVE_FIELD](context, field) {
    return new Promise((resolve, reject) => {
      SettingService.destroy('fields', field)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  [FETCH_WAREHOUSE_LIST]({ commit }, slug) {
    commit(LOAD_WAREHOUSE_LIST);
    return new Promise((resolve, reject) => {
      SettingService.get('/warehouse/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_WAREHOUSE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_WAREHOUSE](context, warehouse) {
    return SettingService.create('/warehouse', warehouse);
  },
  [EDIT_WAREHOUSE](context, warehouse) {
    return SettingService.update('/warehouse', warehouse._id, warehouse);
  },
  [ACTIV_WAREHOUSE](context, warehouse) {
    return SettingService.enable('/warehouse/', warehouse._id +'/'+ warehouse.reply);
  },
  [FETCH_VENDOR_LIST]({ commit }, slug) {
    commit(LOAD_VENDOR_LIST);
    return new Promise((resolve, reject) => {
      SettingService.get('/vendor/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_VENDOR_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_VENDOR](context, vendor) {
    return SettingService.create('/vendor', vendor);
  },
  [EDIT_VENDOR](context, vendor) {
    return SettingService.update('/vendor', vendor._id, vendor);
  },
  [ACTIV_VENDOR](context, vendor) {
    return SettingService.enable('/vendor/', vendor._id +'/'+ vendor.reply);
  },
};

const mutations = {
  [SET_FIELD](state, data) {
    state.customFields = data;
  },
  [PURGE_FIELD](state) {
    state.customFields = null;
  },
  [LOAD_WAREHOUSE_LIST] (state) {
    state.warehouseLoading = true;
  },
  [SET_WAREHOUSE_LIST](state, list) {
    state.warehouseCount = list.query.count;
    state.warehouseList = list.warehouse;
    state.warehouseLoading = false;
  },
  [LOAD_VENDOR_LIST] (state) {
    state.vendorLoading = true;
  },
  [SET_VENDOR_LIST](state, list) {
    state.vendorCount = list.query.count;
    state.vendorList = list.vendor;
    state.vendorLoading = false;
  },
  [LOAD_FIELD_LIST] (state) {
    state.fieldLoading = true;
  },
  [SET_FIELD_LIST](state, list) {
    state.fieldCount = list.query.count;
    state.fieldEnterpriseList = list.arrEnterprise;
    state.fieldList = list.fields;
    state.fieldLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

