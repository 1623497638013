<template>
  <div>
    <span :color="color" dark text class="v-list-item--link" @click="doAction('open_case')">
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    <v-dialog v-model="dialog" persistent scrollable fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-form v-model="valid" @submit.prevent="submitCase()">
        <v-card v-if="caseData && caseData.catalog && caseData.creator && caseData.enterprise && caseData.account" :loading="caseLoading ? 'secondary' : false">
          <case-card-title :caseData="caseData" caseType="repair" v-on:Close="closeDialog"></case-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-expansion-panels v-model="openPanel" multiple accordion flat hover>
              <v-expansion-panel>
                <v-expansion-panel-header>申请信息</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <case-repair-basic-info :caseData="caseData"></case-repair-basic-info>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-divider></v-divider>
                <v-expansion-panel-header>详细信息</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12">
                      <v-tabs>
                        <v-tab>关联设备</v-tab>
                        <v-tab>附件</v-tab>
                        <v-tab>备注</v-tab>
                        <v-tab-item>
                          <case-link-asset :caseData="caseData" :maxLimit=1 caseType="repair" assetKey="link_asset"></case-link-asset>
                        </v-tab-item>
                        <v-tab-item>
                          <widgets-files-list entityType="CaseRepair" :entityId="getId" :type=1></widgets-files-list>
                        </v-tab-item>
                        <v-tab-item>
                          <widgets-remarks-list entityType="CaseRepair" :entityId="getId" :type=0></widgets-remarks-list>
                        </v-tab-item>
                      </v-tabs>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>处理信息</v-expansion-panel-header>
                <v-expansion-panel-content v-if="caseData.catalog.name === Global.REPAIR_CATALOG_TEXT">
                  <v-row v-if="caseData.status === 0">
                    <v-col cols="12">
                      <v-alert dense type="info" class="ma-2" dismissible>
                        受理检测环节需要确认用户信息、设备信息，并指定关联S1系统单号。
                        <span class="font-weight-bold">S1单号输入后不能修改，系统将自动基于S1系统更新信息。</span>
                      </v-alert>
                    </v-col>
                    <v-col cols="2" class="text-right" v-if="caseData.out_req_type !== '三包服务'">S1系统单号</v-col>
                    <v-col cols="5" class="mt-n2" v-if="caseData.isEdit && caseData.out_req_type !== '三包服务'">
                      <v-text-field
                        v-model="editedItem.out_req_id"
                        :rules="[rules.required]"
                        type="text"
                        label="S1单号"
                        placeholder="请输入S1系统唯一凭证号码"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="5" v-if="caseData.out_req_type !== '三包服务'"></v-col>
                    <v-col cols="2" class="text-right">维修价格</v-col>
                    <v-col cols="5" class="mt-n2" v-if="caseData.isEdit">
                      <v-text-field
                        v-model="editedItem.repair_price"
                        label="维修价格"
                        placeholder="请输入本次维修的费用"
                        outlined
                        dense
                        min="0"
                        step="1.0"
                        type="number"
                        prefix="¥"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="5"></v-col>
                  </v-row>
                  <v-row v-else-if="caseData.status === 3">
                    <v-col cols="12">
                      <v-alert dense type="info" class="ma-2" dismissible>
                        设备维修完成后，寄回设备至最终用户，需要提供快递公司和运单号。
                      </v-alert>
                    </v-col>
                    <v-col cols="2" class="text-right">送回方式</v-col>
                    <v-col cols="5">
                      <span v-if="caseData.returns && caseData.returns.express_corp">{{ expressCorp.find(item => item.code === caseData.returns.express_corp).name }}</span>
                      <v-autocomplete
                        class="mt-n2"
                        v-else-if="caseData.isEdit"
                        v-model="editedItem.express_corp"
                        :rules="[rules.required, rules.selected]"
                        :items="expressCorp"
                        item-text="name"
                        item-value="code"
                        autocomplete="off"
                        hide-details
                        outlined
                        dense
                        label="请选择该快递承运公司"
                      ></v-autocomplete>
                      <span v-else>未填写</span>
                    </v-col>
                    <v-col cols="5"></v-col>
                    <v-col cols="2" class="text-right">快递单号</v-col>
                    <v-col cols="5">
                      <span v-if="caseData.returns && caseData.returns.express_number">{{ caseData.returns.express_number }}</span>
                      <v-text-field
                        class="mt-n2"
                        v-else-if="caseData.isEdit"
                        v-model="editedItem.express_number"
                        :rules="[rules.required]"
                        type="text"
                        label="请输入快递单号"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                      <span v-else>未填写</span>
                    </v-col>
                    <v-col cols="5"></v-col>
                    <v-col cols="2" class="text-right">寄回日期</v-col>
                    <v-col cols="5">
                      <span v-if="caseData.returns && caseData.returns.delivery_time">{{ caseData.returns.delivery_time | formatTime }}</span>
                      <v-menu
                        v-else-if="caseData.isEdit"
                        v-model="delivery_time"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        class="mt-n2"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.delivery_time"
                            label="快递发送时间"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.delivery_time" @input="delivery_time = false" no-title scrollable></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="5"></v-col>
                  </v-row>
                  <v-row v-else align="center">
                    <v-col cols="12">
                      <v-alert v-if="(caseData.status > 0 && caseData.status < 3) && caseData.out_req_type === '保外送修'" dense type="info" class="ma-2" dismissible>
                        请确认维修费用，如同意维修，请点击 确认维修 ，我们将安排工程师处理后续事宜。
                        <span class="font-weight-bold"></span>
                      </v-alert>
                      <v-alert v-else-if="caseData.status === 4" dense type="info" class="ma-2" dismissible>
                        工单服务已经结束。
                        <span class="font-weight-bold"></span>
                      </v-alert>
                      <v-alert v-else dense type="info" class="ma-2" dismissible>
                        工单已经被受理，信息将与S1系统进行同步。<span class="font-weight-bold"></span>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"><v-divider></v-divider></v-col>
                    <v-col cols="2" class="text-right">维修费用</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.repair_price | formatMoney}}  元
                      <v-btn v-if="caseData.status < 2 && !caseData.confirm_price" icon small @click="editItem('编辑维修价格', 'repair_price')"><v-icon>create</v-icon></v-btn>
                    </v-col>
                    <v-col cols="2" class="text-right">报价确认</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.confirm_price ? '已确认' : '未确认'}}</v-col>
                    <v-col cols="2" class="text-right">维修类型</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.repair_type || '-'}}</v-col>
                    <v-col cols="2" class="text-right">维修说明</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.repair_explain || '-'}}
                      <v-btn v-if="caseData.status < 4" icon x-small @click="editItem('编辑维修说明', 'repair_explain')"><v-icon>create</v-icon></v-btn>
                    </v-col>
                    <v-col cols="2" class="text-right">故障描述</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.detail_fault || '-'}}
                      <v-btn v-if="caseData.status < 4" icon x-small @click="editItem('编辑故障描述', 'detail_fault')"><v-icon>create</v-icon></v-btn>
                    </v-col>
                    <v-col cols="2" class="text-right">解决方案</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.detail_solve || '-'}}
                      <v-btn v-if="caseData.status < 4" icon x-small @click="editItem('编辑解决方案', 'detail_solve')"><v-icon>create</v-icon></v-btn>
                    </v-col>
                    <v-col cols="2" class="text-right">测试问题描述</v-col>
                    <v-col cols="10" class="text--secondary">{{caseData.detail_problem || '-'}}
                      <v-btn v-if="caseData.status < 4" icon x-small @click="editItem('编辑测试问题描述', 'detail_problem')"><v-icon>create</v-icon></v-btn>
                    </v-col>
                    <v-col cols="2" class="text-right">使用备件</v-col>
                    <v-col cols="10" class="text--secondary">{{caseData.part_name || '-'}}</v-col>
                    <v-col cols="2" class="text-right">备件描述</v-col>
                    <v-col cols="10" class="text--secondary">{{caseData.part_describe || '-'}}</v-col>
                    <v-col cols="2" class="text-right">报告症状</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.report_symptom || '-'}}</v-col>
                    <v-col cols="2" class="text-right">报告问题</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.report_problem || '-'}}</v-col>
                    <v-col cols="2" class="text-right">报告症状描述</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.report_symptom_describe || '-'}}
                      <v-btn v-if="caseData.status < 4" icon x-small @click="editItem('编辑报告症状描述', 'report_symptom_describe')"><v-icon>create</v-icon></v-btn>
                    </v-col>
                    <v-col cols="2" class="text-right">报告问题描述</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.report_problem_describe || '-'}}
                      <v-btn v-if="caseData.status < 4" icon x-small @click="editItem('编辑报告问题描述', 'report_problem_describe')"><v-icon>create</v-icon></v-btn>
                    </v-col>
                    <v-col cols="2" class="text-right">GSX建单时间</v-col>
                    <v-col cols="4" class="text--secondary"><span v-if="caseData.gsx_create_time">{{ caseData.gsx_create_time | formatTime }}</span><span v-else>-</span>
                      <v-btn v-if="caseData.status < 4" icon x-small @click="editItem('编辑GSX建单时间', 'gsx_create_time')"><v-icon>create</v-icon></v-btn>
                    </v-col>
                    <v-col cols="2" class="text-right">GSX维修确认号</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.gsx_confirm_number || '-'}}
                      <v-btn v-if="caseData.status < 4" icon x-small @click="editItem('编辑GSX维修确认号', 'gsx_confirm_number')"><v-icon>create</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12"><v-divider inset></v-divider></v-col>
                    <v-col cols="2" class="text-right">接机人员工号</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.staff_receive_code || '-'}}</v-col>
                    <v-col cols="2" class="text-right">检测人员工号</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.staff_check_code || '-'}}</v-col>
                    <v-col cols="2" class="text-right">维修人员工号</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.staff_repair_code || '-'}}</v-col>
                    <v-col cols="2" class="text-right">取机人员工号</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.staff_taken_code || '-'}}</v-col>
                    <v-col cols="2" class="text-right">开单时间</v-col>
                    <v-col cols="4" class="text--secondary" v-if="caseData.create_time">{{caseData.create_time | formatTime}}</v-col>
                    <v-col cols="4" class="text--secondary" v-else>-</v-col>
                    <v-col cols="2" class="text-right">备件订购时间</v-col>
                    <v-col cols="4" class="text--secondary" v-if="caseData.gsx_create_time">{{caseData.gsx_create_time | formatTime}}</v-col>
                    <v-col cols="4" class="text--secondary" v-else>-</v-col>
<!--                    <v-col cols="2" class="text-right">接机时间</v-col>-->
<!--                    <v-col cols="4" class="text&#45;&#45;secondary" v-if="caseData.pickup_time">{{caseData.pickup_time | formatTime}}</v-col>-->
<!--                    <v-col cols="4" class="text&#45;&#45;secondary" v-else>-</v-col>-->
<!--                    <v-col cols="2" class="text-right">确认维修时间</v-col>-->
<!--                    <v-col cols="4" class="text&#45;&#45;secondary" v-if="caseData.confirm_repair_time">{{caseData.confirm_repair_time | formatTime}}</v-col>-->
<!--                    <v-col cols="4" class="text&#45;&#45;secondary" v-else>-</v-col>-->
                    <v-col cols="2" class="text-right">维修完成时间</v-col>
                    <v-col cols="4" class="text--secondary" v-if="caseData.repaired_time">{{caseData.repaired_time | formatTime}}</v-col>
                    <v-col cols="4" class="text--secondary" v-else>-
                      <v-btn v-if="caseData.status < 4" icon x-small @click="editItem('编辑维修完成时间', 'repaired_time')"><v-icon>create</v-icon></v-btn>
                    </v-col>
                    <v-col cols="2" class="text-right">服务完成时间</v-col>
                    <v-col cols="4" class="text--secondary" v-if="caseData.complete_time">{{caseData.complete_time | formatTime}}</v-col>
                    <v-col cols="4" class="text--secondary" v-else>-</v-col>
                  </v-row>
                  <v-row v-if="caseData.status === 4">
                    <v-col cols="12">
                      <div class="ml-12 text-center">
                        <v-rating
                          v-if="caseData.satisfaction && caseData.satisfaction.rating"
                          v-model="caseData.satisfaction.rating"
                          background-color="secondary lighten-3"
                          color="secondary"
                          readonly
                        ></v-rating>
                        <pre class="body-1">{{caseData.satisfaction && caseData.satisfaction.remarks}}</pre>
                        <div class="font-weight-normal" v-if="caseData.satisfaction && caseData.satisfaction.creator">
                          <strong>{{caseData.satisfaction.creator.personal && caseData.satisfaction.creator.personal.name }}</strong>
                          @{{ caseData.satisfaction.createTime | fromDate}}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-else>
                  <v-row v-if="caseData.isEdit && caseData.status === 0">
                    <v-col cols="2"></v-col>
                    <v-col cols="8">
                      <v-textarea
                        v-model="editedItem.diagnosis.verdict"
                        :rules="[rules.required]"
                        label="诊断结论"
                        placeholder="请输入设备故障原因及建议"
                        rows="5"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                    <v-col cols="2"></v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        autocomplete="off"
                        v-model="editedItem.diagnosis.state"
                        :items="diagnosisState"
                        :rules="[rules.required]"
                        label="设备状态"
                        placeholder="设备是否出现异常情况"
                        item-text="name"
                        item-value="code"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        v-if="editedItem.diagnosis.state === 'exception'"
                        autocomplete="off"
                        v-model="editedItem.diagnosis.class"
                        @change="setReasonList(editedItem.diagnosis.class)"
                        :items="diagnosisClass"
                        :rules="[rules.required]"
                        label="设备种类"
                        placeholder="请选择设备类型"
                        item-text="name"
                        item-value="code"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="2"></v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        v-if="editedItem.diagnosis.state === 'exception'"
                        autocomplete="off"
                        v-model="editedItem.diagnosis.reason"
                        :items="diagnosisReason"
                        :rules="[rules.required]"
                        label="异常原因"
                        placeholder="请选择设备异常原因"
                        multiple
                        chips
                        small-chips
                        deletable-chips
                        clearable
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        v-if="editedItem.diagnosis.state === 'exception'"
                        autocomplete="off"
                        v-model="editedItem.diagnosis.repair"
                        :items="diagnosisRepair"
                        :rules="[rules.required]"
                        label="维修类型"
                        placeholder="请选择维修类型"
                        item-text="name"
                        item-value="code"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="2"></v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <v-card outlined tile class="text-center">
                        <input type="file" name="file" ref="uploadInput" accept="image/*" :multiple="false" @change="detectFiles($event)" />
                        <v-progress-linear color="secondary" v-if="Upload.uploading" :value="Upload.progressUpload"></v-progress-linear>
                        <v-img max-height="275px" contain :src="editedItem.diagnosis.report ? ossURL + '/'+ editedItem.diagnosis.report : '/static/error/empty_state.svg'" >
                        </v-img>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="uploadImage()"><v-icon left>backup</v-icon> 上传报告图片</v-btn>
                          <v-btn text color="primary" @click="deleteImage()"><v-icon left>delete</v-icon> 删除报告图片</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                    <v-col cols="2"></v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="2" class="text-right">诊断结论</v-col>
                    <v-col cols="10" class="text--secondary"><pre class="body-1">{{caseData.diagnosis && caseData.diagnosis.verdict}}</pre></v-col>
                    <v-col cols="2" class="text-right">设备状态</v-col>
                    <v-col cols="4" class="text--secondary">
                      {{caseData.diagnosis && caseData.diagnosis.state && diagnosisState.find(item => item.code === caseData.diagnosis.state).name }}
                    </v-col>
                    <v-col cols="2" class="text-right">设备种类</v-col>
                    <v-col cols="4" class="text--secondary">
                      {{caseData.diagnosis && caseData.diagnosis.class && diagnosisClass.find(item => item.code === caseData.diagnosis.class).name }}
                    </v-col>
                    <v-col cols="2" class="text-right">异常原因</v-col>
                    <v-col cols="4" class="text--secondary">{{caseData.diagnosis && caseData.diagnosis.reason}}</v-col>
                    <v-col cols="2" class="text-right">维修类型</v-col>
                    <v-col cols="4" class="text--secondary">
                      {{caseData.diagnosis && caseData.diagnosis.repair && diagnosisRepair.find(item => item.code === caseData.diagnosis.repair).name }}
                    </v-col>
                    <v-col cols="12" v-if="caseData.diagnosis && caseData.diagnosis.report">
                      <v-card outlined tile class="text-center">
                        <v-img max-height="275px" contain :src="ossURL + '/'+ caseData.diagnosis.report" >
                        </v-img>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-divider></v-divider>
                <v-expansion-panel-header>历史记录</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <case-action-logs :caseData="caseData"></case-action-logs>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-divider></v-divider>
          <case-repair-actions :caseData="caseData" :editedTask="editedItem" v-on:Close="closeDialog" @Update="updateDialog" @ChangeStatus="changeStatus"></case-repair-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dlgEditItem" persistent hide-overlay width="450">
      <v-card>
        <v-card-title>{{ editItemTitle }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-if="editItemTitle === '编辑维修价格'"
              v-model="editedItem.repair_price"
              type="number"
              label="维修价格"
              placeholder="请输入本次维修的费用"
              outlined
              dense
            >
            </v-text-field>
            <v-textarea
              v-if="editItemTitle === '编辑维修说明'"
              v-model="editedItem.repair_explain"
              label="维修说明"
              placeholder="请输入维修说明信息"
              rows="3"
              outlined
              dense
            ></v-textarea>
            <v-textarea
              v-if="editItemTitle === '编辑故障描述'"
              v-model="editedItem.detail_fault"
              label="故障描述"
              placeholder="请输入设备故障描述"
              rows="3"
              outlined
              dense
            ></v-textarea>
            <v-textarea
              v-if="editItemTitle === '编辑解决方案'"
              v-model="editedItem.detail_solve"
              label="解决方案"
              placeholder="请输入维修解决方案或维修建议"
              rows="3"
              outlined
              dense
            ></v-textarea>
            <v-textarea
              v-if="editItemTitle === '编辑测试问题描述'"
              v-model="editedItem.detail_problem"
              label="测试问题描述"
              placeholder="请输入设备测试后确认问题描述"
              rows="3"
              outlined
              dense
            ></v-textarea>
            <v-text-field
              v-if="editItemTitle === '编辑报告症状描述'"
              v-model="editedItem.report_symptom_describe"
              label="报告症状描述"
              placeholder="请输入报告症状对应的描述信息"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="editItemTitle === '编辑报告问题描述'"
              v-model="editedItem.report_problem_describe"
              label="报告问题描述"
              placeholder="请输入报告问题对应的描述信息"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="editItemTitle === '编辑GSX建单时间'"
              :rules="[rules.datatime]"
              v-model="editedItem.gsx_create_time"
              label="GSX建单时间"
              placeholder="请输入标准时间格式 YYYY-MM-DD HH:mm:ss"
              v-mask="'####-##-## ##:##:##'"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="editItemTitle === '编辑GSX维修确认号'"
              v-model="editedItem.gsx_confirm_number"
              label="GSX维修确认号"
              placeholder="请输入GSX维修确认号"
              outlined
              dense
            ></v-text-field>
            <div v-if="editItemTitle === '变更工单状态'">
              <p>当前状态：{{statusRequired.find(v => v.status === editedItem.status).name}}</p>
              <v-autocomplete
                autocomplete="off"
                v-model="editedItem.status"
                :items="statusRequired.filter(v => v.status > editedItem.status)"
                :rules="[rules.required]"
                label="变更后工单状态"
                placeholder="请选择工单变更后的状态"
                item-text="name"
                item-value="status"
                outlined
                dense
              ></v-autocomplete>
            </div>
            <v-text-field
              v-if="editItemTitle === '编辑维修完成时间'"
              :rules="[rules.datatime]"
              v-model="editedItem.repaired_time"
              label="维修完成时间"
              placeholder="请输入标准时间格式 YYYY-MM-DD HH:mm:ss"
              v-mask="'####-##-## ##:##:##'"
              outlined
              dense
            ></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeEditDialog()">取消</v-btn>
          <v-btn text color="secondary" @click="saveCase(caseData._id)">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  FETCH_CASE, FETCH_UPLOAD, SAVE_CASE,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import store from "@/store";
import Util from "@/common/util";
import {SET_ERROR} from "@/store/mutations.type";
import axios from "axios";
export default {
  props: {
    getId: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      caseData: {},
      openPanel: [],
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
        datatime: value => (!isNaN(Date.parse(value)))  || '请输入正确的日期时间格式.',
      },
      editedItem: {},
      defaultItem: {},
      delivery_time: false,
      valid: true,
      diagnosisReason: [],
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      dlgEditItem: false,
      editItemTitle: '',
      statusRequired: [
        { status: 10, name: '检测中', field: ['pickup_time', 'gsx_create_time']},
        { status: 11, name: '检测完成', field: ['pickup_time', 'gsx_create_time']},
        { status: 20, name: '维修中', field: ['pickup_time', 'gsx_create_time', 'repaired_time']},
        { status: 21, name: '维修完成', field: ['pickup_time', 'gsx_create_time', 'repaired_time']},
        { status: 30, name: '已寄出/待取机', field: ['pickup_time', 'gsx_create_time', 'repaired_time', 'returns.express_corp', 'returns.delivery_time', 'returns.express_number']},
        { status: 40, name: '已取机/服务结束', field: ['pickup_time', 'gsx_create_time', 'repaired_time', 'returns.express_corp', 'returns.delivery_time', 'returns.express_number', 'complete_time']},
      ]
    }
  },
  computed: {
    ...mapGetters(['currentEmployer',  'caseLoading', 'uploadSign']),
    expressCorp () {
      return Util.categories('expressRepair')
    },
    diagnosisState () {
      return Util.categories('diagnosisState')
    },
    diagnosisClass () {
      return Util.categories('diagnosisClass')
    },
    diagnosisRepair () {
      return Util.categories('diagnosisRepair')
    },
  },
  mounted() { },
  watch: {
    dialog (val) {
      val || this.closeDialog()
    },
    dlgEditItem (val) {
      val || this.closeEditDialog()
    }
  },
  methods: {
    updateEditTask(info) {
      this.editedTask = info
    },
    closeDialog () {
      this.dialog = false
      this.$emit('Close')
      this.$nextTick(() => {
        this.caseData = Object.assign({}, {})
        this.editedItem = Object.assign({}, this.defaultItem)
        this.openPanel = []
      })
    },
    closeEditDialog () {
      this.dlgEditItem = false
      this.editItemTitle = ''
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    updateDialog() {
      this.getCase(this.getId)
    },
    getCase(id) {
      store.dispatch(FETCH_CASE, {form: 'repair', query: id})
        .then((data) => {
          this.caseData = Object.assign({}, data)
          this.caseData['asset_sn'] = this.caseData.link_asset.map(a => a.serial_number)
          this.caseData['asset_product'] = this.caseData.link_asset.map(a => a.product.name)
          this.caseData['asset_imei'] = this.caseData.link_asset.map(a => a.imei_number)
          this.caseData['link_asset'] = this.caseData.link_asset.map(a => a._id)
          if (data.status < 8) {
            this.openPanel.push(0)
            this.openPanel.push(2)
          } else {
            this.openPanel.push(3)
          }
        })
    },
    editItem(editTitle, editKey) {
      this.editItemTitle = editTitle
      switch (editTitle) {
        case '变更工单状态':
          if (this.caseData.status > 0 && this.caseData.status < 8) {
            if (this.caseData.status === 1) {
              this.editedItem['status'] = this.caseData.gsx_create_time ? 11 : 10
            } else if (this.caseData.status === 2) {
              this.editedItem['status'] = this.caseData.repaired_time ? 21 : 20
            } else if (this.caseData.status === 3) {
              this.editedItem['status'] = 30
            } else if (this.caseData.status === 4) {
              this.editedItem['status'] = 40
            }
            this.dlgEditItem = true
          } else {
            store.commit(SET_ERROR, {msg: '只能变更已受理切未完成的维修工单。'});
          }
          break
        default: {
          this.editedItem[editKey] = this.caseData[editKey]
          this.dlgEditItem = true
          break
        }
      }
    },
    saveCase(caseId) {
      let strUpdate = Object.assign({}, this.editedItem)
      strUpdate.form = 'repair'
      strUpdate.caseId = caseId
      if (this.editItemTitle === '变更工单状态' && this.editedItem.status) {
        let updateStatus = this.editedItem.status
        switch (updateStatus) {
          case 11:
            if (!this.caseData.gsx_create_time) {
              store.commit(SET_ERROR, {msg: '请补充备件订购信息。'});
              return
            }
            strUpdate.status = updateStatus.toString().substring(0,1)
            break
          case 20:
            if (!this.caseData.gsx_create_time) {
              store.commit(SET_ERROR, {msg: '请补充备件订购信息。'});
              return
            }
            strUpdate.status = updateStatus.toString().substring(0,1)
            break
          case 21:
            if (!this.caseData.gsx_create_time) {
              store.commit(SET_ERROR, {msg: '请补充备件订购信息。'});
              return
            }
            if (!this.caseData.repaired_time) {
              store.commit(SET_ERROR, {msg: '请补充维修完成时间。'});
              return
            }
            strUpdate.status = updateStatus.toString().substring(0,1)
            break
          case 30:
            strUpdate.status = updateStatus.toString().substring(0,1)
            break
          case 40:
            strUpdate.status = updateStatus.toString().substring(0,1)
            break
          default: {
            strUpdate.status = updateStatus.toString().substring(0,1)
            break
          }
        }
        strUpdate.status = parseInt(strUpdate.status)
      }
      store.dispatch(SAVE_CASE, strUpdate)
        .then(() => {
          this.closeEditDialog()
          Object.assign(this.caseData, strUpdate)
          store.commit(SET_ERROR, {msg: '操作成功。', color: 'primary'});
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    doAction(action) {
      switch (action) {
        case 'open_case': {
          this.getCase(this.getId)
          this.dialog = true
          break
        }
      }
    },
    changeStatus() {
      this.editItem('变更工单状态')
    },
    submitCase() {
      console.log('editedItem =>'+ JSON.stringify(this.editedItem))
    },
    setReasonList(code = '') {
      let selectedClass = this.diagnosisClass.find(item => item.code === code)
      if (selectedClass) {
        this.diagnosisReason = selectedClass.child
      }
    },
    uploadImage() {
      this.$store.dispatch(FETCH_UPLOAD)
        .then(() => {
          this.$refs.uploadInput.click()
        })
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.caseData._id +'/report/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };
      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.editedItem.diagnosis.report = this.caseData._id +'/report/'+ this.uploadSign.key;
          this.$forceUpdate()
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    deleteImage() {
      this.editedItem.diagnosis.report = ''
      this.$forceUpdate()
    }
  }
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.v-dialog__content {z-index: 203 !important;}
pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
