var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.caseData.files ? _c('v-card-text', [_c('v-list', {
    attrs: {
      "subheader": "",
      "dense": ""
    }
  }, [_vm._l(_vm.caseData.files, function (file) {
    return _c('v-list-item', {
      key: file.href
    }, [file.suffix === 'jpeg' || file.suffix === 'jpg' || file.suffix === 'png' || file.suffix === 'gif' ? _c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": file.preUrl && file.preUrl
      },
      on: {
        "click": function click($event) {
          return _vm.zoomFile(file);
        }
      }
    })], 1) : _c('v-list-item-avatar', [_c('v-icon', [_vm._v("text_snippet")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(file.name)
      }
    }), _c('v-list-item-subtitle', [_vm._v(_vm._s(file.creator._id === _vm.currentUser._id ? '我' : file.creator.personal.name) + " 于 " + _vm._s(_vm._f("formatTime")(file.createTime)) + " 上传")])], 1), _c('v-list-item-action', {
      staticClass: "d-inline"
    }, [file.creator._id === _vm.currentUser._id && _vm.caseData.status < 2 ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteFile(file);
        }
      }
    }, [_c('v-icon', [_vm._v("delete")])], 1) : _vm._e(), file.preUrl ? _c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.zoomFile(file);
        }
      }
    }, [_vm._v("zoom_in")])], 1) : _vm._e(), _c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.downloadFile(file.href);
        }
      }
    }, [_vm._v("get_app")])], 1)], 1)], 1);
  }), _vm._l(_vm.editedItem.files, function (file) {
    return _c('v-list-item', {
      key: file.href
    }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("text_snippet")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(file.name)
      }
    })], 1), _c('v-list-item-action', {
      staticClass: "d-inline"
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.removeFile(file);
        }
      }
    }, [_c('v-icon', [_vm._v("delete")])], 1), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(file.href);
        }
      }
    }, [_c('v-icon', [_vm._v("get_app")])], 1)], 1)], 1);
  }), _c('v-list-item', [_c('v-list-item-content', [_vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('input', {
    ref: "uploadFile",
    attrs: {
      "type": "file",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _vm.caseType === 'service' ? _c('v-list-item-title', [_vm.caseData.status === 0 ? _c('v-btn', {
    attrs: {
      "block": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.uploadFile(-1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("attach_file")]), _vm._v("上传附件")], 1) : _vm.caseData.status === 1 && _vm.currentTask.task.powers.includes('upload_files') ? _c('v-btn', {
    attrs: {
      "block": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.uploadFile(-1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("attach_file")]), _vm._v("上传附件")], 1) : _vm._e()], 1) : _c('v-list-item-title', [_vm.caseData.isEdit ? _c('v-btn', {
    attrs: {
      "block": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.uploadFile(-1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("attach_file")]), _vm._v("上传附件")], 1) : _vm._e()], 1)], 1)], 1)], 2)], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "scrollable": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dlgImage,
      callback: function callback($$v) {
        _vm.dlgImage = $$v;
      },
      expression: "dlgImage"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v(_vm._s(_vm.activeImage.name))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.downloadFile(_vm.activeImage.href);
      }
    }
  }, [_c('v-icon', [_vm._v("get_app")])], 1), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeImage();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-img', {
    attrs: {
      "src": _vm.activeImage.preUrl,
      "aspect-ratio": "1",
      "contain": ""
    }
  })], 1), _vm.activeImage.creator ? _c('v-card-actions', [_c('v-subheader', [_vm._v(_vm._s(_vm.activeImage.creator._id === _vm.currentUser._id ? '我' : _vm.activeImage.creator.personal.name) + " 于 " + _vm._s(_vm._f("formatTime")(_vm.activeImage.createTime)) + " 上传")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.downloadFile(_vm.activeImage.href);
      }
    }
  }, [_vm._v("下载")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeImage();
      }
    }
  }, [_vm._v("关闭")])], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }