var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.editAssetInfo();
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "accordion": "",
      "multiple": ""
    }
  }, _vm._l(_vm.editedList, function (item, i) {
    return _c('v-expansion-panel', {
      key: i
    }, [_c('v-expansion-panel-header', [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [item.product.product_type && item.product.product_type.noSN ? _c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_vm._v(_vm._s(item.name))]) : _c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_vm._v(_vm._s(item.name) + " - " + _vm._s(item.serial_number))]), _c('v-col', {
      staticClass: "text--secondary",
      attrs: {
        "cols": "8"
      }
    }, [_vm._v(_vm._s(item.product.name))])], 1)], 1), _c('v-expansion-panel-content', _vm._l(item.edit_fields, function (field) {
      return _c('v-row', {
        key: field.field,
        attrs: {
          "align": "center"
        }
      }, [_c('v-col', {
        staticClass: "text-right",
        attrs: {
          "cols": "2"
        }
      }, [_vm._v(_vm._s(field.name))]), _c('v-col', {
        staticClass: "text--secondary",
        attrs: {
          "cols": "8"
        }
      }, [field.field === 'asset_from' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "hide-details": "",
          "outlined": "",
          "dense": "",
          "placeholder": "设备获得方式"
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'mac_address' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "hide-details": "",
          "outlined": "",
          "dense": "",
          "hint": "请输入设备的MAC地址"
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'imei_number' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "hide-details": "",
          "outlined": "",
          "dense": "",
          "hint": "请输入设备的IMEI编号"
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'iccid_code' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "hide-details": "",
          "outlined": "",
          "dense": "",
          "hint": "请输入设备的ICCID串号"
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'sim_code' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "hide-details": "",
          "outlined": "",
          "dense": "",
          "hint": "请输入设备的SIM卡号"
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'apple_id' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "hide-details": "",
          "outlined": "",
          "dense": "",
          "hint": "请输入登录Apple的用户名称"
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'apple_password' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "hide-details": "",
          "outlined": "",
          "dense": "",
          "hint": "请输入对应Apple ID的用户名的密码"
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'mdm_token' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "hide-details": "",
          "outlined": "",
          "dense": "",
          "hint": "请输入MDM的Token信息"
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'mdm_account' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "hide-details": "",
          "outlined": "",
          "dense": "",
          "hint": "请输入MDM的账号信息"
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'serial_number' ? _c('v-text-field', {
        attrs: {
          "rules": [_vm.rules.required],
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "placeholder": "请输入新的序列号",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'label' ? _c('v-combobox', {
        attrs: {
          "label": field.name,
          "placeholder": "使用回车键可以输入多个标签。如内部设备代码、编码等信息",
          "disabled": _vm.disabled,
          "hide-details": "",
          "deletable-chips": "",
          "multiple": "",
          "small-chips": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'out_number' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "placeholder": "请输入新的序列号",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'product' ? _c('v-autocomplete', {
        staticClass: "my-2",
        attrs: {
          "autocomplete": "off",
          "items": _vm.productList,
          "disabled": _vm.disabled,
          "label": "产品",
          "placeholder": "选择产品型号",
          "hide-details": "",
          "item-text": "name",
          "item-value": "_id",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }, {
          key: "item",
          fn: function fn(data) {
            return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name) + " - " + _vm._s(data.item.part_number))]), data.item.it_info ? _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.it_info.ram) + "G - " + _vm._s(data.item.it_info.network))]) : _vm._e()], 1)];
          }
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'purchase_time' ? _c('v-text-field', {
        directives: [{
          name: "mask",
          rawName: "v-mask",
          value: '####-##-##',
          expression: "'####-##-##'"
        }],
        attrs: {
          "rules": [_vm.rules.date],
          "label": field.name,
          "placeholder": "YYYY-MM-DD",
          "disabled": _vm.disabled,
          "type": "text",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'mdm_password' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "type": "text",
          "label": field.name,
          "placeholder": "请输入MDM密码",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'state' ? _c('v-autocomplete', {
        attrs: {
          "autocomplete": "off",
          "items": _vm.stateList,
          "label": field.name,
          "disabled": _vm.disabled,
          "placeholder": "选择设备状态",
          "item-text": "name",
          "item-value": "_id",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'asset_state' ? _c('v-autocomplete', {
        attrs: {
          "autocomplete": "off",
          "items": _vm.assetState,
          "label": field.name,
          "disabled": _vm.disabled,
          "multiple": "",
          "placeholder": "选择设备情况",
          "item-text": "name",
          "item-value": "code",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'isABM' ? _c('v-autocomplete', {
        attrs: {
          "autocomplete": "off",
          "items": [{
            name: '是',
            code: true
          }, {
            name: '否',
            code: false
          }],
          "label": field.name,
          "disabled": _vm.disabled,
          "hint": "请选择设备是否ABM",
          "item-text": "name",
          "item-value": "code",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'sim_verified' ? _c('v-autocomplete', {
        attrs: {
          "autocomplete": "off",
          "items": [{
            name: '是',
            code: true
          }, {
            name: '否',
            code: false
          }],
          "label": field.name,
          "disabled": _vm.disabled,
          "hint": "请选择SIM卡是否实名",
          "item-text": "name",
          "item-value": "code",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'warehouse' ? _c('v-autocomplete', {
        attrs: {
          "items": _vm.warehouseList,
          "item-text": "name",
          "item-value": "_id",
          "autocomplete": "off",
          "label": field.name,
          "disabled": _vm.disabled,
          "hint": "请选择设备所在仓库",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }, {
          key: "item",
          fn: function fn(data) {
            return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name) + " - " + _vm._s(data.item.center_code))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.address))])], 1)];
          }
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'warehouse_time' ? _c('v-text-field', {
        directives: [{
          name: "mask",
          rawName: "v-mask",
          value: '####-##-##',
          expression: "'####-##-##'"
        }],
        attrs: {
          "label": field.name,
          "placeholder": "YYYY-MM-DD",
          "disabled": _vm.disabled,
          "type": "text",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'warranty_deadline' ? _c('v-text-field', {
        directives: [{
          name: "mask",
          rawName: "v-mask",
          value: '####-##-##',
          expression: "'####-##-##'"
        }],
        attrs: {
          "rules": [_vm.rules.date],
          "label": field.name,
          "placeholder": "YYYY-MM-DD",
          "disabled": _vm.disabled,
          "type": "text",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'warranty_time' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "hide-details": "",
          "suffix": "月",
          "type": "number",
          "label": field.name,
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'guaranteed_limit' ? _c('v-text-field', {
        attrs: {
          "disabled": _vm.disabled,
          "hide-details": "",
          "suffix": "月",
          "type": "number",
          "label": field.name,
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'guaranteed_time' ? _c('v-text-field', {
        directives: [{
          name: "mask",
          rawName: "v-mask",
          value: '####-##-##',
          expression: "'####-##-##'"
        }],
        attrs: {
          "rules": [_vm.rules.date],
          "label": field.name,
          "placeholder": "YYYY-MM-DD",
          "disabled": _vm.disabled,
          "type": "text",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'guaranteed_deadline' ? _c('v-text-field', {
        directives: [{
          name: "mask",
          rawName: "v-mask",
          value: '####-##-##',
          expression: "'####-##-##'"
        }],
        attrs: {
          "rules": [_vm.rules.date],
          "label": field.name,
          "placeholder": "YYYY-MM-DD",
          "disabled": _vm.disabled,
          "type": "text",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'guaranteed_type' ? _c('v-autocomplete', {
        attrs: {
          "autocomplete": "off",
          "items": _vm.guaranteedType,
          "label": field.name,
          "disabled": _vm.disabled,
          "item-text": "name",
          "item-value": "code",
          "hide-details": "",
          "outlined": "",
          "dense": "",
          "deletable-chips": "",
          "multiple": "",
          "small-chips": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'remarks' ? _c('v-textarea', {
        attrs: {
          "disabled": _vm.disabled,
          "label": field.name,
          "hide-details": "",
          "auto-grow": "",
          "rows": "3",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'rent_info.payment_day' ? _c('v-select', {
        attrs: {
          "items": Array.from({
            length: 28
          }, function (v, k) {
            return k + 1;
          }),
          "label": "客户付款日",
          "disabled": _vm.disabled,
          "hide-details": "",
          "outlined": "",
          "dense": "",
          "clearable": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'rent_info.lease_start' ? _c('v-text-field', {
        directives: [{
          name: "mask",
          rawName: "v-mask",
          value: '####-##-##',
          expression: "'####-##-##'"
        }],
        attrs: {
          "rules": [_vm.rules.date],
          "label": field.name,
          "placeholder": "YYYY-MM-DD",
          "disabled": _vm.disabled,
          "type": "text",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e(), field.field === 'rent_info.expiry_date' ? _c('v-text-field', {
        directives: [{
          name: "mask",
          rawName: "v-mask",
          value: '####-##-##',
          expression: "'####-##-##'"
        }],
        attrs: {
          "rules": [_vm.rules.date],
          "label": field.name,
          "placeholder": "YYYY-MM-DD",
          "disabled": _vm.disabled,
          "type": "text",
          "hide-details": "",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.editAssetInfo();
          }
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function fn() {
            return [_c('v-btn', {
              attrs: {
                "text": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.copyValueAll(field.field, field.value);
                }
              }
            }, [_c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v("content_copy")]), _vm._v("工单内所有设备使用此值")], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: field.value,
          callback: function callback($$v) {
            _vm.$set(field, "value", $$v);
          },
          expression: "field.value"
        }
      }) : _vm._e()], 1), _c('v-col', {
        attrs: {
          "cols": "2"
        }
      })], 1);
    }), 1)], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }