<template>
  <v-form v-model="valid" @submit.prevent="editAssetInfo()">
    <v-row justify="center">
      <v-expansion-panels accordion multiple>
        <v-expansion-panel v-for="(item,i) in editedList" :key="i">
          <v-expansion-panel-header>
            <v-row no-gutters>
              <v-col cols="4" v-if="item.product.product_type && item.product.product_type.noSN">{{item.name}}</v-col>
              <v-col cols="4" v-else>{{item.name}} - {{ item.serial_number }}</v-col>
              <v-col cols="8" class="text--secondary">{{item.product.name}}</v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row align="center" v-for="field in item.edit_fields" :key="field.field">
              <v-col cols="2" class="text-right">{{field.name}}</v-col>
              <v-col cols="8" class="text--secondary">
                <v-text-field
                  v-if="field.field === 'asset_from'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  hide-details
                  outlined
                  dense
                  placeholder="设备获得方式"
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'mac_address'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  hide-details
                  outlined
                  dense
                  hint="请输入设备的MAC地址"
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'imei_number'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  hide-details
                  outlined
                  dense
                  hint="请输入设备的IMEI编号"
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'iccid_code'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  hide-details
                  outlined
                  dense
                  hint="请输入设备的ICCID串号"
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'sim_code'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  hide-details
                  outlined
                  dense
                  hint="请输入设备的SIM卡号"
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'apple_id'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  hide-details
                  outlined
                  dense
                  hint="请输入登录Apple的用户名称"
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'apple_password'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  hide-details
                  outlined
                  dense
                  hint="请输入对应Apple ID的用户名的密码"
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'mdm_token'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  hide-details
                  outlined
                  dense
                  hint="请输入MDM的Token信息"
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'mdm_account'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  hide-details
                  outlined
                  dense
                  hint="请输入MDM的账号信息"
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'serial_number'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :rules="[rules.required]"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  placeholder="请输入新的序列号"
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
                <v-combobox
                  v-if="field.field === 'label'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :label="field.name"
                  placeholder="使用回车键可以输入多个标签。如内部设备代码、编码等信息"
                  :disabled="disabled"
                  hide-details
                  deletable-chips
                  multiple
                  small-chips
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-combobox>
                <v-text-field
                  v-if="field.field === 'out_number'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  placeholder="请输入新的序列号"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-autocomplete
                  class="my-2"
                  v-if="field.field === 'product'"
                  autocomplete="off"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :items="productList"
                  :disabled="disabled"
                  label="产品"
                  placeholder="选择产品型号"
                  hide-details
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.name}} - {{data.item.part_number}}</v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.it_info">{{data.item.it_info.ram}}G - {{data.item.it_info.network}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-if="field.field === 'purchase_time'"
                  :rules="[rules.date]"
                  v-model="field.value"
                  v-mask="'####-##-##'"
                  :label="field.name"
                  placeholder="YYYY-MM-DD"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'mdm_password'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  :label="field.name"
                  placeholder="请输入MDM密码"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-autocomplete
                  v-if="field.field === 'state'"
                  autocomplete="off"
                  v-model="field.value"
                  :items="stateList"
                  :label="field.name"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  placeholder="选择设备状态"
                  item-text="name"
                  item-value="_id"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-if="field.field === 'asset_state'"
                  autocomplete="off"
                  v-model="field.value"
                  :items="assetState"
                  :label="field.name"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  multiple
                  placeholder="选择设备情况"
                  item-text="name"
                  item-value="code"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-if="field.field === 'isABM'"
                  autocomplete="off"
                  v-model="field.value"
                  :items="[{ name: '是', code: true },{ name: '否', code: false }]"
                  :label="field.name"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  hint="请选择设备是否ABM"
                  item-text="name"
                  item-value="code"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-autocomplete>

                <v-autocomplete
                  v-if="field.field === 'sim_verified'"
                  autocomplete="off"
                  v-model="field.value"
                  :items="[{ name: '是', code: true },{ name: '否', code: false }]"
                  :label="field.name"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  hint="请选择SIM卡是否实名"
                  item-text="name"
                  item-value="code"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-if="field.field === 'warehouse'"
                  v-model="field.value"
                  :items="warehouseList"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  :label="field.name"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  hint="请选择设备所在仓库"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.name}} - {{data.item.center_code}}</v-list-item-title>
                      <v-list-item-subtitle>{{data.item.address}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-if="field.field === 'warehouse_time'"
                  v-model="field.value"
                  v-mask="'####-##-##'"
                  :label="field.name"
                  placeholder="YYYY-MM-DD"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'warranty_deadline'"
                  :rules="[rules.date]"
                  v-model="field.value"
                  v-mask="'####-##-##'"
                  :label="field.name"
                  placeholder="YYYY-MM-DD"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'warranty_time'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  hide-details
                  suffix="月"
                  type="number"
                  :label="field.name"
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'guaranteed_limit'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  hide-details
                  suffix="月"
                  type="number"
                  :label="field.name"
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'guaranteed_time'"
                  :rules="[rules.date]"
                  v-model="field.value"
                  v-mask="'####-##-##'"
                  :label="field.name"
                  placeholder="YYYY-MM-DD"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'guaranteed_deadline'"
                  :rules="[rules.date]"
                  v-model="field.value"
                  v-mask="'####-##-##'"
                  :label="field.name"
                  placeholder="YYYY-MM-DD"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-autocomplete
                  v-if="field.field === 'guaranteed_type'"
                  autocomplete="off"
                  v-model="field.value"
                  :items="guaranteedType"
                  :label="field.name"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  item-text="name"
                  item-value="code"
                  hide-details
                  outlined
                  dense
                  deletable-chips
                  multiple
                  small-chips
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-autocomplete>
                <v-textarea
                    v-if="field.field === 'remarks'"
                    v-model="field.value"
                    @change="editAssetInfo()"
                    :disabled="disabled"
                    :label="field.name"
                    hide-details
                    auto-grow
                    rows="3"
                    outlined
                    dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-textarea>
                <v-select
                  v-if="field.field === 'rent_info.payment_day'"
                  v-model="field.value"
                  @change="editAssetInfo()"
                  :items="Array.from({ length: 28 }, (v, k) => k + 1)"
                  label="客户付款日"
                  :disabled="disabled"
                  hide-details
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-select>
                <v-text-field
                  v-if="field.field === 'rent_info.lease_start'"
                  :rules="[rules.date]"
                  v-model="field.value"
                  v-mask="'####-##-##'"
                  :label="field.name"
                  placeholder="YYYY-MM-DD"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-if="field.field === 'rent_info.expiry_date'"
                  :rules="[rules.date]"
                  v-model="field.value"
                  v-mask="'####-##-##'"
                  :label="field.name"
                  placeholder="YYYY-MM-DD"
                  @change="editAssetInfo()"
                  :disabled="disabled"
                  type="text"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn text @click="copyValueAll(field.field, field.value)"><v-icon left>content_copy</v-icon>工单内所有设备使用此值</v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-form>
</template>
<script>
import store from "@/store";
import {
  FETCH_ASSET,
  FETCH_ASSETSTATE_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCTTYPE_LIST,
  FETCH_VENDOR_LIST, FETCH_WAREHOUSE_LIST
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import Util from "@/common/util";

export default {
  props: {
    assetList: {
      type: Array,
      required: true,
    },
    assetField: {
      type: Array,
    },
    saveField: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        date: value => {
          const pattern = /^[0-9]{4}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$/;
          return pattern.test(value) || '请输入正确的日期 如2000-01-01'
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      valid: true,
      editedList: [],
      editedItem: {
        purchase_time: false
      },
    }
  },
  created() {
    this.initialize()
  },
  computed: {
    ...mapGetters([
      "stateList",
      "vendorList",
      "typeList",
      "productList",
      "warehouseList",]),
    assetState () {
      return Util.categories('assetState')
    },
    guaranteedType () {
      return Util.categories('guaranteedType')
    },
    rentType() {
      return Util.categories("rentType");
    },
  },
  methods: {
    async initialize() {

      const [asset_list] = await Promise.all([
        makeEditedAsset(this.assetList, this.assetField, this.saveField),
        store.dispatch(FETCH_ASSETSTATE_LIST, 'isEnable=true'),
        store.dispatch(FETCH_PRODUCTTYPE_LIST, 'isEnable=true'),
        store.dispatch(FETCH_VENDOR_LIST, 'limit=-1&isEnable=true'),
        store.dispatch(FETCH_PRODUCT_LIST, 'isEnable=true&limit=200'),
        store.dispatch(FETCH_WAREHOUSE_LIST, 'isEnable=true'),
      ])
      this.editedList = asset_list
      this.$forceUpdate()
    },
    getAssetList(ids = []) {
      return Promise.all(ids.map(async (id) => {
        if (!id) return null
        const info = await store.dispatch(FETCH_ASSET, id)
        return info;
      }))
    },
    async editAssetInfo() {
      let editInfo = this.editedList.map(item => {
        return {
          assetId: item._id,
          fields: item.edit_fields.map(field => {
            if (field.field === 'product') {
              if (typeof field.value === "object") field.value = field.value._id
              if (typeof field.value.vendor === "object") field.value.vendor = field.value.vendor._id
              if (typeof field.value.product_type === "object") field.value.product_type = field.value.product_type._id
            }
            return {
              field: field.field,
              value: field.value
            }
          })
        }
      })
      this.$emit('Update', editInfo)
    },
    copyValueAll(field, value) {
      if (this.editedList && this.editedList.length) {
        for (let asset of this.editedList) {
          for (let f of asset.edit_fields) {
            if (f.field === field) f.value = value
          }
        }
      }
    }
  }
}
function makeEditedAsset(assetList = [], fieldList = [], savedFields = []) {
  return new Promise((resolve) => {
    let arrFields = []
    Promise.all(assetList.map(async (asset) => {
      if (asset) {
        let objAsset = await store.dispatch(FETCH_ASSET, asset)
        let objFieldValue = await makeFieldValue(objAsset, fieldList, savedFields)
        let objReturn = Object.assign({ edit_fields: objFieldValue}, objAsset )
        await arrFields.push(objReturn)
      }
    }))
    resolve(arrFields)
  })
}
function makeFieldValue(asset = {}, fieldList = [], savedFields = []) {
  return new Promise((resolve) => {
    let objEditFields = []
    Promise.all(fieldList.map(async (field) => {
      // console.log('field =>'+ field.name)
      if (field) {
        let strValue = ''
        let saveAsset = savedFields.find(item => item.assetId === asset._id)
        if (saveAsset) {
          strValue = saveAsset.fields.find(item => item.field === field.field)
          strValue = strValue !== null && strValue !== undefined ? strValue.value : ''
        } else {
          if (field.field.includes('.')) {
            const arrField = field.field.split('.')
            if (asset[arrField[0]]) {
              strValue = asset[arrField[0]][arrField[1]]
            } else {
              strValue = ''
            }
          } else {
            strValue = asset[field.field]
          }
        }
        await objEditFields.push(Object.assign({ value: strValue }, field ))
      }
    }))
    resolve(objEditFields)
  })
}
</script>
<style scoped>
</style>
