import { EnterpriseService } from "@/common/http";
import {
  CHECK_ENTERPRISE_CODE,
  FETCH_ENTERPRISE_LIST,
  FETCH_ENTERPRISE,
  PUBLISH_ENTERPRISE,
  EDIT_ENTERPRISE,
  AUTH_ENTERPRISE,
  BLOCK_ENTERPRISE,
  DELETE_ENTERPRISE,
} from "./actions.type";
import {
  SET_ERROR,
  LOAD_ENTERPRISES,
  SET_ENTERPRISE_LIST,
  SET_ENTERPRISE,
} from "./mutations.type"

const state = {
  enterpriseLoading: false,
  enterpriseList: [],
  enterpriseCount: 0,
  enterprise: {},
};

const getters = {
  enterprise: state => state.enterprise,
  enterpriseList: state => state.enterpriseList,
  enterpriseCount: state => state.enterpriseCount,
  enterpriseLoading: state => state.enterpriseLoading,
};

const actions = {
  [CHECK_ENTERPRISE_CODE](context, code) {
    return new Promise((resolve, reject) => {
      EnterpriseService.get("/check/invitecode", code)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        })
    });
  },
  [FETCH_ENTERPRISE_LIST]({ commit }, slug) {
    commit(LOAD_ENTERPRISES);
    return new Promise((resolve, reject) => {
      EnterpriseService.get('', 'list?'+slug)
        .then(({ data }) => {
          commit(SET_ENTERPRISE_LIST, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_ENTERPRISE]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      EnterpriseService.get('', slug)
        .then(({ data }) => {
          commit(SET_ENTERPRISE, data.enterprise);
          resolve(data.enterprise);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_ENTERPRISE](context, enterprise) {
    return EnterpriseService.create('', enterprise);
  },
  [EDIT_ENTERPRISE](context, enterprise) {
    return EnterpriseService.update(enterprise._id, enterprise);
  },
  [AUTH_ENTERPRISE](context, enterprise) {
    return EnterpriseService.auth(enterprise._id)
  },
  [BLOCK_ENTERPRISE](context, enterprise) {
    return EnterpriseService.block(enterprise._id, enterprise.reply)
  },
  [DELETE_ENTERPRISE](context, enterprise) {
    return EnterpriseService.destroy(enterprise._id)
  }
};

const mutations = {
  [LOAD_ENTERPRISES] (state) {
    state.enterpriseLoading = true;
  },
  [SET_ENTERPRISE_LIST](state, list) {
    state.enterpriseList = list.enterprise;
    state.enterpriseCount = list.query.count;
    state.enterpriseLoading = false;
  },
  [SET_ENTERPRISE](state, enterprise) {
    state.enterprise = enterprise;
    state.enterpriseLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
