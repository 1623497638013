import {RiskService} from "@/common/http";

import {
  FETCH_RISK_ENTGROUP_LIST,
  ADJUST_RISK_ENTGROUP_CREDITLINE,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_RISK_ENTGROUP_LIST,
  SET_RISK_ENTGROUP_LIST,
} from "./mutations.type"

const state = {
  riskEntGroupLoading: true,
  riskEntGroupList: [],
  riskEntGroupCount: 0,
  riskEntGroup: {},
};

const getters = {
  riskEntGroupLoading: state => state.riskEntGroupLoading,
  riskEntGroupList: state => state.riskEntGroupList,
  riskEntGroupCount: state => state.riskEntGroupCount,
  riskEntGroup: state => state.riskEntGroup,
};

const actions = {
  [FETCH_RISK_ENTGROUP_LIST]({ commit }, slug) {
    commit(LOAD_RISK_ENTGROUP_LIST);
    return new Promise((resolve, reject) => {
      RiskService.get('/entgroup/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RISK_ENTGROUP_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [ADJUST_RISK_ENTGROUP_CREDITLINE](context, data) {
    return RiskService.create('/entgroup/adjustcreditline', data);
  },
};

const mutations = {
  [LOAD_RISK_ENTGROUP_LIST] (state) {
    state.riskEntGroupLoading = true;
  },
  [SET_RISK_ENTGROUP_LIST](state, list) {
    state.riskEntGroupCount = list.query.count;
    state.riskEntGroupList = list.docs;
    state.riskEntGroupLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

