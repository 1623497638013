var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "500"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": _vm.btnColor,
            "dark": "",
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("增加自定义字段")])];
      }
    }]),
    model: {
      value: _vm.dlgField,
      callback: function callback($$v) {
        _vm.dlgField = $$v;
      },
      expression: "dlgField"
    }
  }, [_c('v-card', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" 增加" + _vm._s(_vm._f("stringModule")(_vm.module)) + "字段 ")]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "字段名称",
      "hint": "字段名称提示用户输入内容",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedField.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedField, "name", $$v);
      },
      expression: "editedField.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.inputType,
      "rules": [_vm.rules.selected],
      "label": "输入形式",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedField.type,
      callback: function callback($$v) {
        _vm.$set(_vm.editedField, "type", $$v);
      },
      expression: "editedField.type"
    }
  })], 1)], 1), _vm.editedField.type === 'text' ? _c('v-row', {
    staticClass: "mt-n6"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "内容格式",
      "hint": "限定内容，为空即不限制任何输入",
      "append-outer-icon": "help_outline",
      "outlined": "",
      "dense": ""
    },
    on: {
      "click:append-outer": function clickAppendOuter($event) {
        return _vm.doAction('help_mask');
      }
    },
    model: {
      value: _vm.editedField.mask,
      callback: function callback($$v) {
        _vm.$set(_vm.editedField, "mask", $$v);
      },
      expression: "editedField.mask"
    }
  }), _c('v-btn', {
    staticClass: "mt-n6",
    attrs: {
      "text": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('set_mask', 'datatime');
      }
    }
  }, [_vm._v(" 日期时间 2016-12-12 20:32 ")]), _c('v-btn', {
    staticClass: "mt-n6",
    attrs: {
      "text": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('set_mask', 'time');
      }
    }
  }, [_vm._v(" 时间 05:23:12 ")]), _c('v-btn', {
    staticClass: "mt-n6",
    attrs: {
      "text": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('set_mask', 'telnumber');
      }
    }
  }, [_vm._v(" 国际电话 +086-01062011122 ")]), _c('v-btn', {
    staticClass: "mt-n6",
    attrs: {
      "text": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('set_mask', 'compose');
      }
    }
  }, [_vm._v(" 字母数字组合 CN001 ")])], 1)], 1) : _vm._e(), _vm.editedField.type === 'selects' ? _c('v-row', [_vm._l(_vm.editedField.items, function (item, i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-text-field', {
      staticClass: "mt-n6",
      attrs: {
        "type": "text",
        "label": "内容",
        "hint": "下拉菜单选项内容",
        "outlined": "",
        "dense": "",
        "append-outer-icon": "remove"
      },
      on: {
        "click:append-outer": function clickAppendOuter($event) {
          return _vm.doAction('remove_item', item);
        }
      },
      model: {
        value: _vm.editedField.items[i],
        callback: function callback($$v) {
          _vm.$set(_vm.editedField.items, i, $$v);
        },
        expression: "editedField.items[i]"
      }
    })], 1);
  }), _c('v-col', {
    staticClass: "text-center mt-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('add_item');
      }
    }
  }, [_c('v-icon', [_vm._v("add")]), _vm._v(" 增加下拉菜单内容 ")], 1)], 1)], 2) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.inputRules,
      "label": "输入验证",
      "hint": "请选择用户输入该字段时验证方式",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": ""
    },
    model: {
      value: _vm.editedField.rules,
      callback: function callback($$v) {
        _vm.$set(_vm.editedField, "rules", $$v);
      },
      expression: "editedField.rules"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeField
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }