import {RiskService} from "@/common/http";

import {
  INIT_RISK_CITY_LEVEL,
  FETCH_RISK_CITY_LEVEL_LIST,
  PUBLISH_RISK_CITY_LEVEL,
  FETCH_RISK_CITY_LEVEL,
  EDIT_RISK_CITY_LEVEL,
  DELETE_RISK_CITY_LEVEL,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_RISK_CITY_LEVEL_LIST,
  SET_RISK_CITY_LEVEL_LIST,
  SET_RISK_CITY_LEVEL,
} from "./mutations.type"

const state = {
  riskCityLevelLoading: true,
  riskCityLevelList: [],
  riskCityLevelCount: 0,
  riskCityLevel: {},
};

const getters = {
  riskCityLevelLoading: state => state.riskCityLevelLoading,
  riskCityLevelList: state => state.riskCityLevelList,
  riskCityLevelCount: state => state.riskCityLevelCount,
  riskCityLevel: state => state.riskCityLevel,
};

const actions = {
  [INIT_RISK_CITY_LEVEL]({ commit }) {
    return new Promise((resolve, reject) => {
      RiskService.get('/citylevel/initialize')
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          commit(SET_ERROR, { msg: response.data.message });
          reject(response.data.message);
        })
    })
  },
  [FETCH_RISK_CITY_LEVEL_LIST]({ commit }, slug) {
    commit(LOAD_RISK_CITY_LEVEL_LIST);
    return new Promise((resolve, reject) => {
      RiskService.get('/citylevel/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RISK_CITY_LEVEL_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_RISK_CITY_LEVEL]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      RiskService.get('/citylevel', slug)
        .then(({ data }) => {
          commit(SET_RISK_CITY_LEVEL, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_RISK_CITY_LEVEL](context, data) {
    return RiskService.create('/citylevel', data);
  },
  [EDIT_RISK_CITY_LEVEL](context, data) {
    return RiskService.update('/citylevel', data._id, data);
  },
  [DELETE_RISK_CITY_LEVEL](context, data) {
    return RiskService.delete('/citylevel/' + data._id);
  },
};

const mutations = {
  [LOAD_RISK_CITY_LEVEL_LIST] (state) {
    state.riskCityLevelLoading = true;
  },
  [SET_RISK_CITY_LEVEL_LIST](state, list) {
    state.riskCityLevelCount = list.query.count;
    state.riskCityLevelList = list.docs;
    state.riskCityLevelLoading = false;
  },
  [SET_RISK_CITY_LEVEL](state, riskCityLevel) {
    state.riskCityLevel = riskCityLevel;
    state.riskCityLevelLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

