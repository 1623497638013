<template>
  <v-container fluid no-gutters class="px-16">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-sheet :color="editBodyData.bg_color || 'transparent'">
          <v-card-title class="text-center justify-center py-6">
            <h1 :class="'font-weight-bold display-1 '+ editBodyData.color +'--text'">{{editBodyData.title || '服务内容'}}</h1>
          </v-card-title>
          <v-tabs
            v-if="serviceList && serviceList.length"
            background-color="transparent"
            icons-and-text
            :color="editBodyData.tab_color || 'primary'"
            grow
          >
            <v-tab
              v-for="item in editBodyData.services"
              :key="item">
              {{serviceList.find(service => service._id === item).name}}
              <v-icon>{{serviceList.find(service => service._id === item).icon}}</v-icon>
            </v-tab>
            <v-tab-item v-for="(item,i) in editBodyData.services" :key="i">
              <v-card flat :color="editBodyData.bg_color || 'transparent'">
                <v-card-text class="text-center">
                  <pre width="50%">{{serviceList.find(service => service._id === item).remarks}}</pre>
                  <v-btn text class="ma-6">了解详情 >></v-btn>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-fade-transition>
            <v-overlay v-if="hover" absolute class="align-end" style="top:100%" color="grey lighten-5">
              <v-btn class="ma-2" text color="primary" @click="editItem()"><v-icon left>edit</v-icon> 编辑</v-btn>
              <v-btn class="ma-2" text color="primary" @click="deleteItem()"><v-icon left>delete</v-icon> 删除</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-sheet>
      </template>
    </v-hover>
    <v-bottom-sheet v-model="dlgSetting" :retain-focus="false" eager>
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">设置</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    v-model="editBodyData.title"
                    :rules="[rules.required]"
                    type="text"
                    label="标题"
                    hint="显示在服务列表上面的标题"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="editBodyData.color"
                    :items="titleColor"
                    label="标题颜色"
                    autocomplete="off"
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small :color="item">
                        {{item | stringModule}}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip small :color="data.item">{{data.item | stringModule}}</v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="editBodyData.tab_color"
                    :items="tabColor"
                    label="选项卡选中后颜色"
                    autocomplete="off"
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small :color="item">
                        {{item | stringModule}}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip small :color="data.item">{{data.item | stringModule}}</v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <!--<v-switch v-model="editBodyData.isVertical" :label="`纵向显示标签: ${editBodyData.isVertical ? '是' : '否'}`"></v-switch>-->
                  <!--<v-btn text><v-icon>horizontal_split</v-icon> 横向</v-btn>-->
                  <!--<v-btn text><v-icon>vertical_split</v-icon> 纵向</v-btn>-->
                  <v-select
                    v-model="editBodyData.bg_color"
                    :items="bgColor"
                    label="整体背景色"
                    autocomplete="off"
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small :color="item">
                        {{item | stringModule}}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip small :color="data.item">{{data.item | stringModule}}</v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-item-group v-model="editBodyData.services" multiple>
                <v-row>
                  <v-col v-for="(item, i) in flatGroupCatalog(catalogList)" :key="i" cols="2">
                    <v-item v-slot="{ active, toggle }" :value="item._id">
                      <v-card outlined hover @click="toggle">
                        <v-card-title>{{item.name}}<v-spacer></v-spacer><v-icon>{{ active ? 'check_circle' : 'check_circle_outline' }}</v-icon></v-card-title>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>

            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">关闭</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-bottom-sheet>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_PAGE,
  EDIT_BODY_ELEMENT,
  DELETE_BODY_ELEMENT,
  FETCH_CATALOG_LIST,
} from "@/store/actions.type";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        }
      },
      dlgSetting: false,
      valid: true,
      editBodyData: {
        services: []
      },
      serviceList: [],
      titleColor: ['primary', 'secondary', 'white', 'transparent'],
      tabColor: ['primary', 'secondary'],
      bgColor: ['transparent', 'white', '#f5f5f5', 'primary', 'secondary'],
    }
  },
  computed: {
    ...mapGetters(["currentUser", "catalogList"]),
  },
  watch: {
    dlgSetting (val) {
      val || this.close()
    },
  },
  created() {
    this.getPageInfo()
  },
  mounted () {},
  methods: {
    getPageInfo(component = 'body') {
      this.$store.dispatch(FETCH_PAGE, '?name=home&component=' + component +'&owner='+ this.currentUser.employer.ownerId._id)
        .then(data => {
          this.$store.dispatch(FETCH_CATALOG_LIST)
            .then(({catalog}) => {
              this.serviceList = this.flatGroupCatalog(catalog)
            })
          this.editBodyData = Object.assign({_id: data._id}, data.config[this.index])
          if (!this.editBodyData.services) this.editBodyData.services = []
          if (!this.editBodyData.type) this.editBodyData.type = 'service'
        });
    },
    close() {
      this.dlgSetting = false
      this.getPageInfo('body')
    },
    editItem() {
      this.$store.dispatch(FETCH_CATALOG_LIST)
        .then(({catalog}) => {
          this.serviceList = this.flatGroupCatalog(catalog)
        })
      this.dlgSetting = true

    },
    submit(isClose = true) {
      this.$store.dispatch(EDIT_BODY_ELEMENT, {_id: this.id, index: this.index, config: [this.editBodyData]})
        .then(() => {
          this.$emit('UpdateItem')
          this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
          if (isClose) this.close();
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    deleteItem() {
      this.$store.dispatch(DELETE_BODY_ELEMENT, {_id: this.id, index: this.index})
        .then(() => {
          this.$emit('DeleteItem')
          this.getPageInfo('body')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    flatGroupCatalog(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        groupItem.children.forEach((catalogItem) => {
          arrRoles.push({ _id: catalogItem._id, name: catalogItem.name, icon: catalogItem.profileIcon, remarks: catalogItem.remarks, group: groupItem.name })
        })
      })
      return arrRoles
    },
  },
  destroyed () {}
}
</script>
<style scoped>
  .show_upload {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
  .v-dialog__content {z-index: 203 !important;}
  pre {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
</style>
