var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.itemData ? _c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("企业")]), _vm.itemData.enterprise ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_c('strong', {
    staticClass: "v-list-item--link",
    on: {
      "click": function click($event) {
        return _vm.openEnterpriseInfo(_vm.itemData.enterprise._id);
      }
    }
  }, [_vm._v(_vm._s(_vm.itemData.enterprise && _vm.itemData.enterprise.name) + " " + _vm._s(_vm.itemData.branch ? '-' + _vm.itemData.branch : '')), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v("read_more")])], 1)]) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("订单编号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.itemData.order_id && _vm.itemData.order_id.order_number))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("企业PO号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.itemData.order_id && _vm.itemData.order_id.po_number))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("订单创建")]), _vm.itemData.order_id ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm._f("formatTime")(_vm.itemData.order_id.createTime)))])]) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("订单处理")]), _vm.itemData.order_id && _vm.itemData.order_id.creator ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('widgets-employee-dialogs', {
    attrs: {
      "account": _vm.itemData.order_id.creator._id
    }
  }, [_c('strong', {
    staticClass: "v-list-item--link"
  }, [_vm._v(" " + _vm._s(_vm.itemData.order_id.creator.personal ? _vm.itemData.order_id.creator.personal.name : "*未设置姓名") + " "), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v("read_more")])], 1)])], 1) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("销售")]), _vm.itemData.order_id && _vm.itemData.order_id.sales ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('widgets-employee-dialogs', {
    attrs: {
      "account": _vm.itemData.order_id.sales._id
    }
  }, [_c('strong', {
    staticClass: "v-list-item--link"
  }, [_vm._v(" " + _vm._s(_vm.itemData.order_id.sales.personal ? _vm.itemData.order_id.sales.personal.name : "*未设置姓名") + " "), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v("read_more")])], 1)])], 1) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("商务")]), _vm.itemData.pre_case && _vm.itemData.pre_case.order_assigner ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('widgets-employee-dialogs', {
    attrs: {
      "account": _vm.itemData.pre_case.order_assigner._id
    }
  }, [_c('strong', {
    staticClass: "v-list-item--link"
  }, [_vm._v(" " + _vm._s(_vm.itemData.pre_case.order_assigner.personal ? _vm.itemData.pre_case.order_assigner.personal.name : "*未设置姓名") + " "), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v("read_more")])], 1)])], 1) : _vm._e(), _vm.itemData.case_info ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("服务工单")]) : _vm._e(), _vm.itemData.case_info ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.itemData.case_info.case_number))])]) : _vm._e(), _vm.itemData.status === 2 ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("取消说明")]) : _vm._e(), _vm.itemData.status === 2 ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm.itemData.explain ? _c('strong', [_c('pre', [_vm._v(_vm._s(_vm.itemData.explain))])]) : _c('strong', [_vm._v("无")])]) : _vm._e(), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "600px",
      "persistent": ""
    },
    model: {
      value: _vm.dlgEnterprise,
      callback: function callback($$v) {
        _vm.dlgEnterprise = $$v;
      },
      expression: "dlgEnterprise"
    }
  }, [_c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": _vm.closeEnterpriseInfo
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }