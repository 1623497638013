import { ReportService } from "@/common/http";
import {
  FETCH_REPORT_ASSET_COUNT,
  FETCH_REPORT_CASEGROUP_COUNT,
  FETCH_REPORT_ALL_COUNT,
  FETCH_REPORT_CASE_HISTORY,
  FETCH_REPORT_ASSET_HISTORY,
  FETCH_REPORT_ENTERPRISE_HISTORY,
  FETCH_REPORT_ACCOUNT_HISTORY,
  FETCH_REPORT_ORDER_HISTORY,
  FETCH_REPORT_CASE_COUNT_YEAR,
  FETCH_REPORT_CASE_STATUS,
  FETCH_REPORT_RENT_OFFER_STATUS,
  FETCH_REPORT_ORDER_PURCHASE_STATUS,
  FETCH_REPORT_PURCHASE_STATUS
} from "./actions.type";

const state = {};
const getters = {};

const actions = {
  async [FETCH_REPORT_ASSET_COUNT](context, slug) {
    const { data } = await ReportService.get('/asset/group/count', slug);
    return data;
  },

  async [FETCH_REPORT_CASEGROUP_COUNT](context, slug) {
    const { data } = await ReportService.get('/case/group/count', slug);
    return data;
  },

  async [FETCH_REPORT_ALL_COUNT](context, slug) {
    const { data } = await ReportService.get('/count', slug);
    return data;
  },

  async [FETCH_REPORT_CASE_HISTORY](context, slug) {
    const { data } = await ReportService.get('/history/case', slug);
    return data;
  },

  async [FETCH_REPORT_ASSET_HISTORY](context, slug) {
    const { data } = await ReportService.get('/history/asset', slug);
    return data;
  },

  async [FETCH_REPORT_ENTERPRISE_HISTORY](context, slug) {
    const { data } = await ReportService.get('/history/enterprise', slug);
    return data;
  },

  async [FETCH_REPORT_ACCOUNT_HISTORY](context, slug) {
    const { data } = await ReportService.get('/history/account', slug);
    return data;
  },

  async [FETCH_REPORT_ORDER_HISTORY](context, slug) {
    const { data } = await ReportService.get('/history/order', slug);
    return data;
  },

  async [FETCH_REPORT_CASE_COUNT_YEAR](context, slug) {
    const { data } = await ReportService.get('/group/year/case', slug);
    return data;
  },

  async [FETCH_REPORT_CASE_STATUS](context, slug) {
    const { data } = await ReportService.get('/duration/case', slug);
    return data;
  },

  async [FETCH_REPORT_RENT_OFFER_STATUS](context, slug) {
    const { data } = await ReportService.get('/rent/offer/count', slug);
    return data;
  },

  async [FETCH_REPORT_ORDER_PURCHASE_STATUS](context, slug) {
    const { data } = await ReportService.get('/order/purchase/count', slug);
    return data;
  },
  async [FETCH_REPORT_PURCHASE_STATUS](context, slug) {
    const { data } = await ReportService.get('/purchase/count', slug);
    return data;
  }

};

const mutations = {
};

export default {
  state,
  actions,
  mutations,
  getters
};

