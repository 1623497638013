var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "timer"
  }, [_c('div', {
    ref: "startTimer"
  }, [_vm._v("00 天 00:00:00")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }