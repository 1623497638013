import { DealerService } from "@/common/http";
import {
  FETCH_DEALER_LIST,
  FETCH_DEALER,
  PUBLISH_DEALER,
  EDIT_DEALER,
  AUTH_DEALER,
  BLOCK_DEALER,
  BOOKMARK_DEALER,
  DELETE_DEALER,
  CHECK_DEALER_CODE,
  FETCH_DEALER_ENTERPRISE,
} from "./actions.type";
import {
  SET_ERROR,
  GET_DEALERS,
  SET_DEALERS,
  SET_DEALER,
  SET_DEALER_ENTERPRISE,
} from "./mutations.type"

const state = {
  dealerLoading: true,
  dealers: [],
  dealer: {},
  dealer_city: [],
  dealer_enterprise: [],
};

const getters = {
  dealer: state => state.dealer,
  dealers: state => state.dealers,
  dealer_city: state => state.dealer_city,
  dealer_enterprise: state => state.dealer_enterprise,
  dealerLoading: state => state.dealerLoading,
};

const actions = {
  async [FETCH_DEALER_ENTERPRISE](context) {
    const { data } = await DealerService.get('/enterprise/list')
    context.commit(SET_DEALER_ENTERPRISE, data)
  },
  [CHECK_DEALER_CODE](context, code) {
    return new Promise((resolve, reject) => {
      DealerService.get("/check/invitecode", code)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        })
    });
  },
  [FETCH_DEALER_LIST]({ commit }, slug) {
    commit(GET_DEALERS);
    return DealerService.get('/list', '?'+slug)
      .then(({ data }) => {
        commit(SET_DEALERS, data.docs);
      })
      .catch(error => {
        commit(SET_ERROR, { msg: error });
      })
  },
  [FETCH_DEALER]({ commit }, slug) {
    commit(GET_DEALERS);
    return new Promise((resolve, reject) => {
      DealerService.get('', slug)
        .then(({ data }) => {
          commit(SET_DEALER, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_DEALER](context, dealer) {
    return new Promise((resolve, reject) => {
      DealerService.create('', dealer)
        .then(({ data }) => {
          context.commit(SET_DEALER, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [EDIT_DEALER](context, dealer) {
    return new Promise((resolve, reject) => {
      DealerService.update('', dealer._id, dealer)
        .then(({ data }) => {
          context.commit(SET_DEALER, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [AUTH_DEALER](context, update) {
    return new Promise((resolve, reject) => {
      DealerService.auth(update._id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.message);
        });
    });
  },
  [BLOCK_DEALER](context, update) {
    return new Promise((resolve, reject) => {
      DealerService.block(update._id, update.reply)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.message);
        });
    });
  },
  [BOOKMARK_DEALER](context, update) {
    return new Promise((resolve, reject) => {
      DealerService.bookmark(update._id, update.reply)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.message);
        });
    });
  },
  [DELETE_DEALER](context, dealer) {
    return new Promise((resolve, reject) => {
      DealerService.delete(dealer._id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  },
}

const mutations = {
  [GET_DEALERS] (state) {
    state.dealerLoading = true;
  },
  [SET_DEALERS](state, dealer) {
    state.dealers = dealer;
    state.dealer_city = [...new Set([].concat(...dealer.map(e => e.city)))];
    state.dealerLoading = false;
  },
  [SET_DEALER](state, dealer) {
    state.dealer = dealer;
    state.dealerLoading = false;
  },
  [SET_DEALER_ENTERPRISE](state, enterprise) {
    state.dealer_enterprise = enterprise
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
