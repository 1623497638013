var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-actions', [_c('v-subheader', [_vm._v("更新时间：" + _vm._s(_vm._f("formatTime")(_vm.caseData.updateTime)) + " ")]), _vm.caseData.status < 2 && _vm.caseData.accept_sla ? _c('v-subheader', [_vm._v(" 当前环节："), _c('widgets-baseTimer', {
    attrs: {
      "endTime": _vm.caseData.accept_sla.expectTime,
      "alertThreshold": _vm.caseData.accept_sla.sla_limit / 2,
      "warningThreshold": _vm.caseData.accept_sla.sla_limit,
      "type": "text"
    }
  })], 1) : _vm._e(), _vm.caseData.status < 2 && _vm.caseData.total_sla ? _c('v-subheader', [_vm._v(" 整体流程："), _c('widgets-baseTimer', {
    attrs: {
      "endTime": _vm.caseData.total_sla.expectTime,
      "alertThreshold": _vm.caseData.total_sla.sla_limit / 2,
      "warningThreshold": _vm.caseData.total_sla.sla_limit,
      "type": "text"
    }
  })], 1) : _vm._e(), _vm.caseData.express_code && _vm.caseData.express_number ? _c('v-subheader', [_c('case-express-info', {
    attrs: {
      "id": _vm.caseData._id,
      "from": "case"
    }
  }, [_vm._v("物流信息")])], 1) : _vm._e(), _c('v-spacer'), _vm.caseData.isMark ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.markCase(_vm.caseData._id, false);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("star")]), _vm._v("取消关注")], 1) : _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.markCase(_vm.caseData._id, true);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("star_outline")]), _vm._v("特别关注")], 1), _vm.caseData.order_id && _vm.caseData.link_asset && _vm.caseData.link_asset.length ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.exportAsset(_vm.caseData);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("save_alt")]), _vm._v("导出设备")], 1) : _vm._e(), _vm.caseData.isEdit ? _c('div', [_vm.caseData.status === 0 ? _c('div', [_c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('cancel', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("remove_circle")]), _vm._v("取消服务")], 1), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.editedTask.valid,
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('submit', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("check_circle")]), _vm._v("提交")], 1)], 1) : _vm._e(), _vm.caseData.status === 1 ? _c('div', [_vm.currentTask.task.actions.includes('hangup') ? _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("pause_circle_filled")]), _vm._v("挂起")], 1) : _vm._e(), _vm.currentTask.task.actions.includes('cancel') ? _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('cancel', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("remove_circle")]), _vm._v("取消服务")], 1) : _vm._e(), _vm.currentTask.task.actions.includes('assist') ? _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('assist', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("assistant_direction")]), _vm._v("协助")], 1) : _vm._e(), _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('reject', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("replay_circle_filled")]), _vm._v("驳回")], 1), _vm.currentTask.task.actions.includes('save') ? _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('save', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("save")]), _vm._v("保存")], 1) : _vm._e(), _vm.currentTask.task.actions.includes('complete') ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.editedTask.valid,
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('submit', _vm.caseData._id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("check_circle")]), _vm._v("提交")], 1) : _vm._e()], 1) : _vm._e()]) : _c('div', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v("关闭")])], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "350px"
    },
    model: {
      value: _vm.dlgAction,
      callback: function callback($$v) {
        _vm.dlgAction = $$v;
      },
      expression: "dlgAction"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("确认"), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.actionTitle))]), _vm._v("该服务请求？")]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.actionLabel) + " "), _vm.actionKey === 'goto_assist' ? _c('v-autocomplete', {
    staticClass: "mt-4",
    attrs: {
      "items": _vm.getDeptList(_vm.departments[0]),
      "item-text": "name",
      "item-value": "value",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择协助执行部门"
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_employee_list');
      }
    },
    model: {
      value: _vm.department,
      callback: function callback($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  }) : _vm._e(), _vm.actionKey === 'goto_assist' ? _c('v-autocomplete', {
    ref: "selectAccount",
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "协助人员",
      "hint": "请选择协助执行人员"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }], null, false, 59584339),
    model: {
      value: _vm.editor,
      callback: function callback($$v) {
        _vm.editor = $$v;
      },
      expression: "editor"
    }
  }) : _vm._e(), _vm.caseData.accept_sla && new Date() > new Date(_vm.caseData.accept_sla.expectTime) ? _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "label": "超时原因说明"
    },
    model: {
      value: _vm.action_remarks,
      callback: function callback($$v) {
        _vm.action_remarks = $$v;
      },
      expression: "action_remarks"
    }
  }) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('cancel_action');
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "loading": _vm.exeing
    },
    on: {
      "click": function click($event) {
        return _vm.doAction(_vm.actionKey, _vm.actionID);
      }
    }
  }, [_vm._v("确认")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }