export default [
  {
    path: '/admin/dealer',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/admin/dealer'
    ),
    name: 'Dealer',
    meta: {
      title: '经销商管理',
      icon: 'account_balance',
      roles: ['admin']
    }
  },
  {
    path: '/admin/dealer/edit/:id',
    name: 'Dealer_Edit',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/admin/dealer_edit'
    ),
    meta: {
      title: '经销商编辑',
      roles: ['admin']
    }
  },
  {
    path: '/admin/account',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/admin/account'
    ),
    name: 'Account',
    meta: {
      title: '账号管理',
      icon: 'supervisor_account',
      roles: ['admin']
    }
  }
]
