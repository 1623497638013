<template>
  <v-card>
    <v-card-title>
      选择图标
      <v-spacer></v-spacer>
      <v-text-field
        v-model="txtSearch"
        class="ma-2"
        hide-details
        clearable
        outlined
        dense
        label="搜索图标名称"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('Close')"><v-icon>close</v-icon></v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="height: 450px;">
      <v-container>
        <v-icon size="48" class="ma-2" v-for="icon in filteredIcons" :key="icon.id" @click="toggleSelectIcon(icon.id)">{{icon.id}}</v-icon>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('Close')">关闭</v-btn>
      <v-btn color="secondary" text @click="$emit('Select', iconSelected)"><v-icon left>{{iconSelected}}</v-icon> 确定</v-btn>
    </v-card-actions>
  </v-card>

</template>
<script>
export default {
  data () {
    return {
      icons: '',
      iconsMap: '',
      txtSearch: '',
      iconSelected: '',
    }
  },
  created() {
    this.getIconList()
  },
  computed: {
    filteredIcons() {
      if (!this.txtSearch) {
        return this.icons;
      }
      return this.icons.filter(icon => this.isNotFiltered(icon.id));
    }
  },
  methods: {
    getIconList() {
      return fetch("../static/fonts/MaterialIcons-Regular.json").then(res => res.json()).then(data => {
        this.iconsMap = data
        let icons = Object.keys(this.iconsMap);
        icons.sort();
        this.icons = Object.keys(data)
        this.icons.sort()
        this.icons = icons.map((icon) => {
          return {
            'id': icon,
            'codepoint': data[icon]
          }
        })
      })
    },
    isNotFiltered(iconId) {
      return this.txtSearch ? iconId.indexOf(this.txtSearch.trim()) >= 0 : true;
    },
    toggleSelectIcon(iconId) {
      this.iconSelected = iconId
    },
  }
}
</script>
