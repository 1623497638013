<template>
  <div>
    <span dark text class="v-list-item--link" @click="doAction('open_case')">
      <slot></slot>
    </span>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span>{{ offerData.title }}</span>
          <v-btn icon small @click="editOffer('title', '报价名称')">
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-chip label small v-if="offerData.status == '0'" class="ml-4"
            >已保存</v-chip
          >
          <v-chip
            label
            small
            color="primary"
            class="ml-4"
            v-else-if="offerData.status == '1'"
            >已审核</v-chip
          >
          <v-chip
            label
            small
            color="secondary"
            class="ml-4"
            v-else-if="offerData.status == '2'"
            >已更新</v-chip
          >
          <v-chip label small class="ml-4" v-else-if="offerData.status == '3'"
            >已过期</v-chip
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-expansion-panels multiple accordion flat v-model="offerPanels">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="panelTitle">基本信息</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-sheet class="mx-auto">
                  <v-row>
                    <v-col cols="1">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-img
                                src="/static/logo2x.png"
                                width="40"
                              ></v-img>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>客户</v-list-item-title>
                            <v-list-item-subtitle
                              v-if="offerData.enterprise"
                              class="v-list-item--link"
                            >
                              <span
                                @click="openEnterInfo(offerData.enterprise.id)"
                              >
                                {{ offerData.enterprise.name }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>

                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>产品及规格</v-list-item-title>
                            <v-list-item-subtitle v-if="offerData.product_list">
                              {{
                                offerData.product_list.length > 1
                                  ? "系列产品"
                                  : offerData.product_list[0].name
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item
                          @click="editOffer('rent_type', '租赁方式')"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              >租赁方式<v-icon x-small class="ml-2"
                                >edit</v-icon
                              ></v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              offerData.rent_type === 0
                                ? "融资租赁"
                                : "经营性租赁"
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item
                          @click="editOffer('lease_start', '起租时间')"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              >起租时间<v-icon x-small class="ml-2"
                                >edit</v-icon
                              ></v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{
                                offerData.lease_start | formatDate
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>销售人员</v-list-item-title>
                            <v-list-item-subtitle v-if="offerData.creator">
                              <widgets-employee-dialogs
                                :account="offerData.creator._id"
                                class="v-list-item--link"
                                >{{
                                  offerData.creator.personal
                                    ? offerData.creator.personal.name
                                    : "*未设置姓名"
                                }}<v-icon class="mx-2"
                                  >read_more</v-icon
                                ></widgets-employee-dialogs
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="1">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title> </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item
                          @click="editOffer('payment_period', '账期')"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              >账期<v-icon x-small class="ml-2"
                                >edit</v-icon
                              ></v-list-item-title
                            >
                            <v-list-item-subtitle
                              >{{
                                offerData.payment_period
                              }}天</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              官网价格
                              <span
                                class="grey--text ml-2 body-2"
                                v-if="offerData.product_list"
                              >
                                {{
                                  offerData.product_list.length > 1
                                    ? "系列产品"
                                    : "¥" +
                                      offerData.product_list[0].official_price
                                        .$numberDecimal
                                }}</span
                              >
                            </v-list-item-title>
                            <v-list-item-title>
                              采购价格
                              <span
                                class="grey--text ml-2 body-2"
                                v-if="offerData.product_list"
                              >
                                {{
                                  offerData.product_list.length > 1
                                    ? "系列产品"
                                    : "¥" +
                                      offerData.product_list[0].purchase_price
                                        .$numberDecimal
                                }}</span
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item @click="editOffer('lease_term', '租期')">
                          <v-list-item-content>
                            <v-list-item-title
                              >租期<v-icon x-small class="ml-2"
                                >edit</v-icon
                              ></v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{
                                offerData.lease_term / 365
                              }}年</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>结束时间</v-list-item-title>
                            <v-list-item-subtitle v-if="offerData.lease_start">
                              {{
                                new Date(
                                  new Date(offerData.lease_start).setFullYear(
                                    new Date(
                                      offerData.lease_start
                                    ).getFullYear() +
                                      offerData.lease_term / 365
                                  )
                                )
                                  .toISOString()
                                  .substr(0, 10)
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>核价助理</v-list-item-title>
                            <v-list-item-subtitle v-if="offerData.reviewer">
                              <widgets-employee-dialogs
                                :account="offerData.reviewer._id"
                                class="v-list-item--link"
                                >{{
                                  offerData.reviewer.personal
                                    ? offerData.reviewer.personal.name
                                    : "*未设置姓名"
                                }}<v-icon class="mx-2"
                                  >read_more</v-icon
                                ></widgets-employee-dialogs
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="1">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title> </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item
                          @click="editOffer('payment_day', '每月付款日')"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              >每月付款日<v-icon x-small class="ml-2"
                                >edit</v-icon
                              ></v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{ offerData.payment_day }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>产品总数量</v-list-item-title>
                            <v-list-item-subtitle>{{
                              offerData.quantity_total
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item
                          @click="editOffer('payment_cycle', '支付周期')"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              >支付周期<v-icon x-small class="ml-2"
                                >edit</v-icon
                              ></v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{
                                offerData.payment_cycle === 0 ? "月付" : "季付"
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>总期数</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ offerData.NPER }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="2">
                      <v-list subheader three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>核价过期时间</v-list-item-title>
                            <v-list-item-subtitle>
                              {{
                                offerData.expiry_date | formatDate
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <span class="panelTitle">产品列表</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="multheaders"
                  :items="linkedProduct"
                  item-key="product_id"
                  class="elevation-0 mt-4"
                  :items-per-page="100"
                  hide-default-footer
                >
                  <!-- <template v-slot:top>
                    <v-chip-group
                      v-model="headerChip"
                      active-class="secondary--text text--secondary-4"
                      mandatory
                    >
                      <v-chip label outlined>销售</v-chip>
                      <v-chip label outlined>核价助理</v-chip>
                      <v-chip label outlined>产品专员</v-chip>
                      <v-chip label outlined>管理员</v-chip>
                    </v-chip-group>
                  </template> -->
                  <template v-slot:top>
                    <v-btn
                      color="primary"
                      dark
                      small
                      class="ml-4"
                      @click="productDialog = true"
                    >
                      <v-icon small>link</v-icon>
                      关联产品
                    </v-btn>
                    <v-btn
                      color="primary"
                      dark
                      small
                      class="ml-4"
                      @click="editProInfo"
                    >
                      <v-icon small>add</v-icon>
                      新增产品
                    </v-btn>
                  </template>
                  <template v-slot:body.append>
                    <tr
                      class="grey lighten-3 secondary--text"
                      style="width: 100%"
                    >
                      <td>合计</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div class="pl-4">{{ offerData.quantity_total }}</div>
                      </td>
                      <!-- <td v-if="offerData.product_price_total">
                        ¥{{
                          Number(
                            offerData.product_price_total.$numberDecimal
                          ).toFixed(2)
                        }}
                      </td> -->
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td v-if="offerData.rent_price_sum_total">
                        ¥{{
                          Number(
                            offerData.rent_price_sum_total.$numberDecimal
                          ).toFixed(2) | formatMoney
                        }}
                      </td>
                      <td v-if="offerData.rent_price_total_total">
                        ¥{{
                          Number(
                            offerData.rent_price_total_total.$numberDecimal
                          ).toFixed(2) | formatMoney
                        }}
                      </td>
                      <td v-if="offerData.purchase_price_total">
                        <span class="pl-4">
                          ¥{{
                            Number(
                              offerData.purchase_price_total.$numberDecimal
                            ).toFixed(2) | formatMoney
                          }}
                        </span>
                      </td>
                      <td></td>
                      <td>
                        <span class="ml-6">
                          {{
                            Number(offerData.rent_ros_total * 100).toFixed(2)
                          }}%
                        </span>
                      </td>
                      <!-- <td>
                        <div class="pl-5">
                          {{
                            Number(offerData.ros_sales_total * 100).toFixed(2)
                          }}%
                        </div>
                      </td>
                      <td v-if="offerData.sales_total_total">
                        ¥{{
                          Number(
                            offerData.sales_total_total.$numberDecimal
                          ).toFixed(2)
                        }}
                      </td>
                      <td>
                        <div class="pl-5">
                          {{ Number(offerData.CPA_total * 100).toFixed(2) }}%
                        </div>
                      </td>
                      <td v-if="offerData.rent_convert_cash_total">
                        ¥{{
                          Number(offerData.rent_convert_cash_total).toFixed(2)
                        }}
                      </td> -->
                      <td>
                        <span>
                          {{ offerData.rentWin ? "租" : "买" }}
                        </span>
                        <v-btn
                          icon
                          small
                          @click="openCompareDlg('')"
                          class="ml-1"
                        >
                          <v-icon small color="blue">compare_arrows</v-icon>
                        </v-btn>
                      </td>
                      <td></td>
                      <td v-if="pLevel == 2 || pLevel == 3"></td>
                      <td v-if="pLevel == 2 || pLevel == 3"></td>
                      <td v-if="pLevel == 3"></td>
                      <td v-if="pLevel == 3 && offerData.rent_cost_unit_total">
                        ¥{{
                          Number(
                            offerData.rent_cost_unit_total.$numberDecimal
                          ).toFixed(2) | formatMoney
                        }}
                      </td>
                      <td v-if="pLevel == 3"></td>
                      <td v-if="pLevel == 3"></td>
                      <td v-if="pLevel == 3"></td>
                      <td v-if="pLevel == 3"></td>
                      <td v-if="pLevel == 3"></td>
                      <td v-if="pLevel == 2 || pLevel == 3">
                      </td>
                      <!-- <td v-if="pLevel == 3">
                      </td> -->
                      <!-- <td v-if="pLevel == 3"></td> -->
                    </tr>
                  </template>
                  <template v-slot:item.liyun="props">
                    <v-edit-dialog :return-value.sync="props.item.liyun">
                      <div>
                        <v-icon small class="mr-2" color="warning">edit</v-icon>
                        {{ props.item.liyun + "%" }}
                      </div>
                      <template v-slot:input>
                        <div class="mt-4 subtitle-1">修改：销售利润率</div>
                        <v-text-field
                          v-model="props.item.liyun"
                          label="利润率"
                          single-line
                          suffix="%"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- <template v-slot:item.vs="{ item }">
                    <v-btn icon small @click="openCompareDlg(item)">
                      <v-icon small color="blue">compare_arrows</v-icon>
                    </v-btn>
                  </template> -->
                  <template v-slot:item.remove="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          icon
                          @click="removeLinked(item)"
                        >
                          <v-icon>remove</v-icon>
                        </v-btn>
                      </template>
                      <span>移除</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <td
                      nowrap="true"
                      class="d-inline-block text-truncate"
                      width="180"
                      style="cursor: pointer"
                      @click="openProInfo(item.product_id)"
                    >
                      <v-icon small class="mr-1">open_with</v-icon>
                      {{ item.product_code || item.name }}
                    </td>
                  </template>
                  <template v-slot:item.official_price="{ item }">
                    <span
                      >¥{{
                        Number(item.official_price.$numberDecimal).toFixed(2) | formatMoney
                      }}</span
                    >
                  </template>
                  <template v-slot:item.product_price_unit="{ item }">
                    <span>{{
                      !item.isWarranty
                        ? "¥" +
                          Number(
                            item.product_price_unit.$numberDecimal
                          ).toFixed(2)
                        : "-"
                    }}</span>
                  </template>
                  <template v-slot:item.isWarranty="{ item }">
                    <span
                      class="v-list-item--link"
                      @click="editOffer('product_list', '是否延保', item)"
                    >
                      <v-icon x-small>edit</v-icon>
                      {{ item.isWarranty ? "是" : "否" }}
                    </span>
                  </template>
                  <template v-slot:item.quantity="{ item }">
                    <span
                      class="v-list-item--link"
                      @click="editOffer('product_list', '数量', item)"
                      ><v-icon x-small class="mr-1">edit</v-icon
                      >{{ item.quantity }}</span
                    >
                  </template>
                  <template v-slot:item.warranty_price_unit="{ item }">
                    <span
                      v-if="offerData.lease_term > 365"
                      class="v-list-item--link"
                      @click="editOffer('product_list', '延保单价', item)"
                      ><v-icon x-small class="mr-1">edit</v-icon
                      >¥{{
                        Number(item.warranty_price_unit.$numberDecimal).toFixed(
                          2
                        )
                      }}</span
                    >
                    <span
                      v-else
                      >¥{{
                        Number(item.warranty_price_unit.$numberDecimal).toFixed(
                          2
                        )
                      }}</span
                    >
                  </template>
                  <template v-slot:item.deposit_price="{ item }">
                    <span
                      class="v-list-item--link"
                      @click="editOffer('product_list', '保证金', item)"
                      ><v-icon x-small class="mr-1">edit</v-icon>¥{{
                        Number(item.deposit_price.$numberDecimal).toFixed(2) | formatMoney
                      }}</span
                    >
                  </template>
                  <template v-slot:item.rent_price_unit="{ item }">
                    <span>{{
                      item.isWarranty
                        ? "¥" +
                          Number(item.rent_price_unit.$numberDecimal).toFixed(2)
                        : "-"
                    }}</span>
                  </template>
                  <template v-slot:item.rent_price_sum="{ item }">
                    <span
                      >¥{{
                        Number(item.rent_price_sum.$numberDecimal).toFixed(2) | formatMoney
                      }}</span
                    >
                  </template>
                  <template v-slot:item.rent_price_total="{ item }">
                    <span
                      >¥{{
                        Number(item.rent_price_total.$numberDecimal).toFixed(2) | formatMoney
                      }}</span
                    >
                  </template>
                  <template v-slot:item.purchase_price="{ item }">
                    <span
                      @click="editOffer('product_list', '采购单价', item)"
                      class="v-list-item--link"
                      ><v-icon x-small class="mr-1">edit</v-icon>¥{{
                        Number(item.purchase_price.$numberDecimal).toFixed(2) | formatMoney
                      }}</span
                    >
                  </template>
                  <template v-slot:item.residual_values_unit="{ item }">
                    <span
                      class="v-list-item--link"
                      @click="editOffer('product_list', '到期余值', item)"
                      ><v-icon x-small class="mr-1">edit</v-icon>¥{{
                        Number(
                          item.residual_values_unit.$numberDecimal
                        ).toFixed(2) | formatMoney
                      }}</span
                    >
                  </template>
                  <template v-slot:item.rent_cost_unit="{ item }">
                    <span
                      >¥{{
                        Number(item.rent_cost_unit.$numberDecimal).toFixed(2) | formatMoney
                      }}</span
                    >
                  </template>
                  <template v-slot:item.ros_rents="{ item }">
                    <span
                      class="v-list-item--link"
                      @click="editOffer('product_list', '报价系数', item)"
                    >
                      <v-icon x-small class="mr-1">edit</v-icon>
                      {{ (Number(item.ros_rents) * 100).toFixed(2) }}%
                    </span>
                  </template>
                  <template v-slot:item.ros_sales="{ item }">
                    <span
                      class="v-list-item--link"
                      @click="editOffer('ros_sales', '销售利润率', item)"
                    >
                      <v-icon x-small class="mr-1">edit</v-icon>
                      {{ (Number(item.ros_sales) * 100).toFixed(2) }}%
                    </span>
                  </template>
                  <template v-slot:item.sales_total="{ item }">
                    <span
                      >¥{{
                        Number(item.sales_total.$numberDecimal).toFixed(2)
                      }}</span
                    >
                  </template>
                  <template v-slot:item.CPA="{ item }">
                    <span
                      class="v-list-item--link"
                      @click="editOffer('CPA', '客户资金ROE', item)"
                    >
                      <v-icon x-small class="mr-1">edit</v-icon>
                      {{ (Number(item.CPA) * 100).toFixed(2) }}%
                    </span>
                  </template>
                  <template v-slot:item.rent_convert_cash="{ item }">
                    <span
                      >¥{{ Number(item.rent_convert_cash).toFixed(2) }}</span
                    >
                  </template>
                  <template v-slot:item.rentWin="{ item }">
                    <span>{{ item.rentWin ? "租" : "买" }}</span>
                    <v-btn
                      icon
                      small
                      @click="openCompareDlg(item)"
                      class="ml-2"
                    >
                      <v-icon small color="blue">compare_arrows</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.max_recycle_price="{ item }">
                    <span
                      >¥{{
                        Number(item.max_recycle_price.$numberDecimal).toFixed(2)
                      }}</span
                    >
                  </template>
                  <template v-slot:item.cv_init="{ item }">
                    <span
                      class="v-list-item--link"
                      @click="editOffer('cv_init', '风控系数', item)"
                    >
                      <v-icon x-small class="mr-1">edit</v-icon>
                      {{ (Number(item.cv_init) * 100).toFixed(2) }}%
                    </span>
                  </template>
                  <template v-slot:item.cv_period="{ item }">
                    <span
                      class="v-list-item--link"
                      @click="editOffer('cv_period', '风险账期', item)"
                    >
                      <v-icon x-small class="mr-1">edit</v-icon>
                      {{ item.cv_period }}天
                    </span>
                  </template>
                  <template v-slot:item.APR="{ item }">
                    <span
                      class="v-list-item--link"
                      @click="editOffer('product_list', '资金成本', item)"
                    >
                      <v-icon x-small class="mr-1">edit</v-icon>
                      {{ (Number(item.APR) * 100).toFixed(2) }}%
                    </span>
                  </template>
                  <template v-slot:item.gross_profit_unit="{ item }">
                    <span
                      >¥{{ Number(item.gross_profit_unit).toFixed(2) }}</span
                    >
                  </template>
                  <template v-slot:item.residual_values_ratio="{ item }">
                    <span
                      >{{
                        (Number(item.residual_values_ratio) * 100).toFixed(2)
                      }}%</span
                    >
                  </template>
                  <template v-slot:item.rent_buy_ratio="{ item }">
                    <span
                      >{{
                        (Number(item.rent_buy_ratio) * 100).toFixed(2)
                      }}%</span
                    >
                  </template>
                  <template v-slot:item.equal_total_payment="{ item }">
                    <span
                      >¥{{
                        Number(item.equal_total_payment.$numberDecimal).toFixed(
                          2
                        )
                      }}</span
                    >
                  </template>
                  <template v-slot:item.difference_value="{ item }">
                    <span
                      >¥{{
                        Number(item.difference_value.$numberDecimal).toFixed(2)
                      }}</span
                    >
                  </template>
                  <template v-slot:item.IRR_composite="{ item }">
                    <span
                      >{{
                        (Number(item.IRR_composite) * 100).toFixed(2)
                      }}%</span
                    >
                  </template>
                  <template v-slot:item.IRR="{ item }">
                    <span>{{ (Number(item.IRR) * 100).toFixed(2) }}%</span>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" align="right">
                      <!-- {{ createIrrList(item.IRR_list) }} -->
                      <span
                        v-for="(num, i) in item.IRR_list"
                        :key="i"
                        class="ml-3"
                      >
                        <span
                          class="
                            d-inline-block
                            red
                            caption
                            white--text
                            secondary
                            lighten-3
                          "
                          style="
                            width: 20px;
                            height: 18px;
                            border-radius: 4px;
                            line-height: 18px;
                            text-align: center;
                          "
                          >{{ i + 1 }}</span
                        >
                        <span class="caption ml-1">{{
                          Number(num.$numberDecimal).toFixed(2)
                        }}</span>
                      </span>
                    </td>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <span class="panelTitle">付款计划表</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="payheaders"
                  :items="offerData.payment_list"
                  item-key="name"
                  class="elevation-0 mt-4"
                  hide-default-footer
                  :items-per-page="36"
                >
                  <template v-slot:item.startDate="{ item }">
                    <td nowrap="true">{{ item.startDate | formatDate }}</td>
                  </template>
                  <template v-slot:item.endDate="{ item }">
                    <td nowrap="true">{{ item.endDate | formatDate }}</td>
                  </template>
                  <template v-slot:item.paymentDate="{ item }">
                    <td nowrap="true">{{ item.paymentDate | formatDate }}</td>
                  </template>
                  <template v-slot:item.paymentAmount="{ item }">
                    <td nowrap="true">
                      ¥{{
                        Number(item.paymentAmount.$numberDecimal).toFixed(2) | formatMoney
                      }}
                    </td>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <span class="panelTitle">报价单</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="quotationHearder"
                  :items="quoteList"
                  :options.sync="optionsItem"
                  :server-items-length="rentQuoteSheetCount"
                  :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
                  item-key="version"
                  class="elevation-0 mt-4"
                  no-data-text="暂无报价单"
                >
                  <template v-slot:item.title="{ item }">
                    <td>
                      <widgets-quote-dialogs
                        :getId="item._id"
                        :key="item._id"
                        v-on:UpdateQuote="getQuoteList"
                      >
                        {{ item.title }}
                      </widgets-quote-dialogs>
                    </td>
                  </template>
                  <template v-slot:item.createTime="{ item }">
                    <td>{{ item.createTime | formatTime }}</td>
                  </template>
                  <template v-slot:item.isDownload="{ item }">
                    <td>
                      <v-chip
                        label
                        small
                        color="secondary"
                        v-if="!item.isDownload"
                        >未下载</v-chip
                      >
                      <v-chip label small v-else>已下载</v-chip>
                    </td>
                  </template>
                  <template v-slot:item.actions>
                    <v-btn icon>
                      <v-icon small>search</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="closeDialog"> 关闭 </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="editParams" v-if="pLevel === 3">
            修改参数
          </v-btn>
          <v-btn
            text
            @click="confirmApprove"
            v-if="
              pLevel > 0 &&
              offerData.expiry_date > new Date().toISOString() &&
              (offerData.status === 0 || offerData.status === 2)
            "
          >
            审核
          </v-btn>
          <v-btn
            color="secondary darken-1"
            text
            v-if="
              offerData.expiry_date > new Date().toISOString() &&
              offerData.status === 1
            "
            @click="openNewQuote"
          >
            生成报价单
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="comDialog" scrollable max-width="800px" persistent>
        <v-card>
          <v-card-title>
            <span>对比方案</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeComDlg">
              <v-icon>clear</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-0">
            <div ref="compareInfo" class="px-6">
              <div class="watermarkWrap" v-show="isClientV" ref="waterMark">
                <div
                  :style="`color:${textColor};transform: rotate(${rotate}deg);width:${wm_w}%;height:${wm_h}%`"
                  class="wm"
                  v-for="(i, index) in watermarkNum"
                  :key="index"
                >
                  {{ watermarkText }}
                </div>
              </div>
              <v-row>
                <v-col cols="2">
                  <v-list subheader three-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-img src="/static/logo2x.png" width="40"></v-img>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="5">
                  <v-list subheader three-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>客户</v-list-item-title>
                        <v-list-item-title
                          v-if="offerData.enterprise"
                          style="white-space: normal"
                        >
                          <span class="grey--text body-2">{{
                            offerData.enterprise.name
                          }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col cols="5">
                  <v-list subheader three-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>产品及规格</v-list-item-title>
                        <v-list-item-title>
                          <span class="grey--text body-2">{{
                            compareItem.name || "系列产品"
                          }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-list subheader three-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title> </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="5" v-if="!isClientV">
                  <v-list subheader three-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>产品总数量</v-list-item-title>
                        <v-list-item-subtitle>{{
                          compareItem.quantity
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="5">
                  <v-list subheader three-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          官网价格
                          <span
                            class="grey--text ml-2 body-2"
                            v-if="compareItem.official_price"
                            >¥{{
                              Number(
                                compareItem.official_price.$numberDecimal
                              ).toFixed(2)
                            }}</span
                          >
                          <span v-else class="grey--text ml-2 body-2"
                            >系列产品</span
                          >
                        </v-list-item-title>
                        <v-list-item-title v-if="!isClientV">
                          采购价格
                          <span
                            class="grey--text ml-2 body-2"
                            v-if="compareItem.purchase_price"
                            >¥{{
                              Number(
                                compareItem.purchase_price.$numberDecimal
                              ).toFixed(2)
                            }}</span
                          >
                          <span v-else class="grey--text ml-2 body-2"
                            >系列产品</span
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-card color="blue" dark class="fill-height">
                    <v-card-title class="font-weight-regular blue lighten-2">
                      销售方案
                      <v-chip
                        color="orange"
                        label
                        small
                        class="ml-2"
                        v-show="!compareItem.rentWin"
                        >Better One</v-chip
                      >
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list subheader dark color="blue">
                        <v-list-item v-if="!isClientV">
                          <v-list-item-content>
                            <v-list-item-title>销售利润率</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text class="subtitle-1"
                              >{{
                                compareItem.ros_sales
                              }}%</v-list-item-action-text
                            >
                          </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>销售报价</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text class="subtitle-1"
                              >¥{{
                                compareItem.sales_unit
                              }}</v-list-item-action-text
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                    <v-list subheader light class="footerList">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            isClientV ? "采购额机会成本" : "销售额（终值）"
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="subtitle-1"
                            >¥{{
                              Number(compareItem.purchase_price_final).toFixed(
                                2
                              )
                            }}</v-list-item-action-text
                          >
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="subtitle-1"
                            >----</v-list-item-action-text
                          >
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            isClientV ? "采购单价" : "销售额（现值）"
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text
                            class="subtitle-1"
                            v-if="compareItem.sales_total"
                            >¥{{
                              Number(
                                compareItem.sales_total.$numberDecimal
                              ).toFixed(2)
                            }}</v-list-item-action-text
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card color="secondary lighten-1" dark class="fill-height">
                    <v-card-title
                      class="font-weight-regular secondary lighten-2"
                    >
                      租赁方案
                      <v-chip
                        color="orange"
                        label
                        small
                        class="ml-2"
                        v-show="compareItem.rentWin"
                        >Better One</v-chip
                      >
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list subheader dark color="secondary lighten-1">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>租赁方式</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text class="subtitle-1">{{
                              compareItem.rent_type === 0
                                ? "融资租赁"
                                : "经营性租赁"
                            }}</v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>租期</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text class="subtitle-1"
                              >{{
                                offerData.lease_term / 365
                              }}年</v-list-item-action-text
                            >
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>支付周期</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text class="subtitle-1">{{
                              offerData.payment_cycle === 0 ? "月付" : "季付"
                            }}</v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>总期数</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text class="subtitle-1">{{
                              offerData.NPER
                            }}</v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item
                          v-if="!isClientV && compareItem.residual_values_unit"
                        >
                          <v-list-item-content>
                            <v-list-item-title>到期余值</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text
                              class="subtitle-1"
                              v-if="compareItem.residual_values_unit"
                              >¥{{
                                Number(
                                  compareItem.residual_values_unit
                                    .$numberDecimal
                                ).toFixed(2)
                              }}</v-list-item-action-text
                            >
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item
                          v-if="!isClientV && compareItem.max_recycle_price"
                        >
                          <v-list-item-content>
                            <v-list-item-title>最高残值</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text
                              class="subtitle-1"
                              v-if="compareItem.max_recycle_price"
                              >¥{{
                                Number(
                                  compareItem.max_recycle_price.$numberDecimal
                                ).toFixed(2)
                              }}</v-list-item-action-text
                            >
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="!isClientV">
                          <v-list-item-content>
                            <v-list-item-title>报价系数</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text class="subtitle-1"
                              >{{
                                compareItem.ros_rents
                              }}%</v-list-item-action-text
                            >
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>设备租金单价</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text
                              class="subtitle-1"
                              v-if="compareItem.product_price_unit"
                              >¥{{
                                Number(
                                  compareItem.product_price_unit.$numberDecimal
                                ).toFixed(2)
                              }}</v-list-item-action-text
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                    <v-list subheader light>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>设备总租金</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text
                            class="subtitle-1"
                            v-if="compareItem.rent_price_total"
                            >¥{{
                              Number(
                                compareItem.rent_price_total.$numberDecimal
                              ).toFixed(2)
                            }}</v-list-item-action-text
                          >
                        </v-list-item-action>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>客户资金ROE</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="subtitle-1"
                            >{{ compareItem.CPA }}%</v-list-item-action-text
                          >
                        </v-list-item-action>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>设备总租金折现</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text
                            class="subtitle-1 secondary--text"
                            v-if="compareItem.rent_convert_cash"
                            >¥{{
                              Number(compareItem.rent_convert_cash).toFixed(2)
                            }}</v-list-item-action-text
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
              <p class="pb-6 caption text-right" v-if="isClientV">
                * 仅供参考 不作为报价依据
              </p>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-switch v-model="isClientV">
              <template v-slot:label>
                <v-btn text>{{ isClientV ? "客户视角" : "销售视角" }}</v-btn>
              </template>
            </v-switch>
            <v-btn
              color="secondary darken-1"
              text
              v-if="isClientV"
              class="mr-4"
              @click="dlComparePdf('pic')"
            >
              下载
            </v-btn>
            <v-spacer></v-spacer>
            <span style="width: 120px" v-if="!isTotalCompare">
              <v-text-field
                v-model="compareItem.ros_sales"
                outlined
                label="销售利润率"
                dense
                hide-details
                suffix="%"
              ></v-text-field
            ></span>
            <span style="width: 120px" class="ml-2" v-if="!isTotalCompare">
              <v-text-field
                v-model="compareItem.ros_rents"
                outlined
                label="报价系数"
                dense
                hide-details
                suffix="%"
              ></v-text-field
            ></span>
            <span style="width: 120px" class="ml-2">
              <v-text-field
                v-model="compareItem.CPA"
                outlined
                label="客户资金ROE"
                dense
                hide-details
                suffix="%"
              ></v-text-field
            ></span>
            <v-btn
              color="black darken-1"
              text
              class="ml-2"
              @click="reCountCompare"
            >
              重新计算
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-navigation-drawer
        v-model="changedrawer"
        app
        temporary
        right
        width="500"
        height="100%"
      >
        <v-card class="mx-auto" flat>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline py-2"
                >{{ offerData.title }} 参数调整</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-card-text>
            <v-sheet min-height="500">
              <v-sheet color="primary" class="pa-2">
                <span style="color: #fff">参数</span>
              </v-sheet>
              <v-form v-model="validParams">
                <div class="mt-6" v-if="pLevel === 3">
                  <v-text-field
                    v-model="parameter.period"
                    label="报价有效期"
                    autocomplete="off"
                    :rules="[rules.required]"
                    type="number"
                    clearable
                    dense
                    outlined
                    suffix="天"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.APR.base"
                    label="一年利率"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.APR.increase_2_year"
                    label="二年利率"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.APR.increase_3_year"
                    label="三年利率"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.APR.increase_4_year"
                    label="四年利率"
                    :rules="[rules.required]"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.APR.annual"
                    label="账期年利率"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.CV.init"
                    label="风险控制系数"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.CV.period"
                    label="风险账期"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    dense
                    outlined
                    suffix="天"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.CPA"
                    label="客户资金ROE"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <!-- <v-text-field
                    v-model="parameter.ROS.rents"
                    label="报价系数"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.ROS.sales"
                    label="销售利润率"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field> -->
                  <v-text-field
                    v-model="parameter.ETP._1_year_12"
                    label="1年12期"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.ETP._2_year_24"
                    label="2年24期"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.ETP._3_year_36"
                    label="3年36期"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.ETP._1_year_4"
                    label="1年4期"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.ETP._2_year_8"
                    label="2年8期"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    v-model="parameter.ETP._3_year_12"
                    label="3年12期"
                    :rules="[rules.required]"
                    autocomplete="off"
                    type="number"
                    clearable
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                  <v-textarea
                    v-model="parameter.comments"
                    label="报价单备注信息"
                    autocomplete="off"
                    clearable
                    outlined
                    dense
                    no-resize
                  ></v-textarea>
                  <v-textarea
                    v-model="parameter.statement"
                    label="报价单声明信息"
                    autocomplete="off"
                    clearable
                    outlined
                    dense
                    no-resize
                  ></v-textarea>
                </div>
              </v-form>
            </v-sheet>
          </v-card-text>
        </v-card>
        <template v-slot:append>
          <v-divider></v-divider>
          <v-card-actions class="pa-2">
            <v-btn outlined color="grey" @click="closeDrawer()">取消</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="secondary"
              @click="submitEdit"
              :disabled="!validParams"
              >提交</v-btn
            >
          </v-card-actions>
        </template>
      </v-navigation-drawer>
      <v-dialog v-model="enterInfoDlg" scrollable max-width="600px" persistent>
        <widgets-enterprise-dialogs
          :id="activeId"
          :key="activeId"
          v-on:Close="closeEnterInfo"
        ></widgets-enterprise-dialogs>
      </v-dialog>
      <v-dialog v-model="editDlg" max-width="600px" persistent>
        <v-form v-model="editValid">
          <v-card>
            <v-card-title>{{ this.editDlgTitle }}</v-card-title>
            <v-card-text>
              <v-text-field
                v-if="editDlgTitle === '报价名称'"
                v-model="editOfferData.title"
                label="报价名称"
                autocomplete="off"
                clearable
                outlined
                dense
              ></v-text-field>
              <v-select
                v-if="editDlgTitle === '租赁方式'"
                v-model="editOfferData.rent_type"
                :items="rentType"
                label="租赁方式"
                item-text="name"
                item-value="code"
                outlined
                dense
              ></v-select>
              <v-select
                v-if="editDlgTitle === '租期'"
                v-model="editOfferData.lease_term"
                label="租期（年）"
                outlined
                item-text="name"
                item-value="code"
                dense
                :items="leaseTerm"
              ></v-select>
              <v-select
                v-if="editDlgTitle === '支付周期'"
                v-model="editOfferData.payment_cycle"
                :items="paymentCycle"
                label="支付周期"
                outlined
                item-text="name"
                item-value="code"
                dense
              ></v-select>
              <v-text-field
                v-if="editDlgTitle === '账期'"
                v-model="editOfferData.payment_period"
                label="账期（天）"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
              ></v-text-field>
              <v-menu
                v-model="startMenu"
                v-if="editDlgTitle === '起租时间'"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editOfferData.lease_start"
                    label="起租时间"
                    readonly
                    :rules="[rules.required]"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editOfferData.lease_start"
                  @input="startMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-select
                v-if="editDlgTitle === '每月付款日'"
                v-model="editOfferData.payment_day"
                :items="Array.from({ length: 28 }, (v, k) => k + 1)"
                label="每月付款日"
                outlined
                dense
              ></v-select>
              <v-switch
                v-if="editDlgTitle === '是否延保'"
                v-model="editProduct.isWarranty"
                color="secondary"
                hide-details
                class="mt-0"
              >
                <template v-slot:label>
                  <span class="body-2">{{
                    editProduct.isWarranty ? "是否延保：是" : "是否延保：否"
                  }}</span>
                </template>
              </v-switch>
              <v-text-field
                v-if="editDlgTitle === '数量'"
                v-model="editProduct.quantity"
                label="数量"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
              ></v-text-field>
              <v-text-field
                v-if="editDlgTitle === '延保单价'"
                v-model="editProduct.warranty_price_unit"
                label="价格"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
              ></v-text-field>
              <v-text-field
                v-if="editDlgTitle === '到期余值'"
                v-model="editProduct.residual_values_unit"
                label="到期余值"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
              ></v-text-field>
              <v-text-field
                v-if="editDlgTitle === '保证金'"
                v-model="editProduct.deposit_price"
                label="保证金"
                :hint="deposit_hint"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
              ></v-text-field>
              <v-text-field
                v-if="editDlgTitle === '资金成本'"
                v-model="editProduct.APR"
                label="资金成本"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
                suffix="%"
              ></v-text-field>
              <v-text-field
                v-if="editDlgTitle === '采购单价'"
                v-model="editProduct.purchase_price"
                label="采购单价"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
              ></v-text-field>
              <v-text-field
                v-if="editDlgTitle === '报价系数'"
                v-model="editProduct.ros_rents"
                label="报价系数"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
                suffix="%"
              ></v-text-field>
              <v-text-field
                v-if="editDlgTitle === '销售利润率'"
                v-model="editProduct.ros_sales"
                label="销售利润率"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
                suffix="%"
              ></v-text-field>
              <v-text-field
                v-if="editDlgTitle === '客户资金ROE'"
                v-model="editNormalParams.CPA"
                label="客户资金ROE"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
                suffix="%"
              ></v-text-field>
              <v-text-field
                v-if="editDlgTitle === '风控系数'"
                v-model="editNormalParams.cv_init"
                label="风控系数"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
                suffix="%"
              ></v-text-field>
              <v-text-field
                v-if="editDlgTitle === '风险账期'"
                v-model="editNormalParams.cv_period"
                label="风险账期"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn text @click="closeEdit">关闭</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="secondary"
                @click="submitEdit"
                :disabled="!editValid"
                >保存</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="newQuoteDlg" max-width="800px" persistent>
        <v-card>
          <v-card-title>创建报价单</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="quoteTitle"
              label="报价单名称"
              :rules="[rules.required]"
              outlined
              dense
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="newQuoteDlg = false">关闭</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="secondary" @click="createQuote">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="proInfoDlg" persistent scrollable width="700">
        <widgets-product-dialogs
          :id="activeProId"
          :key="activeProId"
          v-on:Close="proInfoDlg = false"
        ></widgets-product-dialogs>
      </v-dialog>
      <v-dialog v-model="productDialog" scrollable max-width="800px">
        <v-card>
          <v-card-title>
            <span>关联产品</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="queryPro.key"
              label="搜索产品"
              outlined
              dense
              prepend-icon="search"
              hide-details
              @keydown.enter="searchProduct"
              clearable
            ></v-text-field>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              v-model="selectedProduct"
              :headers="productHeaders"
              :items="productList"
              :options.sync="optionsItemPro"
              :server-items-length="productCount"
              item-key="product_id"
              class="elevation-0 mt-4"
              show-select
              :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
            >
              <template v-slot:item.name="{ item }">
                <td
                  nowrap="true"
                  class="d-inline-block text-truncate"
                  width="240"
                  style="cursor: pointer"
                  @click="openProInfo(item.product_id)"
                >
                  {{ item.product_code || item.name }}
                </td>
              </template>
              <template v-slot:item.official="{ item }">
                <span
                  >¥{{
                    Number(item.official_price.$numberDecimal).toFixed(2)
                  }}</span
                >
              </template>
              <template v-slot:item.purchase="{ item }">
                <span
                  >¥{{
                    Number(item.purchase_price.$numberDecimal).toFixed(2)
                  }}</span
                >
              </template>
              <template v-slot:item.oneLeft="{ item }">
                <span
                  >¥{{
                    parseFloat(
                      (item.official_price.$numberDecimal > 0
                        ? item.official_price.$numberDecimal *
                          item.SV._1_year_ratio
                        : item.purchase_price.$numberDecimal *
                          item.SV._1_year_ratio
                      ).toFixed(2)
                    ).toFixed(2)
                  }}</span
                >
              </template>
              <template v-slot:item.twoLeft="{ item }">
                <span
                  >¥{{
                    parseFloat(
                      (item.official_price.$numberDecimal > 0
                        ? item.official_price.$numberDecimal *
                          item.SV._2_year_ratio
                        : item.purchase_price.$numberDecimal *
                          item.SV._2_year_ratio
                      ).toFixed(2)
                    ).toFixed(2)
                  }}</span
                >
              </template>
              <template v-slot:item.threeLeft="{ item }">
                <span
                  >¥{{
                    parseFloat(
                      (item.official_price.$numberDecimal > 0
                        ? item.official_price.$numberDecimal *
                          item.SV._3_year_ratio
                        : item.purchase_price.$numberDecimal *
                          item.SV._3_year_ratio
                      ).toFixed(2)
                    ).toFixed(2)
                  }}</span
                >
              </template>
              <template v-slot:item.twoDelay="{ item }">
                <span
                  >¥{{
                    Number(item.warranty._2_year_inside.$numberDecimal).toFixed(
                      2
                    )
                  }}</span
                >
              </template>
              <template v-slot:item.threeDelay="{ item }">
                <span
                  >¥{{
                    Number(item.warranty._3_year_inside.$numberDecimal).toFixed(
                      2
                    )
                  }}</span
                >
              </template>
              <template v-slot:item.delayPrice="{ item }">
                <span>{{ item.isDelay ? item.delayPrice : "0" }}</span>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="grey darken-1" text @click="productDialog = false">
              关闭
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary darken-1"
              text
              @click="productDialog = false"
            >
              确定
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="newDialog" scrollable persistent max-width="600px">
        <v-form v-model="validNewPro">
          <v-card>
            <v-card-title>
              <span>新增产品</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="newProInfo.name"
                    label="产品名称"
                    :rules="[rules.required]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="newProInfo.part_number"
                    :rules="[rules.required]"
                    @change="checkItem()"
                    type="text"
                    label="产品型号"
                    hint="请输入产品型号 如：MGXC2CH/A"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="6">
                  <v-text-field
                    v-model="newProInfo.product_code"
                    label="产品简称"
                    outlined
                    dense
                  ></v-text-field>
                </v-col> -->
                <v-col cols="6">
                  <v-autocomplete
                    v-model="newProInfo.product_vendor"
                    :items="vendorList"
                    :rules="[rules.selected]"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    label="产品供应商"
                    hint="请选择产品供应商"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="newProInfo.product_type"
                    :items="typeList"
                    :rules="[rules.selected]"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    clearable
                    label="产品类型"
                    hint="请选择产品类型"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model="newProInfo.quantity"
                    label="数量"
                    :rules="[rules.required]"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newProInfo.official_price"
                    type="number"
                    label="官网价格"
                    :rules="[rules.required]"
                    outlined
                    dense
                    prefix="¥"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newProInfo.purchase_price"
                    type="number"
                    label="采购价"
                    :rules="[rules.required]"
                    outlined
                    dense
                    prefix="¥"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newProInfo.SV._1_year_ratio"
                    type="number"
                    label="1年余值比例"
                    :rules="[rules.required]"
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newProInfo.SV._2_year_ratio"
                    type="number"
                    label="2年余值比例"
                    :rules="[rules.required]"
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newProInfo.SV._3_year_ratio"
                    type="number"
                    label="3年余值比例"
                    :rules="[rules.required]"
                    outlined
                    dense
                    suffix="%"
                  ></v-text-field>
                </v-col>

                <v-col cols="4" justify="center">
                  <v-switch
                    v-model="newProInfo.isWarranty"
                    color="secondary"
                    class="mt-0"
                  >
                    <template v-slot:label>
                      <span class="body-2">{{
                        newProInfo.isWarranty ? "是否延保：是" : "是否延保：否"
                      }}</span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="ml-6"
                          >
                            info
                          </v-icon>
                        </template>
                        <span class="caption"
                          >请联系MDAAS产品负责人获取延保单价</span
                        >
                      </v-tooltip>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newProInfo.warranty._2_year_inside"
                    type="number"
                    label="2年延保成本单价"
                    :rules="[rules.required]"
                    outlined
                    dense
                    prefix="¥"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newProInfo.warranty._3_year_inside"
                    type="number"
                    label="3年延保成本单价"
                    :rules="[rules.required]"
                    outlined
                    dense
                    prefix="¥"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="grey darken-1" text @click="closeEditPro">
                取消
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary darken-1"
                text
                @click="saveEditPro"
                :disabled="!validNewPro"
              >
                提交
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="dlgConflict" max-width="500px" persistent>
        <v-card>
          <v-card-title class="text-h5"
            >产品型号{{
              newProInfo.part_number
            }}已经存在，请重新填写产品型号或关联现有型号产品。</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="clearPartNo"
              >重新填写型号</v-btn
            >
            <v-btn v-show="isRentPro" color="secondary" text @click="linkPresent"
              >关联现有产品</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
    <v-overlay :value="overlay" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {
  FETCH_RENT_QUOTE,
  EDIT_RENT_QUOTE,
  APPROVE_RENT_QUOTE,
  PUBLISH_RENT_QUOTE_SHEET,
  FETCH_RENT_QUOTE_SHEET_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCTTYPE_LIST,
  FETCH_VENDOR_LIST,
  PUBLISH_PRODUCT,
  CHECK_PRODUCT,
  FETCH_PRODUCT,
} from "@/store/actions.type";
import store from "@/store";
import Util from "@/common/util";
import PDF from "@/common/pdf";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
export default {
  props: {
    getId: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    divider: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        phonenum: (value) => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || "请输入正确的电话号码";
          } else {
            return true;
          }
        },
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
      },
      startMenu: "",
      offerPanels: [0, 1, 2, 3],
      dialog: false,
      comDialog: false,
      isClientV: false,
      quotationHearder: [
        { text: "报价单名称", value: "title" },
        { text: "是否已下载", value: "isDownload" },
        { text: "创建时间", value: "createTime" },
      ],
      payheaders: [
        { text: "期数", value: "term" },
        { text: "开始日", value: "startDate" },
        { text: "结束日", value: "endDate" },
        { text: "应付款日", value: "paymentDate" },
        { text: "应付租金", value: "paymentAmount" },
      ],
      multheaders: [
        { text: "序号", value: "order", width: "80" },
        { text: "移除", value: "remove", width: "80", sortable: false },
        { text: "产品", value: "name", width: "200" },
        { text: "官网价格", value: "official_price", width: "120" },
        { text: "数量", value: "quantity", width: "80" },
        { text: "是否延保", value: "isWarranty", width: "100" },
        { text: "延保单价", value: "warranty_price_unit", width: "100" },
        { text: "租金单价(无延保)", value: "product_price_unit", width: "145" },
        { text: "租金单价(含延保)", value: "rent_price_unit", width: "145" },
        { text: "租金", value: "rent_price_sum", width: "140" },
        { text: "总租金", value: "rent_price_total", width: "120" },
        { text: "采购单价", value: "purchase_price", width: "140" },
        { text: "到期余值", value: "residual_values_unit", width: "140" },
        { text: "报价系数", value: "ros_rents", width: "140" },
        {
          text: "销售利润率",
          value: "ros_sales",
          width: "140",
          align: " d-none",
        },
        {
          text: "销售额",
          value: "sales_total",
          width: "140",
          align: " d-none",
        },
        { text: "客户资金ROE", value: "CPA", width: "180", align: " d-none" },
        {
          text: "总租金折现",
          value: "rent_convert_cash",
          width: "140",
          align: " d-none",
        },
        { text: "BetterOne", value: "rentWin", width: "140" },
        {
          text: "最高残值",
          value: "max_recycle_price",
          width: "140",
          align: " d-none",
        },
        { text: "风控系数", value: "cv_init", width: "140", align: " d-none" },
        {
          text: "风险账期",
          value: "cv_period",
          width: "140",
          align: " d-none",
        },
        { text: "资金成本", value: "APR", width: "140", align: " d-none" },
        {
          text: "租赁成本单价",
          value: "rent_cost_unit",
          width: "140",
          align: " d-none",
        },
        {
          text: "单位毛润",
          value: "gross_profit_unit",
          width: "140",
          align: " d-none",
        },
        {
          text: "余值比例",
          value: "residual_values_ratio",
          width: "140",
          align: " d-none",
        },
        {
          text: "租购比",
          value: "rent_buy_ratio",
          width: "140",
          align: " d-none",
        },
        {
          text: "本息均值法",
          value: "equal_total_payment",
          width: "140",
          align: " d-none",
        },
        { text: "IRR", value: "IRR", width: "140", align: " d-none" },
      ],
      defaultHeaders: [
        { text: "序号", value: "order", width: "80" },
        { text: "移除", value: "remove", width: "80", sortable: false },
        { text: "产品", value: "name", width: "200" },
        { text: "官网价格", value: "official_price", width: "120" },
        { text: "数量", value: "quantity", width: "80" },
        { text: "是否延保", value: "isWarranty", width: "100" },
        { text: "延保单价", value: "warranty_price_unit", width: "120" },
        { text: "保证金", value: "deposit_price", width: "140" },
        { text: "租金单价(无延保)", value: "product_price_unit", width: "145" },
        { text: "租金单价(含延保)", value: "rent_price_unit", width: "145" },
        { text: "租金", value: "rent_price_sum", width: "140" },
        { text: "总租金", value: "rent_price_total", width: "140" },
        { text: "采购单价", value: "purchase_price", width: "140" },
        { text: "到期余值", value: "residual_values_unit", width: "140" },
        { text: "报价系数", value: "ros_rents", width: "140" },
        {
          text: "销售利润率",
          value: "ros_sales",
          width: "140",
          align: " d-none",
        },
        {
          text: "销售额",
          value: "sales_total",
          width: "140",
          align: " d-none",
        },
        { text: "客户资金ROE", value: "CPA", width: "180", align: " d-none" },
        {
          text: "总租金折现",
          value: "rent_convert_cash",
          width: "140",
          align: " d-none",
        },
        { text: "BetterOne", value: "rentWin", width: "140" },
        {
          text: "最高残值",
          value: "max_recycle_price",
          width: "140",
          align: " d-none",
        },
        { text: "风控系数", value: "cv_init", width: "140", align: " d-none" },
        {
          text: "风险账期",
          value: "cv_period",
          width: "140",
          align: " d-none",
        },
        { text: "资金成本", value: "APR", width: "140", align: " d-none" },
        {
          text: "租赁成本单价",
          value: "rent_cost_unit",
          width: "140",
          align: " d-none",
        },
        {
          text: "单位毛润",
          value: "gross_profit_unit",
          width: "140",
          align: " d-none",
        },
        {
          text: "余值比例",
          value: "residual_values_ratio",
          width: "140",
          align: " d-none",
        },
        {
          text: "租购比",
          value: "rent_buy_ratio",
          width: "140",
          align: " d-none",
        },
        {
          text: "本息均值法",
          value: "equal_total_payment",
          width: "140",
          align: " d-none",
        },
        {
          text: "差值提醒",
          value: "difference_value",
          width: "140",
          align: " d-none",
        },
        { text: "IRR", value: "IRR", width: "140", align: " d-none" },
      ],
      editValid: true,
      offerData: {},
      editOfferData: {},
      defaultOfferData: {},
      compareItem: {},
      headerChip: 0,
      changedrawer: false,
      watermarkNum: 99, //水印数量
      watermarkRow: 40, //列数
      watermarkLine: 40, //行数
      rotate: -30, //文字旋转角度
      textColor: "rgba(0,0,0,.1)", //文字颜色
      watermarkText: "", //水印文字
      wm_w: 30, //水印宽度百分比
      wm_h: 20, //水印高度百分比
      activeId: "",
      enterInfoDlg: false,
      parameter: {},
      dParameter: {
        period: "",
        APR: {
          annual: "",
          base: "",
          increase_2_year: "",
          increase_3_year: "",
          increase_4_year: "",
        },
        CV: {
          init: "",
          period: "",
        },
        ROS: {
          sales: "",
          rents: "",
        },
        CPA: "",
        ETP: {
          _1_year_12: "",
          _2_year_24: "",
          _3_year_36: "",
          _1_year_4: "",
          _2_year_8: "",
          _3_year_12: "",
        },
        comments: "",
        statement: "",
      },
      editDlg: false,
      editDlgTitle: "",
      pLevel: 0,
      validParams: true,
      editProduct: {},
      defaultProduct: {
        isWarranty: false,
        ros_rents: "",
        ros_sales: "",
        CPA: "",
        APR: "",
        cv_init: "",
        quantity: "",
        purchase_price: "",
        residual_values_unit: "",
        deposit_price: "",
      },
      resetProList: [],
      editNormalParams: {},
      defaultNormalParams: {
        ros_rents: "",
        ros_sales: "",
        CPA: "",
        cv_init: "",
        cv_period: "",
      },
      newQuoteDlg: false,
      quoteTitle: "",
      quoteList: [],
      compareIndex: -1,
      optionsItem: {},
      proInfoDlg: false,
      activeProId: "",
      isTotalCompare: false,
      linkedProduct: [],
      productDialog: false,
      queryPro: {
        key: "",
      },
      optionsItemPro: {},
      productHeaders: [
        { text: "产品", value: "name", width: "150" },
        { text: "产品类别", value: "product_type.name", width: "100" },
        { text: "品牌", value: "product_vendor.name", width: "100" },
        { text: "官网价格", value: "official", width: "100" },
        { text: "采购价", value: "purchase", width: "100" },
      ],
      productList: [],
      validNewPro: true,
      newDialog: false,
      newProInfo: {
        product_code: "",
        isWarranty: false,
        quantity: "",
        product_vendor: "",
        product_type: "",
        part_number: "",
        name: "",
        isRent: true,
        official_price: "",
        purchase_price: "",
        selected: ["rent"],
        SV: {
          _1_year_ratio: 0,
          _2_year_ratio: 0,
          _3_year_ratio: 0,
        },
        warranty: {
          _2_year_inside: 0,
          _3_year_inside: 0,
        },
      },
      defaultProInfo: {
        product_code: "",
        isWarranty: false,
        quantity: "",
        product_vendor: "",
        product_type: "",
        part_number: "",
        name: "",
        isRent: true,
        official_price: "",
        purchase_price: "",
        selected: ["rent"],
        SV: {
          _1_year_ratio: 0,
          _2_year_ratio: 0,
          _3_year_ratio: 0,
        },
        warranty: {
          _2_year_inside: 0,
          _3_year_inside: 0,
        },
      },
      dlgConflict: false,
      hasProId: "",
      isRentPro: true,
      overlay: false,
      hint_price: 0,
      deposit_hint: '',
      disBtn: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "rentQuoteSheetCount",
      "productCount",
      "vendorList",
      "typeList",
    ]),
    rentType() {
      return Util.categories("rentType");
    },
    leaseTerm() {
      return Util.categories("leaseTerm");
    },
    paymentCycle() {
      return Util.categories("paymentCycle");
    },
    selectedProduct: {
      get() {
        return this.linkedProduct;
      },
      set(val) {
        this.setProductList(val);
      },
    },
  },
  watch: {
    headerChip(val) {
      this.pLevel = val;
      this.changeHeader(val);
    },
    optionsItem: {
      handler() {
        this.getQuoteList();
      },
      deep: true,
    },
    optionsItemPro: {
      handler() {
        this.getProductList();
      },
      deep: true,
    },
    changedrawer(val) {
      val || this.closeDrawer();
    },
  },
  created() {},
  methods: {
    saveEditPro() {
      let pro = JSON.parse(JSON.stringify(this.newProInfo));
      pro.vendor = pro.product_vendor
      pro.rentInfo = {
        SV: {},
        warranty: {},
        official_price: "",
        purchase_price: "",
      };
      pro.rentInfo.SV._1_year_ratio = parseFloat(
        (pro.SV._1_year_ratio / 100).toFixed(4)
      );
      pro.rentInfo.SV._2_year_ratio = parseFloat(
        (pro.SV._2_year_ratio / 100).toFixed(4)
      );
      pro.rentInfo.SV._3_year_ratio = parseFloat(
        (pro.SV._3_year_ratio / 100).toFixed(4)
      );
      delete pro.SV;
      pro.quantity = parseFloat(pro.quantity)
      pro.rentInfo.warranty._2_year_inside = parseFloat(pro.warranty._2_year_inside);
      pro.rentInfo.warranty._3_year_inside = parseFloat(pro.warranty._3_year_inside);
      pro.rentInfo.official_price = parseFloat(pro.official_price);
      pro.rentInfo.purchase_price = parseFloat(pro.purchase_price);
      store
        .dispatch(PUBLISH_PRODUCT, pro)
        .then((res) => {
          let newPro = JSON.parse(JSON.stringify(this.newProInfo));
          newPro.product_id = res.data._id;
          newPro.SV = pro.rentInfo.SV
          let proList = this.formatProductList(this.linkedProduct);
          proList.push(newPro);
          let json = {};
          json.id = this.getId;
          let obj = {};
          obj.product_list = proList;
          json.item = obj;
          store
            .dispatch(EDIT_RENT_QUOTE, json)
            .then(() => {
              this.closeEditPro();
              this.getOffer(this.getId);
              store.commit(SET_ERROR, { msg: "修改成功！", color: "primary" });
            })
            .catch(() => {
              this.closeEditPro();
              store.commit(SET_ERROR, { msg: "修改失败！" });
            });
        })
        .catch((error) => {
          store.commit(SET_ERROR, { msg: error.response.data.message });
        });
    },
    closeEditPro() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.newProInfo = JSON.parse(JSON.stringify(this.defaultProInfo));
      });
    },
    editProInfo() {
      this.newDialog = true;
    },
    checkItem() {
      this.isRentPro = true
      if (this.newProInfo.part_number) {
        store
          .dispatch(CHECK_PRODUCT, "partno=" + this.newProInfo.part_number)
          .then((data) => {
            if (data.query.count > 0) {
              this.dlgConflict = true;
              this.hasProId = data.product[0]._id;
              data.product[0].selected.includes('rent') ? this.isRentPro = true : this.isRentPro = false
            }
          })
          .catch((error) => {
            store.commit(SET_ERROR, { msg: error });
          });
      }
    },
    clearPartNo() {
      this.newProInfo.part_number = "";
      this.dlgConflict = false;
    },
    linkPresent() {
      let num = -1;
      this.linkedProduct.map((item) => {
        if (item.product_id === this.hasProId) num += 1;
      });
      if (num > -1) {
        store.commit(SET_ERROR, {
          msg: "关联列表中已包含此产品",
          color: "primary",
        });
      } else {
        store.dispatch(FETCH_PRODUCT, this.hasProId).then((data) => {
          let pro = JSON.parse(JSON.stringify(data));

          let j = {};
          j.product_id = pro._id;
          j.isWarranty = false;
          j.quantity = 1;
          j.part_number = pro.part_number;
          j.name = pro.name;
          if (pro.product_code) j.product_code = pro.product_code;
          j.official_price = parseFloat(pro.rentInfo.official_price.$numberDecimal);
          j.purchase_price = parseFloat(pro.rentInfo.purchase_price.$numberDecimal);
          j.SV = pro.rentInfo.SV;
          j.warranty = {};
          j.warranty._2_year_inside =
            parseFloat(pro.rentInfo.warranty._2_year_inside.$numberDecimal);
          j.warranty._3_year_inside =
            parseFloat(pro.rentInfo.warranty._3_year_inside.$numberDecimal);

          let proList = this.formatProductList(this.linkedProduct);
          proList.push(j);
          let json = {};
          json.id = this.getId;
          let obj = {};
          obj.product_list = proList;
          json.item = obj;
          store
            .dispatch(EDIT_RENT_QUOTE, json)
            .then(() => {
              this.dlgConflict = false;
              this.hasProId = "";
              this.closeEditPro();
              this.getOffer(this.getId);
              store.commit(SET_ERROR, { msg: "修改成功！", color: "primary" });
            })
            .catch(() => {
              this.dlgConflict = false;
              this.closeEditPro();
              store.commit(SET_ERROR, { msg: "修改失败！" });
            });
        });
      }
    },
    formatProductList(list) {
      let arr = JSON.parse(JSON.stringify(list));
      let proList = [];
      arr.map((item) => {
        let pro = {};
        pro.SV = {};
        pro.SV._1_year_ratio = item.SV._1_year_ratio;
        pro.SV._2_year_ratio = item.SV._2_year_ratio;
        pro.SV._3_year_ratio = item.SV._3_year_ratio;
        pro.SV._1_year_recycle = item.SV._1_year_recycle.$numberDecimal;
        pro.SV._2_year_recycle = item.SV._2_year_recycle.$numberDecimal;
        pro.SV._3_year_recycle = item.SV._3_year_recycle.$numberDecimal;
        if (item.ros_sales) pro.ros_sales = item.ros_sales;
        if (item.ros_rents) pro.ros_rents = item.ros_rents;
        pro.warranty = {};
        pro.warranty._2_year_inside =
          parseFloat(item.warranty._2_year_inside.$numberDecimal);
        pro.warranty._3_year_inside =
          parseFloat(item.warranty._3_year_inside.$numberDecimal);
        pro.warranty._2_year_retail =
          parseFloat(item.warranty._2_year_retail.$numberDecimal);
        pro.warranty._3_year_retail =
          parseFloat(item.warranty._3_year_retail.$numberDecimal);
        pro.product_id = item.product_id
        // if (item.order) pro.order = item.order;
        pro.isWarranty = item.isWarranty;
        pro.quantity = item.quantity;
        pro.name = item.name;
        if (item.product_code) pro.product_code = item.product_code;
        pro.official_price = Number(item.official_price.$numberDecimal);
        pro.purchase_price = Number(item.purchase_price.$numberDecimal);
        if (item.residual_values_unit)
          pro.residual_values_unit = Number(
            item.residual_values_unit.$numberDecimal
          );
        if (item.APR) pro.APR = Number(item.APR);
        if (item.deposit_price)
          pro.deposit_price = Number(item.deposit_price.$numberDecimal);
        pro.IRR_composite = item.IRR_composite
        proList.push(pro);
      });
      return proList;
    },
    setProductList(list) {
      this.overlay = true
      let json = {};
      json.id = this.getId;
      let obj = {};
      obj.product_list = this.formatProductList(list);
      json.item = obj;
      store
        .dispatch(EDIT_RENT_QUOTE, json)
        .then(() => {
          this.overlay = false
          this.getOffer(this.getId);
          store.commit(SET_ERROR, { msg: "修改成功！", color: "primary" });
        })
        .catch(() => {
          this.overlay = false
          store.commit(SET_ERROR, { msg: "修改失败！" });
        });
    },
    removeLinked(item) {
      if (this.linkedProduct.length > 1) {
        let proIndex = this.linkedProduct.indexOf(item);
        if (proIndex > -1) {
          let proList = JSON.parse(JSON.stringify(this.linkedProduct));
          proList.splice(proIndex, 1);
          this.setProductList(proList);
        }
      }else {
        store.commit(SET_ERROR, { msg: "报价产品不能为空，请先关联或增加产品", color: 'primary' })
      }
    },
    getProductList() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItemPro;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      let url =
        "isRent=true&page=" + page + "&limit=" + itemsPerPage + "&sort=" + sort;
      if (this.queryPro.key) {
        url += "&key=" + this.queryPro.key;
      }
      if (Object.keys(this.optionsItemPro).length > 0) {
        store.dispatch(FETCH_PRODUCT_LIST, url).then((data) => {
          this.productList = data.product.map((item) => {
            let j = {};
            j.product_id = item._id;
            j.isWarranty = false;
            j.quantity = 1;
            j.name = item.name;
            if (item.product_code) j.product_code = item.product_code;
            j.official_price = item.rentInfo.official_price;
            j.purchase_price = item.rentInfo.purchase_price;
            j.SV = item.rentInfo.SV;
            j.product_type = item.product_type;
            j.product_vendor = item.vendor || "";
            j.warranty = {};
            j.warranty = item.rentInfo.warranty;
            return j;
          });
        });
      }
    },
    searchProduct() {
      this.getProductList(this.queryPro.key);
    },
    dlComparePdf(type) {
      let dlgEl = this.$refs.compareInfo;
      let pdfName = this.offerData.title + "-销售与租赁方案对比";
      PDF.downloadPDF(dlgEl, pdfName, type);
    },
    getOffer(id) {
      store.dispatch(FETCH_RENT_QUOTE, { query: id }).then((data) => {
        this.offerData = data;
        this.editOfferData = JSON.parse(JSON.stringify(this.offerData));
        this.linkedProduct = JSON.parse(
          JSON.stringify(this.offerData.product_list)
        );
        // this.linkedProduct.forEach(item => {
        //   if (item.isCustom) {
        //     item.product_id = this.createUnrealId()
        //   }
        // })
        this.editOfferData.rent_type = this.editOfferData.rent_type.toString();
        this.editOfferData.payment_cycle =
          this.editOfferData.payment_cycle.toString();
        this.editOfferData.lease_start = new Date(
          this.editOfferData.lease_start
        )
          .toISOString()
          .substr(0, 10);

        this.defaultOfferData = JSON.parse(JSON.stringify(this.editOfferData));
        if (this.compareIndex > -1) {
          if (this.compareIndex === 99999) {
            this.compareItem = {};
            this.compareItem.quantity = this.offerData.quantity_total;
            this.compareItem.ros_sales = Number(
              this.offerData.ros_sales_total * 100
            ).toFixed(2);
            this.compareItem.sales_unit = Number(
              this.offerData.sales_unit_total.$numberDecimal
            ).toFixed(2);
            this.compareItem.ros_rents = Number(
              this.offerData.product_list[0].ros_rents * 100
            ).toFixed(2);
            this.compareItem.product_price_unit =
              this.offerData.product_price_total;
            this.compareItem.rent_price_total =
              this.offerData.rent_price_total_total;
            this.compareItem.rent_convert_cash =
              this.offerData.rent_convert_cash_total;
            this.compareItem.rentWin = this.offerData.rentWin;
            this.compareItem.CPA = Number(
              this.offerData.CPA_total * 100
            ).toFixed(2);
            this.compareItem.sales_total = this.offerData.sales_total_total;
            this.compareItem.purchase_price =
              this.offerData.purchase_price_total;
            this.compareItem.purchase_price_final = this.countPurchaseFinal();
          } else {
            this.compareItem = JSON.parse(
              JSON.stringify(this.linkedProduct[this.compareIndex])
            );
            this.compareItem.sales_unit = Number(
              this.compareItem.sales_unit.$numberDecimal
            ).toFixed(2);
            this.compareItem.ros_rents = Number(
              this.compareItem.ros_rents * 100
            ).toFixed(2);
            this.compareItem.ros_sales = Number(
              this.compareItem.ros_sales * 100
            ).toFixed(2);
            this.compareItem.CPA = Number(this.compareItem.CPA * 100).toFixed(
              2
            );
            this.compareItem.purchase_price_final = this.countPurchaseFinal();
          }
          store.commit(SET_ERROR, {
            msg: "对比方案已重新计算",
            color: "primary",
          });
        }
        this.checkPermission();
        this.getQuoteList();
      });
    },
    createUnrealId() {
      let uId = new Date().getTime() + Math.floor(Math.random() * 100);
      return uId;
    },
    doAction(action) {
      switch (action) {
        case "open_case": {
          store.dispatch(FETCH_PRODUCTTYPE_LIST, "isEnable=true");
          store.dispatch(FETCH_VENDOR_LIST, "limit=-1&isEnable=true");
          this.getOffer(this.getId);
          this.setWarterMark();
          this.dialog = true;
          break;
        }
      }
    },
    setWarterMark() {
      this.watermarkText = this.currentUser.personal.name;
    },
    checkPermission() {
      let p = this.currentUser.permission;
      if (p.includes("Lease_Admin")) {
        this.pLevel = 3;
        this.parameter = JSON.parse(
          JSON.stringify(this.editOfferData.default_parameter)
        );
        this.parameter.APR.base = parseFloat(
          (this.parameter.APR.base * 100).toFixed(4)
        );
        this.parameter.APR.annual = parseFloat(
          (this.parameter.APR.annual * 100).toFixed(4)
        );
        this.parameter.APR.increase_2_year = parseFloat(
          (this.parameter.APR.increase_2_year * 100).toFixed(4)
        );
        this.parameter.APR.increase_3_year = parseFloat(
          (this.parameter.APR.increase_3_year * 100).toFixed(4)
        );
        this.parameter.APR.increase_4_year = parseFloat(
          (this.parameter.APR.increase_4_year * 100).toFixed(4)
        );
        this.parameter.CV.init = parseFloat(
          (this.parameter.CV.init * 100).toFixed(4)
        );
        this.parameter.ROS.sales = parseFloat(
          (this.parameter.ROS.sales * 100).toFixed(4)
        );
        this.parameter.ROS.rents = parseFloat(
          (this.parameter.ROS.rents * 100).toFixed(4)
        );
        this.parameter.CPA = parseFloat((this.parameter.CPA * 100).toFixed(4));
        this.parameter.ETP._1_year_12 = parseFloat(
          (this.parameter.ETP._1_year_12 * 100).toFixed(4)
        );
        this.parameter.ETP._2_year_24 = parseFloat(
          (this.parameter.ETP._2_year_24 * 100).toFixed(4)
        );
        this.parameter.ETP._3_year_36 = parseFloat(
          (this.parameter.ETP._3_year_36 * 100).toFixed(4)
        );
        this.parameter.ETP._1_year_4 = parseFloat(
          (this.parameter.ETP._1_year_4 * 100).toFixed(4)
        );
        this.parameter.ETP._2_year_8 = parseFloat(
          (this.parameter.ETP._2_year_8 * 100).toFixed(4)
        );
        this.parameter.ETP._3_year_12 = parseFloat(
          (this.parameter.ETP._3_year_12 * 100).toFixed(4)
        );
        this.dParameter = JSON.parse(JSON.stringify(this.parameter));
      } else if (p.includes("Lease_Product")) {
        this.pLevel = 2;
      } else if (p.includes("Lease_Audit")) {
        this.pLevel = 1;
      }
      this.changeHeader(this.pLevel);
    },
    closeDialog() {
      this.dialog = false;
      this.$emit("Update");
    },
    openCompareDlg(item) {
      if (item) {
        this.resetEditProduct(item);
        this.compareIndex = this.linkedProduct.indexOf(item);
        this.compareItem = JSON.parse(JSON.stringify(item));
        this.compareItem.sales_unit = Number(
          this.compareItem.sales_unit.$numberDecimal
        ).toFixed(2);
        this.compareItem.ros_rents = Number(
          this.compareItem.ros_rents * 100
        ).toFixed(2);
        this.compareItem.ros_sales = Number(
          this.compareItem.ros_sales * 100
        ).toFixed(2);
        this.compareItem.CPA = Number(this.compareItem.CPA * 100).toFixed(2);
        this.compareItem.purchase_price_final = this.countPurchaseFinal();
      } else {
        this.isTotalCompare = true;
        this.compareIndex = 99999;
        this.compareItem = {};
        this.compareItem.quantity = this.offerData.quantity_total;
        this.compareItem.sales_unit = Number(
          this.offerData.sales_unit_total.$numberDecimal
        ).toFixed(2);
        this.compareItem.ros_sales = Number(
          this.offerData.ros_sales_total * 100
        ).toFixed(2);
        this.compareItem.ros_rents = Number(
          this.offerData.rent_ros_total * 100
        ).toFixed(2);
        this.compareItem.product_price_unit =
          this.offerData.product_price_total;
        this.compareItem.rent_price_total =
          this.offerData.rent_price_total_total;
        this.compareItem.rent_convert_cash =
          this.offerData.rent_convert_cash_total;
        this.compareItem.rentWin = this.offerData.rentWin;
        this.compareItem.CPA = Number(this.offerData.CPA_total * 100).toFixed(
          2
        );
        this.compareItem.sales_total = this.offerData.sales_total_total;
        this.compareItem.purchase_price = this.offerData.purchase_price_total;
        this.compareItem.purchase_price_final = this.countPurchaseFinal();
      }
      this.compareItem.rent_type = this.offerData.rent_type;
      this.$forceUpdate();
      this.comDialog = true;
    },
    countPurchaseFinal() {
      let num = 0;
      let cpa = this.compareItem.CPA / 100;
      if (this.offerData.lease_term === 1) {
        num = Number(
          (this.compareItem.sales_total.$numberDecimal * (1 + cpa)) / 1
        ).toFixed(2);
      } else if (this.offerData.lease_term === 2) {
        num = Number(
          (this.compareItem.sales_total.$numberDecimal *
            (1 + cpa + Math.pow(1 + cpa, 2))) /
            2
        ).toFixed(2);
      } else {
        num = Number(
          (this.compareItem.sales_total.$numberDecimal *
            (1 + cpa + Math.pow(1 + cpa, 2) + Math.pow(1 + cpa, 3))) /
            3
        ).toFixed(2);
      }
      return Number(num).toFixed(2);
    },
    changeHeader(val) {
      switch (val) {
        case 0: {
          this.multheaders = JSON.parse(JSON.stringify(this.defaultHeaders));
          break;
        }
        case 1: {
          this.multheaders = JSON.parse(JSON.stringify(this.defaultHeaders));
          this.multheaders.forEach((item) => {
            if (item.text == "最高残值") {
              item.align = "";
            }
          });
          break;
        }
        case 2: {
          this.multheaders = JSON.parse(JSON.stringify(this.defaultHeaders));
          this.multheaders.forEach((item) => {
            if (
              item.text == "最高残值" ||
              item.text == "风控系数" ||
              item.text == "风险账期" ||
              item.text == "综合IRR"
            ) {
              item.align = "";
            }
          });
          break;
        }
        case 3: {
          this.multheaders = JSON.parse(JSON.stringify(this.defaultHeaders));
          this.multheaders.forEach((item) => {
            if (
              item.text == "最高残值" ||
              item.text == "风控系数" ||
              item.text == "风险账期" ||
              item.text == "资金成本" ||
              item.text == "租赁成本单价" ||
              item.text == "单位毛润" ||
              item.text == "余值比例" ||
              item.text == "租购比" ||
              item.text == "本息均值法" ||
              item.text == "差值提醒" ||
              item.text == "综合IRR" ||
              item.text == "IRR" ||
              item.value == "data-table-expand"
            ) {
              item.align = "";
            }
          });
          break;
        }
      }
    },
    createIrrList(list) {
      let newlist = list.map((item) => Number(item.$numberDecimal).toFixed(2));
      let str = newlist.join(" | ");
      return str;
    },
    openEnterInfo(id) {
      this.activeId = id;
      this.enterInfoDlg = true;
    },
    closeEnterInfo() {
      this.enterInfoDlg = false;
    },
    closeComDlg() {
      this.compareIndex = -1;
      this.isClientV = false;
      this.isTotalCompare = false;
      this.comDialog = false;
    },
    resetEditProduct(item) {
      this.editIndex = this.linkedProduct.indexOf(item);
      let pro = JSON.parse(JSON.stringify(item));
      this.editProduct.APR = parseFloat((pro.APR * 100).toFixed(2));
      this.editProduct.SV = {};
      this.editProduct.ros_sales = Number(pro.ros_sales * 100).toFixed(2);
      this.editProduct.ros_rents = Number(pro.ros_rents * 100).toFixed(2);
      this.editProduct.SV._1_year_ratio = pro.SV._1_year_ratio;
      this.editProduct.SV._2_year_ratio = pro.SV._2_year_ratio;
      this.editProduct.SV._3_year_ratio = pro.SV._3_year_ratio;
      this.editProduct.SV._1_year_recycle =
        pro.SV._1_year_recycle.$numberDecimal;
      this.editProduct.SV._2_year_recycle =
        pro.SV._2_year_recycle.$numberDecimal;
      this.editProduct.SV._3_year_recycle =
        pro.SV._3_year_recycle.$numberDecimal;
      this.editProduct.warranty = {};
      this.editProduct.warranty._2_year_inside =
        pro.warranty._2_year_inside.$numberDecimal;
      this.editProduct.warranty._3_year_inside =
        pro.warranty._3_year_inside.$numberDecimal;
      this.editProduct.warranty._2_year_retail =
        pro.warranty._2_year_retail.$numberDecimal;
      this.editProduct.warranty._3_year_retail =
        pro.warranty._3_year_retail.$numberDecimal;
      // pro.product_id ? this.editProduct.product_id = pro.product_id : this.editProduct.product_id=''
      // if (pro.product_id) {
      //   this.editProduct.product_id = pro.product_id;
      // }
      this.editProduct.product_id = pro.product_id;
      this.editProduct.order = pro.order;
      this.editProduct.isWarranty = pro.isWarranty;
      this.editProduct.quantity = pro.quantity;
      this.editProduct.name = pro.name;
      if (pro.product_code) this.editProduct.product_code = pro.product_code;
      this.editProduct.official_price = Number(
        pro.official_price.$numberDecimal
      );
      this.editProduct.purchase_price = Number(
        pro.purchase_price.$numberDecimal
      );

      this.editProduct.warranty_price_unit = Number(
        pro.warranty_price_unit.$numberDecimal
      ).toFixed(2);
      this.editProduct.residual_values_unit = Number(
        pro.residual_values_unit.$numberDecimal
      ).toFixed(2);
      this.editProduct.deposit_price = Number(pro.deposit_price.$numberDecimal);

      this.hint_price = parseFloat(this.editProduct.purchase_price * 0.3).toFixed(2)
      this.deposit_hint = '最大金额为采购单价的30%：' + this.hint_price
    },
    comfimrEditProduct() {
      this.editProduct.APR = Number((this.editProduct.APR / 100).toFixed(4));
      this.editProduct.ros_rents = parseFloat(
        (this.editProduct.ros_rents / 100).toFixed(4)
      );
      this.editProduct.ros_sales = parseFloat(
        (this.editProduct.ros_sales / 100).toFixed(4)
      );
      if (this.offerData.lease_term === 730) {
        let w = parseFloat(this.editProduct.warranty._2_year_inside).toFixed(2)
        let u = parseFloat(this.editProduct.warranty_price_unit).toFixed(2)
        if (w != u) {
          this.editProduct.isWarranty = true
          this.editProduct.warranty._2_year_inside = this.editProduct.warranty_price_unit
        }
      }else if (this.offerData.lease_term === 1095) {
        let w = parseFloat(this.editProduct.warranty._3_year_inside).toFixed(2)
        let u = parseFloat(this.editProduct.warranty_price_unit).toFixed(2)
        if (w != u) {
          this.editProduct.isWarranty = true
          this.editProduct.warranty._3_year_inside = this.editProduct.warranty_price_unit
        }
      }
      let arr = JSON.parse(JSON.stringify(this.linkedProduct));
      let proList = [];
      arr.map((item) => {
        let pro = {};
        pro.SV = {};
        pro.SV._1_year_ratio = item.SV._1_year_ratio;
        pro.SV._2_year_ratio = item.SV._2_year_ratio;
        pro.SV._3_year_ratio = item.SV._3_year_ratio;
        pro.SV._1_year_recycle = item.SV._1_year_recycle.$numberDecimal;
        pro.SV._2_year_recycle = item.SV._2_year_recycle.$numberDecimal;
        pro.SV._3_year_recycle = item.SV._3_year_recycle.$numberDecimal;
        pro.ros_sales = item.ros_sales;
        pro.ros_rents = item.ros_rents;
        pro.warranty = {};
        pro.warranty._2_year_inside =
          item.warranty._2_year_inside.$numberDecimal;
        pro.warranty._3_year_inside =
          item.warranty._3_year_inside.$numberDecimal;
        pro.warranty._2_year_retail =
          item.warranty._2_year_retail.$numberDecimal;
        pro.warranty._3_year_retail =
          item.warranty._3_year_retail.$numberDecimal;
        // item.product_id ? pro.product_id = item.product_id : pro.product_id = ''
        pro.product_id = item.product_id
        pro.order = item.order;
        pro.isWarranty = item.isWarranty;
        pro.quantity = item.quantity;
        pro.name = item.name;
        if (item.product_code) pro.product_code = item.product_code;
        pro.official_price = Number(item.official_price.$numberDecimal);
        pro.purchase_price = Number(item.purchase_price.$numberDecimal);
        pro.residual_values_unit = Number(
          item.residual_values_unit.$numberDecimal
        );
        pro.APR = Number(item.APR);
        pro.deposit_price = parseFloat(parseFloat(item.deposit_price.$numberDecimal).toFixed(2));
        proList.push(pro);
      });
      this.$set(proList, this.editIndex, this.editProduct);
      return proList;
    },
    editOffer(action, title, item) {
      if (item) {
        this.resetEditProduct(item);
      }
      if (action === "CPA" || action === "cv_init" || action === "cv_period") {
        let pro = JSON.parse(JSON.stringify(this.offerData.product_list[0]));
        this.editNormalParams.CPA = Number(pro.CPA * 100).toFixed(2);
        this.editNormalParams.cv_init = Number(pro.cv_init * 100).toFixed(2);
        this.editNormalParams.cv_period = pro.cv_period;
      }
      this.activeAction = action;
      this.editDlgTitle = title;
      this.editDlg = true;
    },
    submitEdit() {
      this.overlay = true
      let json = {};
      json.id = this.getId;
      let obj = {};
      if (
        this.activeAction === "rent_type" ||
        this.activeAction === "payment_cycle" ||
        this.activeAction === "payment_period"
      ) {
        obj[this.activeAction] = parseInt(
          this.editOfferData[this.activeAction]
        );
      } else if (this.activeAction === "default_parameter") {
        let strInsert = {};
        strInsert.APR = {};
        strInsert.CV = {};
        strInsert.ROS = {};
        strInsert.ETP = {};

        strInsert.period = this.parameter.period;
        strInsert.APR.base = parseFloat(
          (this.parameter.APR.base / 100).toFixed(4)
        );
        strInsert.APR.annual = parseFloat(
          (this.parameter.APR.annual / 100).toFixed(4)
        );
        strInsert.APR.increase_2_year = parseFloat(
          (this.parameter.APR.increase_2_year / 100).toFixed(4)
        );
        strInsert.APR.increase_3_year = parseFloat(
          (this.parameter.APR.increase_3_year / 100).toFixed(4)
        );
        strInsert.APR.increase_4_year = parseFloat(
          (this.parameter.APR.increase_4_year / 100).toFixed(4)
        );
        strInsert.CV.init = parseFloat(
          (this.parameter.CV.init / 100).toFixed(4)
        );
        strInsert.CV.period = this.parameter.CV.period;
        strInsert.ROS.sales = parseFloat(
          (this.parameter.ROS.sales / 100).toFixed(4)
        );
        strInsert.ROS.rents = parseFloat(
          (this.parameter.ROS.rents / 100).toFixed(4)
        );
        strInsert.CPA = parseFloat((this.parameter.CPA / 100).toFixed(4));
        strInsert.ETP._1_year_12 = parseFloat(
          (this.parameter.ETP._1_year_12 / 100).toFixed(4)
        );
        strInsert.ETP._2_year_24 = parseFloat(
          (this.parameter.ETP._2_year_24 / 100).toFixed(4)
        );
        strInsert.ETP._3_year_36 = parseFloat(
          (this.parameter.ETP._3_year_36 / 100).toFixed(4)
        );
        strInsert.ETP._1_year_4 = parseFloat(
          (this.parameter.ETP._1_year_4 / 100).toFixed(4)
        );
        strInsert.ETP._2_year_8 = parseFloat(
          (this.parameter.ETP._2_year_8 / 100).toFixed(4)
        );
        strInsert.ETP._3_year_12 = parseFloat(
          (this.parameter.ETP._3_year_12 / 100).toFixed(4)
        );
        strInsert.comments = this.parameter.comments;
        strInsert.statement = this.parameter.statement;

        obj[this.activeAction] = strInsert;
      } else if (this.activeAction === "product_list") {
        obj[this.activeAction] = this.comfimrEditProduct()
        obj[this.activeAction] = obj[this.activeAction].map(item => {
          item.residual_values_unit = parseFloat(item.residual_values_unit)
          item.deposit_price = parseFloat(item.deposit_price)
          return item
        })
      } else if (
        this.activeAction === "CPA" ||
        this.activeAction === "cv_init" ||
        this.activeAction === "cv_period"
      ) {
        if (this.activeAction === "cv_period") {
          obj[this.activeAction] = this.editNormalParams[this.activeAction];
        } else {
          obj[this.activeAction] = Number(
            this.editNormalParams[this.activeAction] / 100
          ).toFixed(4);
        }
      } else {
        obj[this.activeAction] = this.editOfferData[this.activeAction];
      }
      json.item = obj;
      store
        .dispatch(EDIT_RENT_QUOTE, json)
        .then(() => {
          this.overlay = false
          this.getOffer(this.getId);
          store.commit(SET_ERROR, { msg: "修改成功！", color: "primary" });
          this.closeEdit();
          this.closeDrawer();
        })
        .catch(() => {
          this.overlay = false
          this.closeEdit();
          this.closeDrawer();
          store.commit(SET_ERROR, { msg: "修改失败！" });
        });
    },
    reCountCompare() {
      let json = {};
      json.id = this.getId;
      let obj = {};
      // obj.ros_sales = Number(this.compareItem.ros_sales / 100).toFixed(4)
      // obj.ros_rents = Number(this.compareItem.ros_rents / 100).toFixed(4)
      obj.CPA = Number(parseFloat(this.compareItem.CPA / 100).toFixed(4));
      if (!this.isTotalCompare) {
        let proList = this.comfimrEditProduct();
        proList[this.compareIndex].ros_sales = Number(parseFloat(
          this.compareItem.ros_sales / 100
        ).toFixed(4));
        proList[this.compareIndex].ros_rents = Number(parseFloat(
          this.compareItem.ros_rents / 100
        ).toFixed(4));
        obj.product_list = proList;
      }
      json.item = obj;
      if (
        this.compareItem.ros_sales &&
        this.compareItem.ros_rents &&
        this.compareItem.CPA
      ) {
        store
          .dispatch(EDIT_RENT_QUOTE, json)
          .then(() => {
            this.getOffer(this.getId);
            // store.commit(SET_ERROR, { msg: "对比方案已重新计算", color: 'primary' });
          })
          .catch(() => {
            store.commit(SET_ERROR, { msg: "修改失败！" });
          });
      } else {
        store.commit(SET_ERROR, { msg: "请输入客户ROE、销售利润与报价系数" });
      }
    },
    closeEdit() {
      this.editOfferData = JSON.parse(JSON.stringify(this.defaultOfferData));
      this.editIndex = -1;
      this.editProduct = JSON.parse(JSON.stringify(this.defaultProduct));
      this.editNormalParams = JSON.parse(
        JSON.stringify(this.defaultNormalParams)
      );
      this.hint_price = 0
      this.deposit_hint = ''
      this.activeAction = "";
      this.editDlgTitle = "";
      this.editDlg = false;
    },
    editParams() {
      this.activeAction = "default_parameter";
      this.changedrawer = true;
    },
    closeDrawer() {
      this.changedrawer = false;
      this.parameter = JSON.parse(JSON.stringify(this.dParameter));
    },
    confirmApprove() {
      if (confirm("确定要审核通过 " + this.offerData.title + " 此报价吗？")) {
        store
          .dispatch(APPROVE_RENT_QUOTE, this.offerData._id)
          .then(() => {
            this.getOffer(this.getId);
            store.commit(SET_ERROR, { msg: "审核成功", color: "primary" });
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
          });
      }
    },
    openNewQuote() {
      this.newQuoteDlg = true;
    },
    closeNewQuote() {
      this.newQuoteDlg = false;
      this.quoteTitle = "";
    },
    createQuote() {
      store
        .dispatch(PUBLISH_RENT_QUOTE_SHEET, {
          offer: this.getId,
          title: this.quoteTitle,
        })
        .then(() => {
          store.commit(SET_ERROR, { msg: "创建报价单成功", color: "primary" });
          this.closeNewQuote();
          this.getQuoteList();
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err });
        });
    },
    getQuoteList() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      let url = "offer=" + this.getId;
      if (Object.keys(this.optionsItem).length > 0) {
        store
          .dispatch(FETCH_RENT_QUOTE_SHEET_LIST, {
            query:
              url +
              "&page=" +
              page +
              "&limit=" +
              itemsPerPage +
              "&sort=" +
              sort,
          })
          .then((data) => {
            this.quoteList = data.quotes;
          });
      }
    },
    openProInfo(id) {
      this.activeProId = id;
      this.proInfoDlg = true;
    },
  },
};
</script>
<style scoped>
.footerList {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.panelTitle {
  padding-left: 14px;
  border-left: 2px solid red;
}
.watermarkWrap {
  overflow: hidden;
  position: absolute;
  padding-bottom: 40px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.wm {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
