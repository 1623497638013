<template>
  <v-container fluid no-gutters class="px-16">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-carousel
          height="275px"
          cycle
          hide-delimiter-background
          show-arrows-on-hover
          delimiter-icon="remove"
        >
          <v-carousel-item v-for="(item,i) in editBodyData.images" :key="i" :src="item.src ? ossURL + '/'+ item.src : '/static/error/empty_banner.png'" :href="item.href" target="_blank"></v-carousel-item>
          <v-sheet v-if="editBodyData.images.length === 0" color="grey lighten-2" height="100%" tile>
            <v-row class="fill-height" align="center" justify="center"><div class="grey--text">轮播区域</div>
            </v-row>
          </v-sheet>
          <v-fade-transition>
            <v-overlay v-if="hover" absolute class="align-end" style="top: 225px" color="grey lighten-5">
              <v-btn class="ma-2" text color="primary" @click="editItem()"><v-icon left>edit</v-icon> 编辑</v-btn>
              <v-btn class="ma-2" text color="primary" @click="deleteItem()"><v-icon left>delete</v-icon> 删除</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-carousel>
      </template>
    </v-hover>
    <v-bottom-sheet v-model="dlgSetting" :retain-focus="false" eager>
      <v-form v-model="valid" @submit.prevent="submit">
        <input id="files" type="file" name="file" ref="uploadInput" accept="image/*" :multiple="false" @change="detectFiles($event)" />
        <v-card>
          <v-card-title>
            <span class="headline">设置</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="3" class="text-center" v-for="(item, i) in editBodyData.images" :key="i">
                  <v-row align="center" justify="center" class="pa-3">
                    <v-card outlined hover tile class="text-center">
                      <v-hover>
                        <template v-slot:default="{ hover }">
                          <v-img :src="item.src ? ossURL + '/'+ item.src : '/static/error/empty_banner.png'" style="width: 100%; height: 80px">
                            <v-fade-transition>
                              <v-overlay v-if="hover" absolute class="align-end" color="grey lighten-5">
                                <v-btn class="ma-2" text color="primary" @click="saveImage(i)"><v-icon left>compare_arrows</v-icon> 更换</v-btn>
                                <v-btn class="ma-2" text color="primary" @click="removeImage(i)"><v-icon left>delete</v-icon> 删除</v-btn>
                              </v-overlay>
                            </v-fade-transition>
                          </v-img>
                        </template>
                      </v-hover>
                    </v-card>
                  </v-row>
                  <v-text-field
                    v-model="item.href"
                    width="300px"
                    type="text"
                    label="链接地址"
                    hint="横幅的链接地址"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="3" v-if="editBodyData.images.length < 4">
                  <v-row align="center" justify="center" class="pa-3">
                    <v-card outlined hover class="text-center" style="width: 100%; height: 80px" @click="saveImage(-1)">
                      <v-card-title></v-card-title>
                      <v-card-text><v-icon>add</v-icon></v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">关闭</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-bottom-sheet>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_UPLOAD,
  FETCH_PAGE,
  EDIT_BODY_ELEMENT,
  DELETE_BODY_ELEMENT,
} from "@/store/actions.type";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        }
      },
      dlgSetting: false,
      valid: true,
      editBodyData: {
        images: []
      },
      alignmentsAvailable: [
        'start',
        'center',
        'end',
      ],
      justifyAvailable: [
        'start',
        'center',
        'end',
      ],
      Upload: {
        fileIndex: -1,
        uploading: false,
        progressUpload: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentEmployer', 'uploadSign']),
  },
  watch: {
    dlgSetting (val) {
      val || this.close()
    },
  },
  created() {
    this.getPageInfo()
  },
  mounted () {},
  methods: {
    getPageInfo(component = 'body') {
      this.$store.dispatch(FETCH_PAGE, '?name=home&component=' + component +'&owner='+ this.currentUser.employer.ownerId._id)
        .then(data => {
          this.editBodyData = Object.assign({_id: data._id}, data.config[this.index])
          if (!this.editBodyData.images) this.editBodyData.images = []
          if (!this.editBodyData.type) this.editBodyData.type = 'carousel'
        });
    },
    close() {
      this.dlgSetting = false
      this.getPageInfo('body')
    },
    editItem() {
      this.dlgSetting = true
    },
    saveImage(index) {
      this.Upload.fileIndex = index
      this.$store.dispatch(FETCH_UPLOAD)
        .then(() => {
          this.$refs.uploadInput.click()
        })
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    removeImage(index) {
      if (confirm('确定要删除所选内容？')) {
        this.editBodyData.images.splice(index, 1)
        this.submit(false)
      }
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.currentEmployer.ownerId._id +'/page/home/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };
      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';

          if (this.Upload.fileIndex > -1) {
            Object.assign(this.editBodyData.images[this.Upload.fileIndex], { src: this.currentEmployer.ownerId._id +'/page/home/'+ this.uploadSign.key, href: this.editBodyData.images[this.Upload.fileIndex].href })
          } else {
            this.editBodyData.images.push({ src: this.currentEmployer.ownerId._id +'/page/home/'+ this.uploadSign.key, href: '' })
            this.$forceUpdate()
          }
          this.submit(false)
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    submit(isClose = true) {
      this.$store.dispatch(EDIT_BODY_ELEMENT, {_id: this.id, index: this.index, config: [this.editBodyData]})
        .then(() => {
          this.$emit('UpdateItem')
          this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
          this.$forceUpdate()
          if (isClose) this.close();
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    deleteItem() {
      this.$store.dispatch(DELETE_BODY_ELEMENT, {_id: this.id, index: this.index})
        .then(() => {
          this.$emit('DeleteItem')
          this.getPageInfo('body')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
  },
  destroyed () {}
}
</script>
<style scoped>
  .show_upload {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
</style>
