var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    attrs: {
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openItem(_vm.id);
      }
    }
  }, [_vm._t("default"), _vm._v(_vm._s(_vm.$slots.default ? '：' : '')), _vm.itemData && _vm.itemData.message === 'ok' ? _c('span', [_vm._v(_vm._s(_vm._f("expressState")(parseInt(_vm.itemData.state)))), _c('v-icon', {
    staticClass: "ma-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("route")])], 1) : _c('span', [_vm._v("无")])], 2), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "600"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.itemData ? _c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_vm._v(" 物流信息：" + _vm._s(_vm.itemData.nu || '') + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _vm.itemData.message === 'ok' ? _c('v-card-text', [_c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v("时间")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("描述")])])]), _c('tbody', _vm._l(_vm.itemData.data, function (item) {
          return _c('tr', {
            key: item.time
          }, [_c('td', {
            attrs: {
              "nowrap": "true"
            }
          }, [_vm._v(_vm._s(_vm._f("formatTime")(item.time)))]), _c('td', [_vm._v(_vm._s(item.context || item.content || item.areaName || item.status))])]);
        }), 0)];
      },
      proxy: true
    }], null, false, 2757959814)
  })], 1) : _c('v-card-text', [_vm._v(" " + _vm._s(_vm.itemData.message) + " ")])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }