var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.Global.ENV === 'production' && _vm.Global.DOMAIN_NAME && !_vm.Global.DOMAIN_NAME.includes('mdaas.com') && !_vm.Global.IN_PWA ? _c('v-snackbar', {
    attrs: {
      "timeout": -1,
      "max-width": "450",
      "bottom": "",
      "vertical": ""
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.snackbar = false;
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" 暂不访问 ")]), _c('v-btn', _vm._b({
          attrs: {
            "text": "",
            "color": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.gotoLink();
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" 立即跳转 ")])];
      }
    }], null, false, 4030883288),
    model: {
      value: _vm.snackbar,
      callback: function callback($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_c('v-toolbar-title', [_c('v-icon', {
    attrs: {
      "color": "secondary"
    }
  }, [_vm._v("switch_access_shortcut")]), _vm._v(" 新域名启用 ")], 1), _c('v-card-text', [_vm._v(_vm._s(_vm.text))]), _c('iframe', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "mdaas",
      "src": "https://portal.mdaas.com"
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }