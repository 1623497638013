import { CatalogKnowledgeService } from "@/common/http";

import {
  FETCH_KNOWLEDGE_CATALOG_LIST,
  ORDER_KNOWLEDGE_CATALOG_LIST,
  ORDER_KNOWLEDGE_CATALOG_CHILDREN,
  FETCH_KNOWLEDGE_CATALOG,
  PUBLISH_KNOWLEDGE_CATALOG,
  EDIT_KNOWLEDGE_CATALOG,
  DELETE_KNOWLEDGE_CATALOG,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_KNOWLEDGE_CATALOG_LIST,
  SET_KNOWLEDGE_CATALOG_LIST,
  SET_KNOWLEDGE_CATALOG,
} from "./mutations.type"

const state = {
  KBCatalogLoading: true,
  KBCatalogList: [],
  KBCatalogInfo: '',
};

const getters = {
  KBCatalogInfo: state => state.KBCatalogInfo,
  KBCatalogList: state => state.KBCatalogList,
  KBCatalogLoading: state => state.KBCatalogLoading,
};

const actions = {
  [FETCH_KNOWLEDGE_CATALOG_LIST]({ commit }, slug) {
    commit(LOAD_KNOWLEDGE_CATALOG_LIST);
    return new Promise((resolve, reject) => {
      CatalogKnowledgeService.get('/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_KNOWLEDGE_CATALOG_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [ORDER_KNOWLEDGE_CATALOG_LIST]({ commit }, catalogList) {
    commit(SET_KNOWLEDGE_CATALOG_LIST, catalogList);
    return CatalogKnowledgeService.order(catalogList);
  },
  [ORDER_KNOWLEDGE_CATALOG_CHILDREN](context, catalogList) {
    return CatalogKnowledgeService.order(catalogList);
  },
  async [FETCH_KNOWLEDGE_CATALOG]({ commit }, catalogSlug) {
    const { data } = await CatalogKnowledgeService.get('/'+catalogSlug);
    commit(SET_KNOWLEDGE_CATALOG, data);
    return data;
  },
  [PUBLISH_KNOWLEDGE_CATALOG](context, catalog) {
    return CatalogKnowledgeService.create('', catalog);
  },
  [EDIT_KNOWLEDGE_CATALOG](context, catalog) {
    return CatalogKnowledgeService.update(catalog._id, catalog);
  },
  [DELETE_KNOWLEDGE_CATALOG](context, catalog) {
    return CatalogKnowledgeService.destroy(catalog);
  },
};

const mutations = {
  [LOAD_KNOWLEDGE_CATALOG_LIST] (state) {
    state.KBCatalogLoading = true;
  },
  [SET_KNOWLEDGE_CATALOG_LIST](state, { catalog }) {
    state.KBCatalogList = catalog;
    state.KBCatalogLoading = false;
  },
  [SET_KNOWLEDGE_CATALOG](state, catalog) {
    state.KBCatalogInfo = catalog;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
