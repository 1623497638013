<template>
  <v-row v-if="caseData" align="center">
    <v-col cols="2" class="text--secondary text-right">服务流程</v-col>
    <v-col cols="4"><strong>{{caseData.catalog.name}}</strong></v-col>
    <v-col cols="2" class="text--secondary text-right">{{caseData.order_id ? '采购订单' : '服务类型'}}</v-col>
    <v-col cols="4" v-if="caseData.order_id"><span class="v-list-item--link" @click="goToOrder(caseData.order_id.order_number)"><strong>{{caseData.order_id.order_number || '-'}}</strong></span><v-icon class="mx-2">read_more</v-icon></v-col>
    <v-col cols="4" v-else><strong>{{caseData.process_type || '-'}}</strong></v-col>
    <v-col cols="2" class="text--secondary text-right">服务单号</v-col>
    <v-col cols="4"><strong>{{caseData.case_number}}</strong></v-col>
    <v-col cols="2" class="text--secondary text-right">外部单号</v-col>
    <v-col cols="4" v-if="caseData.order_id"><strong>PO：{{caseData.out_number || '-'}} | DT：{{caseData.dt_number || '-'}}</strong></v-col>
    <v-col cols="4" v-else><strong>{{caseData.out_number || '-'}}</strong></v-col>

    <v-col cols="2" class="text--secondary text-right" v-if="caseData.purchase_id && caseData.purchase_id.request_number">外采单号</v-col>
    <v-col cols="4" v-if="caseData.purchase_id && caseData.purchase_id.request_number"><strong>{{caseData.purchase_id.request_number}}</strong></v-col>
    <v-col cols="2" class="text--secondary text-right" v-if="caseData.purchase_id && caseData.purchase_id.request_number">外采处理</v-col>
    <v-col cols="4" v-if="caseData.purchase_id && caseData.purchase_id.request_number"><widgets-employee-dialogs :account="caseData.purchase_id.editor._id" class="v-list-item--link"><strong>{{caseData.purchase_id.editor.personal ? caseData.purchase_id.editor.personal.name : '*未设置姓名'}}</strong><v-icon class="mx-2">read_more</v-icon></widgets-employee-dialogs></v-col>

    <v-col cols="2" class="text--secondary text-right" v-if="caseData.order_id">ERP单号</v-col>
    <v-col cols="4" v-if="caseData.order_id && !editERPNumber">
      <strong>{{caseData.erp_number}}</strong><v-icon class="mx-2" small v-if="caseData.status > 0 && caseData.isEdit" @click="editERPNumber = true; editedItem.erp_number = caseData.erp_number">edit</v-icon>
    </v-col>
    <v-col cols="4" v-else-if="editERPNumber">
      <v-text-field
        v-model="editedItem.erp_number"
        type="text"
        label="修改商务单号"
        placeholder="请输入商务处理单号"
        hide-details
        append-outer-icon="close"
        append-icon="save"
        @click:append="submit(caseData._id)"
        @click:append-outer="editERPNumber = false; editedItem.erp_number = ''"
        outlined
        dense
      ></v-text-field>
    </v-col>
    <v-col cols="2" class="text--secondary text-right" v-if="caseData.order_id">销售人员</v-col>
    <v-col cols="4" v-if="caseData.order_id"><widgets-employee-dialogs :account="caseData.order_id.sales._id" class="v-list-item--link"><strong>{{caseData.order_id.sales.personal ? caseData.order_id.sales.personal.name : '*未设置姓名'}}</strong><v-icon class="mx-2">read_more</v-icon></widgets-employee-dialogs></v-col>
    <v-col cols="2" class="text--secondary text-right">申请人</v-col>
    <v-col cols="4"><widgets-employee-dialogs :account="caseData.creator._id" class="v-list-item--link"><strong>{{caseData.creator.personal ? caseData.creator.personal.name : '*未设置姓名'}}</strong><v-icon class="mx-2">read_more</v-icon></widgets-employee-dialogs></v-col>
    <v-col cols="2" class="text--secondary text-right">申请日期</v-col>
    <v-col cols="4"><strong>{{caseData.createTime | formatTime}}</strong></v-col>
    <v-col cols="2" class="text--secondary text-right">所属公司</v-col>
    <v-col cols="10" @click="openEnterprise(caseData.enterprise._id)"><strong>{{caseData.enterprise.name}}</strong><v-icon class="mx-2">read_more</v-icon><p v-if="caseData.branch">{{ caseData.branch }}</p></v-col>
    <v-col cols="2" class="text--secondary text-right">设备用户</v-col>
    <v-col cols="10" v-if="caseData.account"><widgets-employee-dialogs :account="caseData.account._id" class="v-list-item--link"><strong>{{caseData.account.personal ? caseData.account.personal.name : '*未设置姓名'}}</strong><v-icon class="mx-2">read_more</v-icon></widgets-employee-dialogs></v-col>
    <v-col cols="10" v-else>--</v-col>
    <v-col cols="2" class="text--secondary text-right">交付地址</v-col>
    <v-col cols="4" v-if="!editCreateAddress">
      <span v-if="caseData.order_id"><strong>{{caseData.express_name ? caseData.express_name +' - ' : ''}}</strong></span><span v-if="caseData.order_id"><strong>{{caseData.express_phone ? caseData.express_phone +' - ' : ''}}</strong></span><span><strong>{{caseData.create_address}}</strong></span>
      <v-icon class="mx-2" small v-if="caseData.status === 0 && caseData.isEdit" @click="editCreateAddress = true; editedItem.create_address = caseData.create_address">edit</v-icon>
    </v-col>
    <v-col cols="4" v-else>
      <v-text-field
        v-model="editedItem.create_address"
        type="text"
        label="修改地址"
        placeholder="请输入新的地址"
        hide-details
        append-outer-icon="close"
        append-icon="save"
        @click:append="submit(caseData._id)"
        @click:append-outer="editCreateAddress = false; editedItem.create_address = ''"
        outlined
        dense
      ></v-text-field>
    </v-col>
    <v-col cols="2" class="text--secondary text-right" v-if="caseData.order_id">发票信息</v-col>
    <v-col cols="4" v-if="caseData.order_id">
      <div v-if="caseData.order_id && caseData.order_id.invoice_info">
        <div><strong>{{ caseData.order_id.invoice_info.title || caseData.order_id.invoice_info.delivery_name }}</strong></div>
        <div><strong>{{ caseData.order_id.invoice_info.license_code || caseData.order_id.invoice_info.delivery_phone }}</strong></div>
        <div v-if="caseData.order_id.invoice_info.title">
          <span v-if="caseData.order_id.invoice_info.invoice_type === 1"><strong>增值税专用发票</strong></span>
          <span v-if="caseData.order_id.invoice_info.invoice_type === 0"><strong>增值税普通发票</strong></span>
          <v-icon class="mx-2" small @click="showInvoiceInfo = !showInvoiceInfo">{{showInvoiceInfo ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
        </div>
        <div v-else>
          <span><strong>{{ caseData.order_id.invoice_info.delivery_address }}</strong></span>
        </div>
      </div>
    </v-col>
    <v-col cols="12" v-if="showInvoiceInfo">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card outlined v-if="caseData.order_id && caseData.order_id.invoice_info">
            <v-list :class="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>发票类型</v-list-item-title>
                  <v-list-item-subtitle>{{caseData.order_id.invoice_info.invoice_type === 0 ? '增值税普通发票' : '增值税专用发票'}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>抬头名称</v-list-item-title>
                  <v-list-item-subtitle>{{caseData.order_id.invoice_info.title}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                <v-list-item-title>统一社会信用代码</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.license_code}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                <v-list-item-title>开户行</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.bank_name}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                <v-list-item-title>银行账号</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.bank_account}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                <v-list-item-title>企业地址</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.license_address}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                <v-list-item-title>企业电话</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.license_telephone}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                <v-list-item-title>发票接收人</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.delivery_name}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                <v-list-item-title>接收人电话</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.delivery_phone}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                <v-list-item-title>发票邮寄地址</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.delivery_address}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

    </v-col>
    <v-dialog v-model="dlgEnterprise" persistent scrollable width="700">
      <widgets-enterprise-dialogs v-if="activeId" :id="activeId" :key="activeId" v-on:Close="closeEnterprise()"></widgets-enterprise-dialogs>
    </v-dialog>
  </v-row>
</template>
<script>
import store from "@/store";
import {
  SAVE_CASE
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dlgEnterprise: false,
      activeId: '',
      editCreateAddress: false,
      editERPNumber: false,
      showInvoiceInfo: false,
      editedItem: {
        create_address: '',
        erp_number: '',
      }
    }
  },
  mounted() {},
  watch: {
    dlgEnterprise (val) {
      val || this.closeEnterprise()
    },
  },
  created() {},
  computed: {},
  methods: {
    openEnterprise(corpId) {
      this.activeId = corpId
      this.dlgEnterprise = true
    },
    closeEnterprise() {
      this.dlgEnterprise = false
      this.$nextTick(() => {
        this.activeId = ''
      })
    },
    goToOrder(po_number) {
      let newWin = this.$router.resolve({path: '/dealer/workbench/order/list?key='+ po_number})
      window.open(newWin.href, '_blank')
    },
    submit(caseId) {
      let strUpdate = {}
      strUpdate.form = 'service'
      strUpdate.caseId = caseId
      strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''
      if (this.editedItem.create_address) {
        strUpdate.create_address = this.editedItem.create_address
        this.editCreateAddress = false
      }
      if (this.editedItem.erp_number) {
        strUpdate.erp_number = this.editedItem.erp_number
        this.editERPNumber = false
      }

      store.dispatch(SAVE_CASE, strUpdate)
        .then(() => {
          Object.assign(this.caseData, strUpdate)
          this.$forceUpdate()
          store.commit(SET_ERROR, {msg: '操作成功。', color: 'primary'});
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    }
  }
}
</script>
