<template>
  <v-row v-if="orderData && orderData.rent_info">
    <v-col cols="2" class="text-right">租赁方式</v-col>
    <v-col cols="4" class="text--secondary">
      {{ orderData.rent_info.rent_type === 0 ? '融资租赁' : '经营性租赁' }}
      <v-btn
        icon
        small
        @click="editOrder('rent_type', '租赁方式')"
        v-if="isEdit && orderData.status === 0"
      >
        <v-icon small>edit</v-icon>
      </v-btn>
    </v-col>
<!--    <v-col cols="2" class="text-right">起租日期</v-col>-->
<!--    <v-col cols="4" class="text&#45;&#45;secondary">-->
<!--      {{ orderData.rent_info.lease_start | formatDate }}-->
<!--      <v-btn-->
<!--        icon-->
<!--        small-->
<!--        @click="editOrder('lease_start', '起租日期')"-->
<!--        v-if="isEdit && orderData.status === 0"-->
<!--      >-->
<!--        <v-icon small>edit</v-icon>-->
<!--      </v-btn>-->
<!--    </v-col>-->
    <v-col cols="2" class="text-right">租期</v-col>
    <v-col cols="4" class="text--secondary">
      {{ orderData.rent_info.lease_term / 365 + '年' }}
<!--      <v-btn-->
<!--        icon-->
<!--        small-->
<!--        @click="editOrder('lease_term', '租期')"-->
<!--        v-if="isEdit && orderData.status === 0"-->
<!--      >-->
<!--        <v-icon small>edit</v-icon>-->
<!--      </v-btn>-->
    </v-col>
<!--    <v-col cols="2" class="text-right">到期日期</v-col>-->
<!--    <v-col cols="4" class="text&#45;&#45;secondary">{{ orderData.rent_info.expiry_date | formatDate }}</v-col>-->
    <v-col cols="2" class="text-right">支付周期</v-col>
    <v-col cols="4" class="text--secondary">
      <span v-if="orderData.rent_info.payment_cycle === 0">月付</span>
      <span v-else-if="orderData.rent_info.payment_cycle === 1">季付</span>
      <span v-else-if="orderData.rent_info.payment_cycle === 2">半年付</span>
      <span v-else>年付</span>
<!--      <v-btn-->
<!--        icon-->
<!--        small-->
<!--        @click="editOrder('payment_cycle', '支付周期')"-->
<!--        v-if="isEdit && orderData.status === 0"-->
<!--      >-->
<!--        <v-icon small>edit</v-icon>-->
<!--      </v-btn>-->
    </v-col>
<!--    <v-col cols="2" class="text-right">资方</v-col>-->
<!--    <v-col cols="4" class="text&#45;&#45;secondary">-->
<!--      {{ orderData.rent_info.stakeholders.investor || '-' }}-->
<!--      <v-btn-->
<!--        icon-->
<!--        small-->
<!--        @click="editOrder('stakeholders', '相关利益人')"-->
<!--        v-if="isEdit && orderData.status === 0"-->
<!--      >-->
<!--        <v-icon small>edit</v-icon>-->
<!--      </v-btn>-->
<!--    </v-col>-->
    <v-col cols="2" class="text-right">账期</v-col>
    <v-col cols="4" class="text--secondary">
      {{ orderData.rent_info.payment_period + '天' }}
      <v-btn
        icon
        small
        @click="editOrder('payment_period', '账期')"
        v-if="isEdit && orderData.status === 0"
      >
        <v-icon small>edit</v-icon>
      </v-btn>
    </v-col>
<!--    <v-col cols="2" class="text-right">代理商</v-col>-->
<!--    <v-col cols="4" class="text&#45;&#45;secondary">-->
<!--      {{ orderData.rent_info.stakeholders.agent || '-' }}-->
<!--      <v-btn-->
<!--        icon-->
<!--        small-->
<!--        @click="editOrder('stakeholders', '相关利益人')"-->
<!--        v-if="isEdit && orderData.status === 0"-->
<!--      >-->
<!--        <v-icon small>edit</v-icon>-->
<!--      </v-btn>-->
<!--    </v-col>-->
    <v-col cols="2" class="text-right">期数</v-col>
    <v-col cols="4" class="text--secondary">{{ orderData.rent_info.total_period + '期' }}</v-col>
<!--    <v-col cols="2" class="text-right">回收商</v-col>-->
<!--    <v-col cols="4" class="text&#45;&#45;secondary">-->
<!--      {{ orderData.rent_info.stakeholders.recycler || '-' }}-->
<!--      <v-btn-->
<!--        icon-->
<!--        small-->
<!--        @click="editOrder('stakeholders', '相关利益人')"-->
<!--        v-if="isEdit && orderData.status === 0"-->
<!--      >-->
<!--        <v-icon small>edit</v-icon>-->
<!--      </v-btn>-->
<!--    </v-col>-->
<!--    <v-col cols="2" class="text-right">客户付款日</v-col>-->
<!--    <v-col cols="4" class="text&#45;&#45;secondary">-->
<!--      {{ orderData.rent_info.payment_day }}-->
<!--      <v-btn-->
<!--        icon-->
<!--        small-->
<!--        @click="editOrder('payment_period', '客户付款日')"-->
<!--        v-if="isEdit && orderData.status === 0"-->
<!--      >-->
<!--        <v-icon small>edit</v-icon>-->
<!--      </v-btn>-->
<!--    </v-col>-->
    <v-col cols="2" class="text-right">签约方</v-col>
    <v-col cols="4" class="text--secondary">
      {{ PartyList.find(p => p.code === orderData.rent_info.stakeholders.party) ? PartyList.find(p => p.code === orderData.rent_info.stakeholders.party).name : orderData.rent_info.stakeholders.party}}
      <v-btn
        icon
        small
        @click="editOrder('stakeholders', '相关利益人')"
        v-if="isEdit && orderData.status === 0"
      >
        <v-icon small>edit</v-icon>
      </v-btn>
    </v-col>
    <v-dialog v-model="editDlg" width="600px" persistent>
      <v-form v-model="editValid">
        <v-card>
          <v-card-title>{{ editDlgTitle }}</v-card-title>
          <v-card-text>
            <v-select
              v-if="editDlgTitle === '客户付款日'"
              v-model="editOrderData.payment_day"
              :items="Array.from({ length: 28 }, (v, k) => k + 1)"
              label="客户付款日"
              outlined
              dense
              clearable
            ></v-select>
            <v-select
              v-if="editDlgTitle === '租赁方式'"
              v-model="editOrderData.rent_type"
              :items="rentType"
              label="租赁方式"
              :rules="[rules.selected]"
              item-text="name"
              item-value="code"
              outlined
              dense
            ></v-select>
            <v-menu
              v-if="editDlgTitle === '起租日期'"
              v-model="startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editOrderData.lease_start"
                  label="起租日期"
                  readonly
                  :rules="[rules.required]"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editOrderData.lease_start"
                @input="startMenu = false"
                @change="setEndDate"
              ></v-date-picker>
            </v-menu>
            <v-select
              v-if="editDlgTitle === '租期'"
              v-model="editOrderData.lease_term"
              label="租期（年）"
              outlined
              :rules="[rules.selected]"
              item-text="name"
              item-value="code"
              dense
              :items="leaseTerm"
              @change="setEndDate"
            ></v-select>
            <v-select
              v-if="editDlgTitle === '支付周期'"
              v-model="editOrderData.payment_cycle"
              :items="paymentCycle"
              label="支付周期"
              :rules="[rules.selected]"
              outlined
              item-text="name"
              item-value="code"
              dense
              @change="setEndDate"
            ></v-select>
            <v-text-field
              v-if="editDlgTitle === '账期'"
              v-model="editOrderData.payment_period"
              label="账期（天）"
              :rules="[rules.required]"
              outlined
              dense
              type="number"
            ></v-text-field>
            <v-text-field
              v-if="editDlgTitle === '相关利益人'"
              v-model="editOrderData.stakeholders.investor"
              label="投资方名称"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="editDlgTitle === '相关利益人'"
              v-model="editOrderData.stakeholders.agent"
              label="代理商名称"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="editDlgTitle === '相关利益人'"
              v-model="editOrderData.stakeholders.recycler"
              label="指定回收商"
              outlined
              dense
            ></v-text-field>
            <v-select
              v-if="editDlgTitle === '相关利益人'"
              v-model="editOrderData.stakeholders.party"
              label="签约方"
              outlined
              dense
              :items="PartyList"
              item-text="name"
              item-value="code"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="closeEdit">关闭</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="secondary"
              @click="submitEdit"
              :disabled="!editValid || !isEdit"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { EDIT_ORDER_PURCHASE } from "@/store/actions.type";
import store from "@/store";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import Util from "@/common/util";
export default {
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeId: '',
      enterInfoDlg: false,
      editDlgTitle: "",
      activeAction: "",
      editDlg: false,
      editValid: true,
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
      },
      editOrderData: {
        payment_day: "",
        rent_type: "",
        lease_start: "",
        lease_term: "",
        payment_cycle: "",
        payment_period: "",
        total_period: "",
        expiry_date: "",
        stakeholders: {
          investor: '',
          agent: '',
          recycler: '',
          party: '',
        },
      },
      isEnterpriseLoading: false,
      searchEnterprise: null,
      enterpriseList: [],
      salesList: [],
      isSalesLoading: false,
      searchSales: null,
      startMenu: false,
    }
  },
  computed: {
    ...mapGetters([
      "orderPurchaseInit",
    ]),
    rentType() {
      return Util.categories("rentType");
    },
    leaseTerm() {
      return Util.categories("leaseTerm");
    },
    paymentCycle() {
      return Util.categories("payment_cycle");
    },
    PartyList() {
      return Util.categories('PartyList')
    }
  },
  methods: {
    editOrder(action, title) {
      this.editOrderData = JSON.parse(JSON.stringify(this.orderData.rent_info));
      this.editOrderData.lease_start = this.$options.filters['formatDate'](this.editOrderData.lease_start)
      this.editOrderData.expiry_date = this.$options.filters['formatDate'](this.editOrderData.expiry_date)
      this.editOrderData.rent_type = this.editOrderData.rent_type.toString()
      this.editOrderData.payment_cycle = this.editOrderData.payment_cycle.toString()
      this.activeAction = action;
      this.editDlgTitle = title;
      this.editDlg = true;
    },
    closeEdit() {
      this.editOrderData = JSON.parse(JSON.stringify(this.orderData.rent_info));
      this.editIndex = -1;
      this.activeAction = "";
      this.editDlgTitle = "";
      this.editDlg = false;
    },
    setEndDate() {
      if (this.editOrderData.lease_term) {
        if (this.editOrderData.lease_start) {
          let date = new Date(this.editOrderData.lease_start).getTime() - 1000*60*60*24
          let startDate = new Date(date)
          let year = startDate.getFullYear() + (this.editOrderData.lease_term / 365)
          let endtDate = new Date(startDate.setFullYear(year))
          this.editOrderData.expiry_date = this.$options.filters['formatDate'](endtDate)
        }
        if (this.editOrderData.payment_cycle) {
          if (this.editOrderData.payment_cycle === '0') {
            this.editOrderData.total_period = this.editOrderData.lease_term / 365 * 12
          } else if (this.editOrderData.payment_cycle === '1') {
            this.editOrderData.total_period = this.editOrderData.lease_term / 365 * 4
          } else if (this.editOrderData.payment_cycle === '2') {
            this.editOrderData.total_period = this.editOrderData.lease_term / 365 * 2
          } else {
            this.editOrderData.total_period = this.editOrderData.lease_term / 365
          }
        }
      }
    },
    submitEdit() {
      let json = {};
      let obj = {};

      json.id = this.orderId;
      this.setEndDate()
      obj.rent_info = JSON.parse(JSON.stringify(this.editOrderData))
      delete obj.rent_info.createTime
      delete obj.rent_info.orderId
      delete obj.rent_info.updateTime
      delete obj.rent_info.__v
      delete obj.rent_info._id
      // if (this.activeAction === 'payment_cycle' || this.activeAction === 'lease_term') {
      //   obj.product_list = this.orderData.product_list
      // }

      json.item = obj;

      store
        .dispatch(EDIT_ORDER_PURCHASE, json)
        .then(() => {
          this.closeEdit();
          this.$emit("Update");
          store.commit(SET_ERROR, { msg: "修改成功。", color: "primary" });
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
  },
}
</script>
