import {OrderService } from "@/common/http";

import {
  INIT_ORDER_PURCHASE,
  PUBLISH_ORDER_PURCHASE,
  FETCH_ORDER_PURCHASE_LIST,
  FETCH_ORDER_PURCHASE,
  EDIT_ORDER_PURCHASE,
  CANCEL_ORDER_PURCHASE,
  ADD_MARK_ORDER_PURCHASE,
  REMOVE_MARK_ORDER_PURCHASE
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_ORDER_PURCHASE_LIST,
  SET_ORDER_PURCHASE_LIST,
  SET_ORDER_PURCHASE,
  SET_ORDER_PURCHASE_INIT,
} from "./mutations.type";

const state = {
  orderPurchaseInit: {},
  orderPurchaseLoading: true,
  orderPurchaseList: [],
  orderPurchaseCount: 0,
  orderPurchaseInfo: {},
};

const getters = {
  orderPurchaseInit: state => state.orderPurchaseInit,
  orderPurchaseLoading: state => state.orderPurchaseLoading,
  orderPurchaseList: state => state.orderPurchaseList,
  orderPurchaseCount: state => state.orderPurchaseCount,
  orderPurchaseInfo: state => state.orderPurchaseInfo,
};

const actions = {
  [INIT_ORDER_PURCHASE]({ commit }, params) {
    return new Promise((resolve, reject) => {
      OrderService.get('/purchase/initialize', '?params='+params)
        .then(({ data }) => {
          commit(SET_ORDER_PURCHASE_INIT, data);
          resolve(data);
        })
        .catch(({response}) => {
          commit(SET_ERROR, { msg: response.data.message });
          reject(response);
        })
    });
  },
  [PUBLISH_ORDER_PURCHASE](context, params) {
    return new Promise((resolve, reject) => {
      OrderService.create('/purchase', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        })
    });
  },
  [FETCH_ORDER_PURCHASE]({ commit }, params) {
    commit(LOAD_ORDER_PURCHASE_LIST);
    return new Promise((resolve, reject) => {
      OrderService.get('/purchase', params.query)
        .then(({ data }) => {
          commit(SET_ORDER_PURCHASE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [EDIT_ORDER_PURCHASE](context, params) {
    return OrderService.update('/purchase', params.id, params.item);
  },
  [FETCH_ORDER_PURCHASE_LIST]({ commit }, params) {
    commit(LOAD_ORDER_PURCHASE_LIST);
    return new Promise((resolve, reject) => {
      OrderService.get('/purchase/list', '?'+params.query)
        .then(({ data }) => {
          commit(SET_ORDER_PURCHASE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [CANCEL_ORDER_PURCHASE](context, params) {
    return OrderService.update('/purchase/cancel', params.orderId, params)
  },
  [ADD_MARK_ORDER_PURCHASE](context, params) {
    return OrderService.create('/bookmaker/'+params)
  },
  [REMOVE_MARK_ORDER_PURCHASE](context, params) {
    return OrderService.delete('/bookmaker/'+params)
  }
}

const mutations = {
  [SET_ORDER_PURCHASE_INIT] (state, info) {
    state.orderPurchaseInit = info;
  },
  [LOAD_ORDER_PURCHASE_LIST] (state) {
    state.orderPurchaseLoading = true;
  },
  [SET_ORDER_PURCHASE_LIST](state, list) {
    state.orderPurchaseCount = list.query.count;
    state.orderPurchaseList = list.orders;
    state.orderPurchaseLoading = false;
  },
  [SET_ORDER_PURCHASE](state, order) {
    state.orderPurchaseInfo = order.orderInfo;
    state.orderPurchaseLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
