var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-autocomplete', {
    attrs: {
      "rules": [_vm.rules.required],
      "items": _vm.employeeResult.filter(function (item) {
        return item.personal && item.personal.name;
      }),
      "loading": _vm.searchEmployee.loadingList,
      "search-input": _vm.searchEmployee.searchList,
      "filter": _vm.filterEmployee,
      "append-icon": "add",
      "return-object": "",
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "选择用户",
      "placeholder": "输入员工姓名、电话或手机号码进行查找",
      "clearable": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        return _vm.$set(_vm.searchEmployee, "searchList", $event);
      },
      "update:search-input": function updateSearchInput($event) {
        return _vm.$set(_vm.searchEmployee, "searchList", $event);
      },
      "change": _vm.selectedEmployee,
      "click:append": _vm.createEmployee
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.account,
      callback: function callback($$v) {
        _vm.account = $$v;
      },
      expression: "account"
    }
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgEmployee,
      callback: function callback($$v) {
        _vm.dlgEmployee = $$v;
      },
      expression: "dlgEmployee"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitEmployee.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validEmployee,
      callback: function callback($$v) {
        _vm.validEmployee = $$v;
      },
      expression: "validEmployee"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("创建员工")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEmployee
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "start",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('input', {
    ref: "uploadAvatar",
    attrs: {
      "id": "filesAvatar",
      "type": "file",
      "name": "filesAvatar",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectAvatar($event);
      }
    }
  }), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-avatar', [_c('v-img', {
          attrs: {
            "src": _vm.employee.editedItem.profile ? _vm.ossURL + '/' + _vm.employee.editedItem.profile : '/static/grey_silhouette.png',
            "alt": "设置头像",
            "aspect-ratio": "1"
          }
        }, [_vm.employee.uploading ? _c('v-progress-circular', {
          attrs: {
            "color": "secondary",
            "rotate": "360",
            "size": "48",
            "width": "5",
            "value": _vm.employee.progressUpload
          }
        }) : _vm._e()], 1), hover ? _c('div', {
          staticClass: "show_upload",
          staticStyle: {
            "height": "100%",
            "cursor": "pointer"
          }
        }, [_c('v-icon', {
          on: {
            "click": function click($event) {
              return _vm.selectAvatar();
            }
          }
        }, [_vm._v("add_a_photo")])], 1) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "员工姓名",
      "hint": "员工姓名为用户的真实姓名",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.employee.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "name", $$v);
      },
      expression: "employee.editedItem.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("https")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "error-messages": _vm.employee.editedItem.msgName,
      "clearable": _vm.employee.editedItem.msgName !== '',
      "type": "text",
      "label": "电子邮件",
      "hint": "电子邮件将作为登陆名称使用",
      "outlined": "",
      "dense": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkName ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.employee.editedItem.username,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "username", $$v);
      },
      expression: "employee.editedItem.username"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("vpn_key")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": _vm.employee.editedItem.showPass ? 'text' : 'password',
      "append-icon": _vm.employee.editedItem.showPass ? 'visibility_off' : 'visibility',
      "label": "账号密码",
      "hint": "请牢记此密码，密码将作为登陆凭证",
      "outlined": "",
      "dense": "",
      "required": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.employee.editedItem.showPass = !_vm.employee.editedItem.showPass;
      }
    },
    model: {
      value: _vm.employee.editedItem.password,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "password", $$v);
      },
      expression: "employee.editedItem.password"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("stay_current_portrait")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "error-messages": _vm.employee.editedItem.msgPhone,
      "clearable": _vm.employee.editedItem.msgPhone !== '',
      "type": "number",
      "label": "手机号码",
      "hint": "手机号码可作为登陆名称使用",
      "outlined": "",
      "color": "secondary",
      "dense": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkPhone ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.employee.editedItem.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "phone", $$v);
      },
      expression: "employee.editedItem.phone"
    }
  })], 1)], 1), _vm.enterprise.branch ? _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("account_tree")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.enterprise.branch,
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择员工所属分公司"
    },
    model: {
      value: _vm.employee.editedItem.sub_corp,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "sub_corp", $$v);
      },
      expression: "employee.editedItem.sub_corp"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("supervisor_account")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.flatGroupRoles(_vm.groupList),
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择所属角色",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_typeof(data.item) !== 'object' ? [_c('v-list-item-content', {
          domProps: {
            "textContent": _vm._s(data.item)
          }
        })] : [_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "input-value": data.attrs.inputValue
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        })], 1)]];
      }
    }]),
    model: {
      value: _vm.employee.editedItem.roles,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "roles", $$v);
      },
      expression: "employee.editedItem.roles"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("integration_instructions")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工编号",
      "hint": "员工编号为企业内部的员工编号",
      "outlined": "",
      "color": "secondary",
      "dense": ""
    },
    model: {
      value: _vm.employee.editedItem.employeeNo,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "employeeNo", $$v);
      },
      expression: "employee.editedItem.employeeNo"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("room")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "error-messages": _vm.employee.editedItem.msgAddress,
      "clearable": _vm.employee.editedItem.msgAddress !== '',
      "type": "text",
      "label": "收件地址",
      "hint": "收件地址为接收设备的目的地",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-fade-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkAddress ? _c('v-progress-circular', {
          attrs: {
            "width": "2",
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "append-outer",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.copyAddress(_vm.enterprise);
            }
          }
        }, [_vm._v("复制公司地址")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.employee.editedItem.address,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "address", $$v);
      },
      expression: "employee.editedItem.address"
    }
  })], 1)], 1), _vm._l(this.employee.editedItem.customFields, function (item, i) {
    return _c('v-row', {
      key: i + '_field',
      staticClass: "mt-n6",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-center",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-icon', {
      staticClass: "mt-n6",
      attrs: {
        "color": "primary lighten-4"
      }
    }, [_vm._v("list")])], 1), item.format.type === 'text' ? _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [item.format.mask ? _c('v-text-field', {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: item.format.mask,
        expression: "item.format.mask"
      }],
      attrs: {
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _c('v-text-field', {
      attrs: {
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.format.type === 'selects' ? _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": item.format.items,
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e()], 1);
  })], 2)], 1), _c('v-divider'), _c('v-card-actions', [_c('widgets-custom-field', {
    attrs: {
      "module": "Account",
      "ownerId": _vm.enterprise._id,
      "ownerType": "Enterprise"
    },
    on: {
      "Complete": function Complete($event) {
        return _vm.updateFields();
      }
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEmployee
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.validEmployee
    },
    on: {
      "click": _vm.submitEmployee
    }
  }, [_vm._v("创建")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }