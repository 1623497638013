var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerAsset,
      "items": _vm.selectedAsset,
      "server-items-length": _vm.selectedAsset.length,
      "footer-props": {
        'items-per-page-options': [5, 10, 15]
      },
      "item-key": "_id",
      "return-object": "false",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('widgets-asset-dialogs', {
          attrs: {
            "getId": item._id
          }
        }, [_c('div', [item.isLock ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("lock")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("设备锁定中")])]) : _vm._e(), _vm._v(" " + _vm._s(item.name) + " "), item.ownerType === 'Enterprise' ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("corporate_fare")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("企业自有设备")])]) : item.ownerType === 'Account' ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("person")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("个人自有设备")])]) : _vm._e()], 1)])];
      }
    }, {
      key: "item.product",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.product ? _c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.openProduct(item.product);
            }
          }
        }, [_vm._v(_vm._s(item.product.name) + " - " + _vm._s(item.product.part_number))]) : _vm._e()];
      }
    }, {
      key: "item.state",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.state.name) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.unlinkAsset(item);
            }
          }
        }, [_vm._v("remove")])];
      }
    }])
  }), _c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openCreateAsset();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("创建新的设备")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.selectAsset();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("link")]), _vm._v("关联现有设备")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "750px"
    },
    model: {
      value: _vm.dlgAsset,
      callback: function callback($$v) {
        _vm.dlgAsset = $$v;
      },
      expression: "dlgAsset"
    }
  }, [_c('widgets-select-asset', {
    key: "selectAsset",
    ref: "select_asset",
    attrs: {
      "vendor": _vm.vendor,
      "enterprise": _vm.enterprise,
      "isRepairService": true,
      "selectItem": _vm.selectedAsset
    },
    on: {
      "Close": _vm.closeAsset,
      "Selected": _vm.setAssetList
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'Product' ? _c('widgets-product-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.closeInfo();
      }
    }
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgCreateAsset,
      callback: function callback($$v) {
        _vm.dlgCreateAsset = $$v;
      },
      expression: "dlgCreateAsset"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.createAsset.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validAsset,
      callback: function callback($$v) {
        _vm.validAsset = $$v;
      },
      expression: "validAsset"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 新增设备 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeCreateAsset
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_vm.currentUser ? _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.currentUser.hasAdmin.includes('enterprise') || _vm.currentUser.employer.ownerType === 'Dealer' ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 设备所属 ")]) : _vm._e(), _vm.currentUser.hasAdmin.includes('enterprise') || _vm.currentUser.employer.ownerType === 'Dealer' ? _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.assetForm.ownerType,
      "rules": [_vm.rules.required],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "设备所属",
      "placeholder": "设备归属人"
    },
    model: {
      value: _vm.assetForm.editedItem.ownerType,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "ownerType", $$v);
      },
      expression: "assetForm.editedItem.ownerType"
    }
  })], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('enterprise') || _vm.currentUser.employer.ownerType === 'Dealer' ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 设备用户 ")]) : _vm._e(), _vm.currentUser.hasAdmin.includes('enterprise') || _vm.currentUser.employer.ownerType === 'Dealer' ? _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "rules": [_vm.rules.required],
      "items": _vm.employeeList,
      "return-object": "",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "item-text": "personal.name",
      "item-value": "_id",
      "label": "选择用户",
      "hide-details": "",
      "placeholder": "输入员工姓名、电话或手机号码进行查找"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }], null, false, 59584339),
    model: {
      value: _vm.assetForm.editedItem.account,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "account", $$v);
      },
      expression: "assetForm.editedItem.account"
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 品牌厂商 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.vendorList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "产品供应商",
      "placeholder": "请选择产品供应商"
    },
    on: {
      "change": _vm.setProductList
    },
    model: {
      value: _vm.assetForm.editedItem.vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "vendor", $$v);
      },
      expression: "assetForm.editedItem.vendor"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 品牌类型 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.typeList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "产品类型",
      "placeholder": "请选择产品类型"
    },
    on: {
      "change": _vm.setProductList
    },
    model: {
      value: _vm.assetForm.editedItem.product_type,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "product_type", $$v);
      },
      expression: "assetForm.editedItem.product_type"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 产品 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.productList,
      "rules": [_vm.rules.required, _vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "关联产品",
      "placeholder": "请选择设备关联的产品"
    },
    on: {
      "change": function change($event) {
        return _vm.setWarrantyTime();
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name) + " - " + _vm._s(data.item.part_number))]), data.item.it_info ? _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.it_info.ram) + "G - " + _vm._s(data.item.it_info.network))]) : _vm._e()], 1)];
      }
    }], null, false, 3405991014),
    model: {
      value: _vm.assetForm.editedItem.product,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "product", $$v);
      },
      expression: "assetForm.editedItem.product"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 序列号 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "序列号",
      "placeholder": "产品唯一标示SN号码",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.assetForm.editedItem.serial_number,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "serial_number", $$v);
      },
      expression: "assetForm.editedItem.serial_number"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeCreateAsset
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "depressed": "",
      "disabled": !_vm.validAsset
    },
    on: {
      "click": _vm.createAsset
    }
  }, [_vm._v("创建")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }