<template>
  <div :id="title" :style="{ width: width, height: height }"></div>
</template>
<script>
import * as echarts from 'echarts';
import "@/common/echarts.theme";
export default {
  props: {
    title: {
      type: String,
    },
    label: {
      type: Array,
    },
    data: {
      type: Array,
    },
    width: {
      type: String
    },
    height: {
      type: String
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.drawLine();
    })

  },
  watch: {
    data() {
      this.drawLine();
    }
  },
  methods: {
    drawLine() {
      let myChart = echarts.init(document.getElementById(this.title), 'espreport2');
      myChart.setOption({
        title: {
          text: this.title,
          left: 'center',
        },
        angleAxis: {
          type: 'category',
          data: this.label,
          boundaryGap: true,
          axisLine: {
            show: false
          },
          splitArea: {
            show: true
          },
        },
        radiusAxis: {
          type: 'value',
          axisLine: {
            show: true
          },
          z: 2
        },
        tooltip: {},
        polar: {},
        legend: {
          show: true,
          bottom: 10,
          left: 'center',
          data: this.data.map(item => item.name)
        },
        series: this.data
      })
    }
  }
}
</script>
