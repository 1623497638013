<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">选择设备</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="searchAsset"
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索用户名称..."
          prepend-inner-icon="search"
          outlined
        ></v-text-field>
      </v-card-title>
    </v-card>
  </div>
</template>
<script>

export default {
  props: {
    enterprise: {
      type: String,
      required: true,
    },
    maxLimit: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      query: {
        key: '',
        enterprise: this.enterprise
      },

    }
  }
}

</script>
