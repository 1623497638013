<template>
  <v-card outlined>
    <v-list :class="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>联系人</v-list-item-title>
          <v-list-item-subtitle>{{branch.contactName}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>联系电话</v-list-item-title>
          <v-list-item-subtitle>{{branch.contactPhone}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>联系地址</v-list-item-title>
          <v-list-item-subtitle>{{branch.address}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
export default {
  props: {
    branch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  created() {},
  computed: {},
  methods: {}
}
</script>
