var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.notesList ? _c('v-card-text', [_c('v-list', {
    attrs: {
      "three-line": "",
      "dense": ""
    }
  }, [_vm._l(_vm.notesList, function (item, i) {
    return [_c('v-list-item', {
      key: 'remarks_' + i
    }, [_c('widgets-employee-dialogs', {
      attrs: {
        "account": item.author._id
      }
    }, [_c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": item.author.personal && item.author.personal.profile ? _vm.ossURL + '/' + item.author.personal.profile : '/static/grey_silhouette.png'
      }
    })], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.author._id === _vm.currentUser._id ? "我" : item.author.personal.name) + " - " + _vm._s(_vm._f("fromDate")(item.createTime)) + " "), item.status === 1 ? _c('span', {
      staticClass: "grey"
    }, [_vm._v(" - 已作废")]) : item.private ? _c('span', {
      staticClass: "red--text text--accent-3"
    }, [_vm._v(" - 仅个人可见")]) : item.public ? _c('span', {
      staticClass: "light-blue--text text--accent-3"
    }, [_vm._v(" - 客户可见")]) : _vm._e()]), _c('v-subheader', [item.status === 1 ? _c('span', {
      staticClass: "grey",
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    }) : item.private ? _c('span', {
      staticClass: "red--text text--accent-3",
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    }) : item.public ? _c('span', {
      staticClass: "light-blue--text text--accent-3",
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    }) : _c('span', {
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    }, [_vm._v(_vm._s(item.content))])])], 1), item.author._id === _vm.currentUser._id && item.status === 0 && item._id ? _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.blockItem(item._id);
        }
      }
    }, [_vm._v("block")])], 1)], 1) : _vm._e()], 1), _c('v-divider', {
      key: 'remarks_divider_' + i,
      attrs: {
        "inset": ""
      }
    })];
  }), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openEditDialog();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("rate_review")]), _vm._v("增加备注 ")], 1)], 1)], 1)], 1)], 2), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "550px"
    },
    model: {
      value: _vm.editDialog,
      callback: function callback($$v) {
        _vm.editDialog = $$v;
      },
      expression: "editDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("增加备注"), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEditDialog();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "\u4EC5\u4E2A\u4EBA\u53EF\u89C1: ".concat(_vm.editedItem.private ? '是' : '否')
    },
    model: {
      value: _vm.editedItem.private,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "private", $$v);
      },
      expression: "editedItem.private"
    }
  })], 1), _vm.entityType !== 'PurchaseOrder' ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "\u5BA2\u6237\u53EF\u89C1: ".concat(_vm.editedItem.public ? '是' : '否')
    },
    model: {
      value: _vm.editedItem.public,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "public", $$v);
      },
      expression: "editedItem.public"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "备注内容",
      "value": ""
    },
    model: {
      value: _vm.editedItem.content,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "content", $$v);
      },
      expression: "editedItem.content"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEditDialog();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.editedItem.content
    },
    on: {
      "click": function click($event) {
        return _vm.createItem();
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1) : _c('v-overlay', {
    attrs: {
      "value": _vm.notesLoading,
      "z-index": "9999"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }