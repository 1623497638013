<template>
  <div>
    <span :color="color" dark text class="v-list-item--link" @click="doAction('open_case')">
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    <v-dialog v-model="dialog" persistent scrollable fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card v-if="caseData && caseData.catalog && caseData.creator && caseData.enterprise" :loading="caseLoading ? 'secondary' : false">
          <case-card-title :caseData="caseData" v-on:Close="closeDialog"></case-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-expansion-panels v-model="openPanel" multiple accordion flat>
              <v-expansion-panel>
                <v-expansion-panel-header>申请信息</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <case-basic-info :caseData="caseData"></case-basic-info>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-divider></v-divider>
                <v-expansion-panel-header>详细信息</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <case-rent-info :caseData="caseData"></case-rent-info>
                  <v-row>
                    <v-col cols="12">
                      <v-tabs>
                        <v-tab v-if="caseData.order_id">订单产品</v-tab>
                        <v-tab>关联设备</v-tab>
                        <v-tab v-if="caseData.process && caseData.process.isRecovery">更换新设备</v-tab>
                        <v-tab>附件</v-tab>
                        <v-tab>备注</v-tab>
                        <v-tab-item v-if="caseData.order_id">
                          <case-order-product :caseData="caseData" @Update="getCase(getId)"></case-order-product>
                        </v-tab-item>
                        <v-tab-item>
                          <case-link-asset-batch v-if="caseData.order_id" :caseData="caseData"></case-link-asset-batch>
                          <case-link-asset v-else :caseData="caseData" assetKey="link_asset"></case-link-asset>
                        </v-tab-item>
                        <v-tab-item v-if="caseData.process && caseData.process.isRecovery">
                          <case-link-asset :caseData="caseData" assetKey="link_asset_new" :isWareHouse="true"></case-link-asset>
                        </v-tab-item>
                        <v-tab-item>
                          <widgets-files-list entityType="Case" :entityId="getId" :type=1></widgets-files-list>
<!--                          <case-appendix-files :caseData="caseData"></case-appendix-files>-->
                        </v-tab-item>
                        <v-tab-item>
<!--                          <case-remarks :caseData="caseData"></case-remarks>-->
                          <widgets-remarks-list entityType="Case" :entityId="getId" :type=0></widgets-remarks-list>
                        </v-tab-item>
                      </v-tabs>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-divider></v-divider>
                <v-expansion-panel-header>相关任务</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <case-task ref="edit_task" :caseData="caseData" @Update="updateEditTask"></case-task>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-divider></v-divider>
                <v-expansion-panel-header>历史记录</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <case-action-logs :caseData="caseData"></case-action-logs>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-divider></v-divider>
          <case-card-actions :caseData="caseData" :editedTask="editedTask" v-on:Close="closeDialog" @Update="updateDialog"></case-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  FETCH_CASE,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import store from "@/store";
export default {
  props: {
    getId: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      caseData: {},
      openPanel: [],
      editedTask: {},
    }
  },
  computed: {
    ...mapGetters(['currentEmployer',  'caseLoading']),
  },
  mounted() { },
  watch: {
    dialog (val) {
      val || this.closeDialog()
    },
  },
  methods: {
    updateEditTask(info) {
      this.editedTask = info
    },
    closeDialog () {
      if (this.dialog) {
        this.dialog = false
        this.$emit('Close')
        this.$nextTick(() => {
          this.caseData = Object.assign({}, {})
          this.currentTask = Object.assign({}, {})
          this.openPanel = []
        })
      }
    },
    updateDialog() {
      this.getCase(this.getId)
    },
    getCase(id) {
      store.dispatch(FETCH_CASE, {form: 'service', query: id})
        .then((data) => {
          this.caseData = Object.assign({}, data)
          if (data.status === 0) {
            this.openPanel.push(0)
            this.openPanel.push(2)
          } else if (data.status === 1) {
            this.openPanel.push(0)
            this.openPanel.push(2)
          } else {
            this.openPanel.push(2)
            this.openPanel.push(3)
          }
          if (data.order_id) this.openPanel.push(1)
          this.$forceUpdate();
        })
    },
    doAction(action) {
      switch (action) {
        case 'open_case': {
          this.getCase(this.getId)
          this.dialog = true
          break
        }
      }
    },
  }
}
</script>
<style scoped>
</style>
