var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "accordion": "",
      "hover": "",
      "multiple": ""
    }
  }, _vm._l(_vm.assetList, function (item, i) {
    return _c('v-expansion-panel', {
      key: i,
      on: {
        "click": function click($event) {
          return _vm.setFields(item._id, i);
        }
      }
    }, [_c('v-expansion-panel-header', [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('v-col', {
      staticClass: "text--secondary",
      attrs: {
        "cols": "8"
      }
    }, [_vm._v(" " + _vm._s(item.product.name) + " ")])], 1)], 1), _c('v-expansion-panel-content', _vm._l(_vm.assetField, function (field) {
      return _c('v-row', {
        key: field.field,
        attrs: {
          "align": "center",
          "no-gutters": ""
        }
      }, [_vm.editedAsset[i] ? _c('v-col', {
        attrs: {
          "cols": "12"
        }
      }, [field.field === 'serial_number' ? _c('v-row', {
        attrs: {
          "align": "center"
        }
      }, [_c('v-col', {
        staticClass: "text-right",
        attrs: {
          "cols": "2"
        }
      }, [_vm._v(_vm._s(field.name))]), !_vm.editedAsset[i].edited_serial_number ? _c('v-col', {
        staticClass: "text--secondary",
        attrs: {
          "cols": "10"
        }
      }, [_vm._v(" " + _vm._s(item.serial_number) + " "), _c('v-icon', {
        staticClass: "mx-2",
        attrs: {
          "small": ""
        },
        on: {
          "click": function click($event) {
            _vm.editedAsset[i].edited_serial_number = true;
            _vm.editedAsset[i].serial_number = item.serial_number;
          }
        }
      }, [_vm._v("edit")])], 1) : _c('v-col', {
        staticClass: "text--secondary",
        attrs: {
          "cols": "10"
        }
      }, [_c('v-text-field', {
        attrs: {
          "rules": [_vm.rules.required],
          "type": "text",
          "label": "修改序列号",
          "placeholder": "请输入新的序列号",
          "hide-details": "",
          "append-outer-icon": "close",
          "outlined": "",
          "dense": ""
        },
        on: {
          "click:append-outer": function clickAppendOuter($event) {
            _vm.editedAsset[i].edited_serial_number = false;
            _vm.editedAsset[i].serial_number = '';
          }
        },
        model: {
          value: _vm.editedAsset[i].serial_number,
          callback: function callback($$v) {
            _vm.$set(_vm.editedAsset[i], "serial_number", $$v);
          },
          expression: "editedAsset[i].serial_number"
        }
      })], 1)], 1) : _vm._e(), field.field === 'label' ? _c('v-row', {
        attrs: {
          "align": "center"
        }
      }, [_c('v-col', {
        staticClass: "text-right",
        attrs: {
          "cols": "2"
        }
      }, [_vm._v(_vm._s(field.name))]), !_vm.editedAsset[i].edited_label ? _c('v-col', {
        staticClass: "text--secondary",
        attrs: {
          "cols": "10"
        }
      }, [_vm._l(item.label, function (label, index) {
        return _c('v-chip', {
          key: index,
          staticClass: "mx-2",
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v(_vm._s(label))]);
      }), _c('v-icon', {
        staticClass: "mx-2",
        attrs: {
          "small": ""
        },
        on: {
          "click": function click($event) {
            _vm.editedAsset[i].edited_label = true;
            _vm.editedAsset[i].label = item.label;
          }
        }
      }, [_vm._v("edit")])], 2) : _c('v-col', {
        staticClass: "text--secondary",
        attrs: {
          "cols": "10"
        }
      }, [_c('v-combobox', {
        attrs: {
          "label": "设备标签",
          "placeholder": "使用回车键可以输入多个标签。如内部设备代码、编码等信息",
          "hide-details": "",
          "deletable-chips": "",
          "multiple": "",
          "small-chips": "",
          "outlined": "",
          "dense": "",
          "append-outer-icon": "close"
        },
        on: {
          "click:append-outer": function clickAppendOuter($event) {
            _vm.editedAsset[i].edited_label = false;
            _vm.editedAsset[i].label = [];
          }
        },
        model: {
          value: _vm.editedAsset[i].label,
          callback: function callback($$v) {
            _vm.$set(_vm.editedAsset[i], "label", $$v);
          },
          expression: "editedAsset[i].label"
        }
      })], 1)], 1) : _vm._e(), field.field === 'out_number' ? _c('v-row', {
        attrs: {
          "align": "center"
        }
      }, [_c('v-col', {
        staticClass: "text-right",
        attrs: {
          "cols": "2"
        }
      }, [_vm._v(_vm._s(field.name))]), !_vm.editedAsset[i].edited_out_number ? _c('v-col', {
        staticClass: "text--secondary",
        attrs: {
          "cols": "10"
        }
      }, [_vm._v(" " + _vm._s(item.out_number) + " "), _c('v-icon', {
        staticClass: "mx-2",
        attrs: {
          "small": ""
        },
        on: {
          "click": function click($event) {
            _vm.editedAsset[i].edited_out_number = true;
            _vm.editedAsset[i].out_number = item.out_number;
          }
        }
      }, [_vm._v("edit")])], 1) : _c('v-col', {
        staticClass: "text--secondary",
        attrs: {
          "cols": "10"
        }
      }, [_c('v-text-field', {
        attrs: {
          "type": "text",
          "label": "修改设备外部编号",
          "placeholder": "请输入新的序列号",
          "hide-details": "",
          "append-outer-icon": "close",
          "outlined": "",
          "dense": ""
        },
        on: {
          "click:append-outer": function clickAppendOuter($event) {
            _vm.editedAsset[i].edited_out_number = false;
            _vm.editedAsset[i].out_number = '';
          }
        },
        model: {
          value: _vm.editedAsset[i].out_number,
          callback: function callback($$v) {
            _vm.$set(_vm.editedAsset[i], "out_number", $$v);
          },
          expression: "editedAsset[i].out_number"
        }
      })], 1)], 1) : _vm._e(), field.field === 'product' ? _c('v-row', {
        attrs: {
          "align": "center"
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "12"
        }
      }, [_vm._v(_vm._s(item.product))]), _c('v-col', {
        staticClass: "text-right",
        attrs: {
          "cols": "2"
        }
      }, [_vm._v("产品")]), !_vm.editedAsset[i].edited_product ? _c('v-col', {
        staticClass: "text--secondary",
        attrs: {
          "cols": "10"
        }
      }, [_vm._v(" " + _vm._s(item.product.name) + " "), _c('v-icon', {
        staticClass: "mx-2",
        attrs: {
          "small": ""
        },
        on: {
          "click": function click($event) {
            _vm.editedAsset[i].edited_product = true;
            _vm.editedAsset[i].product = item.product;
            _vm.editedAsset[i].vendor = item.product.vendor;
            _vm.editedAsset[i].product_type = item.product.product_type;
          }
        }
      }, [_vm._v("edit")])], 1) : _c('v-col', {
        staticClass: "text--secondary",
        attrs: {
          "cols": "10"
        }
      }, [_c('v-row', {
        attrs: {
          "no-gutters": "",
          "align": "center"
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "4"
        }
      }, [_c('v-autocomplete', {
        attrs: {
          "autocomplete": "off",
          "items": _vm.vendorList,
          "label": "品牌厂商",
          "placeholder": "选择产品品牌厂商",
          "hide-details": "",
          "item-text": "name",
          "item-value": "_id",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.doAction('set_product_list', i);
          }
        },
        model: {
          value: _vm.editedAsset[i].vendor,
          callback: function callback($$v) {
            _vm.$set(_vm.editedAsset[i], "vendor", $$v);
          },
          expression: "editedAsset[i].vendor"
        }
      })], 1), _c('v-col', {
        attrs: {
          "cols": "4"
        }
      }, [_c('v-autocomplete', {
        staticClass: "mx-2",
        attrs: {
          "autocomplete": "off",
          "items": _vm.typeList,
          "label": "产品类别",
          "placeholder": "选择产品类别",
          "hide-details": "",
          "item-text": "name",
          "item-value": "_id",
          "outlined": "",
          "dense": ""
        },
        on: {
          "change": function change($event) {
            return _vm.doAction('set_product_list', i);
          }
        },
        model: {
          value: _vm.editedAsset[i].product_type,
          callback: function callback($$v) {
            _vm.$set(_vm.editedAsset[i], "product_type", $$v);
          },
          expression: "editedAsset[i].product_type"
        }
      })], 1), _c('v-col', {
        attrs: {
          "cols": "4"
        }
      }, [_c('v-autocomplete', {
        attrs: {
          "autocomplete": "off",
          "items": _vm.productList,
          "label": "产品",
          "placeholder": "选择产品型号",
          "hide-details": "",
          "item-text": "name",
          "item-value": "_id",
          "outlined": "",
          "dense": "",
          "append-outer-icon": "close"
        },
        on: {
          "change": function change($event) {
            return _vm.doAction('set_warranty_time', i);
          },
          "click:append-outer": function clickAppendOuter($event) {
            _vm.editedAsset[i].edited_product = false;
            _vm.editedAsset[i].vendor = '';
            _vm.editedAsset[i].product_type = '';
            _vm.editedAsset[i].product = '';
          }
        },
        scopedSlots: _vm._u([{
          key: "item",
          fn: function fn(data) {
            return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name) + " - " + _vm._s(data.item.part_number))]), data.item.it_info ? _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.it_info.ram) + "G - " + _vm._s(data.item.it_info.network))]) : _vm._e()], 1)];
          }
        }], null, true),
        model: {
          value: _vm.editedAsset[i].product,
          callback: function callback($$v) {
            _vm.$set(_vm.editedAsset[i], "product", $$v);
          },
          expression: "editedAsset[i].product"
        }
      })], 1)], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()], 1);
    }), 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }