<template>
  <div>
    <span :color="color" dark text class="v-list-item--link" @click="openItem(getId)">
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    <v-dialog v-model="dialog" persistent scrollable fullscreen hide-overlay transition="dialog-bottom-transition" :retain-focus="false" eager>
      <v-card v-if="itemData && itemData.enterprise" :loading="monthlyLoading ? 'secondary' : false">
        <v-card-title>
          {{itemData.enterprise.name}}【{{itemData.month ? itemData.year : itemData.year - 1}}年{{itemData.month ? itemData.month : 12 }}月】统计报告
          <v-chip class="ma-2" label small v-if="itemData.isRelease" color="primary">已发布</v-chip>
          <v-chip class="ma-2" label small v-else color="secondary">未发布</v-chip>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-expansion-panels v-model="openPanel" multiple accordion flat hover>
            <v-expansion-panel>
              <v-expansion-panel-header>统计图表</v-expansion-panel-header>
              <v-expansion-panel-content>
                <template v-for="(report, index) in itemData.data_item">
                  <report-bar-simple
                    v-if="report.graph === 'bar'"
                    :width="windowSize.x - 75 +'px'"
                    height="300px"
                    :label="report.label"
                    :data="report.data"
                    :title="report.name"
                    :key="index">
                  </report-bar-simple>
                  <report-line-stack
                    v-if="report.graph === 'line'"
                    :width="windowSize.x - 75 +'px'"
                    :title="report.name"
                    :label="report.label"
                    :data="JSON.parse(report.data)"
                    height="300px"
                    :key="index">
                  </report-line-stack>
                  <report-bar-polar
                    v-if="report.graph === 'barpolar'"
                    :width="windowSize.x - 75 +'px'"
                    height="600px"
                    :label="report.label"
                    :data="JSON.parse(report.data)"
                    :title="report.name"
                    :key="index">
                  >
                  </report-bar-polar>
                  <report-bar-stack
                    v-if="report.graph === 'stack'"
                    :width="windowSize.x - 75 +'px'"
                    height="300px"
                    :label="report.label"
                    :data="JSON.parse(report.data)"
                    :title="report.name"
                    :legend="report.legend"
                    :key="index">
                  </report-bar-stack>
                  <v-textarea
                    outlined
                    v-model="report.analyse"
                    class="mx-12"
                    label="图表分析"
                    rows="3"
                    :key="index +'_analyse'"
                  ></v-textarea>
                  <v-textarea
                    outlined
                    v-model="report.propose"
                    class="mx-12"
                    label="建议计划"
                    rows="3"
                    :key="index +'_propose'"
                  ></v-textarea>
                  <v-divider class="my-4" :key="index +'_hr'"></v-divider>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>附加内容</v-expansion-panel-header>
              <v-expansion-panel-content>
                <editor v-if="activeEdit" v-model="editedItem.propose" :init="editorInit" :id="'report_'+ getId"></editor>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="saveReport">{{itemData.isRelease ? '修改' : '保存'}}</v-btn>
          <v-btn text v-if="!itemData.isRelease" color="secondary" @click="publishReport">发布</v-btn>
          <v-btn text @click="closeDialog">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// eslint-disable-next-line
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/link'
import 'tinymce/plugins/media'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/preview'
import { mapGetters } from "vuex";
import store from "@/store";
import {
  FETCH_MONTHLY_REPORT,
  FETCH_UPLOAD,
  EDIT_MONTHLY_REPORT,
  PUBLISH_MONTHLY_REPORT,
} from "@/store/actions.type";
import axios from "axios";
import {SET_ERROR} from "@/store/mutations.type";
let _this = {};

export default {
  components: {
    Editor
  },
  props: {
    getId: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      itemData: {},
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      editedItem: {
        propose: '',
      },
      defaultItem: {
        propose: '',
      },
      valid: true,
      openPanel:[],
      windowSize: {
        x: 0,
        y: 0,
      },
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      editorInit: {
        language_url: '/tinymce/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/MDass',
        content_css: '/tinymce/skins/content/MDass/content.css',
        height: 550,
        branding: false,
        menubar: false,
        statusbar: false,
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body,h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif; }",
        font_formats:
          "Roboto=roboto;",
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable | removeformat',
        quickbars_insert_toolbar: 'image hr',
        plugins: 'lists image imagetools wordcount link media quickbars hr preview',
        toolbar: 'bold italic underline strikethrough | fontselect fontsizeselect formatselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | ' +
          'bullist numlist outdent indent | link unlink image media | removeformat undo redo',
        images_upload_handler: function (blobInfo, success, failure) {
          let file = blobInfo.blob();
          if (file) {
            _this.$store.dispatch(FETCH_UPLOAD)
              .then(() => {
                _this.fileName = file.name;
                let param = new FormData();
                param.append('name', file.name);
                param.append('key', 'reports/'+ file.name);
                param.append('policy', _this.uploadSign.policy);
                param.append('OSSAccessKeyId', _this.uploadSign.OSSAccessKeyId);
                param.append('success_action_status', 200);
                param.append('signature', _this.uploadSign.signature);
                param.append('file', file, file.name);

                let config = {
                  headers: {'Content-Type': 'multipart/form-data'},
                };
                axios.post(_this.uploadSign.host, param, config)
                  .then(() => {
                    success(_this.ossURL+'/reports/'+ file.name)
                  })
                  .catch((error) => {
                    failure(error);
                  });
              })
              .catch(err => {
                failure(err);
              });
          }
        },
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',
      },
      activeEdit: false,
    }
  },
  beforeCreate(){
    _this = this;
  },
  computed: {
    ...mapGetters(['currentEmployer', 'currentUser', 'monthlyLoading', 'uploadSign']),
  },
  mounted() {
    this.onResize()
    this.activeEdit = true
  },
  watch: {
    dialog (val) {
      val || this.closeDialog()
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.$emit('Close')
      this.$nextTick(() => {
        this.itemData = Object.assign({}, {})
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    openItem(id) {
      store.dispatch(FETCH_MONTHLY_REPORT, id)
        .then((data) => {
          this.itemData = Object.assign({}, data)
          this.editedItem.propose = data.propose
          this.dialog = true
          this.openPanel.push(0)
          this.openPanel.push(1)
        })
    },
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    saveReport() {
      let strData = {
        _id: this.getId,
        data_item: this.itemData.data_item,
        propose: this.editedItem.propose,
      }

      this.$store.dispatch(EDIT_MONTHLY_REPORT, strData)
        .then(({data}) => {
          this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
          this.itemData = Object.assign({}, data)
          this.editedItem.propose = data.propose
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    publishReport() {
      let strData = {
        _id: this.getId,
        data_item: this.itemData.data_item,
        propose: this.editedItem.propose,
      }

      this.$store.dispatch(EDIT_MONTHLY_REPORT, strData)
        .then(() => {
          store.dispatch(PUBLISH_MONTHLY_REPORT, { _id: this.getId, reply: 1 })
            .then(({data}) => {
              store.commit(SET_ERROR, {msg: '操作成功', color: 'primary'})
              this.itemData = Object.assign({}, data)
              this.editedItem.propose = data.propose
            })
            .catch((err) => {
              store.commit(SET_ERROR, {msg: err})
            });
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  destroyed () {
    this.activeEdit = false
  }
}
</script>
