import {RiskService} from "@/common/http";

import {
  FETCH_RISK_ENTERPRISE_LIST,
  PUBLISH_RISK_ENTERPRISE,
  FETCH_RISK_ENTERPRISE,
  EDIT_RISK_ENTERPRISE,
  DELETE_RISK_ENTERPRISE,
  CAL_CREDITLINE,
  RE_CAL_CREDITLINE,
  REFRESH_RISK_ENTERPRISE,
  FETCH_KINGDEE_CUSTOMER_LIST,
  UPDATE_KINGDEE_CREDITLINE,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_RISK_ENTERPRISE_LIST,
  SET_RISK_ENTERPRISE_LIST,
  SET_RISK_ENTERPRISE,
} from "./mutations.type"

const state = {
  riskEnterpriseLoading: true,
  riskEnterpriseList: [],
  riskEnterpriseCount: 0,
  riskEnterprise: {},
};

const getters = {
  riskEnterpriseLoading: state => state.riskEnterpriseLoading,
  riskEnterpriseList: state => state.riskEnterpriseList,
  riskEnterpriseCount: state => state.riskEnterpriseCount,
  riskEnterprise: state => state.riskEnterprise,
};

const actions = {
  [FETCH_RISK_ENTERPRISE_LIST]({ commit }, slug) {
    commit(LOAD_RISK_ENTERPRISE_LIST);
    return new Promise((resolve, reject) => {
      RiskService.get('/enterprise/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RISK_ENTERPRISE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_RISK_ENTERPRISE]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      RiskService.get('/enterprise', slug)
        .then(({ data }) => {
          commit(SET_RISK_ENTERPRISE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_RISK_ENTERPRISE](context, data) {
    return RiskService.create('/enterprise', data);
  },
  [EDIT_RISK_ENTERPRISE](context, data) {
    return RiskService.update('/enterprise', data._id, data);
  },
  [DELETE_RISK_ENTERPRISE](context, data) {
    return RiskService.delete('/enterprise/' + data._id);
  },
  [CAL_CREDITLINE](context, data) {
    return RiskService.create('/enterprise/calcreditline', data);
  },
  [RE_CAL_CREDITLINE](context, data) {
    return RiskService.create('/enterprise/recalcreditline', data);
  },
  [REFRESH_RISK_ENTERPRISE](context, data) {
    return RiskService.create('/enterprise/refresh', data);
  },
  [FETCH_KINGDEE_CUSTOMER_LIST]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      RiskService.get('/kingdee/customerlist', '?'+slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  [UPDATE_KINGDEE_CREDITLINE](context, data) {
    return RiskService.create('/kingdee/updatecreditline', data);
  },
};

const mutations = {
  [LOAD_RISK_ENTERPRISE_LIST] (state) {
    state.riskEnterpriseLoading = true;
  },
  [SET_RISK_ENTERPRISE_LIST](state, list) {
    state.riskEnterpriseCount = list.query.count;
    state.riskEnterpriseList = list.docs;
    state.riskEnterpriseLoading = false;
  },
  [SET_RISK_ENTERPRISE](state, riskEnterprise) {
    state.riskEnterprise = riskEnterprise;
    state.riskEnterpriseLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

