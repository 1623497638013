import { EntGroupService } from "@/common/http";
import {
  FETCH_ENTGROUP_LIST,
  FETCH_ENTGROUP,
  PUBLISH_ENTGROUP,
  EDIT_ENTGROUP,
  DELETE_ENTGROUP,
} from "./actions.type";
import {
  SET_ERROR,
  LOAD_ENTGROUP_LIST,
  SET_ENTGROUP_LIST,
  SET_ENTGROUP,
} from "./mutations.type"

const state = {
  entGroupLoading: false,
  entGroupList: [],
  entGroupCount: 0,
  entGroup: {},
};

const getters = {
  entGroup: state => state.entGroup,
  entGroupList: state => state.entGroupList,
  entGroupCount: state => state.entGroupCount,
  entGroupLoading: state => state.entGroupLoading,
};

const actions = {
  [FETCH_ENTGROUP_LIST]({ commit }, slug) {
    commit(LOAD_ENTGROUP_LIST);
    return new Promise((resolve, reject) => {
      EntGroupService.get('', 'list?'+slug)
        .then(({ data }) => {
          commit(SET_ENTGROUP_LIST, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_ENTGROUP]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      EntGroupService.get('', slug)
        .then(({ data }) => {
          commit(SET_ENTGROUP, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_ENTGROUP](context, entGroup) {
    return EntGroupService.create('', entGroup);
  },
  [EDIT_ENTGROUP](context, entGroup) {
    return EntGroupService.update(entGroup._id, entGroup);
  },
  [DELETE_ENTGROUP](context, entGroup) {
    return EntGroupService.destroy(entGroup._id)
  }
};

const mutations = {
  [LOAD_ENTGROUP_LIST] (state) {
    state.entGroupLoading = true;
  },
  [SET_ENTGROUP_LIST](state, list) {
    state.entGroupList = list.docs;
    state.entGroupCount = list.query.count;
    state.entGroupLoading = false;
  },
  [SET_ENTGROUP](state, entGroup) {
    state.entGroup = entGroup;
    state.entGroupLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
