var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-breadcrumbs', {
    attrs: {
      "items": _vm.items,
      "divider": "-",
      "transition": "slide-x-reverse-transition"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(props) {
        return [_c('a', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.handleLink(props.item);
            }
          }
        }, [_vm._v(_vm._s(props.item.meta.title))])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }