<template>
  <v-container fluid no-gutters class="mt-16" v-if="editFooterData">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-footer :color="editFooterData.bgColor || 'primary'" absolute padless>
          <v-row justify="center" no-gutters>
            <v-btn v-for="(link, i) in editFooterData.links" :key="i+'_link'" :href="link.url" color="white" text rounded class="my-2">
              {{ link.text }}
            </v-btn>
            <v-col class="py-4 text-center" cols="12">
              <pre v-html="editFooterData.context || '文字区域'"></pre>
            </v-col>
          </v-row>
          <v-fade-transition>
            <v-overlay v-if="hover" absolute color="grey lighten-5">
              <v-btn class="ma-2" text color="primary" @click="editItem()"><v-icon left>edit</v-icon> 编辑</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-footer>
      </template>
    </v-hover>
    <v-bottom-sheet v-model="dlgSetting" persistent :retain-focus="false" eager>
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">设置</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row align="start" justify="center">
                <v-col cols="2">
                  <v-select
                    v-model="editFooterData.bgColor"
                    :items="barColor"
                    autocomplete="off"
                    label="请选择背景颜色"
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small :color="item">
                        {{item | stringModule}}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip small :color="data.item">{{data.item | stringModule}}</v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2" class="text-center" v-for="(link, i) in editFooterData.links" :key="i+'_link'">
                    <v-text-field
                      v-model="link.text"
                      type="text"
                      label="链接文字"
                      hide-details
                      hint="显示的链接文字"
                      outlined
                      dense>
                    </v-text-field>
                    <v-text-field
                      v-model="link.url"
                      class="mt-2"
                      type="text"
                      label="链接地址"
                      hide-details
                      hint="点击跳转的链接地址"
                      outlined
                      dense>
                    </v-text-field>
                    <v-btn icon @click="removeLink(i)"><v-icon>remove</v-icon></v-btn>
                </v-col>
                <v-col cols="2" v-if="editFooterData.links && editFooterData.links.length <= 4"><v-btn text @click="addLink"><v-icon left>link</v-icon>增加链接</v-btn></v-col>
                <v-col cols="12" :class="'edit-inline text-center '+ editFooterData.bgColor">
                  <editor v-if="activeEdit" v-model="editFooterData.context" :init="editorInit" id="element_footer"></editor>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">关闭</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-bottom-sheet>
  </v-container>
</template>
<script>
// eslint-disable-next-line
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/lists'

import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_PAGE,
  EDIT_PAGE,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
export default {
  components: {
    Editor
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        }
      },
      dlgSetting: false,
      valid: true,
      editFooterData: {},
      barColor: ['secondary', 'info', 'primary', 'grey', '#f5f5f5'],
      editorInit: {
        language_url: '/tinymce/zh_CN.js',
        language: 'zh_CN',
        height: '100',
        menubar: false,
        link_quicklink: true,
        inline: true,
        toolbar: false,
        plugins: [
          'lists',
          'link',
          'quickbars'
        ],
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body,h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif}; ",
        font_formats:
          "Roboto=roboto;",
        quickbars_insert_toolbar: 'formatselect forecolor',
        quickbars_selection_toolbar: 'bold italic underline strikethrough | fontselect formatselect forecolor | alignleft aligncenter alignright alignjustify | quicklink unlink ｜ removeformat',
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',
      },
      activeEdit: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    dlgSetting (val) {
      val || this.close()
    },
  },
  created() {
    this.getPageInfo('footer')
  },
  mounted () {
    this.activeEdit = true
  },
  methods: {
    getPageInfo(component='footer') {
      this.$store.dispatch(FETCH_PAGE, '?name=home&component='+ component +'&owner='+ this.currentUser.employer.ownerId._id)
        .then(data => {
          this.editFooterData = Object.assign({ _id: data._id, context: '&copy; 2019-2021 倍升互联（北京）科技有限公司', links: [] }, data.config[0])
        });
    },
    close () {
      this.dlgSetting = false
      this.getPageInfo('footer')
    },
    editItem() {
      this.dlgSetting = true
    },
    addLink() {
      if (this.editFooterData.links.length > 4) {
        this.$store.commit(SET_ERROR, {msg: '尾部链接最多5个。'});
        return
      } else {
        this.editFooterData.links.push({text: '', url: ''})
      }

    },
    removeLink(index) {
      this.editFooterData.links.splice(index, 1)
    },
    submit() {
      if (this.editFooterData._id) {
        this.$store.dispatch(EDIT_PAGE, { _id: this.editFooterData._id, config: this.editFooterData })
          .then(() => {
            this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.close();
          })
          .catch((err) => {
            this.$store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      }
    },
  },
  destroyed () {
    this.activeEdit = false
  }
}
</script>
<style scoped>
  .v-dialog__content {z-index: 203 !important;}
  pre {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  .edit-inline *[contentEditable="true"]:focus,
  .edit-inline *[contentEditable="true"]:hover {
    outline: 2px solid #d41746;
  }
</style>
