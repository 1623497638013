import {RiskService} from "@/common/http";

import {
  FETCH_RISK_ENTERPRISETYPE_LIST,
  PUBLISH_RISK_ENTERPRISETYPE,
  FETCH_RISK_ENTERPRISETYPE,
  EDIT_RISK_ENTERPRISETYPE,
  DELETE_RISK_ENTERPRISETYPE,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_RISK_ENTERPRISETYPE_LIST,
  SET_RISK_ENTERPRISETYPE_LIST,
  SET_RISK_ENTERPRISETYPE,
} from "./mutations.type"

const state = {
  riskEnterpriseTypeLoading: true,
  riskEnterpriseTypeList: [],
  riskEnterpriseTypeCount: 0,
  riskEnterpriseType: {},
};

const getters = {
  riskEnterpriseTypeLoading: state => state.riskEnterpriseTypeLoading,
  riskEnterpriseTypeList: state => state.riskEnterpriseTypeList,
  riskEnterpriseTypeCount: state => state.riskEnterpriseTypeCount,
  riskEnterpriseType: state => state.riskEnterpriseType,
};

const actions = {
  [FETCH_RISK_ENTERPRISETYPE_LIST]({ commit }, slug) {
    commit(LOAD_RISK_ENTERPRISETYPE_LIST);
    return new Promise((resolve, reject) => {
      RiskService.get('/enterprisetype/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RISK_ENTERPRISETYPE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_RISK_ENTERPRISETYPE]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      RiskService.get('/enterprisetype', slug)
        .then(({ data }) => {
          commit(SET_RISK_ENTERPRISETYPE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_RISK_ENTERPRISETYPE](context, data) {
    return RiskService.create('/enterprisetype', data);
  },
  [EDIT_RISK_ENTERPRISETYPE](context, data) {
    return RiskService.update('/enterprisetype', data._id, data);
  },
  [DELETE_RISK_ENTERPRISETYPE](context, data) {
    return RiskService.delete('/enterprisetype/' + data._id);
  },
};

const mutations = {
  [LOAD_RISK_ENTERPRISETYPE_LIST] (state) {
    state.riskEnterpriseTypeLoading = true;
  },
  [SET_RISK_ENTERPRISETYPE_LIST](state, list) {
    state.riskEnterpriseTypeCount = list.query.count;
    state.riskEnterpriseTypeList = list.docs;
    state.riskEnterpriseTypeLoading = false;
  },
  [SET_RISK_ENTERPRISETYPE](state, riskEnterpriseType) {
    state.riskEnterpriseType = riskEnterpriseType;
    state.riskEnterpriseTypeLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

