var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-2",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-icon', {
    staticClass: "float-right",
    staticStyle: {
      "cursor": "pointer",
      "position": "absolute",
      "left": "50%"
    },
    attrs: {
      "small": "",
      "color": "grey lighten-2"
    },
    on: {
      "click": function click($event) {
        return _vm.deleteItem();
      }
    }
  }, [_vm._v("delete")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }