<template>
  <v-card outlined class="mx-auto">
    <v-sheet
      class="v-sheet--offset mx-auto mt-n4"
      color="secondary"
      elevation="12"
      max-width="calc(100% - 32px)"
    >
      <v-sparkline
        :labels="labels"
        :value="value"
        color="white"
        line-width="2"
        label-size="12"
        padding="20"
        smooth
      ></v-sparkline>
    </v-sheet>
    <v-card-text class="pt-2">
      <div class="title font-weight-light mb-2" v-text="title"></div>
      <div class="subheading font-weight-light">
        近七天的{{ title }}趋势
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text class="py-1">
      <v-icon class="mr-2" small>schedule</v-icon>
      <span class="caption font-weight-light">更新时间：{{updateTime | fromDate}}</span>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    labels: {
      type: Array,
    },
    value: {
      type: Array,
    },
    label: {
      type: String,
    },
    title: {
      type: String,
    },
    updateTime: {
      type: Number
    }
  },
}
</script>
