var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "clipped": "",
      "app": "",
      "mini-variant": _vm.mini
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('div', {
          staticClass: "pa-2"
        }, [_c('core-request-bug', [_vm._v("提交 BUG")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [!_vm.mini ? _c('v-list', {
    attrs: {
      "dense": "",
      "nav": ""
    }
  }, [_vm._l(_vm.drawer_routers, function (item) {
    return [item.meta.divider ? _c('v-divider', {
      key: item.name
    }) : _vm._e(), item.meta.divider ? _c('v-subheader', {
      key: item.meta.title
    }, [_vm._v(_vm._s(item.meta.title))]) : _c('v-list-item', {
      key: item.name,
      attrs: {
        "to": item.path,
        "link": "",
        "color": "primary"
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(item.meta.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.meta.title))])], 1)], 1)];
  })], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }