var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "px-16",
    attrs: {
      "fluid": "",
      "no-gutters": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_vm.editBodyData.cover ? _c('v-img', {
          attrs: {
            "src": _vm.editBodyData.cover[0] ? _vm.ossURL + '/' + _vm.editBodyData.cover[0] : '/static/error/empty_state.svg',
            "height": "150"
          }
        }) : _vm._e(), _vm.editBodyData.title_color ? _c('v-card-title', {
          class: 'headline ' + _vm.editBodyData.title_color[0] + '--text justify-center',
          domProps: {
            "textContent": _vm._s(_vm.editBodyData.title[0] || '输入标题文字')
          }
        }) : _vm._e(), _vm.editBodyData.context ? _c('v-card-subtitle', [_c('pre', {
          staticClass: "grey--text mt-4 text-center",
          domProps: {
            "innerHTML": _vm._s(_vm.editBodyData.context[0] || '文字区域')
          }
        })]) : _vm._e(), _c('v-fade-transition', [hover ? _c('v-overlay', {
          staticClass: "align-end",
          attrs: {
            "absolute": "",
            "color": "grey lighten-5"
          }
        }, [_c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(0);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("edit")]), _vm._v(" 编辑")], 1), _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(0);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("delete")]), _vm._v(" 删除")], 1)], 1) : _vm._e()], 1)], 1)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var hover = _ref2.hover;
        return [_c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_vm.editBodyData.cover ? _c('v-img', {
          attrs: {
            "src": _vm.editBodyData.cover[1] ? _vm.ossURL + '/' + _vm.editBodyData.cover[1] : '/static/error/empty_state.svg',
            "height": "150"
          }
        }) : _vm._e(), _vm.editBodyData.title_color ? _c('v-card-title', {
          class: 'headline ' + _vm.editBodyData.title_color[1] + '--text justify-center',
          domProps: {
            "textContent": _vm._s(_vm.editBodyData.title[1] || '输入标题文字')
          }
        }) : _vm._e(), _vm.editBodyData.context ? _c('v-card-subtitle', [_c('pre', {
          staticClass: "grey--text mt-4 text-center",
          domProps: {
            "innerHTML": _vm._s(_vm.editBodyData.context[1] || '文字区域')
          }
        })]) : _vm._e(), _c('v-fade-transition', [hover ? _c('v-overlay', {
          staticClass: "align-end",
          attrs: {
            "absolute": "",
            "color": "grey lighten-5"
          }
        }, [_c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(1);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("edit")]), _vm._v(" 编辑")], 1), _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(1);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("delete")]), _vm._v(" 删除")], 1)], 1) : _vm._e()], 1)], 1)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var hover = _ref3.hover;
        return [_c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_vm.editBodyData.cover ? _c('v-img', {
          attrs: {
            "src": _vm.editBodyData.cover[2] ? _vm.ossURL + '/' + _vm.editBodyData.cover[2] : '/static/error/empty_state.svg',
            "height": "150"
          }
        }) : _vm._e(), _vm.editBodyData.title_color ? _c('v-card-title', {
          class: 'headline ' + _vm.editBodyData.title_color[2] + '--text justify-center',
          domProps: {
            "textContent": _vm._s(_vm.editBodyData.title[2] || '输入标题文字')
          }
        }) : _vm._e(), _vm.editBodyData.context ? _c('v-card-subtitle', [_c('pre', {
          staticClass: "grey--text mt-4 text-center",
          domProps: {
            "innerHTML": _vm._s(_vm.editBodyData.context[2] || '文字区域')
          }
        })]) : _vm._e(), _c('v-fade-transition', [hover ? _c('v-overlay', {
          staticClass: "align-end",
          attrs: {
            "absolute": "",
            "color": "grey lighten-5"
          }
        }, [_c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(2);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("edit")]), _vm._v(" 编辑")], 1), _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(2);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("delete")]), _vm._v(" 删除")], 1)], 1) : _vm._e()], 1)], 1)];
      }
    }])
  })], 1)], 1), _c('v-bottom-sheet', {
    attrs: {
      "retain-focus": false,
      "eager": ""
    },
    model: {
      value: _vm.dlgSetting,
      callback: function callback($$v) {
        _vm.dlgSetting = $$v;
      },
      expression: "dlgSetting"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "id": 'orc_file' + _vm.index,
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _vm.editIndex > -1 ? _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("设置")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-card', {
    staticClass: "text-center",
    attrs: {
      "outlined": "",
      "tile": ""
    }
  }, [_c('v-img', {
    key: 'img_' + _vm.editIndex,
    attrs: {
      "src": _vm.editBodyData.cover[_vm.editIndex] ? _vm.ossURL + '/' + _vm.editBodyData.cover[_vm.editIndex] : '/static/error/empty_state.svg'
    }
  }), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.uploadImage();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("backup")]), _vm._v(" 上传图片")], 1), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.choiceMaterial();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("photo_library")]), _vm._v(" 选择素材")], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "width": "300px",
      "type": "text",
      "label": "标题",
      "hint": "输入标题",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editBodyData.title[_vm.editIndex],
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData.title, _vm.editIndex, $$v);
      },
      expression: "editBodyData.title[editIndex]"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.fontColor,
      "autocomplete": "off",
      "label": "标题颜色",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": item
          }
        }, [_vm._v(" " + _vm._s(_vm._f("stringModule")(item)) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": data.item
          }
        }, [_vm._v(_vm._s(_vm._f("stringModule")(data.item)))])];
      }
    }], null, false, 1547827071),
    model: {
      value: _vm.editBodyData.title_color[_vm.editIndex],
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData.title_color, _vm.editIndex, $$v);
      },
      expression: "editBodyData.title_color[editIndex]"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.activeEdit ? _c('editor', {
    attrs: {
      "init": _vm.editorInit,
      "id": 'element' + _vm.index + '_' + _vm.editIndex
    },
    model: {
      value: _vm.editBodyData.context[_vm.editIndex],
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData.context, _vm.editIndex, $$v);
      },
      expression: "editBodyData.context[editIndex]"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-dialog', {
    attrs: {
      "hide-overlay": "",
      "max-width": "450px"
    },
    model: {
      value: _vm.dlgMaterial,
      callback: function callback($$v) {
        _vm.dlgMaterial = $$v;
      },
      expression: "dlgMaterial"
    }
  }, [_vm.editIndex > -1 ? _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v("选择内容")]), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.materialList,
      "item-text": "title",
      "item-value": "_id",
      "autocomplete": "off",
      "return-object": "",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "label": "关联素材",
      "hint": "请选择该服务关联的素材详细介绍",
      "prepend-inner-icon": "photo_library"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.title)
          }
        }), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.word_count) + "字 " + _vm._s(_vm._f("fromDate")(data.item.updateTime)))])], 1)];
      }
    }], null, false, 2150620492),
    model: {
      value: _vm.editBodyData.material[_vm.editIndex],
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData.material, _vm.editIndex, $$v);
      },
      expression: "editBodyData.material[editIndex]"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dlgMaterial = false;
      }
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": _vm.confirmMaterial
    }
  }, [_vm._v("确定")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }