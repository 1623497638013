<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>{{product.name}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('Close')"><v-icon>close</v-icon></v-btn>
    </v-toolbar>
    <v-carousel v-if="product.pictures && product.pictures.length" delimiter-icon="remove" show-arrows-on-hover height="250">
      <v-carousel-item
        v-for="(item,i) in product.pictures"
        :key="i+'_'+item"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-img :src="ossURL+'/'+item" contain max-height="250"></v-img>
      </v-carousel-item>
    </v-carousel>
    <v-card-text>
      <v-row class="mt-4">
        <v-col cols="2" class="text-right">产品名称</v-col>
        <v-col cols="10" class="font-weight-bold">{{product.name}}</v-col>
        <v-col cols="2" class="text-right">产品简称</v-col>
        <v-col cols="4" class="font-weight-bold">{{product.product_code}}</v-col>
        <v-col cols="2" class="text-right">产品型号</v-col>
        <v-col cols="4" class="font-weight-bold">{{product.part_number}}</v-col>
        <v-col cols="2" class="text-right">品牌</v-col>
        <v-col cols="4" class="font-weight-bold" v-if="product.vendor">{{product.vendor.name}}</v-col>
        <v-col cols="2" class="text-right">产品类型</v-col>
        <v-col cols="4" class="font-weight-bold" v-if="product.product_type">{{product.product_type.name}}</v-col>

        <v-col cols="12" class="text-right">
          <v-btn text @click="showMore = !showMore">{{showMore ? '隐藏' : '查看'}}更多信息</v-btn>
          <v-btn text
                 v-if="product.isRent && (currentUser.hasAdmin.includes('dealer') ||
                 currentUser.permission.includes('Lease_Product') ||
                 currentUser.permission.includes('Lease_Admin') ||
                 currentUser.permission.includes('Lease_Audit'))"
                 @click="showRental = !showRental"
          >
            {{showRental ? '隐藏' : '查看'}}租赁信息</v-btn>
        </v-col>

        <v-row v-if="showRental">
          <v-col cols="2" class="text-right">官网价格</v-col>
          <v-col cols="2" class="font-weight-bold">{{product.rentInfo.official_price.$numberDecimal}}元</v-col>
          <v-col cols="2" class="text-right">采购价格</v-col>
          <v-col cols="2" class="font-weight-bold">{{product.rentInfo.purchase_price.$numberDecimal}}元</v-col>
          <v-col cols="2" class="text-right">采购折扣比例</v-col>
          <v-col cols="2" class="font-weight-bold">{{parseFloat((product.rentInfo.purchase_price.$numberDecimal / product.rentInfo.official_price.$numberDecimal*100).toFixed(2))}}%</v-col>
          <v-col cols="2" class="text-right">1年余值比例</v-col>
          <v-col cols="2" class="font-weight-bold">{{parseFloat((product.rentInfo.SV._1_year_ratio*100).toFixed(4))}}%</v-col>
          <v-col cols="2" class="text-right">2年余值比例</v-col>
          <v-col cols="2" class="font-weight-bold">{{parseFloat((product.rentInfo.SV._2_year_ratio*100).toFixed(4))}}%</v-col>
          <v-col cols="2" class="text-right">3年余值比例</v-col>
          <v-col cols="2" class="font-weight-bold">{{parseFloat((product.rentInfo.SV._3_year_ratio*100).toFixed(4))}}%</v-col>
          <v-col cols="2" class="text-right">1年到期余值</v-col>
          <v-col cols="2" class="font-weight-bold">{{parseFloat((product.rentInfo.official_price.$numberDecimal > 0 ? product.rentInfo.official_price.$numberDecimal * product.rentInfo.SV._1_year_ratio : product.rentInfo.purchase_price.$numberDecimal * product.rentInfo.SV._1_year_ratio).toFixed(2))}}元</v-col>
          <v-col cols="2" class="text-right">2年到期余值</v-col>
          <v-col cols="2" class="font-weight-bold">{{parseFloat((product.rentInfo.official_price.$numberDecimal > 0 ? product.rentInfo.official_price.$numberDecimal * product.rentInfo.SV._2_year_ratio : product.rentInfo.purchase_price.$numberDecimal * product.rentInfo.SV._2_year_ratio).toFixed(2))}}元</v-col>
          <v-col cols="2" class="text-right">3年到期余值</v-col>
          <v-col cols="2" class="font-weight-bold">{{parseFloat((product.rentInfo.official_price.$numberDecimal > 0 ? product.rentInfo.official_price.$numberDecimal * product.rentInfo.SV._3_year_ratio : product.rentInfo.purchase_price.$numberDecimal * product.rentInfo.SV._3_year_ratio).toFixed(2))}}元</v-col>
          <v-col cols="2" class="text-right">1年回收残值</v-col>
          <v-col cols="2" class="font-weight-bold">{{product.rentInfo.SV._1_year_recycle.$numberDecimal}}元</v-col>
          <v-col cols="2" class="text-right">2年回收残值</v-col>
          <v-col cols="2" class="font-weight-bold">{{product.rentInfo.SV._2_year_recycle.$numberDecimal}}元</v-col>
          <v-col cols="2" class="text-right">3年回收残值</v-col>
          <v-col cols="2" class="font-weight-bold">{{product.rentInfo.SV._3_year_recycle.$numberDecimal}}元</v-col>
          <v-col cols="2" class="text-right">1年残值比例</v-col>
          <v-col cols="2" class="font-weight-bold">{{parseFloat((product.rentInfo.official_price.$numberDecimal > 0 ? product.rentInfo.SV._1_year_recycle.$numberDecimal / product.rentInfo.official_price.$numberDecimal * 100 : product.rentInfo.SV._1_year_recycle.$numberDecimal / product.rentInfo.purchase_price.$numberDecimal * 100).toFixed(2))}}%</v-col>
          <v-col cols="2" class="text-right">2年残值比例</v-col>
          <v-col cols="2" class="font-weight-bold">{{parseFloat((product.rentInfo.official_price.$numberDecimal > 0 ? product.rentInfo.SV._2_year_recycle.$numberDecimal / product.rentInfo.official_price.$numberDecimal * 100 : product.rentInfo.SV._2_year_recycle.$numberDecimal / product.rentInfo.purchase_price.$numberDecimal * 100).toFixed(2))}}%</v-col>
          <v-col cols="2" class="text-right">3年残值比例</v-col>
          <v-col cols="2" class="font-weight-bold">{{parseFloat((product.rentInfo.official_price.$numberDecimal > 0 ? product.rentInfo.SV._3_year_recycle.$numberDecimal / product.rentInfo.official_price.$numberDecimal * 100 : product.rentInfo.SV._3_year_recycle.$numberDecimal / product.rentInfo.purchase_price.$numberDecimal * 100).toFixed(2))}}%</v-col>
          <v-col cols="4"></v-col>
          <v-col cols="2" class="text-right">2年延保单价</v-col>
          <v-col cols="2" class="text--secondary">{{product.rentInfo.warranty._2_year_inside.$numberDecimal}}元</v-col>
          <v-col cols="2" class="text-right">3年延保单价</v-col>
          <v-col cols="2" class="text--secondary">{{product.rentInfo.warranty._3_year_inside.$numberDecimal}}元</v-col>
          <v-col cols="4"></v-col>
          <v-col cols="2" class="text-right">2年延保零售</v-col>
          <v-col cols="2" class="text--secondary">{{product.rentInfo.warranty._2_year_retail.$numberDecimal}}元</v-col>
          <v-col cols="2" class="text-right">3年延保零售</v-col>
          <v-col cols="2" class="text--secondary">{{product.rentInfo.warranty._3_year_retail.$numberDecimal}}元</v-col>
        </v-row>

        <v-row v-if="showMore">
          <v-col cols="2" class="text-right">产品描述</v-col>
          <v-col cols="10" class="font-weight-bold"><pre>{{product.remarks}}</pre></v-col>
          <v-col cols="12" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Product')">
            <v-row>
              <v-col cols="2" class="text-right">部件号</v-col>
              <v-col cols="4" class="font-weight-bold">{{product.model_number}}</v-col>
              <v-col cols="2" class="text-right">ERP物料号</v-col>
              <v-col cols="4" class="font-weight-bold">{{product.erp_code}}</v-col>
              <v-col cols="2" class="text-right">成本</v-col>
              <v-col cols="4" class="font-weight-bold" v-if="product.cost_price">{{product.cost_price.$numberDecimal}}</v-col>
              <v-col cols="2" class="text-right">保修期</v-col>
              <v-col cols="4" class="font-weight-bold">{{product.guarantee}} 个月</v-col>
              <v-col cols="2" class="text-right">可用模块</v-col>
              <v-col cols="10" class="font-weight-bold">{{product.selected | productEnableModel}}</v-col>
            </v-row>
          </v-col>
          <v-col cols="2" class="text-right">内存大小</v-col>
          <v-col cols="4" class="font-weight-bold" v-if="product.it_info">{{product.it_info.ram}} G</v-col>
          <v-col cols="2" class="text-right">网络制式</v-col>
          <v-col cols="4" class="font-weight-bold" v-if="product.it_info">{{product.it_info.network}}</v-col>
          <template v-for="(item, i) in customFields">
            <v-col :key="i+'_'+item.name" cols="2" class="text-right">{{item.name}}</v-col>
            <v-col :key="i+'_'+item.value" cols="4" class="font-weight-bold">{{item.value}}</v-col>
          </template>
        </v-row>
      </v-row>

    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Asset')">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close">关闭</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import {
  FETCH_PRODUCT,
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      customFields: [],
      showMore: false,
      showRental: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'product']),
  },
  mounted() {
    this.getItem(this.id)
  },
  methods: {
    getItem(id) {
      this.$store.dispatch(FETCH_PRODUCT, id)
        .then((data) => {
          this.customFields = data.product_type.customFields
          this.customFields.forEach(field => {
            let beforeField = data.customFields.find(item => item._id === field._id)
            if (beforeField) field['value'] = beforeField.value
          })
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        })

    },
    close() {
      this.$emit('Close')
    },
    jump(query) {
      this.$emit('Goto', query)
      this.$router.push({path:'/dealer/asset/list?'+query })
    }
  },
}
</script>
