<template>
  <v-timeline dense clipped align-top v-if="logList && logList.length">
    <v-timeline-item v-for="(item, n) in logList" :key="n" small fill-dot
      :icon="item.action === 'Create' ? 'add' : item.action === 'Batch' ? 'layers' : 'create'">
      <v-row class="mt-n4">
        <v-col cols="5">
          <strong>{{ item.createTime | formatTime }}</strong> {{ item.action | stringModule }}
          <div class="caption"><widgets-employee-dialogs :account="item.operator._id"
              class="v-list-item--link">{{ item.operator.personal.name ? item.operator.personal.name : '*未设置姓名' }}<v-icon
                class="mx-2">read_more</v-icon></widgets-employee-dialogs></div>
        </v-col>
        <v-col cols="7" class="text-right" v-if="item.record.length">
          <div class="caption text-left" v-for="(change, j) in item.record" :key="j">
            <span v-if="item.action === 'Batch'">{{ change.text }}: {{ change.value }}</span>
            <span v-else>{{ change }}</span>
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>
  </v-timeline>
  <div v-else>无数据</div>
</template>
<script>
import store from "@/store";
import { FETCH_RISK_LOG_LIST } from "@/store/actions.type";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    businessType: {
      type: Number,
    },
  },
  data() {
    return {
      logList: [],
    }
  },
  mounted() { },
  // watch: {
  //   id() {
  //     this.initialize();
  //   },
  // },
  created() {
    this.initialize()
  },
  computed: {},
  methods: {
    initialize() {
      store.dispatch(FETCH_RISK_LOG_LIST, '&limit=100&key=' + this.id + '&businessType=' + (this.businessType || ''))
        .then((data) => {
          this.logList = data.docs.concat()
        })
    }
  }
}
</script>
