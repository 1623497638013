<template>
  <v-container fluid no-gutters class="px-16">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-carousel hide-delimiters hide-delimiter-background :show-arrows="false" :height="editBodyData.height || 175">
          <v-carousel-item>
            <v-sheet :color="editBodyData.color || 'transparent'" height="100%">
              <v-row class="fill-height pa-6" :align="editBodyData.alignment || 'center'" :justify="editBodyData.justify || 'center'">
                <pre class="grey--text" v-html="editBodyData.text || '文字区域'"></pre>
              </v-row>
            </v-sheet>
          </v-carousel-item>
          <v-fade-transition>
            <v-overlay v-if="hover" absolute class="align-end" color="grey lighten-2">
              <v-btn class="ma-2" text color="primary" @click="editItem()"><v-icon left>edit</v-icon> 编辑</v-btn>
              <v-btn class="ma-2" text color="primary" @click="deleteItem()"><v-icon left>delete</v-icon> 删除</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-carousel>
      </template>
    </v-hover>
    <v-bottom-sheet v-model="dlgSetting" :retain-focus="false" eager>
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">设置</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="8" class="text-center">
                  <editor v-if="activeEdit" v-model="editBodyData.text" :init="editorInit" :id="'element'+index"></editor>
                </v-col>
                <v-col cols="4" class="text-center">
                  <v-select
                    v-model="editBodyData.alignment"
                    :items="alignmentsAvailable"
                    label="垂直对齐"
                    autocomplete="off"
                    outlined
                    dense
                  ><template v-slot:selection="{ item }">
                    {{item | stringModule}}
                  </template>
                    <template v-slot:item="data">
                      {{data.item | stringModule}}
                    </template>
                  </v-select>
                  <v-select
                    v-model="editBodyData.justify"
                    :items="justifyAvailable"
                    label="水平对齐"
                    autocomplete="off"
                    outlined
                    dense
                  ><template v-slot:selection="{ item }">
                    {{item | stringModule}}
                  </template>
                    <template v-slot:item="data">
                      {{data.item | stringModule}}
                    </template>
                  </v-select>
                  <v-select
                    v-model="editBodyData.color"
                    :items="bgColor"
                    label="区域背景颜色"
                    autocomplete="off"
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small :color="item">
                        {{item | stringModule}}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip small :color="data.item">{{data.item | stringModule}}</v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">关闭</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-bottom-sheet>
  </v-container>
</template>
<script>
// eslint-disable-next-line
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/lists'

import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_PAGE,
  EDIT_BODY_ELEMENT,
  DELETE_BODY_ELEMENT,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
export default {
  components: {
    Editor
  },
  props: {
    id: {
      type: String,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        }
      },
      dlgSetting: false,
      valid: true,
      editBodyData: {},
      bgColor: ['transparent', 'white', '#f5f5f5', 'primary', 'secondary'],
      editorInit: {
        language_url: '/tinymce/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/MDass',
        content_css: '/tinymce/skins/content/MDass/content.css',
        height: '100%',
        branding: false,
        menubar: false,
        statusbar: false,
        link_quicklink: true,
        // inline: true,
        toolbar: false,
        plugins: [
          'lists',
          'link',
          'quickbars'
        ],
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Lato', sans-serif; }",
        font_formats:
          "Arial Black=arial black,avant garde; Courier New=courier new,courier; Lato Black=lato; Roboto=roboto;",
        quickbars_insert_toolbar: 'formatselect forecolor',
        quickbars_selection_toolbar: 'bold italic underline strikethrough | formatselect forecolor | alignleft aligncenter alignright alignjustify | bullist numlist | quicklink unlink ｜ removeformat',
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',
      },
      activeEdit: false,
      alignmentsAvailable: [
        'start',
        'center',
        'end',
      ],
      justifyAvailable: [
        'start',
        'center',
        'end',
      ],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    dlgSetting (val) {
      val || this.close()
    },
  },
  created() {
    this.getPageInfo()
  },
  mounted () {
    this.activeEdit = true
  },
  methods: {
    getPageInfo(component = 'body') {
      this.$store.dispatch(FETCH_PAGE, '?name=home&component=' + component +'&owner='+ this.currentUser.employer.ownerId._id)
        .then(data => {
          this.editBodyData = Object.assign({_id: data._id}, data.config[this.index])
          if (!this.editBodyData.height) this.editBodyData.height = '100%'
          if (!this.editBodyData.type) this.editBodyData.type = 'text'
          if (!this.editBodyData.color) this.editBodyData.color = 'transparent'
          if (!this.editBodyData.alignment) this.editBodyData.alignment = 'center'
          if (!this.editBodyData.justify) this.editBodyData.justify = 'center'
        });
    },
    close() {
      this.dlgSetting = false
      this.getPageInfo('body')
    },
    editItem() {
      this.dlgSetting = true
    },
    submit() {
      this.$store.dispatch(EDIT_BODY_ELEMENT, {_id: this.id, index: this.index, config: [this.editBodyData]})
        .then(() => {
          this.$emit('UpdateItem')
          this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
          this.close();
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    deleteItem() {
      this.$store.dispatch(DELETE_BODY_ELEMENT, {_id: this.id, index: this.index})
        .then(() => {
          this.$emit('DeleteItem')
          this.getPageInfo('body')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
  },
  destroyed () {
    this.activeEdit = false
  }
}
</script>
<style scoped>
  .show_upload {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
  .v-dialog__content {z-index: 203 !important;}
  pre {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
</style>
