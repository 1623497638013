<template>
  <div id="Heatmap" :style="{ width: width, height: height }"></div>
</template>
<script>
import * as echarts from 'echarts';
import "@/common/echarts.theme";
export default {
  props: {
    data: {
      type: Array,
    },
    year: {
      type: Number,
      default: 2021
    },
    max: {
      type: Number,
      default: 0
    },
    updateTime: {
      type: Number
    },
    width: {
      type: String
    },
    height: {
      type: String
    }
  },
  data() {
    return {
      myChart: null,
    }
  },
  mounted() {
    this.drawLine();
  },
  watch: {
    data() {
      this.drawLine();
    },
    '$vuetify.theme.dark'() {
      this.drawLine();
    },
  },
  methods: {
    drawLine() {
      let chartTheme = this.$vuetify.theme.dark ? 'dark' : 'espreport2'
      if (this.myChart) this.myChart.dispose()
      this.myChart = echarts.init(document.getElementById("Heatmap"), chartTheme);
      this.myChart.setOption({
        backgroundColor: '',
        tooltip: {
          position: "top",
          formatter: function (p) {
            let format = echarts.time.format('yyyy-MM-dd', p.data[0]);
            return format + ': ' + p.data[1];
          }
        },
        visualMap: {
          min: 0,
          max: this.max,
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: 30
        },
        calendar: {
          left: 30,
          right: 30,
          cellSize: ['auto', 20],
          range: this.year,
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.5
            }
          },
          itemStyle: {
            borderWidth: 0.1,
            color: "transparent"
          },
          yearLabel: {show: false},
          dayLabel: {
            firstDay: 1,
            nameMap: 'cn'
          },
          monthLabel: {
            nameMap: 'cn',
          },
        },
        series: {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: this.data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      })
      window.onresize = this.myChart.resize;
    },
  },
}
</script>
