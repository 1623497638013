<template>
  <v-dialog v-model="dialog" scrollable persistent width="600" @input="getEmployee(account)">
    <template v-slot:activator="{ on, attrs }">
      <span :color="btnColor" dark v-bind="attrs" v-on="on" text :ripple="false">
        <v-avatar v-if="avatar" class="mr-2">
          <v-img :src="ossURL +'/'+ avatar" aspect-ratio="1"></v-img>
        </v-avatar>
        <!--<slot v-if="$slots.default"></slot>-->
        <slot></slot> <span v-if="divider">{{divider}}</span>
      </span>
    </template>
    <v-card v-if="!isEdited && employeeInfo.personal && employeeInfo.employer">
      <v-toolbar flat>
        <v-avatar size="52" class="mr-2">
          <v-img :src="employeeInfo.personal.profile ? ossURL +'/'+ employeeInfo.personal.profile : '/static/grey_silhouette.png'" aspect-ratio="1" contain></v-img>
        </v-avatar>
        <v-toolbar-title>{{employeeInfo.personal.name ? employeeInfo.personal.name : '用户信息'}}</v-toolbar-title>
        <v-chip v-if="employeeInfo.isBlock" class="mx-2" small>已注销</v-chip>
        <v-chip v-if="employeeInfo.status === 9" class="mx-2" small>已删除</v-chip>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text v-if="employeeInfo.status !== 9">
        <v-expansion-panels v-model="panelInfo" multiple accordion flat>
          <v-expansion-panel>
            <v-expansion-panel-header>人员资料</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="3"><v-icon class="mr-2">face</v-icon>真实姓名</v-col>
                <v-col cols="9" class="text--secondary">{{employeeInfo.personal.name}}</v-col>
                <v-col cols="3"><v-icon class="mr-2">corporate_fare</v-icon>所属企业</v-col>
                <v-col cols="9" class="text--secondary">{{employeeInfo.employer.ownerId.name}} {{employeeInfo.employer.sub_corp ? ' - '+ employeeInfo.employer.sub_corp : ''}}</v-col>
                <v-col cols="3"><v-icon class="mr-2">folder_shared</v-icon>所属部门</v-col>
                <v-col cols="9" v-if="employeeInfo.employer.ownerType !== 'Enterprise'" class="text--secondary">{{employeeInfo.employer.department.map(item => item.name)}}</v-col>
                <v-col cols="9" v-else class="text--secondary">{{employeeInfo.employer.subordinate || '-'}}</v-col>
                <v-col cols="3"><v-icon class="mr-2">perm_identity</v-icon>所属角色</v-col>
                <v-col cols="9" class="text--secondary">{{employeeInfo.employer.roles.map(item => item.name)}}</v-col>
                <v-col cols="3" v-if="employeeInfo.employer.ownerType !== 'Enterprise'"><v-icon class="mr-2">store</v-icon>所属门店</v-col>
                <v-col cols="9" v-if="employeeInfo.employer.ownerType !== 'Enterprise'" class="text--secondary">{{employeeInfo.employer.branch ? employeeInfo.employer.branch.name : '未分派门店'}}</v-col>
                <v-col cols="3"><v-icon class="mr-2">assignment_ind</v-icon>员工编号</v-col>
                <v-col cols="9" class="text--secondary">{{employeeInfo.employer.employeeNo}}</v-col>
                <v-col cols="3"><v-icon class="mr-2">stay_current_portrait</v-icon>手机号码</v-col>
                <v-col cols="9" class="text--secondary">{{employeeInfo.phone}}</v-col>
                <v-col cols="3"><v-icon class="mr-2">email</v-icon>电子邮件</v-col>
                <v-col cols="9" class="text--secondary">{{employeeInfo.username}}</v-col>
                <v-col cols="3" v-if="employeeInfo.employer.ownerType === 'Enterprise'"><v-icon class="mr-2">room</v-icon>地址</v-col>
                <v-col cols="9" v-if="employeeInfo.employer.ownerType === 'Enterprise'" class="text--secondary">{{employeeInfo.personal.address}}</v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-divider></v-divider>
            <v-expansion-panel-header>自定义字段</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-for="(item, i) in employeeInfo.customFields.filter(f => !f._id.name.includes('已删除字段'))" :key="i">
                <v-col cols="4"><v-icon class="mr-2">list</v-icon>{{item._id.name}}</v-col>
                <v-col cols="8" class="text--secondary">{{item.value}}</v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-divider v-if="employeeInfo.status !== 9"></v-divider>
      <v-card-actions v-if="employeeInfo.status !== 9 && employeeInfo.employer.ownerType === 'Enterprise' && (currentUser._id === employeeInfo._id || currentUser.hasAdmin.includes('dealer') || currentUser.hasAdmin.includes('enterprise') || currentUser.permission.includes('Enterprise_Employee'))">
        <v-btn text @click="resetPass" v-if="!employeeInfo.isBlock">重置密码</v-btn>
        <v-btn text @click="blockAccount" v-if="!employeeInfo.isBlock">注销用户</v-btn>
        <v-btn text @click="removeAccount" v-if="!employeeInfo.isBlock">删除用户</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">取消</v-btn>
        <v-btn color="secondary" text @click="editEmployee" v-if="!employeeInfo.isBlock">编辑</v-btn>
        <v-btn color="secondary" text @click="recover" v-else>恢复</v-btn>
      </v-card-actions>
      <v-card-actions v-if="employeeInfo.status !== 9 && employeeInfo.employer.ownerType === 'Dealer' && (currentUser._id === employeeInfo._id || currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Organize'))">
        <v-btn text @click="resetPass" v-if="!employeeInfo.isBlock">重置密码</v-btn>
        <v-btn text @click="blockAccount" v-if="!employeeInfo.isBlock">注销用户</v-btn>
        <v-btn text @click="removeAccount" v-if="!employeeInfo.isBlock">删除用户</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">取消</v-btn>
        <v-btn color="secondary" text @click="editEmployee" v-if="!employeeInfo.isBlock">编辑</v-btn>
        <v-btn color="secondary" text @click="recover" v-else>恢复</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>
        编辑人员
        <v-spacer></v-spacer>
        <v-btn icon @click="closeEdit"><v-icon>arrow_back</v-icon></v-btn>
      </v-card-title>
      <v-card-text v-if="employeeInfo.personal && employeeInfo.employer">
        <v-form v-model="valid" @submit.prevent="submit">
          <v-container>
            <v-row align="start" justify="center">
              <v-col cols="2" class="text-center">
                <input
                  id="files"
                  type="file"
                  name="file"
                  ref="uploadInput"
                  accept="image/*"
                  :multiple="false"
                  @change="detectFiles($event)"/>
                <v-hover v-slot:default="{ hover }">
                  <v-avatar>
                    <v-img
                      :src="editedEmployee.personal.profile ? ossURL +'/'+ editedEmployee.personal.profile : '/static/grey_silhouette.png'"
                      alt="设置照片"
                      aspect-ratio="1">
                      <v-progress-circular
                        color="secondary"
                        rotate="360"
                        size="48"
                        width="5"
                        v-if="Upload.uploading"
                        :value="Upload.progressUpload">
                      </v-progress-circular>
                    </v-img>
                    <div v-if="hover" class="show_upload" style="height: 100%; cursor: pointer">
                      <v-icon @click="uploadAvatar" >add_a_photo</v-icon>
                    </div>
                  </v-avatar>
                </v-hover>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="editedEmployee.personal.name"
                  type="text"
                  label="员工姓名"
                  hint="员工姓名为用户的真实姓名"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-n6">
              <v-col cols="2" class="text-center">
                <v-icon class="mt-n6" color="primary lighten-4">https</v-icon>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="editedEmployee.username"
                  type="text"
                  label="电子邮件"
                  hint="电子邮件将作为登陆名称使用"
                  outlined
                  dense
                  required
                  disabled
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-n6">
              <v-col cols="2" class="text-center">
                <v-icon class="mt-n6" color="primary lighten-4">stay_current_portrait</v-icon>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="editedEmployee.phone"
                  :rules="[rules.phonenum]"
                  :error-messages="showErr"
                  :clearable="showErr !== ''"
                  type="number"
                  @mousewheel.native.prevent @DOMMouseScroll.native.prevent
                  label="手机号码"
                  hint="电话号码将用于恢复密码和登陆凭证"
                  outlined
                  dense
                >
                  <template v-slot:append>
                    <v-scale-transition leave-absolute>
                      <v-progress-circular
                        v-if="editedEmployee.chkPhone"
                        :width="2"
                        size="24"
                        color="secondary"
                        indeterminate
                      ></v-progress-circular>
                    </v-scale-transition>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-n6" v-if="employeeInfo.employer.ownerType === 'Enterprise' && employeeInfo.employer.ownerId.branch">
              <v-col cols="2" class="text-center">
                <v-icon class="mt-n6" color="primary lighten-4">account_tree</v-icon>
              </v-col>
              <v-col cols="10">
                <v-autocomplete
                  v-model="editedEmployee.employer.sub_corp"
                  :items="employeeInfo.employer.ownerId.branch"
                  autocomplete="off"
                  outlined
                  dense
                  label="请选择员工所属分公司"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-n6" v-if="employeeInfo.employer.ownerType !== 'Enterprise'">
              <v-col cols="2" class="text-center">
                <v-icon class="mt-n6" color="primary lighten-4">account_tree</v-icon>
              </v-col>
              <v-col cols="10">
                <v-autocomplete
                  v-model="editedEmployee.department"
                  :items="getDeptList(departments[0])"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="value"
                  autocomplete="off"
                  outlined
                  dense
                  chips
                  small-chips
                  label="请选择所属部门"
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-n6" v-else>
              <v-col cols="2" class="text-center">
                <v-icon class="mt-n6" color="primary lighten-4">account_tree</v-icon>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="editedEmployee.employer.subordinate"
                  type="text"
                  label="所属部门"
                  hint="请填写员工所属部门"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-n6">
              <v-col cols="2" class="text-center">
                <v-icon class="mt-n6" color="primary lighten-4">perm_identity</v-icon>
              </v-col>
              <v-col cols="10">
                <v-autocomplete
                  v-model="editedEmployee.roles"
                  :items="flatGroupRoles(groupList)"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  chips
                  small-chips
                  label="请选择所属角色"
                  multiple
                  :disabled="editedEmployee._id === currentUser._id"
                >
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-action>
                        <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-n6" v-if="employeeInfo.employer.ownerType === 'Enterprise'">
              <v-col cols="2" class="text-center">
                <v-icon class="mt-n6" color="primary lighten-4">room</v-icon>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  type="text"
                  v-model="editedEmployee.address"
                  :rules="[rules.required]"
                  :error-messages="check.chkAddressMessage"
                  :clearable="check.chkAddressMessage !== ''"
                  label="收件地址"
                  hint="收件地址为接收设备的目的地"
                  outlined
                  dense
                >
                  <template v-slot:append>
                    <v-fade-transition leave-absolute>
                      <v-progress-circular
                        v-if="check.chkAddressLoading"
                        width="2"
                        size="24"
                        color="secondary"
                        indeterminate
                      ></v-progress-circular>
                    </v-fade-transition>
                  </template>
                  <template v-slot:append-outer>
                    <v-btn text @click="copyAddress()">复制公司地址</v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-n6" v-if="employeeInfo.employer.ownerType !== 'Enterprise'">
              <v-col cols="2" class="text-center">
                <v-icon class="mt-n6" color="primary lighten-4">store</v-icon>
              </v-col>
              <v-col cols="10">
                <v-autocomplete
                  v-model="editedEmployee.employer.branch"
                  :items="branchList"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  clearable
                  label="请选择所属门店"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-n6">
              <v-col cols="2" class="text-center">
                <v-icon class="mt-n6" color="primary lighten-4">assignment_ind</v-icon>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="editedEmployee.employer.employeeNo"
                  type="text"
                  label="员工编号"
                  hint="员工编号为企业内部的员工编号"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-n6" v-for="(item, i) in customFields" :key="i">
              <v-col cols="2" class="text-center">
                <v-icon class="mt-n6" color="primary lighten-4">list</v-icon>
              </v-col>
              <v-col cols="10" v-if="item.format.type === 'text'">
                <v-text-field
                  v-if="item.format.mask"
                  v-model="item.value"
                  v-mask="item.format.mask"
                  :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                  :label="item.name"
                  outlined
                  dense>
                </v-text-field>
                <v-text-field
                  v-else
                  v-model="item.value"
                  :label="item.name"
                  :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
              <v-col cols="10" v-if="item.format.type === 'selects'">
                <v-autocomplete
                  autocomplete="off"
                  v-model="item.value"
                  :items="item.format.items"
                  :label="item.name"
                  :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="resetPass">重置密码</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="closeEdit">返回</v-btn>
        <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogPass" width="500">
      <v-form v-model="validPass" @submit.prevent="submitPass">
        <v-card>
        <v-card-title>
          <span class="headline">更新密码</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="editedEmployee.password"
              label="请输入新的密码"
              :rules="[rules.required]"
              :type="showPass ? 'text' : 'password'"
              :append-icon="showPass ? 'visibility_off' : 'visibility'"
              @click:append="showPass = !showPass"
              outlined
              dense>
            </v-text-field>
            <v-text-field
              v-model="editedEmployee.repassword"
              label="请再次输入密码"
              :rules="[rules.required, rules.confirmpass]"
              :type="showPass ? 'text' : 'password'"
              :append-icon="showPass ? 'visibility_off' : 'visibility'"
              @click:append="showPass = !showPass"
              outlined
              dense>
            </v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogPass = false">取消</v-btn>
          <v-btn color="secondary" text @click="submitPass" :disabled="!validPass">保存</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
  </v-dialog>
</template>
<script>
import _ from 'lodash'
import axios from "axios";
import { SET_ERROR } from "@/store/mutations.type";
import { mapGetters } from 'vuex'
import {
  FETCH_DEPT_LIST,
  CHECK_USERPHONE,
  FETCH_UPLOAD,
  FETCH_GROUP_LIST,
  FETCH_FIELDS,
  FETCH_EMPLOYEE,
  EDIT_EMPLOYEE,
  LOGOUT,
  FETCH_BRANCH_LIST,
  CHECK_ADDR, BLOCK_EMPLOYEE, REMOVE_EMPLOYEE
} from "@/store/actions.type";
import store from "@/store";

export default {
  props: {
    account: {
      type: String,
      required: true,
    },
    btnColor: {
      type: String,
      default: "primary"
    },
    avatar: {
      type: String,
    },
    divider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      dialogPass: false,
      panelInfo: [0],
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
        confirmpass: () => {
          if (this.editedEmployee.password !== this.editedEmployee.repassword) {
            return '两次输入必须一致'
          } else {
            return true
          }
        }
      },
      valid: true,
      validPass: true,
      showPass: false,
      showErr: '',
      employeeInfo: {},
      isEdited: false,
      editedEmployee: {},
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      check: {
        chkAddressMessage: '',
        chkAddressLoading: false,
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentEmployer', 'uploadSign', 'departments', 'groupList', 'customFields', 'branchList']),
  },
  mounted() {},
  watch: {
    'editedEmployee.phone'() {
      this.checkPhone();
    },
    'editedEmployee.address'() {
      this.checkAddress();
    },
  },
  methods: {
    getEmployee(id) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(FETCH_EMPLOYEE, id)
          .then((data) => {
            this.employeeInfo = Object.assign({}, data)
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })

    },
    closeDialog () {
      this.getEmployee(this.employeeInfo._id)
      this.dialog = false
    },
    closeEdit () {
      this.getEmployee(this.employeeInfo._id)
      this.isEdited = false
    },
    async editEmployee () {
      await this.$store.dispatch(FETCH_GROUP_LIST, 'owner='+ this.employeeInfo.employer.ownerId._id)
      await this.$store.dispatch(FETCH_DEPT_LIST, 'owner='+ this.employeeInfo.employer.ownerId._id)
      await this.$store.dispatch(FETCH_BRANCH_LIST)
      await this.$store.dispatch(FETCH_FIELDS, this.employeeInfo.employer.ownerId._id + '/Account')
      this.customFields.forEach(field => {
        let accountField = this.employeeInfo.customFields.find(accountField => accountField._id._id === field._id)
        if (accountField) field['value'] = accountField.value
      })
      this.editedEmployee = Object.assign({
        department: this.employeeInfo.employer.department.map(item => item._id),
        roles: this.employeeInfo.employer.roles.map(item => item._id),
        address: this.employeeInfo.personal.address,
      }, this.employeeInfo)
      this.isEdited = true
    },

    checkPhone: _.debounce(function () {
      if (this.editedEmployee.phone && this.editedEmployee.phone !== this.employeeInfo.phone) {
        this.editedEmployee.chkPhone = true;
        this.$store.dispatch(CHECK_USERPHONE, this.editedEmployee.phone)
          .then(() => {
            this.showErr = '手机号码已被注册，请更换其它号码';
            this.editedEmployee.chkPhone = false;
          })
          .catch(() => {
            this.showErr = '';
            this.editedEmployee.chkPhone = false;
          })
      } else {
        this.showErr = '';
        this.editedEmployee.chkPhone = false;
      }
    }, 1000),
    checkAddress: _.debounce(function () {
      if (this.editedEmployee && this.editedEmployee.address) {
        this.check.chkAddressLoading = true;
        this.$store.dispatch(CHECK_ADDR, this.editedEmployee.address)
          .then((data) => {
            if (data.status !== 0 || data.result.reliability < 7) {
              this.check.chkAddressMessage = '地址信息错误，请输入正确的地址信息';
            } else {
              this.editedEmployee.addressInfo = data.result;
              this.check.chkAddressMessage = '';
            }
            this.check.chkAddressLoading = false;
          })
          .catch((err) => {
            this.check.chkAddressMessage = err.data.message;
            this.check.chkAddressLoading = false;
          })
      }
    }, 500),
    resetPass() {
      this.dialogPass = true
    },
    submitPass() {
      let reqEmployee = {}
      reqEmployee._id = this.employeeInfo._id
      reqEmployee.password = this.editedEmployee.password
      this.$store.dispatch(EDIT_EMPLOYEE, reqEmployee)
        .then(() => {
          this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
          if (this.currentUser._id === this.employeeInfo._id) {
            this.$store.dispatch(LOGOUT).then(() => {
              location.reload()
            })
          } else {
            this.editedEmployee.password = ''
            this.editedEmployee.repassword = ''
            this.dialogPass = false
          }
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    recover() {
      let reqEmployee = {}
      reqEmployee._id = this.employeeInfo._id
      reqEmployee.isBlock = false
      this.$store.dispatch(EDIT_EMPLOYEE, reqEmployee)
        .then(() => {
          this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
          this.getEmployee(this.employeeInfo._id)
            .then((data => {
              this.$emit('UpdateEmployee', data)
              // this.closeEdit()
            }))
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    blockAccount() {
      store.dispatch(BLOCK_EMPLOYEE, {ids: [this.employeeInfo._id]})
        .then(() => {
          store.commit(SET_ERROR, {msg: '修改完成', color: 'primary'});
          this.getEmployee(this.employeeInfo._id)
            .then((data => {
              this.$emit('UpdateEmployee', data)
            }))
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    removeAccount() {
      store.dispatch(REMOVE_EMPLOYEE, {ids: [this.employeeInfo._id]})
        .then(() => {
          store.commit(SET_ERROR, {msg: '修改完成', color: 'primary'});
          this.getEmployee(this.employeeInfo._id)
            .then((data => {
              this.$emit('UpdateEmployee', data)
            }))
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    copyAddress() {
      this.editedEmployee.address = this.editedEmployee.employer.ownerId.address
    },
    submit() {
      let reqEmployee = {}
      reqEmployee._id = this.editedEmployee._id
      reqEmployee.name = this.editedEmployee.personal.name
      reqEmployee.phone = this.editedEmployee.phone
      reqEmployee.department = this.editedEmployee.department
      reqEmployee.roles = this.editedEmployee.roles
      reqEmployee.employeeNo = this.editedEmployee.employer.employeeNo
      reqEmployee.sub_corp = this.editedEmployee.employer.sub_corp
      reqEmployee.profile = this.editedEmployee.personal.profile
      if (!this.editedEmployee.employer.branch) this.editedEmployee.employer.branch = ''
      if (typeof this.editedEmployee.employer.branch === "object") {
        reqEmployee.branch = this.editedEmployee.employer.branch._id
      } else {
        reqEmployee.branch = this.editedEmployee.employer.branch
      }
      if (this.customFields.length) {
        reqEmployee['customFields'] = []
        this.customFields.forEach(item => {
          if (item.value) reqEmployee['customFields'].push({ _id: item._id, value: item.value })
        })
      }

      if (this.editedEmployee.address) reqEmployee.address = this.editedEmployee.address
      if (this.editedEmployee.employer.ownerType === 'Enterprise') {
        if (this.editedEmployee.addressInfo) {
          reqEmployee.addressInfo = this.editedEmployee.addressInfo
        } else {
          this.checkAddress();
          this.$store.commit(SET_ERROR, {msg: '请等待地址验证中...', color: 'secondary', timeout: 500});
          return
        }
      }

      reqEmployee.subordinate = this.editedEmployee.employer.subordinate

      this.$store.dispatch(EDIT_EMPLOYEE, reqEmployee)
        .then(() => {
          this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
          this.getEmployee(this.editedEmployee._id)
            .then((data => {
              this.$emit('UpdateEmployee', data)
              this.closeEdit()
            }))
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    uploadAvatar () {
      this.$store.dispatch(FETCH_UPLOAD)
        .then(this.$refs.uploadInput.click())
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.currentEmployer.ownerId._id +'/avatar/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.editedEmployee.personal.profile = this.currentEmployer.ownerId._id +'/avatar/'+ this.uploadSign.key;
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    getDeptList(obj) {
      let arrObj = [];
      if (obj) {
        arrObj.push({name: obj.name, value: obj._id})
        if (obj.children) {
          for (let item_1 of obj.children) {
            arrObj.push({name: obj.name +'>>'+ item_1.name, value: item_1._id})
            if (item_1.children) {
              for (let item_2 of item_1.children) {
                arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name, value: item_2._id})
                if (item_2.children) {
                  for (let item_3 of item_2.children) {
                    arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name +'>>'+ item_3.name, value: item_3._id})
                  }
                }
              }
            }
          }
        }
      }
      return arrObj;
    },
    flatGroupRoles(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.roles.forEach((rolesItem) => {
          arrRoles.push({ _id: rolesItem._id, name: rolesItem.name, group: groupItem.name })
        })
        arrRoles.push({ divider: true })
      })
      return arrRoles
    },
  },
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
