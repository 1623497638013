var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "374"
    },
    on: {
      "input": function input($event) {
        return _vm.getItem(_vm.id);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          attrs: {
            "color": _vm.btnColor,
            "dark": "",
            "text": "",
            "ripple": false
          }
        }, 'span', attrs, false), on), [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2)];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.itemData ? _c('v-card', [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "prominent": "",
      "src": _vm.itemData.profileImage ? _vm.ossURL + '/' + _vm.itemData.profileImage : '/static/error/empty_state.svg',
      "fade-img-on-scroll": ""
    },
    scopedSlots: _vm._u([{
      key: "img",
      fn: function fn(_ref2) {
        var props = _ref2.props;
        return [_c('v-img', _vm._b({
          staticClass: "white--text align-end",
          attrs: {
            "gradient": "to top right, rgba(0,0,0,.5), rgba(0,0,0,.1)"
          }
        }, 'v-img', props, false))];
      }
    }], null, false, 4098848071)
  }, [_c('v-toolbar-title', [_vm._v(_vm._s(_vm.itemData.name))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('div', {
    staticClass: "mt-6"
  }, [_vm._v(_vm._s(_vm.itemData.city))]), _c('div', [_vm._v(_vm._s(_vm.itemData.address))]), _c('div', {
    staticClass: "mt-2"
  }, [_vm._v(_vm._s(_vm.itemData.contactName))]), _c('div', [_vm._v(_vm._s(_vm.itemData.contactPhone))])]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("关闭")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }