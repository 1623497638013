import { KnowledgeService } from "@/common/http";
import {
  FETCH_KNOWLEDGE_LIST,
  FETCH_KNOWLEDGE,
  PUBLISH_KNOWLEDGE,
  EDIT_KNOWLEDGE,
  AUTH_KNOWLEDGE,
  TOP_KNOWLEDGE,
  DELETE_KNOWLEDGE,
  CREATE_USEFUL_KNOWLEDGE,
  CANCEL_USEFUL_KNOWLEDGE,
} from "./actions.type";
import {
  SET_ERROR,
  LOAD_KNOWLEDGE,
  SET_KNOWLEDGE_LIST,
  SET_KNOWLEDGE,
} from "./mutations.type"

const state = {
  knowledgeLoading: true,
  knowledgeList: [],
  knowledgeCount: 0,
  knowledge: {},
};

const getters = {
  knowledge: state => state.knowledge,
  knowledgeList: state => state.knowledgeList,
  knowledgeCount: state => state.knowledgeCount,
  knowledgeLoading: state => state.knowledgeLoading,
};

const actions = {
  [FETCH_KNOWLEDGE_LIST]({ commit }, slug) {
    commit(LOAD_KNOWLEDGE);
    return new Promise((resolve, reject) => {
      KnowledgeService.get('', 'list?'+slug)
        .then(({ data }) => {
          commit(SET_KNOWLEDGE_LIST, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_KNOWLEDGE]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      KnowledgeService.get('', slug)
        .then(({ data }) => {
          commit(SET_KNOWLEDGE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_KNOWLEDGE](context, knowledge) {
    return KnowledgeService.create('', knowledge);
  },
  [CREATE_USEFUL_KNOWLEDGE](context, knowledge) {
    return KnowledgeService.create('/useful/'+ knowledge._id);
  },
  [CANCEL_USEFUL_KNOWLEDGE](context, knowledge) {
    return KnowledgeService.destroy('/useful/'+ knowledge._id)
  },
  [EDIT_KNOWLEDGE](context, knowledge) {
    return KnowledgeService.update(knowledge._id, knowledge);
  },
  [AUTH_KNOWLEDGE](context, knowledge) {
    return KnowledgeService.auth(knowledge._id +'/'+ knowledge.reply)
  },
  [TOP_KNOWLEDGE](context, knowledge) {
    return KnowledgeService.top(knowledge._id +'/'+ knowledge.reply)
  },
  [DELETE_KNOWLEDGE](context, knowledge) {
    return KnowledgeService.destroy('/'+ knowledge._id)
  }
};

const mutations = {
  [LOAD_KNOWLEDGE] (state) {
    state.knowledgeLoading = true;
  },
  [SET_KNOWLEDGE_LIST](state, list) {
    state.knowledgeList = list.knowledge;
    state.knowledgeCount = list.query.count;
    state.knowledgeLoading = false;
  },
  [SET_KNOWLEDGE](state, knowledge) {
    state.knowledge = knowledge;
    state.knowledgeLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
