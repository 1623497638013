var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    attrs: {
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openItem();
      }
    }
  }, [_vm._t("default")], 2), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.itemData ? _c('v-card', [_c('v-card-title', [_vm._v(" 知识预览 "), _vm.itemData.status === 0 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "secondary"
    }
  }, [_vm._v("待审核")]) : _vm.itemData.status === 1 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "primary"
    }
  }, [_vm._v("正常")]) : _vm._e(), _vm.itemData.isTop ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "warning"
    }
  }, [_vm._v("置顶")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _vm.itemData.isVideo ? _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-container', {
    staticClass: "d-flex justify-center"
  }, [_c('video', {
    ref: "videoSource",
    attrs: {
      "poster": _vm.ossURL + '/' + _vm.itemData.profile_image,
      "controls": "",
      "width": "80%"
    }
  }, [_c('source', {
    attrs: {
      "src": _vm.ossURL + '/' + _vm.itemData.video_key
    }
  })])])], 1) : _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "height": "375px",
      "dark": "",
      "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
      "aspect-ratio": 32 / 9,
      "src": _vm.itemData.profile_image ? _vm.ossURL + '/' + _vm.itemData.profile_image : '/static/error/empty_state.svg'
    }
  }, [_c('v-row', {
    staticClass: "lightbox white--text pa-2 fill-height",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "text-h3 text-center"
  }, [_vm._v(_vm._s(_vm.itemData.title))]), _c('div', {
    staticClass: "subheading text-center"
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.itemData.publish_date)) + " " + _vm._s(_vm.itemData.author))])])], 1)], 1), _c('v-container', [_c('pre', {
    domProps: {
      "innerHTML": _vm._s(_vm.itemData.context)
    }
  })])], 1), _c('v-divider'), _c('v-card-actions', [_c('v-subheader', [_vm.itemData.editor ? _c('widgets-employee-dialogs', {
    staticClass: "v-list-item--link",
    attrs: {
      "account": _vm.itemData.editor._id
    }
  }, [_vm._v(" " + _vm._s(_vm.itemData.editor.personal ? _vm.itemData.editor.personal.name : '*未设置姓名') + " ")]) : _vm._e(), _vm._v(" - 创建于 " + _vm._s(_vm._f("fromDate")(_vm.itemData.createTime)) + " - 最后更新时间：" + _vm._s(_vm._f("formatTime")(_vm.itemData.updateTime)) + " ")], 1), _vm._v(" - "), _vm.itemData.useful_account && _vm.itemData.useful_account.includes(_vm.currentUser._id) ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.setUseful(_vm.itemData, 'cancel');
      }
    }
  }, [_c('v-icon', [_vm._v("thumb_up")])], 1) : _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.setUseful(_vm.itemData, 'create');
      }
    }
  }, [_c('v-icon', [_vm._v("thumb_up_off_alt")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v("关闭")]), _vm.currentUser.hasAdmin.includes('dealer') || _vm.checkAuditPower(_vm.itemData, _vm.currentUser) ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.deleteItem(_vm.itemData);
      }
    }
  }, [_vm._v("删除")]) : _vm._e(), _vm.checkAuditPower(_vm.itemData, _vm.currentUser) && !_vm.itemData.isTop ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.setTop(_vm.itemData, true);
      }
    }
  }, [_vm._v("置顶")]) : _vm._e(), _vm.checkAuditPower(_vm.itemData, _vm.currentUser) && _vm.itemData.isTop ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.setTop(_vm.itemData, false);
      }
    }
  }, [_vm._v("取消置顶")]) : _vm._e(), _vm.checkAuditPower(_vm.itemData, _vm.currentUser) && _vm.itemData.status === 0 ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.setAuth(_vm.itemData, 1);
      }
    }
  }, [_vm._v("审核发布")]) : _vm._e(), _vm.checkAuditPower(_vm.itemData, _vm.currentUser) && _vm.itemData.status === 1 ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.setAuth(_vm.itemData, 0);
      }
    }
  }, [_vm._v("下架离线")]) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.checkCreatePower(_vm.itemData, _vm.currentUser) ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.goEdit(_vm.itemData._id, _vm.itemData.isVideo);
      }
    }
  }, [_vm._v("编辑")]) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }