<template>
  <div :id="title" :style="{ width: width, height: height }"></div>
</template>
<script>
import * as echarts from 'echarts';
import "@/common/echarts.theme";
export default {
  props: {
    data: {
      type: Array,
    },
    label: {
      type: Array,
    },
    legend: {
      type: Array,
    },
    title: {
      type: String,
    },
    width: {
      type: String
    },
    height: {
      type: String
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.drawLine();
    })

  },
  watch: {
    data() {
      this.drawLine();
    }
  },
  methods: {
    drawLine() {
      let myChart = echarts.init(document.getElementById(this.title), 'espreport2');
      myChart.setOption({
        title: {
          text: this.title,
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          data: this.legend, //['保内送修', '保外送修', '总量']
          bottom: 10,
          left: 'center',
        },
        xAxis: {
          type: 'category',
          data: this.label,
          axisLabel:{
            width: 75,
            interval: 0,
            overflow: 'break'
          },
        },
        yAxis: {
          type: 'value'
        },
        series: this.data
      })
    }
  }
}
</script>
