var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.type === 'circle' ? _c('div', {
    staticClass: "base-timer"
  }, [_c('svg', {
    staticClass: "base-timer__svg",
    attrs: {
      "viewBox": "0 0 100 100",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    staticClass: "base-timer__circle"
  }, [_c('circle', {
    staticClass: "base-timer__path-elapsed",
    attrs: {
      "cx": "50",
      "cy": "50",
      "r": "45"
    }
  }), _c('path', {
    staticClass: "base-timer__path-remaining",
    class: _vm.remainingPathColor,
    attrs: {
      "stroke-dasharray": _vm.circleDasharray,
      "d": "\n          M 50, 50\n          m -45, 0\n          a 45,45 0 1,0 90,0\n          a 45,45 0 1,0 -90,0\n        "
    }
  })])]), _c('span', {
    staticClass: "base-timer__label"
  }, [_vm._v(" " + _vm._s(_vm.formattedTimeLeft) + " ")])]) : _c('div', [_c('font', {
    attrs: {
      "color": _vm.remainingPathColor
    }
  }, [_vm._v(" " + _vm._s(_vm.formattedTextLeft) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }