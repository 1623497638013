<template>
  <v-row justify="center">
    <v-expansion-panels accordion hover multiple>
      <v-expansion-panel v-for="(item,i) in assetList" :key="i" @click="setFields(item._id, i)">
        <v-expansion-panel-header>
          <v-row no-gutters>
            <v-col cols="4">
              {{item.name}}
            </v-col>
            <v-col cols="8" class="text--secondary">
              {{item.product.name}}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-for="field in assetField" :key="field.field" align="center" no-gutters>
            <v-col cols="12" v-if="editedAsset[i]">
              <v-row v-if="field.field === 'serial_number'" align="center">
                <v-col cols="2" class="text-right">{{ field.name }}</v-col>
                <v-col cols="10" class="text--secondary" v-if="!editedAsset[i].edited_serial_number">
                  {{item.serial_number}}
                  <v-icon class="mx-2" small @click="editedAsset[i].edited_serial_number = true; editedAsset[i].serial_number = item.serial_number">edit</v-icon>
                </v-col>
                <v-col cols="10" class="text--secondary" v-else>
                  <v-text-field
                    v-model="editedAsset[i].serial_number"
                    :rules="[rules.required]"
                    type="text"
                    label="修改序列号"
                    placeholder="请输入新的序列号"
                    hide-details
                    append-outer-icon="close"
                    @click:append-outer="editedAsset[i].edited_serial_number = false; editedAsset[i].serial_number = ''"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="field.field === 'label'" align="center">
                <v-col cols="2" class="text-right">{{ field.name }}</v-col>
                <v-col cols="10" class="text--secondary" v-if="!editedAsset[i].edited_label">
                  <v-chip class="mx-2" label small v-for="(label, index) in item.label" :key="index">{{ label }}</v-chip>
                  <v-icon class="mx-2" small @click="editedAsset[i].edited_label = true; editedAsset[i].label = item.label">edit</v-icon>
                </v-col>
                <v-col cols="10" class="text--secondary" v-else>
                  <v-combobox
                    v-model="editedAsset[i].label"
                    label="设备标签"
                    placeholder="使用回车键可以输入多个标签。如内部设备代码、编码等信息"
                    hide-details
                    deletable-chips
                    multiple
                    small-chips
                    outlined
                    dense
                    append-outer-icon="close"
                    @click:append-outer="editedAsset[i].edited_label = false; editedAsset[i].label = []"
                  >
                  </v-combobox>
                </v-col>
              </v-row>
              <v-row v-if="field.field === 'out_number'" align="center">
                <v-col cols="2" class="text-right">{{ field.name }}</v-col>
                <v-col cols="10" class="text--secondary" v-if="!editedAsset[i].edited_out_number">
                  {{item.out_number}}
                  <v-icon class="mx-2" small @click="editedAsset[i].edited_out_number = true; editedAsset[i].out_number = item.out_number">edit</v-icon>
                </v-col>
                <v-col cols="10" class="text--secondary" v-else>
                  <v-text-field
                    v-model="editedAsset[i].out_number"
                    type="text"
                    label="修改设备外部编号"
                    placeholder="请输入新的序列号"
                    hide-details
                    append-outer-icon="close"
                    @click:append-outer="editedAsset[i].edited_out_number = false; editedAsset[i].out_number = ''"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="field.field === 'product'" align="center">
                <v-col cols="12">{{item.product}}</v-col>
                <v-col cols="2" class="text-right">产品</v-col>
                <v-col cols="10" class="text--secondary" v-if="!editedAsset[i].edited_product">
                  {{item.product.name}}
                  <v-icon class="mx-2" small @click="
                          editedAsset[i].edited_product = true;
                          editedAsset[i].product = item.product;
                          editedAsset[i].vendor = item.product.vendor;
                          editedAsset[i].product_type = item.product.product_type;">edit</v-icon>
                </v-col>
                <v-col cols="10" class="text--secondary" v-else>
                  <v-row no-gutters align="center">
                    <v-col cols="4">
                      <v-autocomplete
                        autocomplete="off"
                        @change="doAction('set_product_list', i)"
                        v-model="editedAsset[i].vendor"
                        :items="vendorList"
                        label="品牌厂商"
                        placeholder="选择产品品牌厂商"
                        hide-details
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        class="mx-2"
                        autocomplete="off"
                        @change="doAction('set_product_list', i)"
                        v-model="editedAsset[i].product_type"
                        :items="typeList"
                        label="产品类别"
                        placeholder="选择产品类别"
                        hide-details
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        autocomplete="off"
                        @change="doAction('set_warranty_time', i)"
                        v-model="editedAsset[i].product"
                        :items="productList"
                        label="产品"
                        placeholder="选择产品型号"
                        hide-details
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                        append-outer-icon="close"
                        @click:append-outer="editedAsset[i].edited_product = false; editedAsset[i].vendor = ''; editedAsset[i].product_type = ''; editedAsset[i].product = ''"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>{{data.item.name}} - {{data.item.part_number}}</v-list-item-title>
                            <v-list-item-subtitle v-if="data.item.it_info">{{data.item.it_info.ram}}G - {{data.item.it_info.network}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
<!--          <v-row v-for="item in assetField" :key="item.field" align="center">-->
<!--            <v-col cols="1" class="text-right">-->
<!--              <v-icon class="mt-n6" color="primary lighten-4">devices</v-icon>-->
<!--            </v-col>-->
<!--            <v-col cols="10" class="text&#45;&#45;secondary my-n4" v-if="item.format.type === 'text'">-->
<!--              <v-text-field-->
<!--                v-model="item.value"-->
<!--                :label="item.name"-->
<!--                outlined-->
<!--                dense>-->
<!--              </v-text-field>-->
<!--            </v-col>-->
<!--            <v-col cols="10" class="text&#45;&#45;secondary my-n4" v-if="item.format.type === 'label'">-->
<!--              <v-combobox-->
<!--                v-model="item.value"-->
<!--                :label="item.name"-->
<!--                hint="使用回车键可以输入多个标签"-->
<!--                deletable-chips-->
<!--                multiple-->
<!--                small-chips-->
<!--                outlined-->
<!--                dense-->
<!--              >-->
<!--              </v-combobox>-->
<!--            </v-col>-->
<!--            <v-col cols="10" class="text&#45;&#45;secondary my-n4" v-if="item.format.type === 'select'">-->
<!--              <v-autocomplete-->
<!--                v-if="item.format.source === 'AssetState' && stateList"-->
<!--                autocomplete="off"-->
<!--                v-model="item.value"-->
<!--                :items="stateList"-->
<!--                :label="item.name"-->
<!--                item-text="name"-->
<!--                item-value="_id"-->
<!--                outlined-->
<!--                dense-->
<!--              ></v-autocomplete>-->
<!--              <v-autocomplete-->
<!--                v-else-if="item.format.source === 'Vendor' && vendorList"-->
<!--                autocomplete="off"-->
<!--                v-model="item.value"-->
<!--                :items="vendorList"-->
<!--                :label="item.name"-->
<!--                item-text="name"-->
<!--                item-value="_id"-->
<!--                outlined-->
<!--                dense-->
<!--              ></v-autocomplete>-->
<!--              <v-autocomplete-->
<!--                v-else-if="item.format.source === 'ProductType' && typeList"-->
<!--                autocomplete="off"-->
<!--                v-model="item.value"-->
<!--                :items="typeList"-->
<!--                :label="item.name"-->
<!--                item-text="name"-->
<!--                item-value="_id"-->
<!--                outlined-->
<!--                dense-->
<!--              ></v-autocomplete>-->
<!--              <v-autocomplete-->
<!--                v-else-if="item.format.source === 'Product' && productList"-->
<!--                autocomplete="off"-->
<!--                v-model="item.value"-->
<!--                :items="productList"-->
<!--                :label="item.name"-->
<!--                item-text="name"-->
<!--                item-value="_id"-->
<!--                outlined-->
<!--                dense-->
<!--              >-->
<!--                <template v-slot:item="data">-->
<!--                  <v-list-item-content>-->
<!--                    <v-list-item-title>{{data.item.name}} - {{data.item.part_number}}</v-list-item-title>-->
<!--                    <v-list-item-subtitle v-if="data.item.it_info">{{data.item.it_info.ram}}G - {{data.item.it_info.network}}</v-list-item-subtitle>-->
<!--                  </v-list-item-content>-->
<!--                </template>-->
<!--              </v-autocomplete>-->
<!--              <v-autocomplete-->
<!--                v-else-if="item.format.source === 'AssetStatus' && assetState"-->
<!--                autocomplete="off"-->
<!--                v-model="item.value"-->
<!--                :items="assetState"-->
<!--                :label="item.name"-->
<!--                item-text="name"-->
<!--                item-value="code"-->
<!--                outlined-->
<!--                dense-->
<!--              ></v-autocomplete>-->
<!--            </v-col>-->
<!--            <v-col cols="10" class="text&#45;&#45;secondary my-n4" v-if="item.format.type === 'datetime'">-->
<!--              <v-menu-->
<!--                v-if="item.field === 'purchase_time'"-->
<!--                v-model="editAsset.purchase_time"-->
<!--                :close-on-content-click="false"-->
<!--                transition="scale-transition"-->
<!--                offset-y-->
<!--                min-width="290px"-->
<!--              >-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-text-field-->
<!--                    v-model="item.value"-->
<!--                    :label="item.name"-->
<!--                    readonly-->
<!--                    v-bind="attrs"-->
<!--                    outlined-->
<!--                    dense-->
<!--                    v-on="on"-->
<!--                  ></v-text-field>-->
<!--                </template>-->
<!--                <v-date-picker v-model="item.value" no-title scrollable></v-date-picker>-->
<!--              </v-menu>-->
<!--            </v-col>-->
<!--            <v-col cols="1" class="text-right"></v-col>-->
<!--          </v-row>-->
<!--          <v-card-actions>-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-btn text>复制到全部设备</v-btn>-->
<!--            <v-btn text @click="saveAsset">保存</v-btn>-->
<!--          </v-card-actions>-->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>
<script>
import Util from "@/common/util";
import {mapGetters} from "vuex";
import store from "@/store";
import {
  FETCH_ASSETSTATE_LIST, FETCH_PRODUCT_LIST, FETCH_PRODUCTTYPE_LIST, FETCH_VENDOR_LIST, FETCH_WAREHOUSE_LIST,
} from "@/store/actions.type";

export default {
  props: {
    assetList: {
      type: Array,
      required: true,
    },
    assetField: {
      type: Array,
    },
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      defaultAsset: {
        edited_serial_number: false,
        edited_label: false,
        edited_out_number: false,
        edited_vendor: false,
        edited_product_type: false,
        edited_product: false,
        edited_purchase_time: false,
        edited_warranty_time: false,
        edited_warranty_deadline: false,
        edited_warehouse: false,
        edited_warehouse_time: false,
        edited_asset_from: false,
        edited_asset_state: false,
        edited_guaranteed_type: false,
        edited_guaranteed_time: false,
        edited_guaranteed_limit: false,
        edited_guaranteed_deadline: false,
        edited_apple_id: false,
        edited_apple_password: false,
        edited_isABM: false,
        edited_sim_code: false,
        edited_iccid_code: false,
        edited_imei_number: false,
        edited_mdm_token: false,
        edited_mdm_account: false,
        edited_mdm_password: false,
      },
      editedAsset: []
    }
  },
  mounted() { },
  watch: {},
  created() {
    this.assetInit()
  },
  computed: {
    ...mapGetters([
      "stateList",
      "vendorList",
      "typeList",
      "productList",
      "warehouseList",]),
    assetFields () {
      return Util.categories('assetFields')
    },
    assetState () {
      return Util.categories('assetState')
    },
  },

  methods: {
    assetInit() {
      if (this.editedAsset && this.editedAsset.length) this.editedAsset.length = 0

      store.dispatch(FETCH_ASSETSTATE_LIST, 'isEnable=true')
      store.dispatch(FETCH_PRODUCTTYPE_LIST, 'isEnable=true')
      store.dispatch(FETCH_VENDOR_LIST, 'limit=-1&isEnable=true')
      store.dispatch(FETCH_PRODUCT_LIST, 'isEnable=true&limit=200')
      store.dispatch(FETCH_WAREHOUSE_LIST, 'isEnable=true')
    },
    setFields(assetId) {
      if (!this.editedAsset.find(item => item._id === assetId)) {
        this.editedAsset.push(Object.assign({_id: assetId}, this.defaultAsset))
      }
    },
    doAction(action, item = {}) {
      switch (action) {
        case 'set_product_list': {
          let strQuery = 'isEnable=true&limit=1000'
          if (this.editedAsset[item].vendor) {
            if (typeof this.editedAsset[item].vendor === "object") {
              strQuery += '&vendor='+ this.editedAsset[item].vendor._id
            } else {
              strQuery += '&vendor='+ this.editedAsset[item].vendor
            }
          }
          if (this.editedAsset[item].product_type) {
            if (typeof this.editedAsset[item].product_type === "object") {
              strQuery += '&type='+ this.editedAsset[item].product_type._id
            } else {
              strQuery += '&type='+ this.editedAsset[item].product_type
            }
          }

          store.dispatch(FETCH_PRODUCT_LIST, strQuery)
          break
        }
        case 'set_warranty_time': {
          if (this.editedAsset[item].product) {
            let objProduct = this.productList.find(o => o._id === this.editedAsset[item].product)
            this.editedAsset[item].vendor = objProduct.vendor._id
            this.editedAsset[item].productType = objProduct.product_type._id
            if (objProduct.guarantee) {
              this.editedAsset[item].warranty_time = objProduct.guarantee
            } else {
              this.editedAsset[item].warranty_time = 0
            }
            if (this.editedAsset[item].purchase_time) this.doAction('set_warranty_deadline')
          }
          break
        }
      }
    },
  }
}
</script>
