var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_vm._v(" 查看素材 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('Close');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-img', {
    attrs: {
      "height": "375px",
      "gradient": "to bottom, rgba(0,0,0,0), rgba(0,0,0,.2)",
      "src": _vm.material.profile_image ? _vm.ossURL + '/' + _vm.material.profile_image : '/static/error/empty_state.svg'
    }
  }, [_c('v-row', {
    staticClass: "white--text pa-4 fill-height",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "subheading"
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.material.publish_date)) + " " + _vm._s(_vm.material.author))]), _c('div', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(_vm.material.title))])])], 1)], 1), _c('div', {
    staticClass: "ma-12",
    domProps: {
      "innerHTML": _vm._s(_vm.material.context)
    }
  })], 1), _c('v-divider'), _vm.currentUser.employer.ownerId._id === _vm.material.ownerId && (_vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('CMS_Material')) ? _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.deleteItem(_vm.material);
      }
    }
  }, [_vm._v("删除素材")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('Close');
      }
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/dealer/material/edit/' + _vm.material._id
        });
      }
    }
  }, [_vm._v("编辑")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }