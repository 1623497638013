import Vue from 'vue'
import Router from 'vue-router'
import publicPaths from './public.paths'
import adminPaths from './admin.paths'
import dealerPaths from "./dealer.paths";

Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  base: "/",
  mode: "hash",
  scrollBehavior: () => ({ y: 0 }),
  routes: publicPaths
});

export const constantRouterMap = publicPaths;
export const notFoundRoutes = [
  {
    path: "*",
    hidden: true,
    meta: {
      public: true,
      breadcrumb: false,
      roles: ['admin', 'enterprise', 'member', 'dealer']
    },
    redirect: {
      path: "/404"
    }
  }
];
// export const adminRouterMap = adminPaths.concat(dealerPaths);
export const adminRouterMap = adminPaths;
export const dealerRouterMap = dealerPaths;
