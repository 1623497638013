var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    attrs: {
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('open_case');
      }
    }
  }, [_vm._t("default")], 2), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "fullscreen": ""
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._v(_vm._s(_vm.quoteData.title))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('div', {
    ref: "quoteInfo",
    staticClass: "pt-12",
    staticStyle: {
      "padding-left": "160px",
      "padding-right": "160px",
      "min-width": "1857px"
    }
  }, [_c('div', {
    staticClass: "mb-12"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/logo2x.png",
      "width": "40"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('p', {
    staticClass: "display-1"
  }, [_vm._v(" " + _vm._s(_vm.quoteData.enterprise + '租赁报价') + " ")])])], 1)], 1), _c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "accordion": "",
      "flat": "",
      "disabled": ""
    },
    model: {
      value: _vm.offerPanels,
      callback: function callback($$v) {
        _vm.offerPanels = $$v;
      },
      expression: "offerPanels"
    }
  }, [_c('v-expansion-panel', [_c('div', {
    ref: "infoPanel"
  }, [_c('v-expansion-panel-header', [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("基本信息")])]), _c('v-expansion-panel-content', [_c('table', {
    staticClass: "infoTable",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('thead'), _c('tbody', [_c('tr', {
    staticClass: "caption"
  }, [_c('td', {
    staticClass: "tit twiceSize",
    attrs: {
      "width": "7%",
      "align": "center"
    }
  }, [_vm._v("客户")]), _c('td', {
    staticClass: "twiceSize",
    attrs: {
      "width": "20%",
      "align": "center"
    }
  }, [_vm._v(_vm._s(_vm.quoteData.enterprise))]), _c('td', {
    staticClass: "tit twiceSize",
    attrs: {
      "width": "7%",
      "align": "center"
    }
  }, [_vm._v("产品")]), _vm.quoteData.product_list ? _c('td', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.quoteData.product_list.length > 1 ? "系列产品" : _vm.quoteData.product_list[0].product_code || _vm.quoteData.product_list[0].name) + " ")]) : _vm._e(), _c('td', {
    staticClass: "tit twiceSize",
    attrs: {
      "width": "7%",
      "align": "center"
    }
  }, [_vm._v("租赁方式")]), _c('td', {
    staticClass: "twiceSize",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(_vm._s(_vm.quoteData.rent_type))]), _c('td', {
    staticClass: "tit twiceSize",
    attrs: {
      "width": "7%",
      "align": "center"
    }
  }, [_vm._v("预计起租")]), _c('td', {
    staticClass: "twiceSize",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.quoteData.lease_start)))]), _c('td', {
    staticClass: "tit twiceSize",
    attrs: {
      "width": "7%",
      "align": "center"
    }
  }, [_vm._v("销售AE")]), _c('td', {
    staticClass: "twiceSize",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(_vm._s(_vm.quoteData.sales))])]), _c('tr', [_c('td', {
    staticClass: "tit",
    attrs: {
      "width": "7%",
      "align": "center",
      "rowspan": "2"
    }
  }, [_vm._v("账期(天)")]), _c('td', {
    attrs: {
      "align": "center",
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.quoteData.payment_period))]), _c('td', {
    staticClass: "tit",
    attrs: {
      "width": "7%",
      "align": "center"
    }
  }, [_vm._v("官网价格")]), _vm.quoteData.product_list ? _c('td', {
    attrs: {
      "align": "center"
    }
  }, [_vm.quoteData.product_list.length > 1 ? _c('span', [_vm._v(" 系列产品 ")]) : _c('span', [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(_vm.quoteData.product_list[0].official_price.$numberDecimal)) + " ")])]) : _vm._e(), _c('td', {
    staticClass: "tit",
    attrs: {
      "width": "7%",
      "align": "center",
      "rowspan": "2"
    }
  }, [_vm._v("租期(年)")]), _c('td', {
    attrs: {
      "align": "center",
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.quoteData.lease_term))]), _c('td', {
    staticClass: "tit",
    attrs: {
      "width": "7%",
      "align": "center",
      "rowspan": "2"
    }
  }, [_vm._v("结束时间")]), _vm.quoteData.lease_start ? _c('td', {
    attrs: {
      "align": "center",
      "rowspan": "2"
    }
  }, [_vm._v(" " + _vm._s(new Date(new Date(_vm.quoteData.lease_start).setFullYear(new Date(_vm.quoteData.lease_start).getFullYear() + _vm.quoteData.lease_term)).toISOString().substr(0, 10)) + " ")]) : _vm._e(), _c('td', {
    staticClass: "tit",
    attrs: {
      "width": "7%",
      "align": "center",
      "rowspan": "2"
    }
  }, [_vm._v("报价日期")]), _c('td', {
    attrs: {
      "align": "center",
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.quoteData.createTime)))])]), _c('tr', [_c('td', {
    staticClass: "tit",
    attrs: {
      "align": "center"
    }
  }, [_vm._v("采购价格")]), _vm.quoteData.product_list ? _c('td', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.quoteData.product_list.length > 1 ? "系列产品" : '无') + " ")]) : _vm._e()]), _c('tr', [_c('td', {
    staticClass: "tit twiceSize",
    attrs: {
      "width": "7%",
      "align": "center"
    }
  }, [_vm._v("每月付款日")]), _c('td', {
    staticClass: "twiceSize",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(_vm._s(_vm.quoteData.payment_day))]), _c('td', {
    staticClass: "tit twiceSize",
    attrs: {
      "width": "7%",
      "align": "center"
    }
  }, [_vm._v("产品总数")]), _c('td', {
    staticClass: "twiceSize",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(_vm._s(_vm.quoteData.quantity_total))]), _c('td', {
    staticClass: "tit twiceSize",
    attrs: {
      "width": "7%",
      "align": "center"
    }
  }, [_vm._v("支付周期")]), _c('td', {
    staticClass: "twiceSize",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(_vm._s(_vm.quoteData.payment_cycle))]), _c('td', {
    staticClass: "tit twiceSize",
    attrs: {
      "width": "7%",
      "align": "center"
    }
  }, [_vm._v("总期数")]), _c('td', {
    staticClass: "twiceSize",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(_vm._s(_vm.quoteData.NPER))]), _c('td', {
    staticClass: "tit twiceSize",
    attrs: {
      "width": "7%",
      "align": "center"
    }
  }, [_vm._v("核价过期")]), _c('td', {
    staticClass: "twiceSize",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.quoteData.expiry_date)))])])])])])], 1)]), _c('v-expansion-panel', [_c('div', {
    ref: "proPanel"
  }, [_c('v-expansion-panel-header', [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("产品列表")])]), _c('v-expansion-panel-content', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "headers": _vm.quoteHeaders,
      "items": _vm.quoteData.product_list,
      "item-key": "name",
      "hide-default-footer": "",
      "items-per-page": 36
    },
    scopedSlots: _vm._u([{
      key: "body.append",
      fn: function fn() {
        return [_c('tr', {
          staticClass: "primary--text",
          staticStyle: {
            "width": "100%"
          }
        }, [_c('td', {
          staticClass: "body-1"
        }, [_vm._v("合计")]), _c('td'), _c('td'), _c('td'), _c('td'), _c('td'), _c('td'), _c('td', {
          staticClass: "body-1"
        }, [_vm._v(_vm._s(_vm.quoteData.quantity_total))]), _vm.quoteData.rent_price_sum_total ? _c('td', {
          staticClass: "body-1"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(Number(_vm.quoteData.rent_price_sum_total.$numberDecimal).toFixed(2))))]) : _vm._e(), _vm.quoteData.rent_price_total_total ? _c('td', {
          staticClass: "body-1"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(Number(_vm.quoteData.rent_price_total_total.$numberDecimal).toFixed(2))))]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "item.order",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" " + _vm._s(item.order) + " ")])];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.quantity",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" " + _vm._s(item.quantity) + " ")])];
      }
    }, {
      key: "item.official_price",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(item.official_price.$numberDecimal).toFixed(2))) + " ")])];
      }
    }, {
      key: "item.isWarranty",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" " + _vm._s(item.isWarranty ? '是' : '否') + " ")])];
      }
    }, {
      key: "item.product_price_unit",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [!item.isWarranty ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(item.product_price_unit.$numberDecimal).toFixed(2))) + " ")]) : _c('td', [_vm._v(" - ")])];
      }
    }, {
      key: "item.warranty_price_unit",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(item.warranty_price_unit.$numberDecimal).toFixed(2))) + " ")])];
      }
    }, {
      key: "item.rent_price_unit",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.isWarranty ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(item.rent_price_unit.$numberDecimal).toFixed(2))) + " ")]) : _c('td', [_vm._v(" - ")])];
      }
    }, {
      key: "item.rent_price_sum",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(item.rent_price_sum.$numberDecimal).toFixed(2))) + " ")])];
      }
    }, {
      key: "item.rent_price_total",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(item.rent_price_total.$numberDecimal).toFixed(2))) + " ")])];
      }
    }])
  })], 1)], 1)]), _c('v-expansion-panel', [_c('div', {
    ref: "commentsPanel"
  }, [_c('v-expansion-panel-header', [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("备注")])]), _c('v-expansion-panel-content', [_c('v-alert', {
    attrs: {
      "border": "top",
      "type": "info",
      "outlined": ""
    }
  }, [_c('pre', {
    domProps: {
      "innerHTML": _vm._s(_vm.quoteData.comments)
    }
  })])], 1)], 1)]), _c('div', {
    style: "height:".concat(_vm.wm_h, "px")
  }), _c('v-expansion-panel', [_c('div', {
    ref: "payPanel"
  }, [_vm.isPrinting ? _c('div', {
    staticClass: "mb-12"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/logo2x.png",
      "width": "40"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('p', {
    staticClass: "display-1"
  }, [_vm._v(" " + _vm._s(_vm.quoteData.enterprise + '租赁报价') + " ")])])], 1)], 1) : _vm._e(), _c('v-expansion-panel-header', [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("付款计划表")])]), _c('v-expansion-panel-content', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "headers": _vm.payheaders,
      "items": _vm.quoteData.payment_list,
      "item-key": "name",
      "hide-default-footer": "",
      "items-per-page": 36,
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.startDate",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.startDate)))])];
      }
    }, {
      key: "item.endDate",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.endDate)))])];
      }
    }, {
      key: "item.paymentDate",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.paymentDate)))])];
      }
    }, {
      key: "item.paymentAmount",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(item.paymentAmount.$numberDecimal).toFixed(2))) + " ")])];
      }
    }])
  })], 1)], 1)]), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("声明")])]), _c('v-expansion-panel-content', [_c('v-alert', {
    attrs: {
      "border": "top",
      "type": "info",
      "outlined": ""
    }
  }, [_c('pre', {
    domProps: {
      "innerHTML": _vm._s(_vm.quoteData.statement)
    }
  })])], 1)], 1)], 1)], 1)]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("关闭")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.downQuote
    }
  }, [_vm._v("下载")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }