const REPAIR_CATALOG_TEXT = '设备维修'
const IN_BROWSER = typeof window !== 'undefined';
const DOMAIN_NAME = document.domain;
const ENV = process.env.NODE_ENV;
const IN_PWA = window.matchMedia('(display-mode: standalone)').matches;

export default {
  REPAIR_CATALOG_TEXT,
  IN_BROWSER,
  DOMAIN_NAME,
  ENV,
  IN_PWA
};

