import { PageService } from "@/common/http";
import {
  FETCH_PAGE,
  EDIT_PAGE,
  EDIT_BODY_ELEMENT,
  DELETE_BODY_ELEMENT,
} from "./actions.type";
import {
  SET_ERROR,
  SET_PAGE,
} from "./mutations.type"

const state = {
  page: {},
};

const getters = {
  page: state => state.page,
};

const actions = {
  [FETCH_PAGE]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      PageService.get('', slug)
        .then(({ data }) => {
          commit(SET_PAGE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [EDIT_PAGE](context, page) {
    return PageService.update(page._id, page.config);
  },
  [EDIT_BODY_ELEMENT](context, element) {
    return PageService.update(element._id+'/'+ element.index, element.config)
  },
  [DELETE_BODY_ELEMENT](context, element) {
    return PageService.destroy(element._id+'/'+ element.index)
  }
};

const mutations = {
  [SET_PAGE](state, page) {
    state.page = page;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
