var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    attrs: {
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('open_case');
      }
    }
  }, [_vm._t("default")], 2), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('span', [_vm._v(_vm._s(_vm.orderData.order_number))]), _vm.orderData.status == '0' ? _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "secondary"
    }
  }, [_vm._v("授信审批中")]) : _vm._e(), _vm.orderData.status == '1' ? _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "primary"
    }
  }, [_vm._v("处理中")]) : _vm._e(), _vm.orderData.status == '2' ? _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("已取消")]) : _vm._e(), _vm.orderData.status == '3' ? _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("已拒绝")]) : _vm._e(), _vm.orderData.status == '4' ? _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("已完成")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "accordion": "",
      "flat": ""
    },
    model: {
      value: _vm.offerPanels,
      callback: function callback($$v) {
        _vm.offerPanels = $$v;
      },
      expression: "offerPanels"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_vm._v("基本信息")])]), _c('v-expansion-panel-content', [_c('case-order-basic-info', {
    ref: "orderBasic",
    attrs: {
      "orderData": _vm.orderData,
      "orderId": _vm.getId,
      "isEdit": false
    },
    on: {
      "Update": function Update($event) {
        return _vm.getOrder(_vm.getId);
      }
    }
  })], 1)], 1), _vm.orderData.type === 1 ? _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_c('span', [_vm._v("租赁信息")])]), _c('v-expansion-panel-content', [_c('case-order-rent-info', {
    attrs: {
      "orderData": _vm.orderData,
      "orderId": _vm.getId,
      "isEdit": false
    },
    on: {
      "Update": function Update($event) {
        return _vm.getOrder(_vm.getId);
      }
    }
  })], 1)], 1) : _vm._e(), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_c('span', [_vm._v("详细信息")])]), _c('v-expansion-panel-content', [_c('case-order-detail', {
    attrs: {
      "orderData": _vm.orderData,
      "orderId": _vm.getId,
      "isEdit": false
    },
    on: {
      "Update": function Update($event) {
        return _vm.getOrder(_vm.getId);
      }
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_c('span', [_vm._v("相关工单")])]), _c('v-expansion-panel-content', [_c('case-order-cases', {
    attrs: {
      "orderData": _vm.orderData,
      "purchaseList": _vm.purchaseList,
      "activeCaseTab": _vm.activeCaseTab,
      "isEdit": false
    },
    on: {
      "setNoData": _vm.getToSetNoData
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-subheader', [_vm._v("最后更新时间： " + _vm._s(_vm._f("formatTime")(_vm.orderData.updateTime)))]), _c('v-spacer'), _vm.orderData.isMark ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.markOrder(_vm.orderData._id, false);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("star")]), _vm._v("取消关注")], 1) : _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.markOrder(_vm.orderData._id, true);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("star_outline")]), _vm._v("特别关注")], 1), _c('v-btn', {
    attrs: {
      "text": "",
      "disabled": ""
    },
    on: {
      "click": _vm.returnNotice
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("error")]), _vm._v("退货说明")], 1), _vm.orderData.type === 1 ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.leaseOrder
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("autorenew")]), _vm._v("续租订单")], 1) : _vm._e(), _vm.isEdit && _vm.orderData.status < 2 ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.confirmCancelOrder();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("remove_circle")]), _vm._v("取消订单")], 1) : _vm._e(), _vm.isEdit && _vm.orderData.status < 2 ? _c('v-btn', {
    attrs: {
      "color": "secondary darken-1",
      "text": "",
      "disabled": ""
    },
    on: {
      "click": _vm.createCase
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add_circle")]), _vm._v("创建工单")], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "width": "800",
      "persistent": ""
    },
    model: {
      value: _vm.createCaseDlg,
      callback: function callback($$v) {
        _vm.createCaseDlg = $$v;
      },
      expression: "createCaseDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._v("创建工单")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEditCase
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "选择服务流程",
      "autocomplete": "off",
      "items": _vm.processList.filter(function (item) {
        return item.isEnable;
      }),
      "rules": [_vm.rules.required],
      "hide-no-data": "",
      "item-text": "name",
      "item-value": "_id",
      "return-object": "",
      "outlined": "",
      "dense": "",
      "prepend-inner-icon": "low_priority"
    },
    on: {
      "change": _vm.setActionAccount
    },
    model: {
      value: _vm.caseProcess,
      callback: function callback($$v) {
        _vm.caseProcess = $$v;
      },
      expression: "caseProcess"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "选择服务受理人",
      "items": _vm.caseAccountList,
      "dense": "",
      "rules": [_vm.rules.selected],
      "item-text": "personal.name",
      "item-value": "_id",
      "outlined": "",
      "hide-no-data": "",
      "hide-details": "",
      "prepend-inner-icon": "person_outline"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.caseAccount,
      callback: function callback($$v) {
        _vm.caseAccount = $$v;
      },
      expression: "caseAccount"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "选择外采受理人",
      "items": _vm.casePurchaserList,
      "dense": "",
      "item-text": "personal.name",
      "item-value": "_id",
      "outlined": "",
      "hide-no-data": "",
      "hide-details": "",
      "clearable": "",
      "prepend-inner-icon": "shopping_cart"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.casePurchaser,
      callback: function callback($$v) {
        _vm.casePurchaser = $$v;
      },
      expression: "casePurchaser"
    }
  })], 1)], 1), _vm.casePurchase && _vm.noPurchaser ? _c('v-alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("以下品牌厂商没有配置外采负责人，请联系管理员进行配置。" + _vm._s(_vm.noPurchaserList))]) : _vm._e(), _c('v-data-table', {
    attrs: {
      "headers": _vm.caseProHeaders,
      "items": _vm.caseProList,
      "hide-default-footer": "",
      "items-per-page": 100,
      "item-class": _vm.itemRowBackground,
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.product_name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('td', {
          staticClass: "text-truncate",
          staticStyle: {
            "max-width": "200px"
          },
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.product_name))])];
      }
    }, {
      key: "item.part_number",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          staticClass: "text-truncate",
          staticStyle: {
            "max-width": "200px"
          },
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.part_number))])];
      }
    }, {
      key: "item.quantity",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.quantity,
            "large": "",
            "persistent": "",
            "save-text": "保存",
            "cancel-text": "取消"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "quantity", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "quantity", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('div', {
                staticClass: "mt-4 subtitle-1"
              }, [_vm._v("修改：开单数量")]), _c('v-text-field', {
                attrs: {
                  "label": "开单数量",
                  "single-line": "",
                  "type": "number",
                  "rules": [_vm.rules.required, _vm.rules.negative]
                },
                model: {
                  value: props.item.quantity,
                  callback: function callback($$v) {
                    _vm.$set(props.item, "quantity", $$v);
                  },
                  expression: "props.item.quantity"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(" " + _vm._s(props.item.quantity) + " "), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("edit")])], 1)])];
      }
    }, {
      key: "item.remove",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.removeCasePro(item);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("remove")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("移除")])])];
      }
    }])
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEditCase
    }
  }, [_vm._v("关闭")]), _c('v-spacer'), this.caseProList.length !== this.defaultCaseProList.length ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.resetCaseProList
    }
  }, [_vm._v("重置产品")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.caseProcess || !_vm.caseAccount || !_vm.caseProList.length || _vm.casePurchase && _vm.noPurchaser,
      "loading": _vm.exeing
    },
    on: {
      "click": _vm.submitCase
    }
  }, [_vm._v("提交")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "800px",
      "persistent": ""
    },
    model: {
      value: _vm.dlgCancelOrder,
      callback: function callback($$v) {
        _vm.dlgCancelOrder = $$v;
      },
      expression: "dlgCancelOrder"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._v("取消订单")]), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("remove_circle")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-6"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.cancelHeaders,
      "items": _vm.cancelList,
      "hide-default-footer": "",
      "items-per-page": 100
    },
    scopedSlots: _vm._u([{
      key: "item.quantity",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.quantity,
            "large": "",
            "persistent": "",
            "save-text": "保存",
            "cancel-text": "取消"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "quantity", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "quantity", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('div', {
                staticClass: "mt-4 subtitle-1"
              }, [_vm._v("修改：取消数量")]), _c('v-text-field', {
                attrs: {
                  "label": "取消数量",
                  "single-line": "",
                  "type": "number",
                  "min": "0",
                  "max": parseInt(props.item.count.cancel),
                  "rules": [_vm.rules.required, _vm.rules.negative]
                },
                model: {
                  value: props.item.count.cancel,
                  callback: function callback($$v) {
                    _vm.$set(props.item.count, "cancel", $$v);
                  },
                  expression: "props.item.count.cancel"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(" " + _vm._s(props.item.count.cancel) + " "), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("edit")])], 1)])];
      }
    }, {
      key: "item.remove",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.removeCancelPro(item);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("remove")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("移除")])])];
      }
    }])
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEditCancel
    }
  }, [_vm._v("关闭")]), _c('v-spacer'), this.cancelList.length !== this.cancelDefault.length ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.resetCancelList
    }
  }, [_vm._v("重置产品")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary darken-1",
      "disabled": !_vm.cancelList.length,
      "loading": _vm.exeing
    },
    on: {
      "click": _vm.cancelOrder
    }
  }, [_vm._v("确定")])], 1)], 1)], 1), _c('v-overlay', {
    attrs: {
      "value": _vm.overlay,
      "z-index": "9999"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }