<template>
  <v-card>
    <v-card-title>
        查看素材
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('Close')"><v-icon>close</v-icon></v-btn>
    </v-card-title>

    <v-card-text class="pa-0">
      <v-img
        height="375px"
        gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.2)"
        :src="material.profile_image ? ossURL +'/'+ material.profile_image : '/static/error/empty_state.svg'">
        <v-row align="end" class="white--text pa-4 fill-height">
          <v-col>
            <div class="subheading">{{ material.publish_date | formatDate }} {{ material.author }}</div>
            <div class="text-h4">{{ material.title }}</div>
          </v-col>
        </v-row>
      </v-img>
      <div class="ma-12" v-html="material.context"></div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if="currentUser.employer.ownerId._id === material.ownerId && (currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('CMS_Material'))">
      <v-btn color="primary" text @click="deleteItem(material)">删除素材</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="$emit('Close')">关闭</v-btn>
      <v-btn color="secondary" text @click="$router.push({path:'/dealer/material/edit/'+material._id})">编辑</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import Util from '@/common/util';
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_MATERIAL,
  DELETE_MATERIAL
} from "@/store/actions.type";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'material']),
    materialType () {
      return Util.categories('materialType')
    },
  },
  mounted() {
    this.getInfoData(this.id)
  },
  methods: {
    getInfoData(id) {
      this.$store.dispatch(FETCH_MATERIAL, id)
    },
    getTypeText(code) {
      return this.materialType.find(item => item.code === code)
    },
    deleteItem(item) {
      if (confirm('确定要删除 '+ this.material.title +'？删除后该素材将不能被使用。')) {
        if (confirm('已经引用该素材的内容将不受影响。如首页、知识库、咨询等内容。')) {
          this.$store.dispatch(DELETE_MATERIAL, item)
            .then(() => {
              this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'})
              this.$emit('Delete')
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err})
            });
        }
      }
    },
  },
}
</script>
