var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', [_c('v-tab', [_vm._v("产品")]), _c('v-tab', [_vm._v("附件")]), _c('v-tab', [_vm._v("备注")]), _c('v-tab-item', [_c('v-card-text', {
    staticClass: "px-0"
  }, [_c('v-data-table', {
    staticClass: "elevation-0 my-4",
    attrs: {
      "headers": _vm.productHeaders,
      "items": _vm.linkedProduct,
      "items-per-page": 100,
      "hide-default-footer": "",
      "item-key": "index",
      "dense": ""
    },
    scopedSlots: _vm._u([_vm.orderData.product_list && _vm.orderData.product_list.length ? {
      key: "body.append",
      fn: function fn() {
        return [_c('tr', {
          staticClass: "secondary--text"
        }, [_c('td', [_vm._v("合计")]), _c('td'), _c('td'), _vm.orderData.type === 1 ? _c('td') : _vm._e(), _c('td', [_c('span', [_vm._v(_vm._s(_vm.orderData.total_quantity))])]), _c('td'), _c('td'), _c('td'), _c('td', [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(Number(_vm.orderData.total_price.$numberDecimal).toFixed(2))))]), _c('td'), _c('td'), _vm.orderData.status === 0 ? _c('td') : _vm._e()])];
      },
      proxy: true
    } : null, {
      key: "item.product_name",
      fn: function fn(_ref) {
        var item = _ref.item,
            index = _ref.index;
        return [_c('td', {
          class: !_vm.open[index] ? 'text-truncate' : '',
          staticStyle: {
            "max-width": "200px",
            "cursor": "pointer"
          },
          attrs: {
            "nowrap": _vm.open[index] ? false : true
          },
          on: {
            "click": function click($event) {
              return _vm.showText(index);
            }
          }
        }, [_vm._v(" " + _vm._s(item.product_name) + " ")])];
      }
    }, {
      key: "item.product_text",
      fn: function fn(_ref2) {
        var item = _ref2.item,
            index = _ref2.index;
        return [item.product_text ? _c('td', {
          class: !_vm.open[index] ? 'text-truncate text-decoration-underline' : 'text-decoration-underline',
          staticStyle: {
            "max-width": "150px",
            "cursor": "pointer"
          },
          attrs: {
            "nowrap": _vm.open[index] ? false : true
          },
          on: {
            "click": function click($event) {
              return _vm.showText(index);
            }
          }
        }, [_vm._v(" " + _vm._s(item.product_text) + " ")]) : _c('td', [_vm._v("无")])];
      }
    }, {
      key: "item.quantity",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.count ? _c('span', [_vm._v(_vm._s(item.count.order))]) : _vm._e()];
      }
    }, {
      key: "item.quantityInCase",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.count ? _c('span', [_vm._v(_vm._s(item.count.cases))]) : _vm._e()];
      }
    }, {
      key: "item.quantityCancel",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.count ? _c('span', [_vm._v(_vm._s(item.count.cancel))]) : _vm._e()];
      }
    }, {
      key: "item.price",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm.orderData.type === 0 ? _c('span', [_vm._v("¥" + _vm._s(Number(item.sales_price)))]) : _c('span', [_vm._v("¥" + _vm._s(Number(item.rent_price)))])];
      }
    }, _vm.orderData.type === 1 ? {
      key: "item.total_period",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.orderData.rent_info.total_period))])];
      },
      proxy: true
    } : null, {
      key: "item.sum_price",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v("¥" + _vm._s(Number(item.sum_price)))];
      }
    }, {
      key: "item.part_number",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm.isEdit && _vm.orderData.status <= 1 && item.count.cases === 0 ? _c('td', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.editProInfo(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("edit")]), _vm._v(" " + _vm._s(item.part_number || "无") + " ")], 1) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.part_number || "无"))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_vm.isEdit && _vm.orderData.status === 0 ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref10) {
              var on = _ref10.on,
                  attrs = _ref10.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.removeLinked(item);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("remove")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("移除")])]) : _vm._e()];
      }
    }], null, true)
  }), _vm.isEdit && _vm.orderData.status === 0 ? _c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        _vm.newProDlg = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("link")]), _vm._v("关联产品")], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-tab-item', [_c('widgets-files-list', {
    attrs: {
      "entityType": "PurchaseOrder",
      "entityId": _vm.orderId,
      "type": 1
    }
  })], 1), _c('v-tab-item', [_c('widgets-remarks-list', {
    attrs: {
      "entityType": "PurchaseOrder",
      "entityId": _vm.orderId,
      "type": 0
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.newProDlg,
      callback: function callback($$v) {
        _vm.newProDlg = $$v;
      },
      expression: "newProDlg"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveEditPro.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validNewPro,
      callback: function callback($$v) {
        _vm.validNewPro = $$v;
      },
      expression: "validNewPro"
    }
  }, [_c('v-card', {
    staticStyle: {
      "height": "500px"
    }
  }, [_c('v-card-title', [_c('span', [_vm._v("增加产品")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEditPro
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    ref: "myBox",
    attrs: {
      "items": _vm.partNoBox,
      "search-input": _vm.checkItem,
      "type": "text",
      "return-object": "",
      "item-text": "part_number",
      "item-value": "part_number",
      "label": "产品型号",
      "error-messages": _vm.errPartNo,
      "hint": "请输入产品型号 如：MGXC2CH/A",
      "outlined": "",
      "loading": _vm.isCheckProLoading,
      "dense": "",
      "rules": [_vm.rules.required]
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.checkItem = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.checkItem = $event;
      },
      "change": _vm.changeProInfo
    },
    model: {
      value: _vm.newProInfo.part_number,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "part_number", $$v);
      },
      expression: "newProInfo.part_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "产品名称",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.newProInfo.product_name,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "product_name", $$v);
      },
      expression: "newProInfo.product_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "总数量",
      "rules": [_vm.rules.required],
      "type": "number",
      "outlined": "",
      "dense": "",
      "disabled": _vm.disableNum
    },
    model: {
      value: _vm.newProInfo.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "quantity", $$v);
      },
      expression: "newProInfo.quantity"
    }
  })], 1), _vm.orderData.type === 0 ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "销售价格",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "disabled": _vm.disablePrice,
      "prefix": "¥"
    },
    model: {
      value: _vm.newProInfo.sales_price,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "sales_price", $$v);
      },
      expression: "newProInfo.sales_price"
    }
  })], 1) : _vm._e(), _vm.orderData.type === 1 ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "租赁价格",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "disabled": _vm.disablePrice,
      "prefix": "¥"
    },
    model: {
      value: _vm.newProInfo.rent_price,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "rent_price", $$v);
      },
      expression: "newProInfo.rent_price"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.vendorList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "产品品牌",
      "hint": "请选择产品品牌"
    },
    model: {
      value: _vm.newProInfo.product_vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "product_vendor", $$v);
      },
      expression: "newProInfo.product_vendor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.typeList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "产品类型",
      "hint": "请选择产品类型"
    },
    model: {
      value: _vm.newProInfo.product_type,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "product_type", $$v);
      },
      expression: "newProInfo.product_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "产品描述",
      "no-resize": "",
      "rows": "3",
      "outlined": ""
    },
    model: {
      value: _vm.newProInfo.product_text,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "product_text", $$v);
      },
      expression: "newProInfo.product_text"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.guaranteedType,
      "rules": [_vm.rules.selected],
      "label": "延保类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": ""
    },
    model: {
      value: _vm.newProInfo.guaranteed_type,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "guaranteed_type", $$v);
      },
      expression: "newProInfo.guaranteed_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "suffix": "月",
      "type": "number",
      "label": "延保期限",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.newProInfo.guaranteed_limit,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "guaranteed_limit", _vm._n($$v));
      },
      expression: "newProInfo.guaranteed_limit"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": [{
        name: '是',
        code: true
      }, {
        name: '否',
        code: false
      }],
      "label": "是否ABM",
      "hint": "请选择设备是否ABM",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    model: {
      value: _vm.newProInfo.isABM,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "isABM", $$v);
      },
      expression: "newProInfo.isABM"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.preSoftware,
      "label": "预装软件",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": ""
    },
    model: {
      value: _vm.newProInfo.preinstall_software,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "preinstall_software", $$v);
      },
      expression: "newProInfo.preinstall_software"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.proService,
      "label": "包含服务",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": ""
    },
    model: {
      value: _vm.newProInfo.provide_service,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "provide_service", $$v);
      },
      expression: "newProInfo.provide_service"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEditPro
    }
  }, [_vm._v(" 取消 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.validNewPro
    },
    on: {
      "click": _vm.saveEditPro
    }
  }, [_vm._v(" 提交 ")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "700"
    },
    model: {
      value: _vm.proInfoDlg,
      callback: function callback($$v) {
        _vm.proInfoDlg = $$v;
      },
      expression: "proInfoDlg"
    }
  }, [_c('widgets-product-dialogs', {
    key: _vm.activeProId,
    attrs: {
      "id": _vm.activeProId
    },
    on: {
      "Close": function Close($event) {
        _vm.proInfoDlg = false;
      }
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500px",
      "persistent": ""
    },
    model: {
      value: _vm.dlgConflict,
      callback: function callback($$v) {
        _vm.dlgConflict = $$v;
      },
      expression: "dlgConflict"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v("产品型号" + _vm._s(_vm.editedItem.part_number) + "已经存在，请重新填写产品型号或关联现有型号产品。")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": _vm.clearPartNo
    }
  }, [_vm._v("重新填写型号")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": _vm.linkPresent
    }
  }, [_vm._v("关联现有产品")]), _c('v-spacer')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }