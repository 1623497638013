var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    attrs: {
      "color": _vm.color,
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openItem(_vm.getId);
      }
    }
  }, [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition",
      "retain-focus": false,
      "eager": ""
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.itemData && _vm.itemData.enterprise ? _c('v-card', {
    attrs: {
      "loading": _vm.monthlyLoading ? 'secondary' : false
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.itemData.enterprise.name) + "【" + _vm._s(_vm.itemData.month ? _vm.itemData.year : _vm.itemData.year - 1) + "年" + _vm._s(_vm.itemData.month ? _vm.itemData.month : 12) + "月】统计报告 "), _vm.itemData.isRelease ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "primary"
    }
  }, [_vm._v("已发布")]) : _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "secondary"
    }
  }, [_vm._v("未发布")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "accordion": "",
      "flat": "",
      "hover": ""
    },
    model: {
      value: _vm.openPanel,
      callback: function callback($$v) {
        _vm.openPanel = $$v;
      },
      expression: "openPanel"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("统计图表")]), _c('v-expansion-panel-content', [_vm._l(_vm.itemData.data_item, function (report, index) {
    return [report.graph === 'bar' ? _c('report-bar-simple', {
      key: index,
      attrs: {
        "width": _vm.windowSize.x - 75 + 'px',
        "height": "300px",
        "label": report.label,
        "data": report.data,
        "title": report.name
      }
    }) : _vm._e(), report.graph === 'line' ? _c('report-line-stack', {
      key: index,
      attrs: {
        "width": _vm.windowSize.x - 75 + 'px',
        "title": report.name,
        "label": report.label,
        "data": JSON.parse(report.data),
        "height": "300px"
      }
    }) : _vm._e(), report.graph === 'barpolar' ? _c('report-bar-polar', {
      key: index,
      attrs: {
        "width": _vm.windowSize.x - 75 + 'px',
        "height": "600px",
        "label": report.label,
        "data": JSON.parse(report.data),
        "title": report.name
      }
    }, [_vm._v(" > ")]) : _vm._e(), report.graph === 'stack' ? _c('report-bar-stack', {
      key: index,
      attrs: {
        "width": _vm.windowSize.x - 75 + 'px',
        "height": "300px",
        "label": report.label,
        "data": JSON.parse(report.data),
        "title": report.name,
        "legend": report.legend
      }
    }) : _vm._e(), _c('v-textarea', {
      key: index + '_analyse',
      staticClass: "mx-12",
      attrs: {
        "outlined": "",
        "label": "图表分析",
        "rows": "3"
      },
      model: {
        value: report.analyse,
        callback: function callback($$v) {
          _vm.$set(report, "analyse", $$v);
        },
        expression: "report.analyse"
      }
    }), _c('v-textarea', {
      key: index + '_propose',
      staticClass: "mx-12",
      attrs: {
        "outlined": "",
        "label": "建议计划",
        "rows": "3"
      },
      model: {
        value: report.propose,
        callback: function callback($$v) {
          _vm.$set(report, "propose", $$v);
        },
        expression: "report.propose"
      }
    }), _c('v-divider', {
      key: index + '_hr',
      staticClass: "my-4"
    })];
  })], 2)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_vm._v("附加内容")]), _c('v-expansion-panel-content', [_vm.activeEdit ? _c('editor', {
    attrs: {
      "init": _vm.editorInit,
      "id": 'report_' + _vm.getId
    },
    model: {
      value: _vm.editedItem.propose,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "propose", $$v);
      },
      expression: "editedItem.propose"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.saveReport
    }
  }, [_vm._v(_vm._s(_vm.itemData.isRelease ? '修改' : '保存'))]), !_vm.itemData.isRelease ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.publishReport
    }
  }, [_vm._v("发布")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("关闭")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }