import { RiskService } from "@/common/http";

import {
  FETCH_RISK_LOG_LIST,
} from "./actions.type";
import {
  SET_ERROR,
  LOAD_RISK_LOG_LIST,
  SET_RISK_LOG_LIST,
} from "./mutations.type"

const state = {
  riskLogLoading: true,
  riskLogList: [],
  riskLogCount: 0,
};
const getters = {
  riskLogLoading: state => state.riskLogLoading,
  riskLogList: state => state.riskLogList,
  riskLogCount: state => state.riskLogCount,
};
const actions = {
  [FETCH_RISK_LOG_LIST]({ commit }, slug) {
    commit(LOAD_RISK_LOG_LIST);
    return new Promise((resolve, reject) => {
      RiskService.get('/log/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RISK_LOG_LIST, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
};
const mutations = {
  [LOAD_RISK_LOG_LIST] (state) {
    state.riskLogLoading = true;
  },
  [SET_RISK_LOG_LIST](state, list) {
    state.riskLogList = list.docs;
    state.riskLogCount = list.query.count;
    state.riskLogLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};


