<template>
  <v-tabs :value="activeCaseTab">
    <v-tab>授信</v-tab>
    <v-tab>外采</v-tab>
    <v-tab>服务</v-tab>
    <v-tab-item>
      <v-card-text class="px-0">
        <v-data-table
          :headers="riskHeaders"
          :items="riskListData"
          :items-per-page="50"
          class="elevation-0 my-4"
          hide-default-footer
          dense
        >
          <template v-slot:item.prelimit="{ item }">
            {{item.prelimit | formatMoney}}
          </template>
          <template v-slot:item.actlimit="{ item }">
            {{item.actlimit | formatMoney}}
          </template>
          <template v-slot:item.createtime="{ item }">
            {{ item.createtime | formatTime }}
          </template>
          <template v-slot:item.status="{ item }">
            <td nowrap="true">
              <v-chip label small v-if="item.status === 'PENDING'">审批中</v-chip>
              <v-chip label small v-else-if="item.status === 'APPROVED'" color="primary">通过</v-chip>
              <v-chip label small v-else-if="item.status === 'REJECTED'">拒绝</v-chip>
              <v-chip label small v-else-if="item.status === 'CANCELED'">撤回</v-chip>
              <v-chip label small v-else>删除</v-chip>
            </td>
          </template>
<!--          <template v-slot:item.current_task_id="{ item }">-->
<!--            <span>{{ item.status === 0 ? "订单处理" : item.current_task_id ? item.current_task_id.name : "无" || "无" }}</span>-->
<!--          </template>-->
<!--          <template v-slot:item.ordered_quantity="{ item }">-->
<!--            {{ item.product_count ? item.product_count : "0" }}-->
<!--          </template>-->
<!--          <template v-slot:item.link_asset="{ item }">-->
<!--            {{ item.link_asset.length }}-->
<!--          </template>-->
<!--          <template v-slot:item.status="{ item }">-->
<!--            <td nowrap="true">-->
<!--              <v-chip label small v-if="item.status === 0">待受理</v-chip>-->
<!--              <v-chip label small v-else-if="item.status === 1" color="primary">处理中</v-chip>-->
<!--              <v-chip label small v-else-if="item.status === 2">已取消</v-chip>-->
<!--              <v-chip label small v-else-if="item.status === 3">已取消</v-chip>-->
<!--              <v-chip label small v-else>已完成</v-chip>-->
<!--            </td>-->
<!--          </template>-->
<!--          <template v-slot:item.order_assigner="{ item }">-->
<!--            <td nowrap="true" v-if="item.order_assigner && item.order_assigner.personal">{{ item.order_assigner.personal.name }}</td>-->
<!--          </template>-->
<!--          <template v-slot:item.updateTime="{ item }">-->
<!--            {{ item.updateTime | formatTime }}-->
<!--          </template>-->
<!--          <template v-slot:item.createTime="{ item }">-->
<!--            {{ item.createTime | formatTime }}-->
<!--          </template>-->
<!--          <template v-slot:item.actions="{ item }">-->
<!--            <v-tooltip bottom>-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <v-btn-->
<!--                  v-bind="attrs"-->
<!--                  v-on="on"-->
<!--                  small-->
<!--                  icon-->
<!--                  @click.stop="exportAsset(item)"-->
<!--                >-->
<!--                  <v-icon small>save_alt</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <span>导出设备</span>-->
<!--            </v-tooltip>-->
<!--          </template>-->
          <template v-slot:no-data>
            {{ orderData.no_order_text ? orderData.no_order_text : "无工单数据" }}
            <v-btn
              v-if="isEdit"
              icon
              small
              @click="readyNoData"
            >
              <v-icon small>edit</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-tab-item>
    <v-tab-item>
      <v-card-text class="px-0">
        <v-data-table
          :headers="purchaseHeaders"
          :items="purchaseList"
          :items-per-page="50"
          class="elevation-0 my-4"
          hide-default-footer
          dense
        >
          <template v-slot:item.request_number="{ item }">
            <td nowrap="true" class="v-list-item--link" @click="goToPurchase(item.request_number)">{{ item.request_number }}</td>
          </template>
          <template v-slot:item.case_info.case_number="{ item }">
            <td nowrap="true" class="v-list-item--link" v-if="item.case_info && item.case_info.case_number" @click="goToCase(item.case_info.case_number)">{{ item.case_info.case_number }}</td>
          </template>
          <template v-slot:item.product_name_list="{ item }">
            <td nowrap="true">
              <div class="my-2" v-for="(pn,i) in item.product_name_list" :key="i">
                <v-chip label small style="width: 200px;">
                  <span class="text-truncate" style="max-width: 200px;">{{ pn }}</span>
                </v-chip>
              </div>
            </td>
          </template>
          <template v-slot:item.product_quantity_list="{ item }">
            <div class="my-2" v-for="(pn,i) in item.product_quantity_list" :key="i">
              <v-chip label small>
                <span style="min-width:40px;" class="text-center">{{ pn }}</span>
              </v-chip>
            </div>
          </template>
          <template v-slot:item.product_purchase_price_list="{ item }">
            <div class="my-2" v-for="(pn,i) in item.product_purchase_price_list" :key="i">
              <v-chip label small>
                <span style="min-width:75px;" class="text-right">{{ pn.$numberDecimal | formatMoney }}</span>
              </v-chip>
            </div>
          </template>
          <template v-slot:item.product_purchase_margin_list="{ item }">
            <div class="my-2" v-for="(pn,i) in item.product_purchase_margin_list" :key="i">
              <v-chip label small>
                <span style="min-width:75px;" class="text-right">{{ parseFloat(pn * 100).toFixed(2) }}%</span>
              </v-chip>
            </div>
          </template>
          <template v-slot:item.editor="{ item }">
            <td nowrap="true" v-if="item.editor && item.editor.personal">{{ item.editor.personal.name }}</td>
          </template>
          <template v-slot:item.updateTime="{ item }">
            <td nowrap="true">{{ item.updateTime | formatTime }}</td>
          </template>
          <template v-slot:item.status="{ item }">
            <td nowrap="true">
              <v-chip label small v-if="item.status === 0">待受理</v-chip>
              <v-chip label small v-else-if="item.status === 1" color="primary">处理中</v-chip>
              <v-chip label small v-else-if="item.status === 2" @click="alertExplain(item.explain)">已取消 <v-icon right small>sms</v-icon></v-chip>
              <v-chip label small v-else-if="item.status === 3">已取消</v-chip>
              <v-chip label small v-else>已完成</v-chip>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-tab-item>
    <v-tab-item>
      <v-card-text class="px-0">
        <v-data-table
          :headers="caseHeaders"
          :items="orderData.case_list"
          :items-per-page="50"
          class="elevation-0 my-4"
          hide-default-footer
          dense
        >
          <template v-slot:item.case_number="{ item }">
            <span class="v-list-item--link" @click="goToCase(item.case_number)">{{ item.case_number }}</span>
          </template>
          <template v-slot:item.current_task_id="{ item }">
            <span>{{ item.status === 0 ? "订单处理" : item.current_task_id ? item.current_task_id.name : "无" || "无" }}</span>
          </template>
          <template v-slot:item.ordered_quantity="{ item }">
            {{ item.product_count ? item.product_count : "0" }}
          </template>
          <template v-slot:item.link_asset="{ item }">
            {{ item.link_asset.length }}
          </template>
          <template v-slot:item.status="{ item }">
            <td nowrap="true">
              <v-chip label small v-if="item.status === 0">待受理</v-chip>
              <v-chip label small v-else-if="item.status === 1" color="primary">处理中</v-chip>
              <v-chip label small v-else-if="item.status === 2">已取消</v-chip>
              <v-chip label small v-else-if="item.status === 3">已取消</v-chip>
              <v-chip label small v-else>已完成</v-chip>
            </td>
          </template>
          <template v-slot:item.order_assigner="{ item }">
            <td nowrap="true" v-if="item.order_assigner && item.order_assigner.personal">{{ item.order_assigner.personal.name }}</td>
          </template>
          <template v-slot:item.updateTime="{ item }">
            {{ item.updateTime | formatTime }}
          </template>
          <template v-slot:item.createTime="{ item }">
            {{ item.createTime | formatTime }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  icon
                  @click.stop="exportAsset(item)"
                >
                  <v-icon small>save_alt</v-icon>
                </v-btn>
              </template>
              <span>导出设备</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            {{ orderData.no_order_text ? orderData.no_order_text : "无工单数据" }}
            <v-btn
              v-if="isEdit"
              icon
              small
              @click="readyNoData"
            >
              <v-icon small>edit</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import ApiService from "@/common/http";
import store from "@/store";
import { SET_ERROR } from "@/store/mutations.type";

export default {
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    purchaseList: {
      type: Array,
      default: function () {
        return []
      }
    },
    activeCaseTab: {
      type: Number,
      default: 0
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      riskHeaders: [
        { text: '客户名称', value: 'enterprise' },
        { text: '授信额度预估', value: 'prelimit' },
        { text: '授信额度审定', value: 'actlimit' },
        { text: '飞书审批单', value: 'fsnumber' },
        { text: '当前审批节点', value: 'tasknode' },
        { text: '创建时间', value: 'createtime' },
        { text: '状态', value: 'status' },
      ],
      riskListData: [
        { _id: '20240801001', enterprise: '非凡量子', prelimit: '15200000', actlimit: null, fsnumber: '202102060002', tasknode: '开始', createtime: '2024-08-05T05:11:12.617Z', status: 'PENDING' }
      ],
      caseHeaders: [
        { text: "服务工单", value: "case_number" },
        { text: "当前任务", value: "current_task_id" },
        { text: "开单设备数量", value: "product_count" },
        { text: "已出库数量", value: "link_asset" },
        { text: "商务处理人", value: "order_assigner" },
        { text: "更新时间", value: "updateTime" },
        { text: "创建时间", value: "createTime" },
        { text: "状态", value: "status" },
        { text: "操作", value: "actions", sortable: false },
      ],
      purchaseHeaders: [
        { text: "外采请求", value: "request_number" },
        { text: "服务工单", value: "case_info.case_number" },
        { text: '产品名称', value: 'product_name_list', sortable: false},
        { text: '产品数量', value: 'product_quantity_list', sortable: false},
        { text: '采购价格', value: 'product_purchase_price_list', sortable: false},
        { text: '利润率', value: 'product_purchase_margin_list', sortable: false},
        { text: '采购处理人', value: 'editor'},
        { text: '更新时间', value: 'updateTime'},
        { text: '状态', value: 'status', width: '100px'},
      ],
    }
  },
  methods: {
    showText(i) {
      // console.log('click =>'+ i)
      this.open[i]= !this.open[i]
      this.$forceUpdate()
    },
    readyNoData() {
      this.$emit('setNoData')
    },
    exportAsset(caseInfo) {
      ApiService.download('/asset/export?page=1&limit=-1&enterprise='+ this.orderData.enterprise._id +'&caseId='+ caseInfo._id, {
        responseType:'blob'
      })
        .then(resp => {
          let fileName = caseInfo.case_number +'_资产设备清单.xlsx';
          let contentDisposition = resp.headers['content-disposition'];
          if (contentDisposition) {
            fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
          }
          let blob = new Blob([resp.data], {type: 'application/octet-stream'});
          if (window.navigator.msSaveOrOpenBlob) { //支持IE
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement('a');
            link.style.display = "none";
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            //释放内存
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
        })
        .catch(err => { // 请求失败处理
          store.commit(SET_ERROR, {msg: err});
        });
    },
    goToCase(num) {
      let newWin = this.$router.resolve({
        path: "/dealer/workbench/service/list?key=" + num,
      });
      window.open(newWin.href, "_blank");
    },
    goToPurchase(num) {
      let newWin = this.$router.resolve({
        path: "/dealer/workbench/purchase/list?key=" + num,
      });
      window.open(newWin.href, "_blank");
    },
    alertExplain(explain) {
      if (!explain) explain = '无取消说明'
      alert(explain)
    }
  },
}
</script>
