var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.caseData ? _c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("服务流程")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.catalog.name))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("服务类型")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.caseData.out_req_type || '-') + " "), _vm.caseData.out_req_type && _vm.caseData.out_req_type.includes('三包') ? _c('span', [_vm._v(" - " + _vm._s(_vm.repairPolicy.find(function (p) {
    return p.code === _vm.caseData.return_policy;
  }).name) + " "), _vm.caseData.status < 4 && _vm.caseData.isEdit ? _c('v-icon', {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editCaseType();
      }
    }
  }, [_vm._v("create")]) : _vm._e()], 1) : _vm._e()]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("ESP工单编号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.case_number))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("维修单号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.out_req_id || '-'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("申请人")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('widgets-employee-dialogs', {
    staticClass: "v-list-item--link",
    attrs: {
      "account": _vm.caseData.creator._id
    }
  }, [_vm._v(_vm._s(_vm.caseData.creator.personal ? _vm.caseData.creator.personal.name : '*未设置姓名') + "-" + _vm._s(_vm.caseData.creator.phone)), _c('v-icon', {
    staticClass: "mx-2"
  }, [_vm._v("read_more")])], 1)], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("申请日期")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatTime")(_vm.caseData.createTime)))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("所属公司")]), _c('v-col', {
    staticClass: "text--secondary v-list-item--link",
    attrs: {
      "cols": "4"
    },
    on: {
      "click": function click($event) {
        return _vm.openEnterprise(_vm.caseData.enterprise._id);
      }
    }
  }, [_vm._v(_vm._s(_vm.caseData.enterprise.name)), _c('v-icon', {
    staticClass: "mx-2"
  }, [_vm._v("read_more")])], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("使用人")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('widgets-employee-dialogs', {
    staticClass: "v-list-item--link",
    attrs: {
      "account": _vm.caseData.account._id
    }
  }, [_vm._v(_vm._s(_vm.caseData.account.personal ? _vm.caseData.account.personal.name : '*未设置姓名')), _c('v-icon', {
    staticClass: "mx-2"
  }, [_vm._v("read_more")])], 1)], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("送修方式")]), _vm.caseData.recovery ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.recoveryType.find(function (item) {
    return item.code === _vm.caseData.recovery.channel;
  }).name))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("服务门店")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm.caseData.service_branch ? _c('widgets-branch-dialogs', {
    attrs: {
      "id": _vm.caseData.service_branch._id
    }
  }, [_vm._v(_vm._s(_vm.caseData.service_branch.name) + " "), _c('v-icon', {
    staticClass: "mx-2"
  }, [_vm._v("read_more")])], 1) : _vm._e(), _vm.caseData.status < 4 && _vm.caseData.isEdit ? _c('v-icon', {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editBranchInfo();
      }
    }
  }, [_vm._v("create")]) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("寄回方式")]), _vm.caseData.returns && _vm.caseData.returns.channel ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.returnType.find(function (item) {
    return item.code === _vm.caseData.returns.channel;
  }).name))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("寄回联系")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm.caseData.returns ? _c('div', [_vm._v(_vm._s(_vm.caseData.returns.express_address))]) : _vm._e(), _vm.caseData.returns ? _c('div', [_vm._v(_vm._s(_vm.caseData.returns.contact_name))]) : _vm._e(), _vm.caseData.returns ? _c('div', [_vm._v(_vm._s(_vm.caseData.returns.contact_phone))]) : _vm._e(), _vm.caseData.status < 4 && _vm.caseData.isEdit ? _c('v-icon', {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editReturnInfo();
      }
    }
  }, [_vm._v("create")]) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("序列号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.asset_sn ? _vm.caseData.asset_sn.join() : '-'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("故障描述")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.caseData.detail_fault)
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("验证邮箱")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(_vm._s(_vm.caseData.verifyMail ? _vm.protectEmail(_vm.caseData.verifyMail) : ''))]), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "700"
    },
    model: {
      value: _vm.dlgEnterprise,
      callback: function callback($$v) {
        _vm.dlgEnterprise = $$v;
      },
      expression: "dlgEnterprise"
    }
  }, [_vm.activeId ? _c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.closeEnterprise();
      }
    }
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "450"
    },
    model: {
      value: _vm.dlgEditInfo,
      callback: function callback($$v) {
        _vm.dlgEditInfo = $$v;
      },
      expression: "dlgEditInfo"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.editTitle))]), _c('v-card-text', [_vm.editType === 'returns' ? _c('v-container', [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.returnType,
      "rules": [_vm.rules.required],
      "label": "寄回方式",
      "placeholder": "服务完成或被取消后将按此方式退回设备",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.returns.channel,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.returns, "channel", $$v);
      },
      expression: "editedItem.returns.channel"
    }
  }), _vm.editedItem.returns.channel === 'express' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "append-icon": _vm.editedItem.account ? 'content_copy' : '',
      "type": "text",
      "label": "联系人",
      "placeholder": "请输入联系人姓名",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.returns.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.returns, "contact_name", $$v);
      },
      expression: "editedItem.returns.contact_name"
    }
  }) : _vm._e(), _vm.editedItem.returns.channel === 'express' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "联系电话",
      "placeholder": "请输入联系电话号码",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.returns.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.returns, "contact_phone", $$v);
      },
      expression: "editedItem.returns.contact_phone"
    }
  }) : _vm._e(), _vm.editedItem.returns.channel === 'express' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "寄回地址",
      "placeholder": "请输入地址信息",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.returns.express_address,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.returns, "express_address", $$v);
      },
      expression: "editedItem.returns.express_address"
    }
  }) : _vm._e()], 1) : _vm.editType === 'branch' ? _c('v-container', [_vm.branchList ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.branchList,
      "return-object": "",
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "服务门店",
      "placeholder": "请选择一家服务门店"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name ? data.item.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.city) + " - " + _vm._s(data.item.address))])], 1)];
      }
    }], null, false, 43629064),
    model: {
      value: _vm.editedItem.service_branch,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "service_branch", $$v);
      },
      expression: "editedItem.service_branch"
    }
  }) : _vm._e()], 1) : _vm.editType === 'caseType' ? _c('v-container', [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.repairPolicy,
      "rules": [_vm.rules.required],
      "label": "三包服务",
      "placeholder": "退换货策略",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.return_policy,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "return_policy", $$v);
      },
      expression: "editedItem.return_policy"
    }
  })], 1) : _c('v-container', [_vm._v(" 无编辑内容 ")])], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeReturnInfo();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.saveCase(_vm.caseData._id);
      }
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }