var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-list', {
    class: _vm.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3',
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("联系人")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.branch.contactName))])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("联系电话")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.branch.contactPhone))])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("联系地址")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.branch.address))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }