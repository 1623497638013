var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    attrs: {
      "color": _vm.color,
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('open_case');
      }
    }
  }, [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitCase();
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_vm.caseData && _vm.caseData.catalog && _vm.caseData.creator && _vm.caseData.enterprise && _vm.caseData.account ? _c('v-card', {
    attrs: {
      "loading": _vm.caseLoading ? 'secondary' : false
    }
  }, [_c('case-card-title', {
    attrs: {
      "caseData": _vm.caseData,
      "caseType": "repair"
    },
    on: {
      "Close": _vm.closeDialog
    }
  }), _c('v-divider'), _c('v-card-text', [_c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "accordion": "",
      "flat": "",
      "hover": ""
    },
    model: {
      value: _vm.openPanel,
      callback: function callback($$v) {
        _vm.openPanel = $$v;
      },
      expression: "openPanel"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("申请信息")]), _c('v-expansion-panel-content', [_c('case-repair-basic-info', {
    attrs: {
      "caseData": _vm.caseData
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_vm._v("详细信息")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', [_c('v-tab', [_vm._v("关联设备")]), _c('v-tab', [_vm._v("附件")]), _c('v-tab', [_vm._v("备注")]), _c('v-tab-item', [_c('case-link-asset', {
    attrs: {
      "caseData": _vm.caseData,
      "maxLimit": 1,
      "caseType": "repair",
      "assetKey": "link_asset"
    }
  })], 1), _c('v-tab-item', [_c('widgets-files-list', {
    attrs: {
      "entityType": "CaseRepair",
      "entityId": _vm.getId,
      "type": 1
    }
  })], 1), _c('v-tab-item', [_c('widgets-remarks-list', {
    attrs: {
      "entityType": "CaseRepair",
      "entityId": _vm.getId,
      "type": 0
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("处理信息")]), _vm.caseData.catalog.name === _vm.Global.REPAIR_CATALOG_TEXT ? _c('v-expansion-panel-content', [_vm.caseData.status === 0 ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 受理检测环节需要确认用户信息、设备信息，并指定关联S1系统单号。 "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("S1单号输入后不能修改，系统将自动基于S1系统更新信息。")])])], 1), _vm.caseData.out_req_type !== '三包服务' ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("S1系统单号")]) : _vm._e(), _vm.caseData.isEdit && _vm.caseData.out_req_type !== '三包服务' ? _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "5"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "S1单号",
      "placeholder": "请输入S1系统唯一凭证号码",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.out_req_id,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "out_req_id", $$v);
      },
      expression: "editedItem.out_req_id"
    }
  })], 1) : _vm._e(), _vm.caseData.out_req_type !== '三包服务' ? _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("维修价格")]), _vm.caseData.isEdit ? _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "5"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "维修价格",
      "placeholder": "请输入本次维修的费用",
      "outlined": "",
      "dense": "",
      "min": "0",
      "step": "1.0",
      "type": "number",
      "prefix": "¥"
    },
    model: {
      value: _vm.editedItem.repair_price,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "repair_price", $$v);
      },
      expression: "editedItem.repair_price"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  })], 1) : _vm.caseData.status === 3 ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 设备维修完成后，寄回设备至最终用户，需要提供快递公司和运单号。 ")])], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("送回方式")]), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_vm.caseData.returns && _vm.caseData.returns.express_corp ? _c('span', [_vm._v(_vm._s(_vm.expressCorp.find(function (item) {
    return item.code === _vm.caseData.returns.express_corp;
  }).name))]) : _vm.caseData.isEdit ? _c('v-autocomplete', {
    staticClass: "mt-n2",
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.selected],
      "items": _vm.expressCorp,
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "label": "请选择该快递承运公司"
    },
    model: {
      value: _vm.editedItem.express_corp,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "express_corp", $$v);
      },
      expression: "editedItem.express_corp"
    }
  }) : _c('span', [_vm._v("未填写")])], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("快递单号")]), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_vm.caseData.returns && _vm.caseData.returns.express_number ? _c('span', [_vm._v(_vm._s(_vm.caseData.returns.express_number))]) : _vm.caseData.isEdit ? _c('v-text-field', {
    staticClass: "mt-n2",
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "请输入快递单号",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.express_number,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "express_number", $$v);
      },
      expression: "editedItem.express_number"
    }
  }) : _c('span', [_vm._v("未填写")])], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("寄回日期")]), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_vm.caseData.returns && _vm.caseData.returns.delivery_time ? _c('span', [_vm._v(_vm._s(_vm._f("formatTime")(_vm.caseData.returns.delivery_time)))]) : _vm.caseData.isEdit ? _c('v-menu', {
    staticClass: "mt-n2",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "快递发送时间",
            "readonly": "",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.editedItem.delivery_time,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "delivery_time", $$v);
            },
            expression: "editedItem.delivery_time"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 2951888627),
    model: {
      value: _vm.delivery_time,
      callback: function callback($$v) {
        _vm.delivery_time = $$v;
      },
      expression: "delivery_time"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.delivery_time = false;
      }
    },
    model: {
      value: _vm.editedItem.delivery_time,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "delivery_time", $$v);
      },
      expression: "editedItem.delivery_time"
    }
  })], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  })], 1) : _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.caseData.status > 0 && _vm.caseData.status < 3 && _vm.caseData.out_req_type === '保外送修' ? _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 请确认维修费用，如同意维修，请点击 确认维修 ，我们将安排工程师处理后续事宜。 "), _c('span', {
    staticClass: "font-weight-bold"
  })]) : _vm.caseData.status === 4 ? _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 工单服务已经结束。 "), _c('span', {
    staticClass: "font-weight-bold"
  })]) : _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 工单已经被受理，信息将与S1系统进行同步。"), _c('span', {
    staticClass: "font-weight-bold"
  })])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("维修费用")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.caseData.repair_price)) + " 元 "), _vm.caseData.status < 2 && !_vm.caseData.confirm_price ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('编辑维修价格', 'repair_price');
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("报价确认")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.confirm_price ? '已确认' : '未确认'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("维修类型")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.repair_type || '-'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("维修说明")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.repair_explain || '-') + " "), _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('编辑维修说明', 'repair_explain');
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("故障描述")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.detail_fault || '-') + " "), _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('编辑故障描述', 'detail_fault');
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("解决方案")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.detail_solve || '-') + " "), _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('编辑解决方案', 'detail_solve');
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("测试问题描述")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(_vm._s(_vm.caseData.detail_problem || '-') + " "), _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('编辑测试问题描述', 'detail_problem');
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("使用备件")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(_vm._s(_vm.caseData.part_name || '-'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("备件描述")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(_vm._s(_vm.caseData.part_describe || '-'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("报告症状")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.report_symptom || '-'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("报告问题")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.report_problem || '-'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("报告症状描述")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.report_symptom_describe || '-') + " "), _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('编辑报告症状描述', 'report_symptom_describe');
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("报告问题描述")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.report_problem_describe || '-') + " "), _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('编辑报告问题描述', 'report_problem_describe');
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("GSX建单时间")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm.caseData.gsx_create_time ? _c('span', [_vm._v(_vm._s(_vm._f("formatTime")(_vm.caseData.gsx_create_time)))]) : _c('span', [_vm._v("-")]), _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('编辑GSX建单时间', 'gsx_create_time');
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("GSX维修确认号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.gsx_confirm_number || '-') + " "), _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('编辑GSX维修确认号', 'gsx_confirm_number');
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    attrs: {
      "inset": ""
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("接机人员工号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.staff_receive_code || '-'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("检测人员工号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.staff_check_code || '-'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("维修人员工号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.staff_repair_code || '-'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("取机人员工号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.staff_taken_code || '-'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("开单时间")]), _vm.caseData.create_time ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatTime")(_vm.caseData.create_time)))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("备件订购时间")]), _vm.caseData.gsx_create_time ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatTime")(_vm.caseData.gsx_create_time)))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("维修完成时间")]), _vm.caseData.repaired_time ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatTime")(_vm.caseData.repaired_time)))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("- "), _vm.caseData.status < 4 ? _c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('编辑维修完成时间', 'repaired_time');
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("服务完成时间")]), _vm.caseData.complete_time ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatTime")(_vm.caseData.complete_time)))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")])], 1), _vm.caseData.status === 4 ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "ml-12 text-center"
  }, [_vm.caseData.satisfaction && _vm.caseData.satisfaction.rating ? _c('v-rating', {
    attrs: {
      "background-color": "secondary lighten-3",
      "color": "secondary",
      "readonly": ""
    },
    model: {
      value: _vm.caseData.satisfaction.rating,
      callback: function callback($$v) {
        _vm.$set(_vm.caseData.satisfaction, "rating", $$v);
      },
      expression: "caseData.satisfaction.rating"
    }
  }) : _vm._e(), _c('pre', {
    staticClass: "body-1"
  }, [_vm._v(_vm._s(_vm.caseData.satisfaction && _vm.caseData.satisfaction.remarks))]), _vm.caseData.satisfaction && _vm.caseData.satisfaction.creator ? _c('div', {
    staticClass: "font-weight-normal"
  }, [_c('strong', [_vm._v(_vm._s(_vm.caseData.satisfaction.creator.personal && _vm.caseData.satisfaction.creator.personal.name))]), _vm._v(" @" + _vm._s(_vm._f("fromDate")(_vm.caseData.satisfaction.createTime)) + " ")]) : _vm._e()], 1)])], 1) : _vm._e()], 1) : _c('v-expansion-panel-content', [_vm.caseData.isEdit && _vm.caseData.status === 0 ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "诊断结论",
      "placeholder": "请输入设备故障原因及建议",
      "rows": "5",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.diagnosis.verdict,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.diagnosis, "verdict", $$v);
      },
      expression: "editedItem.diagnosis.verdict"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.diagnosisState,
      "rules": [_vm.rules.required],
      "label": "设备状态",
      "placeholder": "设备是否出现异常情况",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.diagnosis.state,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.diagnosis, "state", $$v);
      },
      expression: "editedItem.diagnosis.state"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.editedItem.diagnosis.state === 'exception' ? _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.diagnosisClass,
      "rules": [_vm.rules.required],
      "label": "设备种类",
      "placeholder": "请选择设备类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.setReasonList(_vm.editedItem.diagnosis.class);
      }
    },
    model: {
      value: _vm.editedItem.diagnosis.class,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.diagnosis, "class", $$v);
      },
      expression: "editedItem.diagnosis.class"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.editedItem.diagnosis.state === 'exception' ? _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.diagnosisReason,
      "rules": [_vm.rules.required],
      "label": "异常原因",
      "placeholder": "请选择设备异常原因",
      "multiple": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "clearable": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.diagnosis.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.diagnosis, "reason", $$v);
      },
      expression: "editedItem.diagnosis.reason"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.editedItem.diagnosis.state === 'exception' ? _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.diagnosisRepair,
      "rules": [_vm.rules.required],
      "label": "维修类型",
      "placeholder": "请选择维修类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.diagnosis.repair,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.diagnosis, "repair", $$v);
      },
      expression: "editedItem.diagnosis.repair"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "text-center",
    attrs: {
      "outlined": "",
      "tile": ""
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('v-img', {
    attrs: {
      "max-height": "275px",
      "contain": "",
      "src": _vm.editedItem.diagnosis.report ? _vm.ossURL + '/' + _vm.editedItem.diagnosis.report : '/static/error/empty_state.svg'
    }
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.uploadImage();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("backup")]), _vm._v(" 上传报告图片")], 1), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.deleteImage();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("delete")]), _vm._v(" 删除报告图片")], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  })], 1) : _c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("诊断结论")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_c('pre', {
    staticClass: "body-1"
  }, [_vm._v(_vm._s(_vm.caseData.diagnosis && _vm.caseData.diagnosis.verdict))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("设备状态")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.caseData.diagnosis && _vm.caseData.diagnosis.state && _vm.diagnosisState.find(function (item) {
    return item.code === _vm.caseData.diagnosis.state;
  }).name) + " ")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("设备种类")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.caseData.diagnosis && _vm.caseData.diagnosis.class && _vm.diagnosisClass.find(function (item) {
    return item.code === _vm.caseData.diagnosis.class;
  }).name) + " ")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("异常原因")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.caseData.diagnosis && _vm.caseData.diagnosis.reason))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("维修类型")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.caseData.diagnosis && _vm.caseData.diagnosis.repair && _vm.diagnosisRepair.find(function (item) {
    return item.code === _vm.caseData.diagnosis.repair;
  }).name) + " ")]), _vm.caseData.diagnosis && _vm.caseData.diagnosis.report ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "text-center",
    attrs: {
      "outlined": "",
      "tile": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-height": "275px",
      "contain": "",
      "src": _vm.ossURL + '/' + _vm.caseData.diagnosis.report
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_vm._v("历史记录")]), _c('v-expansion-panel-content', [_c('case-action-logs', {
    attrs: {
      "caseData": _vm.caseData
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('case-repair-actions', {
    attrs: {
      "caseData": _vm.caseData,
      "editedTask": _vm.editedItem
    },
    on: {
      "Close": _vm.closeDialog,
      "Update": _vm.updateDialog,
      "ChangeStatus": _vm.changeStatus
    }
  })], 1) : _vm._e()], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "hide-overlay": "",
      "width": "450"
    },
    model: {
      value: _vm.dlgEditItem,
      callback: function callback($$v) {
        _vm.dlgEditItem = $$v;
      },
      expression: "dlgEditItem"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.editItemTitle))]), _c('v-card-text', [_c('v-container', [_vm.editItemTitle === '编辑维修价格' ? _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "维修价格",
      "placeholder": "请输入本次维修的费用",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.repair_price,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "repair_price", $$v);
      },
      expression: "editedItem.repair_price"
    }
  }) : _vm._e(), _vm.editItemTitle === '编辑维修说明' ? _c('v-textarea', {
    attrs: {
      "label": "维修说明",
      "placeholder": "请输入维修说明信息",
      "rows": "3",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.repair_explain,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "repair_explain", $$v);
      },
      expression: "editedItem.repair_explain"
    }
  }) : _vm._e(), _vm.editItemTitle === '编辑故障描述' ? _c('v-textarea', {
    attrs: {
      "label": "故障描述",
      "placeholder": "请输入设备故障描述",
      "rows": "3",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.detail_fault,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "detail_fault", $$v);
      },
      expression: "editedItem.detail_fault"
    }
  }) : _vm._e(), _vm.editItemTitle === '编辑解决方案' ? _c('v-textarea', {
    attrs: {
      "label": "解决方案",
      "placeholder": "请输入维修解决方案或维修建议",
      "rows": "3",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.detail_solve,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "detail_solve", $$v);
      },
      expression: "editedItem.detail_solve"
    }
  }) : _vm._e(), _vm.editItemTitle === '编辑测试问题描述' ? _c('v-textarea', {
    attrs: {
      "label": "测试问题描述",
      "placeholder": "请输入设备测试后确认问题描述",
      "rows": "3",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.detail_problem,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "detail_problem", $$v);
      },
      expression: "editedItem.detail_problem"
    }
  }) : _vm._e(), _vm.editItemTitle === '编辑报告症状描述' ? _c('v-text-field', {
    attrs: {
      "label": "报告症状描述",
      "placeholder": "请输入报告症状对应的描述信息",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.report_symptom_describe,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "report_symptom_describe", $$v);
      },
      expression: "editedItem.report_symptom_describe"
    }
  }) : _vm._e(), _vm.editItemTitle === '编辑报告问题描述' ? _c('v-text-field', {
    attrs: {
      "label": "报告问题描述",
      "placeholder": "请输入报告问题对应的描述信息",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.report_problem_describe,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "report_problem_describe", $$v);
      },
      expression: "editedItem.report_problem_describe"
    }
  }) : _vm._e(), _vm.editItemTitle === '编辑GSX建单时间' ? _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '####-##-## ##:##:##',
      expression: "'####-##-## ##:##:##'"
    }],
    attrs: {
      "rules": [_vm.rules.datatime],
      "label": "GSX建单时间",
      "placeholder": "请输入标准时间格式 YYYY-MM-DD HH:mm:ss",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.gsx_create_time,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "gsx_create_time", $$v);
      },
      expression: "editedItem.gsx_create_time"
    }
  }) : _vm._e(), _vm.editItemTitle === '编辑GSX维修确认号' ? _c('v-text-field', {
    attrs: {
      "label": "GSX维修确认号",
      "placeholder": "请输入GSX维修确认号",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.gsx_confirm_number,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "gsx_confirm_number", $$v);
      },
      expression: "editedItem.gsx_confirm_number"
    }
  }) : _vm._e(), _vm.editItemTitle === '变更工单状态' ? _c('div', [_c('p', [_vm._v("当前状态：" + _vm._s(_vm.statusRequired.find(function (v) {
    return v.status === _vm.editedItem.status;
  }).name))]), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.statusRequired.filter(function (v) {
        return v.status > _vm.editedItem.status;
      }),
      "rules": [_vm.rules.required],
      "label": "变更后工单状态",
      "placeholder": "请选择工单变更后的状态",
      "item-text": "name",
      "item-value": "status",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.status,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "status", $$v);
      },
      expression: "editedItem.status"
    }
  })], 1) : _vm._e(), _vm.editItemTitle === '编辑维修完成时间' ? _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '####-##-## ##:##:##',
      expression: "'####-##-## ##:##:##'"
    }],
    attrs: {
      "rules": [_vm.rules.datatime],
      "label": "维修完成时间",
      "placeholder": "请输入标准时间格式 YYYY-MM-DD HH:mm:ss",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.repaired_time,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "repaired_time", $$v);
      },
      expression: "editedItem.repaired_time"
    }
  }) : _vm._e()], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEditDialog();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.saveCase(_vm.caseData._id);
      }
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }