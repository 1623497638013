var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-title', [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _vm._v(" " + _vm._s(_vm.caseData.group ? _vm.caseData.group.name + '-' : '') + _vm._s(_vm.caseData.catalog ? _vm.caseData.catalog.name : '') + ": " + _vm._s(_vm.caseData.case_number) + " "), _c('case-chip-status', {
    attrs: {
      "caseData": _vm.caseData,
      "caseType": _vm.caseType
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }