<template>
  <v-container fluid no-gutters class="pa-0" v-if="editHeadData">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-app-bar elevate-on-scroll :color="editHeadData.bar_color" :src="ossURL +'/'+ editHeadData.bar_image" :dark="editHeadData.bar_color === 'primary' || editHeadData.bar_image !== ''">
          <template v-slot:img="{ props }">
            <v-img
              v-if="editHeadData.bar_image"
              v-bind="props"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            ></v-img>
          </template>

          <v-img alt="ESP平台" class="shrink mr-2" contain :src="editHeadData.logo ? ossURL +'/'+ editHeadData.logo +'?x-oss-process=image/auto-orient,1/resize,m_lfit,w_125/quality,q_90' : '/static/logo2x.svg'"
                 transition="scale-transition" width="25"
          ></v-img>
          <v-toolbar-title class="ml-2">
            {{editHeadData.title}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon>search</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>account_circle</v-icon>
          </v-btn>
          <v-fade-transition>
            <v-overlay v-if="hover" absolute color="grey lighten-5">
              <v-btn class="ma-2" text color="primary" @click="editItem('head')"><v-icon left>edit</v-icon> 编辑头部</v-btn>
              <v-btn class="ma-2" text color="primary" @click="addCover" v-if="!editHeadData.banner"><v-icon left>add</v-icon> 增加横幅</v-btn>
              <v-btn class="ma-2" text color="primary" @click="addAlerts" v-if="!editHeadData.alerts"><v-icon left>add</v-icon> 增加公告</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-app-bar>
      </template>
    </v-hover>
    <v-hover v-if="editHeadData.alerts">
      <template v-slot:default="{ hover }">
        <v-alert dense class="mb-n1" :color="editAlertsData.color || 'secondary'" type="info" dismissible style="z-index: 4">
          {{editAlertsData.title}}
          <v-fade-transition>
            <v-overlay v-if="hover" absolute color="grey lighten-5">
              <v-btn class="ma-2" text color="primary" @click="editItem('alerts')"><v-icon left>edit</v-icon> 编辑组件</v-btn>
              <v-btn class="ma-2" text color="primary" @click="removeAlerts"><v-icon left>delete</v-icon> 删除组件</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-alert>
      </template>
    </v-hover>
    <v-hover v-if="editHeadData.banner">
      <template v-slot:default="{ hover }">
        <v-img dark :src="editBannerData.image ? ossURL + '/'+ editBannerData.image : '/static/error/empty_banner.png'" :height="getHeight(editBannerData.image_height)">
          <v-row :align="editBannerData.alignment" class="fill-height">
            <v-col :class="'text-'+ editBannerData.justify+' pa-12'" cols="12" class="justify-center">
              <h1 v-if="editBannerData.title_class" :class="editBannerData.title_class[0]+' font-weight-thin mb-4 '+ editBannerData.title_class[1] +'--text'">{{editBannerData.title}}</h1>
              <h4 v-if="editBannerData.sub_title_class" :class="editBannerData.sub_title_class[0]+' '+ editBannerData.sub_title_class[1] +'--text'">{{editBannerData.sub_title}}</h4>
            </v-col>
          </v-row>
          <v-fade-transition>
            <v-overlay v-if="hover" absolute class="align-end" color="grey lighten-5">
              <v-btn class="ma-2" text color="primary" @click="editItem('banner')"><v-icon left>edit</v-icon> 编辑组件</v-btn>
              <v-btn class="ma-2" text color="primary" @click="setHeight('full')"><v-icon left>insert_photo</v-icon> 满屏</v-btn>
              <v-btn class="ma-2" text color="primary" @click="setHeight('large')"><v-icon left>subtitles</v-icon> 大</v-btn>
              <v-btn class="ma-2" text color="primary" @click="setHeight('banner')"><v-icon left>web</v-icon> 小</v-btn>
              <v-btn class="ma-2" text color="primary" @click="removeCover"><v-icon left>delete</v-icon> 删除组件</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-img>
      </template>
    </v-hover>
    <v-bottom-sheet v-model="dlgSetting" persistent>
      <v-form v-model="valid" @submit.prevent="submit">
        <input id="files" type="file" name="file" ref="uploadInput" accept="image/*" :multiple="false" @change="detectFiles($event)" />
        <v-card>
          <v-card-title>
            <span class="headline">设置</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text v-if="editComponent === 'head'">
            <v-container>
              <v-row align="start" justify="center">
                <v-col cols="4">
                  <v-avatar color="grey lighten-2" tile>
                    <v-img
                      :src="editHeadData.logo ? ossURL +'/'+ editHeadData.logo : '/static/logo2x.svg'"
                      alt="设置LOGO"
                      contain
                      aspect-ratio="1">
                      <v-progress-circular
                        color="secondary"
                        rotate="360"
                        size="48"
                        width="5"
                        v-if="Upload.uploading && Upload.fileType === 'logo'"
                        :value="Upload.progressUpload">
                      </v-progress-circular>
                    </v-img>
                  </v-avatar>
                  <v-btn text @click="uploadLogo">上传LOGO</v-btn>
                </v-col>
                <v-col cols="8">
                  <v-progress-linear color="secondary" v-if="Upload.uploading && Upload.fileType === 'barImage'" :value="Upload.progressUpload"></v-progress-linear>
                  <v-img
                    :src="editHeadData.bar_image ? ossURL +'/'+ editHeadData.bar_image : '/static/error/empty_banner.png'"
                    class="white--text align-center text-center"
                    dark
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="48px"
                    :aspect-ratio="24/9">
                    <v-btn class="ma-2" text @click="uploadBarImage" >上传标题栏背景</v-btn>
                    <v-btn v-if="editHeadData.bar_image" class="ma-2 text-right" icon @click="removeBarImage"><v-icon>close</v-icon></v-btn>
                  </v-img>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="editHeadData.bar_color"
                    :items="barColor"
                    autocomplete="off"
                    label="请选择头部背景颜色"
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small :color="item">
                        {{item | stringModule}}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip small :color="data.item">{{data.item | stringModule}}</v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="8" class="text-center">
                  <v-text-field
                    v-model="editHeadData.title"
                    :rules="[rules.required]"
                    type="text"
                    label="网站标题"
                    hint="显示在头部的网站标题"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="editComponent === 'alerts'">
            <v-container>
              <v-row>
                <v-col cols="8" class="text-center">
                  <v-text-field
                    v-model="editAlertsData.title"
                    :rules="[rules.required]"
                    type="text"
                    label="公告内容"
                    hint="公告的文字内容"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="text-center">
                  <v-select
                    v-model="editAlertsData.color"
                    :items="fontColor"
                    autocomplete="off"
                    label="公告栏背景色"
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small :color="item">
                        {{item | stringModule}}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip small :color="data.item">{{data.item | stringModule}}</v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="editComponent === 'banner'">
            <v-container>
              <v-row>
                <v-col cols="6" class="text-center">
                  <v-text-field
                    v-model="editBannerData.title"
                    :rules="[rules.required]"
                    type="text"
                    label="横幅大标题"
                    hint="横幅的重点文字内容"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="3" class="text-center">
                  <v-select
                    v-model="editBannerData.title_class[0]"
                    :items="fontSize"
                    label="大小"
                    dense
                    outlined
                  >
                    <template v-slot:selection="{ item }">
                      {{item | stringModule}}
                    </template>
                    <template v-slot:item="data">
                      {{data.item | stringModule}}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="3" class="text-center">
                  <v-select
                    v-model="editBannerData.title_class[1]"
                    :items="fontColor"
                    label="颜色"
                    autocomplete="off"
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small :color="item">
                        {{item | stringModule}}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip small :color="data.item">{{data.item | stringModule}}</v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="mt-n6">
                <v-col cols="6" class="text-center">
                  <v-text-field
                    v-model="editBannerData.sub_title"
                    type="text"
                    label="横幅子标题"
                    hint="横幅的描述文字"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="3" class="text-center">
                  <v-select
                    v-model="editBannerData.sub_title_class[0]"
                    :items="fontSize"
                    label="大小"
                    dense
                    outlined
                  >
                    <template v-slot:selection="{ item }">
                      {{item | stringModule}}
                    </template>
                    <template v-slot:item="data">
                      {{data.item | stringModule}}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="3" class="text-center">
                  <v-select
                    v-model="editBannerData.sub_title_class[1]"
                    :items="fontColor"
                    label="颜色"
                    autocomplete="off"
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small :color="item">
                        {{item | stringModule}}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip small :color="data.item">{{data.item | stringModule}}</v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="mt-n6">
                <v-col cols="6" class="text-center">
                  <v-text-field
                    v-model="editBannerData.link_to"
                    type="text"
                    label="链接地址"
                    hint="横幅的链接地址"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="3" class="text-center">
                  <v-select
                    v-model="editBannerData.alignment"
                    :items="alignmentsAvailable"
                    label="垂直对齐"
                    autocomplete="off"
                    outlined
                    dense
                  ><template v-slot:selection="{ item }">
                    {{item | stringModule}}
                  </template>
                    <template v-slot:item="data">
                      {{data.item | stringModule}}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="3" class="text-center">
                  <v-select
                    v-model="editBannerData.justify"
                    :items="justifyAvailable"
                    label="水平对齐"
                    autocomplete="off"
                    outlined
                    dense
                  ><template v-slot:selection="{ item }">
                    {{item | stringModule}}
                  </template>
                    <template v-slot:item="data">
                      {{data.item | stringModule}}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="mt-n6">
                <v-col cols="12" class="text-center">
                  <v-progress-linear color="secondary" v-if="Upload.uploading && Upload.fileType === 'bannerImage'" :value="Upload.progressUpload"></v-progress-linear>
                  <v-img
                    :src="editBannerData.image ? ossURL +'/'+ editBannerData.image : '/static/error/empty_banner.png'"
                    class="white--text align-center text-center"
                    dark
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="75px"
                    :aspect-ratio="24/9">
                    <v-btn class="ma-2" text @click="uploadBannerImage" >上传横幅图片</v-btn>
                    <v-btn v-if="editHeadData.bar_image" class="ma-2 text-right" icon @click="removeBarImage"><v-icon>close</v-icon></v-btn>
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">关闭</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-bottom-sheet>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_UPLOAD,
  FETCH_PAGE,
  EDIT_PAGE,
} from "@/store/actions.type";
export default {
  components: {},
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        }
      },
      dlgSetting: false,
      valid: true,
      Upload: {
        fileType: 'logo',
        uploading: false,
        progressUpload: 0,
      },
      editComponent: 'head',
      editHeadData: {},
      editAlertsData: {},
      editBannerData: {},
      barColor: ['white', 'primary', 'transparent'],
      fontSize: ['display-4', 'display-3', 'display-2', 'display-1', 'headline', 'title', 'subheading'],
      fontColor: ['red', 'secondary', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey', 'grey', 'primary', 'white'],
      alignmentsAvailable: [
        'start',
        'center',
        'end',
      ],
      justifyAvailable: [
        'start',
        'center',
        'end',
      ],
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentEmployer', 'uploadSign']),
  },
  watch: {
    dlgSetting (val) {
      val || this.close()
    },
  },
  created() {
    this.getPageInfo('head')
    this.getPageInfo('banner')
    this.getPageInfo('alerts')
  },
  methods: {
    getPageInfo(component='head') {
      this.$store.dispatch(FETCH_PAGE, '?name=home&component='+ component +'&owner='+ this.currentUser.employer.ownerId._id)
        .then(data => {
          if (component === 'head') this.editHeadData = Object.assign({ _id: data._id }, data.config[0])
          if (component === 'banner') this.editBannerData = Object.assign({ _id: data._id }, data.config[0])
          if (component === 'alerts') this.editAlertsData = Object.assign({ _id: data._id }, data.config[0])
        });
    },
    addAlerts() {
      this.editHeadData.alerts = true
      this.$store.dispatch(EDIT_PAGE, { _id: this.editHeadData._id, config: this.editHeadData })
        .then(() => {
          this.getPageInfo('head')
          this.getPageInfo('banner')
          this.getPageInfo('alerts')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    removeAlerts() {
      this.editHeadData.alerts = false
      this.$store.dispatch(EDIT_PAGE, { _id: this.editHeadData._id, config: this.editHeadData })
        .then(() => {
          this.getPageInfo('head')
          this.getPageInfo('banner')
          this.getPageInfo('alerts')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    addCover() {
      this.editHeadData.banner = true
      this.$store.dispatch(EDIT_PAGE, { _id: this.editHeadData._id, config: this.editHeadData })
        .then(() => {
          this.getPageInfo('head')
          this.getPageInfo('banner')
          this.getPageInfo('alerts')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    removeCover() {
      this.editHeadData.banner = false
      this.$store.dispatch(EDIT_PAGE, { _id: this.editHeadData._id, config: this.editHeadData })
        .then(() => {
          this.getPageInfo('head')
          this.getPageInfo('banner')
          this.getPageInfo('alerts')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    setHeight(size) {
      this.editBannerData.image_height = size
      this.$store.dispatch(EDIT_PAGE, { _id: this.editBannerData._id, config: this.editBannerData })
        .then(() => {
          this.getPageInfo('head')
          this.getPageInfo('banner')
          this.getPageInfo('alerts')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    getHeight(size) {
      let numSize = '520px'
      switch (size) {
        case 'full': {
          const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
          numSize =  `calc(${height} - ${this.$vuetify.application.top}px - 48px - 64px - 36px)`
          break
        }
        case 'large': {
          numSize = '520px'
          break
        }
        case 'banner': {
          numSize = '340px'
          break
        }
        default: {
          numSize = '520px'
          break
        }
      }
      return numSize
    },
    close () {
      this.dlgSetting = false
      this.getPageInfo('head')
      this.getPageInfo('banner')
      this.getPageInfo('alerts')
    },
    editItem(component) {
      this.editComponent = component
      this.dlgSetting = true
    },
    submit() {
      if (this.editComponent === 'head') {
        if (this.editHeadData._id) {
          this.$store.dispatch(EDIT_PAGE, { _id: this.editHeadData._id, config: this.editHeadData })
            .then(() => {
              this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
              this.close();
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err.response.data.message});
            });
        }
      } else if (this.editComponent === 'banner') {
        if (this.editBannerData._id) {
          this.$store.dispatch(EDIT_PAGE, { _id: this.editBannerData._id, config: this.editBannerData })
            .then(() => {
              this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
              this.close();
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err.response.data.message});
            });
        }
      } else if (this.editComponent === 'alerts') {
        if (this.editAlertsData._id) {
          this.$store.dispatch(EDIT_PAGE, { _id: this.editAlertsData._id, config: this.editAlertsData })
            .then(() => {
              this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
              this.close();
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err.response.data.message});
            });
        }
      }
    },
    uploadLogo () {
      this.Upload.fileType = 'logo'
      this.$store.dispatch(FETCH_UPLOAD)
        .then(this.$refs.uploadInput.click())
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    uploadBarImage() {
      this.editHeadData.bar_color = 'transparent'
      this.Upload.fileType = 'barImage'
      this.$store.dispatch(FETCH_UPLOAD)
        .then(this.$refs.uploadInput.click())
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    uploadBannerImage() {
      this.Upload.fileType = 'bannerImage'
      this.$store.dispatch(FETCH_UPLOAD)
        .then(this.$refs.uploadInput.click())
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    removeBarImage() {
      this.editHeadData.bar_image = ''
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.currentEmployer.ownerId._id +'/page/home/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };
      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          if (this.Upload.fileType === 'logo') this.editHeadData.logo = this.currentEmployer.ownerId._id +'/page/home/'+ this.uploadSign.key;
          if (this.Upload.fileType === 'barImage') this.editHeadData.bar_image = this.currentEmployer.ownerId._id +'/page/home/'+ this.uploadSign.key;
          if (this.Upload.fileType === 'bannerImage') this.editBannerData.image = this.currentEmployer.ownerId._id +'/page/home/'+ this.uploadSign.key;
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
  },
}
</script>
<style scoped>
  .show_upload {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
</style>
