export default [
  {
    path: '/',
    redirect: {
      name: 'dashboard'
    },
    hidden: true,
    name: 'home',
    meta: {
      title: 'Home',
      roles: ['admin', 'dealer']
    },
  },
  {
    path: '/dashboard',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dashboard.vue'
    ),
    name: 'dashboard',
    meta: {
      title: '仪表盘',
      icon: 'dashboard',
      roles: ['admin', 'dealer']
    }
  },
  {
    path: '/dealer/workbench',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/workbench/index.vue'
    ),
    name: 'Workbench',
    meta: {
      title: '工作台',
      icon: 'assignment',
      roles: ['admin', 'dealer']
    }
  },
  {
    path: '/dealer/knowledge',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/knowledge'
    ),
    name: 'Knowledge',
    meta: {
      title: '知识库',
      icon: 'school',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/knowledge/edit/:id',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/dealer/knowledge_edit'
    ),
    name: 'Knowledge_Edit',
    meta: {
      title: '知识编辑',
      roles: ['dealer']
    }
  },
  {
    path: '/dealer/knowledge/edit/video/:id',
    hidden: true,
    component: () => import('@/views/dealer/knowledge_edit_video'),
    name: 'Knowledge_Edit_Video',
    meta: {
      title: '知识视频编辑',
      roles: ['dealer']
    }
  },
  {
    path: '/403',
    hidden: true,
    name: 'accessdenied',
    meta: {
      title: 'AccessDenied',
      public: true,
      breadcrumb: false,
      roles: ['admin', 'enterprise', 'member', 'dealer']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/Deny.vue`
    )
  },
  {
    path: '/404',
    hidden: true,
    name: 'notfound',
    meta: {
      title: 'NotFound',
      public: true,
      breadcrumb: false,
      roles: ['admin', 'enterprise', 'member', 'dealer']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/NotFound.vue`
    )
  },
  {
    path: '/500',
    hidden: true,
    name: 'servererror',
    meta: {
      title: 'ServerError',
      public: true,
      breadcrumb: false,
      roles: ['admin', 'enterprise', 'member', 'dealer']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/Error.vue`
    )
  },
  {
    path: '/login',
    hidden: true,
    name: 'login',
    meta: {
      title: 'Login',
      public: true,
      breadcrumb: false,
      roles: ['admin', 'enterprise', 'member', 'dealer']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/login`
    )
  },
  {
    path: '/register',
    hidden: true,
    name: 'register',
    meta: {
      title: 'Register',
      public: true,
      breadcrumb: false,
      roles: ['admin', 'enterprise', 'member', 'dealer']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/register`
    )
  },
]
