var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.editTaskInfo();
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_vm.caseData.status === 0 ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 受理环节需要确认用户信息、设备信息，并指定服务团队，服务团队可以为内部团队或服务商。 "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("该服务申请成功受理后，系统将自动创建任务。")])])], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("服务团队")]), _vm.caseData.isEdit && _vm.caseData.status === 0 ? _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "4"
    }
  }, [_vm.deptList.length ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.getDeptList(_vm.deptList[0]),
      "rules": [_vm.rules.required, _vm.rules.selected],
      "item-text": "name",
      "item-value": "value",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择该服务请求的服务团队"
    },
    model: {
      value: _vm.editedItem.deliver_team,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "deliver_team", $$v);
      },
      expression: "editedItem.deliver_team"
    }
  }) : _vm._e()], 1) : _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("服务方式")]), _vm.caseData.isEdit && _vm.caseData.status === 0 ? _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.handoverType,
      "clearable": "",
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择该工单服务方式"
    },
    on: {
      "change": function change($event) {
        return _vm.editTaskInfo();
      }
    },
    model: {
      value: _vm.editedItem.handover_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "handover_type", $$v);
      },
      expression: "editedItem.handover_type"
    }
  })], 1) : _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  })], 1) : _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.selectedTask,
      callback: function callback($$v) {
        _vm.selectedTask = $$v;
      },
      expression: "selectedTask"
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.changeTask(-1);
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', {
    attrs: {
      "color": "green"
    }
  }, [_vm._v("check_circle")])], 1), _c('v-list-item-title', [_vm._v("受理")]), _c('v-list-item-icon', [_c('v-icon', [_vm._v("keyboard_arrow_right")])], 1)], 1), _c('v-divider'), _vm._l(_vm.caseData.tasks_list, function (taskinfo, index) {
    return [_c('v-list-item', {
      key: taskinfo._id,
      on: {
        "click": function click($event) {
          return _vm.changeTask(index);
        }
      }
    }, [taskinfo.isActive ? _c('v-list-item-icon', [_vm.caseData.isEdit ? _c('v-icon', {
      attrs: {
        "color": "secondary"
      }
    }, [_vm._v("build_circle")]) : _c('v-icon', [_vm._v("hourglass_top")])], 1) : taskinfo.status === 2 ? _c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": "green"
      }
    }, [_vm._v("check_circle")])], 1) : taskinfo.status === 3 ? _c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v("highlight_off")])], 1) : _c('v-list-item-icon'), _c('v-list-item-title', [_vm._v(_vm._s(taskinfo.task.name))]), _c('v-list-item-icon', [_c('v-icon', [_vm._v("keyboard_arrow_right")])], 1)], 1), _c('v-divider', {
      key: taskinfo._id + '_hr'
    })];
  })], 2)], 1)], 1), _vm.activeList < 0 ? _c('v-col', {
    staticClass: "mt-n16",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "subtitle-1"
  }, [_vm._v("受理")]), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 受理环节需要确认用户信息、设备信息，并指定服务团队，服务团队可以为内部团队或服务商。 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("服务团队")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.caseData.deliver_team ? _vm.caseData.deliver_team.name : '未指定') + " ")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("服务方式")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.caseData.handover_type ? _vm.handoverType.find(function (item) {
    return item.code === _vm.caseData.handover_type;
  }).name : '未指定') + " ")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  })], 1)], 1)], 1) : _c('v-col', {
    staticClass: "mt-n16",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.activeTask.task.name))]), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.activeTask && _vm.activeTask.task.remarks) + " ")])], 1), _vm.activeTask.task.isExpress ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("发货途径")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-switch', {
    attrs: {
      "disabled": !_vm.caseData.isEdit || !_vm.activeTask.isActive,
      "label": "\u76F4\u63A5\u53D1\u9001: ".concat(_vm.editedItem.express_direct ? '是（门店直发）' : '否（储运发货）')
    },
    model: {
      value: _vm.editedItem.express_direct,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "express_direct", $$v);
      },
      expression: "editedItem.express_direct"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  })], 1), _vm.editedItem.express_direct ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("收件人电话")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "disabled": !_vm.caseData.isEdit || !_vm.activeTask.isActive,
      "type": "text",
      "label": "请输入收件人手机号码",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.editTaskInfo();
      }
    },
    model: {
      value: _vm.editedItem.express_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "express_phone", $$v);
      },
      expression: "editedItem.express_phone"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("快递公司")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.expressCorp,
      "rules": [_vm.rules.required, _vm.rules.selected],
      "disabled": !_vm.caseData.isEdit || !_vm.activeTask.isActive,
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "label": "请选择该快递承运公司"
    },
    on: {
      "change": function change($event) {
        return _vm.editTaskInfo();
      }
    },
    model: {
      value: _vm.editedItem.express_code,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "express_code", $$v);
      },
      expression: "editedItem.express_code"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("快递单号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "id": "copyTarget",
      "rules": [_vm.rules.required],
      "disabled": !_vm.caseData.isEdit || !_vm.activeTask.isActive,
      "type": "text",
      "label": "请输入快递单号",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.editTaskInfo();
      }
    },
    model: {
      value: _vm.editedItem.express_number,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "express_number", $$v);
      },
      expression: "editedItem.express_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.copyText
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.clicked ? 'done' : 'content_copy'))])], 1)], 1)], 1), _vm.activeTask.expressFields ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "10"
    }
  }, [_c('case-express-info', {
    key: _vm.activeTask._id,
    attrs: {
      "id": _vm.activeTask._id
    }
  }, [_vm._v("物流信息")])], 1) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.activeTask.task_fields ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._l(_vm.activeTask.task_fields, function (item, i) {
    return _c('v-row', {
      key: i + '_task_fields',
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), item.format.type === 'text' ? _c('v-col', {
      staticClass: "text--secondary",
      attrs: {
        "cols": "8"
      }
    }, [item.format.mask ? _c('v-text-field', {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: item.format.mask,
        expression: "item.format.mask"
      }],
      attrs: {
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "disabled": !_vm.caseData.isEdit || !_vm.activeTask.isActive,
        "hide-details": "",
        "outlined": "",
        "dense": ""
      },
      on: {
        "change": function change($event) {
          return _vm.editTaskInfo();
        }
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _c('v-text-field', {
      attrs: {
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "disabled": !_vm.caseData.isEdit || !_vm.activeTask.isActive,
        "hide-details": "",
        "outlined": "",
        "dense": ""
      },
      on: {
        "change": function change($event) {
          return _vm.editTaskInfo();
        }
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.format.type === 'selects' ? _c('v-col', {
      staticClass: "text--secondary",
      attrs: {
        "cols": "8"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": item.format.items,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "hide-details": "",
        "outlined": "",
        "disabled": !_vm.caseData.isEdit || !_vm.activeTask.isActive,
        "dense": ""
      },
      on: {
        "change": function change($event) {
          return _vm.editTaskInfo();
        }
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), _c('v-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "2"
      }
    })], 1);
  }), _c('v-divider', {
    staticClass: "my-2"
  }), _vm.activeTask.task.isWarehouse && _vm.activeTask.asset_fields.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("请对设备进行入库检查，并设置工单完成后该设备状态。")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.caseData.process ? _c('case-edit-asset', {
    attrs: {
      "disabled": !_vm.caseData.isEdit || !_vm.activeTask.isActive,
      "assetList": _vm.caseData.link_asset,
      "assetField": _vm.activeTask.asset_fields,
      "saveField": _vm.activeTask.assetFields
    },
    on: {
      "Update": _vm.updateEditAsset
    }
  }) : _vm._e(), _c('v-divider')], 1)], 1)], 1) : _vm.activeTask.asset_fields.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("请确认并更新关联设备信息。")]), _vm.caseData.link_asset && _vm.caseData.link_asset.length <= 30 ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.caseData.process ? _c('case-edit-asset', {
    attrs: {
      "disabled": !_vm.caseData.isEdit || !_vm.activeTask.isActive,
      "assetList": _vm.caseData.process.isRecovery ? _vm.caseData.link_asset_new : _vm.caseData.link_asset,
      "assetField": _vm.activeTask.asset_fields,
      "saveField": _vm.activeTask.assetFields
    },
    on: {
      "Update": _vm.updateEditAsset
    }
  }) : _vm._e()], 1) : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 该工单关联设备大于30台，请使用详细信息中关联设备中的批量更新完成设备更新操作。 ")])], 1)], 1) : _vm._e()], 2) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }