var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('core-card', _vm._g(_vm._b({
    staticClass: "v-card--material-stats"
  }, 'core-card', _vm.$attrs, false), _vm.$listeners), [_c('v-card', {
    staticClass: "pa-4",
    class: _vm.elevation - 5,
    attrs: {
      "slot": "offset",
      "color": _vm.color
    },
    slot: "offset"
  }, [_c('v-icon', {
    attrs: {
      "dark": "",
      "size": "40"
    }
  }, [_vm._v(" " + _vm._s(_vm.icon) + " ")])], 1), _c('div', {
    staticClass: "text-xs-right"
  }, [_c('p', {
    staticClass: "category font-weight-light",
    domProps: {
      "textContent": _vm._s(_vm.title)
    }
  }), _c('h3', {
    staticClass: "font-weight-black headline"
  }, [_vm._v(_vm._s(_vm.value) + " "), _c('small', [_vm._v(_vm._s(_vm.smallValue))])])]), _c('template', {
    slot: "actions"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": _vm.subIconColor,
      "size": "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.subIcon) + " ")]), _c('span', {
    staticClass: "caption font-weight-light",
    class: _vm.subTextColor,
    domProps: {
      "textContent": _vm._s(_vm.subText)
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }