import { CatalogService } from "@/common/http";

import {
  FETCH_CATALOG_LIST,
  ORDER_CATALOG_LIST,
  ORDER_CATALOG_CHILDREN,
  FETCH_CATALOG,
  PUBLISH_CATALOG,
  EDIT_CATALOG,
  DELETE_CATALOG,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_CATALOG_LIST,
  SET_CATALOG_LIST,
  SET_CATALOG,
} from "./mutations.type"

const state = {
  catalogLoading: true,
  catalogList: [],
  catalogInfo: '',
};

const getters = {
  catalogInfo: state => state.catalogInfo,
  catalogList: state => state.catalogList,
  catalogLoading: state => state.catalogLoading,
};

const actions = {
  [FETCH_CATALOG_LIST]({ commit }, slug) {
    commit(LOAD_CATALOG_LIST);
    return new Promise((resolve, reject) => {
      CatalogService.get('/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_CATALOG_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [ORDER_CATALOG_LIST]({ commit }, catalogList) {
    commit(SET_CATALOG_LIST, catalogList);
    return CatalogService.order(catalogList);
  },
  [ORDER_CATALOG_CHILDREN](context, catalogList) {
    return CatalogService.order(catalogList);
  },
  async [FETCH_CATALOG]({ commit }, catalogSlug) {
    const { data } = await CatalogService.get('/'+catalogSlug);
    commit(SET_CATALOG, data);
    return data;
  },
  [PUBLISH_CATALOG](context, catalog) {
    return CatalogService.create('', catalog);
  },
  [EDIT_CATALOG](context, catalog) {
    return CatalogService.update(catalog._id, catalog);
  },
  [DELETE_CATALOG](context, catalog) {
    return CatalogService.destroy(catalog);
  },
};

const mutations = {
  [LOAD_CATALOG_LIST] (state) {
    state.catalogLoading = true;
  },
  [SET_CATALOG_LIST](state, { catalog }) {
    state.catalogList = catalog;
    state.catalogLoading = false;
  },
  [SET_CATALOG](state, catalog) {
    state.catalogInfo = catalog;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
