<template>
    <v-card>
      <v-card-title>
        <span class="headline">选择设备</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header v-slot="{ open }">
            <v-row no-gutters>
              <v-col cols="4">筛选内容</v-col>
              <v-col cols="8" class="text--secondary">
                <v-fade-transition leave-absolute>
                  <span v-if="open">请选择筛选内容</span>
                  <v-row v-else style="width: 100%">
                    <v-col cols="12" v-if="query.state && query.state.length">
                      设备状态: {{ query.state.map(state => stateList.find(item => item._id === state).name) || 'Not set' }}
                    </v-col>
                    <v-col cols="12" v-if="query.key">
                      设备关键字: {{ query.key }}
                    </v-col>
                    <v-col cols="12" v-if="!query.state.length && !query.key">
                      无
                    </v-col>
                  </v-row>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="space-around">
              <v-col cols="12">
                <v-text-field
                  v-model="query.key"
                  @keydown.enter="searchAsset"
                  @change="searchAsset"
                  flat
                  dense
                  hide-details
                  label="设备关键字..."
                  prepend-inner-icon="search"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="!isInWarehouse">
                设备状态：
                <v-chip-group v-if="stateList.length" v-model="query.state" column multiple @change="searchAsset">
                  <v-chip v-for="state in stateList" :key="state._id" :value="state._id" filter label outlined>{{state.name}}</v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-text>
        <v-data-table
          v-model="selectItem_"
          :headers="headerTable"
          :items="tableList"
          :options.sync="optionsTable"
          :server-items-length="assetCount"
          :loading="assetLoading"
          loading-text="数据读取中... 请稍后"
          :footer-props="{'items-per-page-options': [5, 10, 15]}"
          item-key="_id"
          dense
          show-select
        >
          <template v-slot:item.name="{ item }">
            <td nowrap="true">
              <widgets-asset-dialogs :getId="item._id">
                <div>
                  <v-tooltip bottom v-if="item.isLock">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>lock</v-icon>
                    </template>
                    <span>设备锁定中</span>
                  </v-tooltip>
                  {{item.name}}
                  <v-tooltip bottom v-if="item.ownerType === 'Enterprise'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>corporate_fare</v-icon>
                    </template>
                    <span>企业自有设备</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else-if="item.ownerType === 'Account'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>person</v-icon>
                    </template>
                    <span>个人自有设备</span>
                  </v-tooltip>
                </div>
              </widgets-asset-dialogs>
            </td>
          </template>
          <template v-slot:item.product="{ item }">
            <td nowrap="true" v-if="item.product" @click="openProduct(item.product)" class="v-list-item--link text-truncate" style="max-width: 175px;">
              {{item.product.name}} - {{item.product.part_number}}
            </td>
          </template>
          <template v-slot:item.serial_number="{ item }">
            <td nowrap="true" v-if="item.noSN">无序列号</td>
            <td nowrap="true" v-else>{{item.serial_number}}</td>
          </template>
          <template v-slot:item.state="{ item }">
            <td nowrap="true">{{item.state.name}}</td>
          </template>
          <template v-slot:item.branch="{ item }">
            <td nowrap="true">{{item.branch}}</td>
          </template>
          <template v-slot:item.label="{ item }">
            <td nowrap="true">{{item.label}}</td>
          </template>
          <template v-slot:item.asset_state="{ item }">
            <td nowrap="true">{{item.asset_state.map(state => assetState.find(o => o.code === state).name)}}</td>
          </template>
          <template v-slot:item.account="{ item }">
            <td nowrap="true">{{item.account ? item.account.personal.name : ''}}</td>
          </template>
          <template v-slot:top>
            <tr>
              <td colspan="5">最多:{{maxLimit === -1 ? '10' : maxLimit}} - 已选: {{selectItem_.length}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span class="text-caption"></span>
        <v-btn text @click="closeDialog">取消</v-btn>
        <v-btn color="secondary" v-if="maxLimit < 0" depressed :disabled="!selectItem_.length" @click="selectAsset">确定</v-btn>
        <v-btn color="secondary" v-else depressed :disabled="maxLimit !== -1 && maxLimit !== selectItem_.length" @click="selectAsset">确定</v-btn>
      </v-card-actions>
      <v-dialog v-model="dlgProduct" persistent scrollable max-width="600px">
        <widgets-product-dialogs :id="activeProduct" :key="activeProduct" v-on:Close="closeProduct"></widgets-product-dialogs>
      </v-dialog>
    </v-card>
</template>
<script>
import {
  FETCH_ASSET_LIST, FETCH_ASSETSTATE_LIST,
} from "@/store/actions.type";
import store from "@/store";
import {mapGetters} from "vuex";
import {SET_ERROR} from "@/store/mutations.type";
import Util from "@/common/util";
export default {
  props: {
    enterprise: {
      type: String,
      required: true,
    },
    maxLimit: {
      type: Number,
      default: -1
    },
    selectItem: {
      type: Array
    },
    isInWarehouse: {
      type: Boolean,
      default: false
    },
    isRepairService: {
      type: Boolean,
      default: false
    },
    vendor: {
      type: String
    }
  },
  data() {
    return {
      query: {
        key: '',
        enterprise: this.enterprise,
        state: []
      },
      headerTable: [
        { text: '名称', value: 'name', width: "155px" },
        { text: '产品', value: 'product', width: "155px" },
        { text: '序列号', value: 'serial_number', width: "85px" },
        { text: '标签', value: 'label', width: "95px" },
        { text: '状态', width: "75px", value: 'state' },
        { text: '分支机构', width: "100px", value: 'branch' },
        { text: '用户', value: 'account', width: "85px" },
        { text: '设备情况', value: 'asset_state', width: "100px" },
      ],
      tableList: [],
      optionsTable: {},
      selectedAsset: [],
      activeProduct: '',
      dlgProduct: false,
    }
  },
  computed: {
    ...mapGetters([
      "assetList",
      "assetLoading",
      "assetCount",
      "stateList"
    ]),
    selectItem_: {
      get () {
        return this.selectItem
      },
      set (val) {
        this.$emit('Selected', val)
      }
    },
    assetState () {
      return Util.categories('assetState')
    },
  },
  watch: {
    optionsTable: {
      handler() {
        this.getAssetList(this.query)
      },
      deep: true,
    },
    selectItem_(val, oldVal) {
      if (val.length > 10) {
        store.commit(SET_ERROR, {msg: '最多关联10台设备。'});
        this.$nextTick(() => {
          this.selectItem_ = oldVal
        })
      }
      if (val.length && this.isRepairService) {
        let strVendor = val[0].vendor
        let diffVendor = val.find(item => item.vendor !== strVendor)
        if (diffVendor) {
          store.commit(SET_ERROR, {msg: '报修申请只能选择同一个厂商品牌的设备。'});
          this.$nextTick(() => {
            this.selectItem_ = oldVal
          })
        }
      }
    }
  },
  methods: {
    async getAssetList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      let urlQuery = '';
      urlQuery += '&enterprise='+ query.enterprise || this.enterprise
      if (query.key) urlQuery += '&key='+ query.key
      if (query.state && query.state.length) urlQuery += '&state='+ query.state
      if (this.isInWarehouse) {
        let assetStateList = await store.dispatch(FETCH_ASSETSTATE_LIST, 'isEnable=true')
        urlQuery += '&state='+ assetStateList.state.find(item => item.name === '在库')._id
      } else {
        store.dispatch(FETCH_ASSETSTATE_LIST, 'isEnable=true')
      }
      if (Object.keys(this.optionsTable).length > 0) {
        let strQuery = 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery
        if (this.vendor) strQuery += '&vendor='+ this.vendor
        strQuery += '&lock=no'
        store.dispatch(FETCH_ASSET_LIST, strQuery)
          .then(({asset}) => {
            this.tableList = asset.map(obj => {
              let objNew = {}
              objNew['_id'] = obj._id
              objNew['name'] = obj.name
              objNew['isLock'] = obj.isLock
              objNew['isSelectable'] = obj.isSelectable
              objNew['serial_number'] = obj.serial_number
              objNew['label'] = obj.label
              objNew['ownerType'] = obj.ownerType
              objNew['vendor']= obj.vendor
              objNew['product']= obj.product
              objNew['account']= obj.account
              objNew['state'] = obj.state
              objNew['branch'] = obj.branch
              objNew['asset_state'] = obj.asset_state
              objNew['noSN'] = obj.product_type.noSN
              return objNew
            })
          })
      }
    },
    searchAsset() {
      this.optionsTable.page = 1
      this.getAssetList(this.query)
    },
    closeDialog () {
      this.$emit('Close')
    },
    selectAsset() {
      this.$emit('Selected', Object.assign(this.selectItem_, {close: true}))
    },
    openProduct(item) {
      this.activeProduct = item._id
      this.dlgProduct = true
    },
    closeProduct() {
      this.dlgProduct = false
    }
  }
}
</script>
