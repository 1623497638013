<template>
  <div>
    <span dark text class="v-list-item--link" @click="openItem(id)">
      <slot></slot>{{$slots.default ? '：' : ''}}<span v-if="itemData && itemData.message === 'ok'">{{parseInt(itemData.state) | expressState}}<v-icon small class="ma-2">route</v-icon></span><span v-else>无</span>
    </span>
    <v-dialog v-model="dialog" scrollable persistent width="600">
      <v-card v-if="itemData">
        <v-toolbar flat>
          物流信息：{{itemData.nu || ''}}
          <v-spacer></v-spacer>
          <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text v-if="itemData.message === 'ok'">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">时间</th>
                <th class="text-left">描述</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in itemData.data"
                :key="item.time"
              >
                <td nowrap="true">{{ item.time | formatTime }}</td>
                <td>{{ item.context || item.content || item.areaName || item.status }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-text v-else>
          {{itemData.message}}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {
  FETCH_EXPRESS,
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    from: {
      type: String,
      default: 'task'
    }
  },
  data() {
    return {
      dialog: false,
      itemData: {},
    }
  },
  computed: { },
  mounted() {
    this.getItem(this.id)
  },
  methods: {
    getItem(id) {
      let query = {
        id: id,
        query: '?from='+ this.from
      }
      store.dispatch(FETCH_EXPRESS, query)
        .then((data) => {
          this.itemData = Object.assign({}, data)
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    openItem() {
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
  }
}

</script>
