var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pa-0 text-center",
    attrs: {
      "fluid": "",
      "no-gutters": ""
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-img', {
          staticClass: "align-center",
          attrs: {
            "contain": _vm.editBodyData.contain || false,
            "max-height": _vm.editBodyData.height || 150,
            "max-width": _vm.editBodyData.width || 200,
            "src": "https://picsum.photos/id/11/500/300"
          }
        }, [_c('v-fade-transition', [hover ? _c('v-overlay', {
          staticClass: "align-end",
          attrs: {
            "absolute": "",
            "color": "grey lighten-2"
          }
        }, [_c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("edit")]), _vm._v(" 编辑")], 1), _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("delete")]), _vm._v(" 删除")], 1)], 1) : _vm._e()], 1)], 1)];
      }
    }])
  }), _c('v-bottom-sheet', {
    attrs: {
      "retain-focus": false,
      "eager": ""
    },
    model: {
      value: _vm.dlgSetting,
      callback: function callback($$v) {
        _vm.dlgSetting = $$v;
      },
      expression: "dlgSetting"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("设置")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "8"
    }
  }, [_vm.activeEdit ? _c('editor', {
    attrs: {
      "init": _vm.editorInit,
      "id": 'element' + _vm.index
    },
    model: {
      value: _vm.editBodyData.text,
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData, "text", $$v);
      },
      expression: "editBodyData.text"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.alignmentsAvailable,
      "label": "垂直对齐",
      "autocomplete": "off",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm._f("stringModule")(item)) + " ")];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm._f("stringModule")(data.item)) + " ")];
      }
    }]),
    model: {
      value: _vm.editBodyData.alignment,
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData, "alignment", $$v);
      },
      expression: "editBodyData.alignment"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.justifyAvailable,
      "label": "水平对齐",
      "autocomplete": "off",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm._f("stringModule")(item)) + " ")];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm._f("stringModule")(data.item)) + " ")];
      }
    }]),
    model: {
      value: _vm.editBodyData.justify,
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData, "justify", $$v);
      },
      expression: "editBodyData.justify"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.bgColor,
      "label": "区域背景颜色",
      "autocomplete": "off",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": item
          }
        }, [_vm._v(" " + _vm._s(_vm._f("stringModule")(item)) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": data.item
          }
        }, [_vm._v(_vm._s(_vm._f("stringModule")(data.item)))])];
      }
    }]),
    model: {
      value: _vm.editBodyData.color,
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData, "color", $$v);
      },
      expression: "editBodyData.color"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }