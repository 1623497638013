import { RentService } from "@/common/http";

import {
  INIT_RENT_QUOTE,
  PUBLISH_RENT_QUOTE,
  FETCH_RENT_QUOTE,
  FETCH_RENT_QUOTE_LIST,
  EDIT_RENT_QUOTE,
  APPROVE_RENT_QUOTE,
  DELETE_RENT_QUOTE,
  PUBLISH_RENT_QUOTE_SHEET,
  FETCH_RENT_QUOTE_SHEET,
  FETCH_RENT_QUOTE_SHEET_LIST,
  DOWNLOAD_RENT_QUOTE_SHEET,
} from "./actions.type";

import {
  SET_ERROR,
  SET_RENT_QUOTE_INIT,
  LOAD_RENT_QUOTE_LIST,
  SET_RENT_QUOTE_LIST,
  SET_RENT_QUOTE,
  LOAD_RENT_QUOTE_SHEET_LIST,
  SET_RENT_QUOTE_SHEET_LIST,
  SET_RENT_QUOTE_SHEET
} from "./mutations.type";

const state = {
  init_rent_info: {},
  rentQuoteLoading: true,
  rentQuoteList: [],
  rentQuoteCount: 0,
  rentQuoteInfo: {},
  rentQuoteSheetLoading: true,
  rentQuoteSheetList: [],
  rentQuoteSheetCount: 0,
  rentQuoteSheetInfo: {},
};

const getters = {
  rentQuoteInfo: state => state.rentQuoteInfo,
  init_rent_info: state => state.init_rent_info,
  rentQuoteLoading: state => state.rentQuoteLoading,
  rentQuoteList: state => state.rentQuoteList,
  rentQuoteCount: state => state.rentQuoteCount,
  rentQuoteSheetInfo: state => state.rentQuoteSheetInfo,
  rentQuoteSheetLoading: state => state.rentQuoteSheetLoading,
  rentQuoteSheetList: state => state.rentQuoteSheetList,
  rentQuoteSheetCount: state => state.rentQuoteSheetCount,

};

const actions = {
  [INIT_RENT_QUOTE]({ commit }, params) {
    return new Promise((resolve, reject) => {
      RentService.get('/offer/initialize', '?template='+params)
        .then(({ data }) => {
          commit(SET_RENT_QUOTE_INIT, data);
          resolve(data);
        })
        .catch(({response}) => {
          commit(SET_ERROR, { msg: response.data.message });
          reject(response);
        })
    });
  },
  [PUBLISH_RENT_QUOTE](context, params) {
    return new Promise((resolve, reject) => {
      RentService.create('/offer', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        })
    });
  },
  [FETCH_RENT_QUOTE]({ commit }, params) {
    commit(LOAD_RENT_QUOTE_LIST);
    return new Promise((resolve, reject) => {
      RentService.get('/offer', params.query)
        .then(({ data }) => {
          commit(SET_RENT_QUOTE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_RENT_QUOTE_LIST]({ commit }, params) {
    commit(LOAD_RENT_QUOTE_LIST);
    return new Promise((resolve, reject) => {
      RentService.get('/offer/list', '?'+params.query)
        .then(({ data }) => {
          commit(SET_RENT_QUOTE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [EDIT_RENT_QUOTE](context, params) {
    return RentService.update('/offer', params.id, params.item);
  },
  [APPROVE_RENT_QUOTE](context, id) {
    return RentService.update('/offer/approve', id)
  },
  [DELETE_RENT_QUOTE](context, id) {
    return RentService.delete('/offer/'+id)
  },
  [PUBLISH_RENT_QUOTE](context, params) {
    return new Promise((resolve, reject) => {
      RentService.create('/offer', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        })
    });
  },
  [PUBLISH_RENT_QUOTE_SHEET]({ commit }, params) {
    commit(LOAD_RENT_QUOTE_SHEET_LIST);
    return new Promise((resolve, reject) => {
      RentService.create('/quote', params)
        .then(({ data }) => {
          commit(SET_RENT_QUOTE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_RENT_QUOTE_SHEET_LIST]({ commit }, params) {
    commit(LOAD_RENT_QUOTE_SHEET_LIST);
    return new Promise((resolve, reject) => {
      RentService.get('/quote/list', '?'+params.query)
        .then(({ data }) => {
          commit(SET_RENT_QUOTE_SHEET_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_RENT_QUOTE_SHEET]({ commit }, params) {
    commit(LOAD_RENT_QUOTE_SHEET_LIST);
    return new Promise((resolve, reject) => {
      RentService.get('/quote', params.query)
        .then(({ data }) => {
          commit(SET_RENT_QUOTE_SHEET, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [DOWNLOAD_RENT_QUOTE_SHEET](context, id) {
    return RentService.update('/quote/download', id)
  },
}

const mutations = {
  [SET_RENT_QUOTE_INIT](state, rent) {
    state.init_rent_info = rent;
  },
  [SET_RENT_QUOTE_LIST](state, list) {
    state.rentQuoteCount = list.query.count;
    state.rentQuoteList = list.offers;
    state.rentQuoteLoading = false;
  },
  [LOAD_RENT_QUOTE_LIST] (state) {
    state.rentQuoteLoading = true;
  },
  [SET_RENT_QUOTE](state, offer) {
    state.rentQuoteInfo = offer.offerInfo;
    state.rentQuoteLoading = false;
  },
  [LOAD_RENT_QUOTE_SHEET_LIST] (state) {
    state.rentQuoteSheetLoading = true;
  },
  [SET_RENT_QUOTE_SHEET_LIST](state, list) {
    state.rentQuoteSheetCount = list.query.count;
    state.rentQuoteSheetList = list.quotes;
    state.rentQuoteSheetLoading = false;
  },
  [SET_RENT_QUOTE_SHEET](state, sheet) {
    state.rentQuoteSheetInfo = sheet;
    state.rentQuoteSheetLoading = false;
  },

};

export default {
  state,
  actions,
  mutations,
  getters
};
