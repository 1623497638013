var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    attrs: {
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openItem(_vm.id);
      }
    }
  }, [_vm._t("default")], 2), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "600"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("发行说明")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_vm._v(" 开发团队将持续跟进和处理用户反馈的需求与问题。并对代码进行修正，定期发布新的版本。在此将罗列所有更新记录，以便对需求完成情况进行回顾与确认。此外，在主要版本之间，可能会有许多突破性的更改，您可以遵循下面最新的迁移指南来帮助您简化过渡。 "), _c('v-autocomplete', {
    staticClass: "mt-8 mb-12",
    attrs: {
      "items": _vm.releaseList,
      "loading": _vm.releaseLoading,
      "dense": "",
      "flat": "",
      "solo": "",
      "dark": "",
      "hide-details": "",
      "item-text": "version",
      "item-value": "_id",
      "label": "选择一个发行版本",
      "prepend-inner-icon": "search",
      "background-color": "primary"
    },
    on: {
      "change": function change($event) {
        return _vm.getItem(_vm.selectedItem);
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_vm._v(" v" + _vm._s(data.item.version) + " ")];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [[_c('v-list-item-icon', [_c('v-icon', [_vm._v("new_releases")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("v" + _vm._s(data.item.version))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm._f("formatDate")(data.item.publish_date)) + "发布")])], 1)]];
      }
    }]),
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  }), _c('v-card', {
    attrs: {
      "min-height": "180",
      "outlined": ""
    }
  }, [!!_vm.itemData ? _c('div', {
    staticClass: "d-flex"
  }, [_vm.itemData.publisher && _vm.itemData.publisher.personal ? _c('v-list-item', [_c('v-list-item-avatar', {
    attrs: {
      "size": "48"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.itemData.publisher.personal.profile ? _vm.ossURL + '/' + _vm.itemData.publisher.personal.profile : '/static/grey_silhouette.png',
      "aspect-ratio": "1",
      "contain": ""
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "mb-1 text-h6"
  }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.itemData.publish_date)) + "发布 ")]), _c('v-list-item-subtitle', [_vm._v(" 发布者："), _c('widgets-employee-dialogs', {
    staticClass: "v-list-item--link",
    attrs: {
      "account": _vm.itemData.publisher._id
    }
  }, [_vm._v(_vm._s(_vm.itemData.publisher.personal ? _vm.itemData.publisher.personal.name : '*未设置姓名'))])], 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _c('v-divider'), _c('div', {
    staticClass: "pa-4",
    domProps: {
      "innerHTML": _vm._s(_vm.itemData.context)
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("关闭")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }