var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('draggable', {
    staticClass: "dragArea",
    attrs: {
      "tag": "ul",
      "list": _vm.tasks,
      "group": {
        name: 'g1'
      }
    }
  }, _vm._l(_vm.tasks, function (el) {
    return _c('li', {
      key: el.name,
      on: {
        "click": function click($event) {
          return _vm.showTask(el.name);
        }
      }
    }, [_c('p', [_vm._v(_vm._s(el.name))]), _c('widgets-task-list', {
      attrs: {
        "tasks": el.tasks
      }
    })], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }