var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-title', [_vm._v(" " + _vm._s(_vm.itemData.request_number) + " "), _vm.itemData.status === 0 ? _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "secondary"
    }
  }, [_vm._v("待处理")]) : _vm.itemData.status === 1 ? _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "primary"
    }
  }, [_vm._v("处理中")]) : _vm.itemData.status === 2 ? _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("已取消")]) : _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("已完成")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }