<template>
  <div>
    <v-breadcrumbs :items="items" divider="-" transition="slide-x-reverse-transition">
      <template slot="item" slot-scope="props">
        <a @click.prevent="handleLink(props.item)">{{ props.item.meta.title }}</a>
      </template>
    </v-breadcrumbs>
  </div>
</template>
<script>
import pathToRegexp from 'path-to-regexp'
export default {
  data: () => ({
    items: null
  }),
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.name)
      const first = matched[0]
      if (first && first.name.trim().toLocaleLowerCase() !== 'Dashboard'.toLocaleLowerCase()) {
        matched = [{ path: '/dashboard', meta: { title: 'dashboard' }}].concat(matched)
      }
      this.items = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
    },
    pathCompile(path) {
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    }
  }
}
</script>
