<template>
  <div>
    <span dark text class="v-list-item--link" @click="doAction('open_case')">
      <slot></slot>
    </span>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      fullscreen
    >
      <v-card>
        <v-card-title>
          <span>{{ quoteData.title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div ref="quoteInfo" class="pt-12" style="padding-left: 160px; padding-right: 160px; min-width: 1857px;">
            <div class="mb-12">
              <v-row>
                <v-col align="center">
                  <v-img
                    src="/static/logo2x.png"
                    width="40"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center">
                  <p class="display-1">
                    {{ quoteData.enterprise + '租赁报价'}}
                  </p>
                </v-col>
              </v-row>
            </div>
            <v-expansion-panels multiple accordion flat v-model="offerPanels" disabled>
              <v-expansion-panel>
                <div ref="infoPanel">
                  <v-expansion-panel-header>
                  <span class="panelTitle">基本信息</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <table class="infoTable" cellspacing="0">
                    <thead>
                    </thead>
                    <tbody>
                    <tr class="caption">
                      <td width="7%" class="tit twiceSize" align="center">客户</td>
                      <td width="20%" align="center" class="twiceSize">{{ quoteData.enterprise }}</td>
                      <td width="7%" class="tit twiceSize" align="center">产品</td>
                      <td align="center" v-if="quoteData.product_list">
                        {{
                          quoteData.product_list.length > 1
                            ? "系列产品"
                            : (quoteData.product_list[0].product_code || quoteData.product_list[0].name)
                        }}
                      </td>
                      <td width="7%" class="tit twiceSize" align="center">租赁方式</td>
                      <td align="center" class="twiceSize">{{ quoteData.rent_type }}</td>
                      <td width="7%" class="tit twiceSize" align="center">预计起租</td>
                      <td align="center" class="twiceSize">{{ quoteData.lease_start | formatDate }}</td>
                      <td width="7%" class="tit twiceSize" align="center">销售AE</td>
                      <td align="center" class="twiceSize">{{ quoteData.sales }}</td>

                    </tr>
                    <tr>
                      <td width="7%" class="tit" align="center" rowspan="2">账期(天)</td>
                      <td align="center" rowspan="2">{{ quoteData.payment_period }}</td>
                      <td width="7%" class="tit" align="center">官网价格</td>
                      <td align="center" v-if="quoteData.product_list">
                        <span v-if="quoteData.product_list.length > 1">
                          系列产品
                        </span>
                        <span v-else>
                          ¥{{
                            quoteData.product_list[0].official_price.$numberDecimal | formatMoney
                          }}
                        </span>
                      </td>
                      <td width="7%" class="tit" align="center" rowspan="2">租期(年)</td>
                      <td align="center" rowspan="2">{{ quoteData.lease_term }}</td>
                      <td width="7%" class="tit" align="center" rowspan="2">结束时间</td>
                      <td align="center" rowspan="2" v-if="quoteData.lease_start">
                        {{
                          new Date(new Date(quoteData.lease_start).setFullYear(new Date(quoteData.lease_start).getFullYear() + quoteData.lease_term)).toISOString().substr(0, 10)
                        }}
                      </td>


                      <td width="7%" class="tit" align="center" rowspan="2">报价日期</td>
                      <td align="center" rowspan="2">{{ quoteData.createTime | formatDate }}</td>
                    </tr>
                    <tr>
                      <td class="tit" align="center">采购价格</td>
                      <td align="center" v-if="quoteData.product_list">
                        {{
                          quoteData.product_list.length > 1 ? "系列产品" : '无'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td width="7%" class="tit twiceSize" align="center">每月付款日</td>
                      <td align="center" class="twiceSize">{{ quoteData.payment_day }}</td>
                      <td width="7%" class="tit twiceSize" align="center">产品总数</td>
                      <td align="center" class="twiceSize">{{ quoteData.quantity_total }}</td>
                      <td width="7%" class="tit twiceSize" align="center">支付周期</td>
                      <td align="center" class="twiceSize">{{ quoteData.payment_cycle }}</td>
                      <td width="7%" class="tit twiceSize" align="center">总期数</td>
                      <td align="center" class="twiceSize">{{ quoteData.NPER }}</td>
                      <td width="7%" class="tit twiceSize" align="center">核价过期</td>
                      <td align="center" class="twiceSize">{{ quoteData.expiry_date | formatDate }}</td>
                    </tr>
                    </tbody>
                  </table>
                </v-expansion-panel-content>
                </div>
              </v-expansion-panel>

              <v-expansion-panel>
                <div ref="proPanel">
                  <v-expansion-panel-header>
                    <span class="panelTitle">产品列表</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      :headers="quoteHeaders"
                      :items="quoteData.product_list"
                      item-key="name"
                      class="elevation-0 mt-4"
                      hide-default-footer
                      :items-per-page="36"
                    >
                      <template v-slot:body.append>
                        <tr
                          class="primary--text"
                          style="width: 100%;"
                        >
                          <td class="body-1">合计</td>
                          <td></td>
                          <td></td>
                          <!-- <td v-if="quoteData.product_price_total">¥{{ Number(quoteData.product_price_total.$numberDecimal).toFixed(2) }}</td> -->
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="body-1">{{ quoteData.quantity_total }}</td>
                          <td class="body-1" v-if="quoteData.rent_price_sum_total">¥{{ Number(quoteData.rent_price_sum_total.$numberDecimal).toFixed(2) | formatMoney }}</td>
                          <td class="body-1" v-if="quoteData.rent_price_total_total">¥{{ Number(quoteData.rent_price_total_total.$numberDecimal).toFixed(2) | formatMoney }}</td>
                        </tr>
                      </template>
                      <template v-slot:item.order="{ item }">
                        <td nowrap="true">
                          {{
                            item.order
                          }}
                        </td>
                      </template>
                      <template v-slot:item.name="{ item }">
                        <td nowrap="true">
                          {{
                            item.name
                          }}
                        </td>
                      </template>
                      <template v-slot:item.quantity="{ item }">
                        <td nowrap="true">
                          {{
                            item.quantity
                          }}
                        </td>
                      </template>
                      <template v-slot:item.official_price="{ item }">
                        <td nowrap="true">
                          ¥{{
                            Number(item.official_price.$numberDecimal).toFixed(2) | formatMoney
                          }}
                        </td>
                      </template>
                      <template v-slot:item.isWarranty="{ item }">
                        <td nowrap="true">
                          {{
                            item.isWarranty?'是':'否'
                          }}
                        </td>
                      </template>
                      <template v-slot:item.product_price_unit="{ item }">
                        <td nowrap="true" v-if="!item.isWarranty">
                          ¥{{
                            Number(item.product_price_unit.$numberDecimal).toFixed(2) | formatMoney
                          }}
                        </td>
                        <td v-else>
                          -
                        </td>
                      </template>
                      <template v-slot:item.warranty_price_unit="{ item }">
                        <td nowrap="true">
                          ¥{{
                            Number(item.warranty_price_unit.$numberDecimal).toFixed(2) | formatMoney
                          }}
                        </td>
                      </template>
                      <template v-slot:item.rent_price_unit="{ item }">
                        <td nowrap="true" v-if="item.isWarranty">
                          ¥{{
                            Number(item.rent_price_unit.$numberDecimal).toFixed(2) | formatMoney
                          }}
                        </td>
                        <td v-else>
                          -
                        </td>
                      </template>
                      <template v-slot:item.rent_price_sum="{ item }">
                        <td nowrap="true">
                          ¥{{
                            Number(item.rent_price_sum.$numberDecimal).toFixed(2) | formatMoney
                          }}
                        </td>
                      </template>
                      <template v-slot:item.rent_price_total="{ item }">
                        <td nowrap="true">
                          ¥{{
                            Number(item.rent_price_total.$numberDecimal).toFixed(2) | formatMoney
                          }}
                        </td>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </div>
              </v-expansion-panel>

              <v-expansion-panel>
                <div ref="commentsPanel">
                  <v-expansion-panel-header>
                    <span class="panelTitle">备注</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-alert border="top" type="info" outlined>
                      <pre v-html="quoteData.comments"></pre>
                    </v-alert>
                  </v-expansion-panel-content>
                </div>
              </v-expansion-panel>

              <div :style="`height:${wm_h}px`"></div>

              <v-expansion-panel>
                <div ref="payPanel">
                  <div v-if="isPrinting" class="mb-12">
                    <v-row>
                      <v-col align="center">
                        <v-img
                          src="/static/logo2x.png"
                          width="40"
                        ></v-img>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col align="center">
                        <p class="display-1">
                          {{ quoteData.enterprise + '租赁报价'}}
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                  <v-expansion-panel-header>
                    <span class="panelTitle">付款计划表</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      :headers="payheaders"
                      :items="quoteData.payment_list"
                      item-key="name"
                      class="elevation-0 mt-4"
                      hide-default-footer
                      :items-per-page="36"
                      dense
                    >
                      <template v-slot:item.startDate="{ item }">
                        <td nowrap="true">{{ item.startDate | formatDate }}</td>
                      </template>
                      <template v-slot:item.endDate="{ item }">
                        <td nowrap="true">{{ item.endDate | formatDate }}</td>
                      </template>
                      <template v-slot:item.paymentDate="{ item }">
                        <td nowrap="true">{{ item.paymentDate | formatDate }}</td>
                      </template>
                      <template v-slot:item.paymentAmount="{ item }">
                        <td nowrap="true">
                          ¥{{
                            Number(item.paymentAmount.$numberDecimal).toFixed(2) | formatMoney
                          }}
                        </td>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </div>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="panelTitle">声明</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-alert border="top" type="info" outlined>
                    <pre v-html="quoteData.statement"></pre>
                  </v-alert>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="closeDialog">关闭</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="downQuote">下载</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  FETCH_RENT_QUOTE_SHEET,
  DOWNLOAD_RENT_QUOTE_SHEET,
} from "@/store/actions.type";
import store from "@/store";
import PDF from "@/common/pdf";
export default ({
  props: {
    getId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      offerPanels: [0, 1, 2, 3, 4],
      quoteData: {},
      payheaders: [
        { text: "期数", value: "term", class: 'body-1' },
        { text: "开始日", value: "startDate", class: 'body-1' },
        { text: "结束日", value: "endDate", class: 'body-1' },
        { text: "应付款日", value: "paymentDate", class: 'body-1' },
        { text: "应付租金", value: "paymentAmount", class: 'body-1' },
      ],
      quoteHeaders: [
        { text: "序号", value: "order", class: 'body-1' },
        { text: "产品", value: "name", class: 'body-1' },
        { text: "官网价", value: "official_price", class: 'body-1' },
        { text: "采购价", value: "purchase_price", align: ' d-none', class: 'body-1' },
        { text: "是否延保", value: "isWarranty", class: 'body-1' },
        { text: "延保单价", value: "warranty_price_unit", class: 'body-1' },
        { text: "租金单价(无延保)", value: "product_price_unit", class: 'body-1' },
        { text: "租金单价(含延保)", value: "rent_price_unit", class: 'body-1' },
        { text: "数量", value: "quantity", class: 'body-1' },
        { text: "租金", value: "rent_price_sum", class: 'body-1' },
        { text: "总租金", value: "rent_price_total", class: 'body-1' },
      ],
      wm_h: 0,
      isPrinting: false,
    }
  },
  methods: {
    doAction(action) {
      switch (action) {
        case "open_case": {
          this.getQuote(this.getId)
          this.offerPanels = [0, 1, 2, 3, 4]
          this.dialog = true
          break;
        }
      }
    },
    getQuote(id) {
      store.dispatch(FETCH_RENT_QUOTE_SHEET, { query: id }).then((data) => {
        this.quoteData = data;
      });
    },
    closeDialog() {
      this.wm_h = 0
      this.$emit('UpdateQuote')
      this.dialog = false
    },
    downQuote() {
      let pageHeight = this.$refs.quoteInfo.offsetWidth / 592.28 * 841.89
      let mHeight = this.$refs.infoPanel.offsetHeight + this.$refs.proPanel.offsetHeight + this.$refs.commentsPanel.offsetHeight
      this.wm_h = pageHeight - mHeight + 130
      this.isPrinting = true

      this.$nextTick(() => {
        this.activeDownload()
        PDF.downloadPDF(this.$refs.quoteInfo, this.quoteData.title)
        this.wm_h = 0
        this.isPrinting = false
      })
    },
    activeDownload() {
      store.dispatch(DOWNLOAD_RENT_QUOTE_SHEET, this.getId)
    },
  },
})
</script>

<style scoped>
pre {
  white-space: pre-line;

  white-space: -moz-pre-line;

  white-space: -pre-line;

  white-space: -o-pre-line;

  word-wrap: break-word;

  word-break: break-all;

  overflow: hidden;

  font-family: inherit;

  font-size: 14px;
}
.panelTitle {
  padding-left: 14px;
  border-left: 2px solid #424242;
}
.infoTable {
  width: 100%;
  border-collapse: collapse;
}
.infoTable td {
  height: 30px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: #000 !important;
}
.infoTable td.twiceSize {
  height: 60px;
}
.v-data-table td {
   font-size: 16px;
 }
/* .infoTable td.tit {
  background-color: #424242;
} */
</style>
