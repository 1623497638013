var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "v-list-item--link",
    attrs: {
      "color": _vm.color,
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('open_asset');
      }
    }
  }, [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "750"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.assetInfo.product ? _c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.assetInfo.name ? _vm.assetInfo.name : '设备信息') + " "), _vm.assetInfo.asset_type === 3 ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "warning"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v("currency_exchange")]), _vm._v("租赁设备")], 1) : _vm.assetInfo.ownerType === 'Account' ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "warning"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v("corporate_fare")]), _vm._v("个人设备")], 1) : _vm.assetInfo.ownerType === 'Enterprise' ? _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v("corporate_fare")]), _vm._v("企业自有")], 1) : _c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "secondary"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v("verified")]), _vm._v("经销商服务")], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "accordion": "",
      "flat": ""
    },
    model: {
      value: _vm.openPanel,
      callback: function callback($$v) {
        _vm.openPanel = $$v;
      },
      expression: "openPanel"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("基本信息")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("设备名称")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.name))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("设备状态")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.state ? _vm.assetInfo.state.name : _vm.assetInfo.state) + " "), _vm.assetInfo.state && _vm.assetInfo.state.name === '维修' ? _c('span', [_vm._v(" - [" + _vm._s(_vm._f("repairStatus")(_vm.assetInfo.repair_state)) + "]")]) : _vm._e()]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("产品")]), _c('v-col', {
    staticClass: "text--secondary v-list-item--link",
    attrs: {
      "cols": "10"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('open_product', _vm.assetInfo.product);
      }
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.product.name)), _c('v-icon', {
    staticClass: "mx-2"
  }, [_vm._v("read_more")])], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("设备序列号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.product.product_type.noSN ? '产品类型不需要序列号' : _vm.assetInfo.serial_number))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("设备标签")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, _vm._l(_vm.assetInfo.label, function (label, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "mx-2",
      attrs: {
        "label": "",
        "small": ""
      }
    }, [_vm._v(_vm._s(label))]);
  }), 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("外部编号")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.out_number))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("所属企业")]), _vm.assetInfo.enterprise ? _c('v-col', {
    staticClass: "text--secondary v-list-item--link",
    attrs: {
      "cols": "10"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('open_enterprise', _vm.assetInfo.enterprise);
      }
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.enterprise.name)), _c('v-icon', {
    staticClass: "mx-2"
  }, [_vm._v("read_more")]), _vm._v(" " + _vm._s(_vm.assetInfo.branch ? '-' + _vm.assetInfo.branch : ''))], 1) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("使用人员")]), _vm.assetInfo.account ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('widgets-employee-dialogs', {
    staticClass: "v-list-item--link",
    attrs: {
      "account": _vm.assetInfo.account._id
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.account.personal ? _vm.assetInfo.account.personal.name : '*未设置姓名')), _c('v-icon', {
    staticClass: "mx-2"
  }, [_vm._v("read_more")])], 1)], 1) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  })], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_vm._v("详细信息")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("品牌")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.product.vendor && _vm.assetInfo.product.vendor.name ? _vm.assetInfo.product.vendor.name : ''))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("产品类型")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.product.product_type && _vm.assetInfo.product.product_type.name ? _vm.assetInfo.product.product_type.name : ''))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("购买日期")]), _vm.assetInfo.purchase_time ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.assetInfo.purchase_time)))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("质保期限")]), _vm.assetInfo.warranty_time ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.warranty_time) + "个月")]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("质保到期")]), _vm.assetInfo.warranty_deadline ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.assetInfo.warranty_deadline)) + "（" + _vm._s(_vm._f("fromDate")(_vm.assetInfo.warranty_deadline)) + "）")]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("延保类型")]), _vm.assetInfo.guaranteed_type ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.guaranteedType.filter(function (g) {
    return _vm.assetInfo.guaranteed_type.includes(g.code);
  }).map(function (g) {
    return g.name;
  })))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("延保日期")]), _vm.assetInfo.guaranteed_time ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.assetInfo.guaranteed_time)))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("延保期限")]), _vm.assetInfo.guaranteed_limit ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.guaranteed_limit) + "个月")]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("延保到期")]), _vm.assetInfo.guaranteed_deadline ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.assetInfo.guaranteed_deadline)) + "（" + _vm._s(_vm._f("fromDate")(_vm.assetInfo.guaranteed_deadline)) + "）")]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("所在仓库")]), _vm.assetInfo.warehouse ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.warehouse.name))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("入库日期")]), _vm.assetInfo.warehouse_time ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.assetInfo.warehouse_time)))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("设备来源")]), _vm.assetInfo.asset_from ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.asset_from))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("设备情况")]), Array.isArray(_vm.assetInfo.asset_state) ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.asset_state.map(function (state) {
    return _vm.assetState.find(function (o) {
      return o.code === state;
    }).name;
  })))]) : Array.isArray(_vm.assetInfo.asset_state) ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(!isNaN(_vm.assetInfo.asset_state) ? _vm.assetState.find(function (o) {
    return o.code === _vm.assetInfo.asset_state;
  }).name : ''))]) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("SIM卡实名")]), _vm.assetInfo.sim_verified !== undefined ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.sim_verified ? '是' : '否'))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  })], 1), _vm.assetInfo.asset_type === 3 ? _c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("租赁方式")]), _vm.assetInfo.rent_info ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.rent_info.rent_type === 0 ? '融资租赁' : '经营租赁'))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("起租日期")]), _vm.assetInfo.rent_info && _vm.assetInfo.rent_info.lease_start ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.assetInfo.rent_info.lease_start)))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("租期")]), _vm.assetInfo.rent_info && _vm.assetInfo.rent_info.lease_term ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.rent_info.lease_term / 365) + " 年")]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("支付周期")]), _vm.assetInfo.rent_info ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.rent_info.payment_cycle === '0' ? '月付' : _vm.assetInfo.rent_info.payment_cycle === '1' ? '季付' : '半年付'))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("总期数")]), _vm.assetInfo.rent_info ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.rent_info.total_period))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("租赁到期")]), _vm.assetInfo.rent_info && _vm.assetInfo.rent_info.expiry_date ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.assetInfo.rent_info.expiry_date)))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("付款日")]), _vm.assetInfo.rent_info ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.rent_info.payment_day))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("客户帐期")]), _vm.assetInfo.rent_info ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.rent_info.payment_period))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("投资方")]), _vm.assetInfo.rent_info && _vm.assetInfo.rent_info.stakeholders ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.rent_info.stakeholders.investor))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("代理商")]), _vm.assetInfo.rent_info && _vm.assetInfo.rent_info.stakeholders ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.rent_info.stakeholders.agent))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("回收商")]), _vm.assetInfo.rent_info && _vm.assetInfo.rent_info.stakeholders ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.rent_info.stakeholders.recycler))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("签约方")]), _vm.assetInfo.rent_info && _vm.assetInfo.rent_info.stakeholders ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.rent_info.stakeholders.party))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("-")])], 1) : _c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("Apple ID")]), _vm.assetInfo.apple_id ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.apple_id))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("Password")]), _vm.assetInfo.apple_password ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.apple_password))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("是否ABM")]), _vm.assetInfo.isABM !== undefined ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.isABM ? '是' : '否'))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("SIM卡号")]), _vm.assetInfo.sim_code ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.sim_code))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("MAC地址")]), _vm.assetInfo.mac_address ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.mac_address))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("IMEI编号")]), _vm.assetInfo.imei_number ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.imei_number))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("ICCID串号")]), _vm.assetInfo.iccid_code ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(_vm._s(_vm.assetInfo.iccid_code))]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("备注信息")]), _vm.assetInfo.remarks ? _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.assetInfo.remarks || '无概述信息'))])]) : _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  })], 1), _c('v-row', [_vm.assetInfo.enterprise && _vm.assetInfo.enterprise.asset_field ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.assetInfo.enterprise.asset_field, function (field) {
    return _c('v-row', {
      key: field._id
    }, [_c('v-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(_vm._s(field.name))]), _c('v-col', {
      staticClass: "text--secondary",
      attrs: {
        "cols": "4"
      }
    }, [_vm._v(_vm._s(_vm.assetInfo[field._id]))])], 1);
  }), 1) : _vm._e(), _vm.assetInfo.files ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "dense": ""
    }
  }, [_c('v-subheader', [_vm._v("附件清单")]), _vm._l(_vm.assetInfo.files, function (file) {
    return _c('v-list-item', {
      key: file.href
    }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("text_snippet")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(file.name)
      }
    }), _c('v-list-item-subtitle', [_vm._v(_vm._s(file.creator.personal.name) + " - " + _vm._s(_vm._f("formatTime")(file.createTime)) + "上传")])], 1), _c('v-list-item-action', {
      staticClass: "d-inline"
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "grey lighten-1"
      },
      on: {
        "click": function click($event) {
          return _vm.doAction('download_file', '', file.href);
        }
      }
    }, [_vm._v("get_app")])], 1)], 1)], 1);
  })], 2)], 1) : _vm._e()], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_vm._v("服务请求")]), _c('v-expansion-panel-content', [_vm.assetCase && _vm.assetCase.length || _vm.repairCase && _vm.repairCase.length ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.assetCase, function (cases) {
    return _c('v-list-item', {
      key: cases._id
    }, [_c('v-list-item-content', [_vm.caseType === 'service' ? _c('v-list-item-title', [_vm._v(_vm._s(cases.catalog.name) + " - " + _vm._s(_vm._f("caseStatus")(cases.status)))]) : _c('v-list-item-title', [_vm._v(_vm._s(cases.catalog.name) + " - " + _vm._s(_vm._f("repairStatus")(cases.status)))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm._f("formatTime")(cases.createTime)) + " - " + _vm._s(cases.case_number))])], 1), _c('v-list-item-action', [_vm.caseType === 'repair' ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            path: '/dealer/workbench/maintain/list?serial_number=' + _vm.assetInfo.serial_number
          });
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "grey lighten-1"
      }
    }, [_vm._v("search")])], 1) : _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            path: '/dealer/workbench/' + _vm.caseType + '/list?serial_number=' + _vm.assetInfo.serial_number
          });
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "grey lighten-1"
      }
    }, [_vm._v("search")])], 1)], 1)], 1);
  }), _vm._l(_vm.repairCase, function (cases) {
    return _c('v-list-item', {
      key: cases._id
    }, [_c('v-list-item-content', [_vm.caseType === 'service' ? _c('v-list-item-title', [_vm._v(_vm._s(cases.catalog.name) + " - " + _vm._s(_vm._f("repairStatus")(cases.status)))]) : _c('v-list-item-title', [_vm._v(_vm._s(cases.catalog.name) + " - " + _vm._s(_vm._f("caseStatus")(cases.status)))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm._f("formatTime")(cases.createTime)) + " - " + _vm._s(cases.case_number))])], 1), _c('v-list-item-action', [_vm.caseType === 'service' ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            path: '/dealer/workbench/maintain/list?serial_number=' + _vm.assetInfo.serial_number
          });
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "grey lighten-1"
      }
    }, [_vm._v("search")])], 1) : _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            path: '/dealer/workbench/service/list?serial_number=' + _vm.assetInfo.serial_number
          });
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "grey lighten-1"
      }
    }, [_vm._v("search")])], 1)], 1)], 1);
  })], 2) : _vm._e()], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_vm._v("历史记录")]), _c('v-expansion-panel-content', [_c('v-timeline', {
    attrs: {
      "dense": "",
      "clipped": "",
      "align-top": ""
    }
  }, _vm._l(_vm.assetLogs, function (item, n) {
    return _c('v-timeline-item', {
      key: n,
      attrs: {
        "color": "grey",
        "icon-color": "grey lighten-2",
        "small": "",
        "fill-dot": "",
        "icon": item.action === 'Create' ? 'add' : item.action === 'Batch' ? 'layers' : 'create'
      }
    }, [_c('v-row', {
      staticClass: "mt-n4"
    }, [_c('v-col', {
      attrs: {
        "cols": "5"
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))]), _vm._v(" " + _vm._s(_vm._f("stringModule")(item.action)) + " "), _c('div', {
      staticClass: "caption"
    }, [_c('widgets-employee-dialogs', {
      staticClass: "v-list-item--link",
      attrs: {
        "account": item.operator._id
      }
    }, [_vm._v(_vm._s(item.operator.personal.name ? item.operator.personal.name : '*未设置姓名')), _c('v-icon', {
      staticClass: "mx-2"
    }, [_vm._v("read_more")])], 1)], 1)]), item.record.length ? _c('v-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "7"
      }
    }, _vm._l(item.record, function (change, j) {
      return _c('div', {
        key: j,
        staticClass: "caption text-left"
      }, [item.action === 'Batch' ? _c('span', [_vm._v(_vm._s(change.text) + ": " + _vm._s(change.value))]) : _c('span', [_vm._v(_vm._s(change))])]);
    }), 0) : _vm._e()], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1), _c('v-divider'), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Asset_Manage') ? _c('v-card-actions', [_c('v-subheader', [_vm._v("最后更新时间：" + _vm._s(_vm._f("formatTime")(_vm.assetInfo.updateTime)) + " ")]), _c('v-subheader', [_vm.assetInfo.transport ? _c('case-express-info', {
    attrs: {
      "id": _vm.assetInfo.transport._id,
      "from": "asset"
    }
  }, [_vm._v("物流状态")]) : _vm._e()], 1), _c('v-spacer'), _vm.assetInfo.isLock && !_vm.assetCase.find(function (item) {
    return item.status < 2;
  }) ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('unlock_asset', _vm.assetInfo);
      }
    }
  }, [_vm._v("解锁")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": _vm.assetInfo.isLock
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/dealer/asset/edit/' + _vm.assetInfo._id
        });
      }
    }
  }, [_vm._v("编辑")])], 1) : _vm._e()], 1) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "700"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'enterprise' ? _c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.doAction('close_info');
      }
    }
  }) : _vm._e(), _vm.activeType === 'product' ? _c('widgets-product-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.doAction('close_info');
      }
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }